import { useTranslation } from "react-i18next";
import { Col, Input } from "antd";
import RetailFormRow from "components/Form/RetailFormRow";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailFormInput from "components/Form/RetailFormInput";

interface NameInputProps {
  name: string;
  columnNumber: string;
  handleFieldChange(e: any): void;
}

const NameInput = ({
  name,
  columnNumber,
  handleFieldChange,
}: NameInputProps) => {
  const { t } = useTranslation();
  return (
    <RetailFormRow className="form-row">
      <RetailFormInfo
        column={{
          number: columnNumber,
          title: t("components.campaignForm.firstStep.campaignInfoTitle"),
          text: t("components.campaignForm.firstStep.campaignInfoText"),
        }}
      />
      <Col>
        <RetailFormInput
          isFocused={name !== ""}
          label={t("components.campaignForm.firstStep.campaignLabel")}
          name="name"
          rules={[{ required: true }]}
          className="floating"
          help={t("components.campaignForm.firstStep.campaignSub")}
        >
          <Input
            onChange={handleFieldChange}
            className="floating"
            name="name"
            value={name}
            data-test="campaign-form-name"
          />
        </RetailFormInput>
      </Col>
    </RetailFormRow>
  );
};

export default NameInput;
