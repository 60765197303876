import { Col, Upload, UploadProps } from "antd";
import { Trans, useTranslation } from "react-i18next";
import cc from "classcat";

import cm from "./style.module.scss";
import RetailTitle from "components/Typography/RetailTitle";
import RetailText from "components/Typography/RetailText";
import { ReactComponent as DocumentFilled } from "assets/icons/documentFilled.svg";


export interface CsvUploadDraggerProps {
    handleOnChange: (info: any) => void;
}

const CsvUploadDragger = ({ handleOnChange }: CsvUploadDraggerProps) => {
  const { t } = useTranslation();

  const props: UploadProps = {
    name: "files",
    multiple: false,
    className: cc(["dragger", cm.creativeUploadDragger]),
    beforeUpload: () => false,
    onChange: handleOnChange,
    accept: ".csv",
    maxCount: 1,
  };

  return (
    <div className={cm.uploadContainer}>
      <Col className={cm.uploadTextContainer}>
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.secondStep.uploadFileTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium">
          {t("components.campaignForm.secondStep.uploadFileSubtitle")}
        </RetailText>
      </Col>
      <Upload.Dragger {...props}>
        <div className={cm.uploadContainer}>
          <div className={cm.uploadInner}>
            <DocumentFilled className={`${cm.icon} ${cm.documentIcon}`} />
            <RetailText size="s" weight="medium" className={cm.draggerTitle}>
              <Trans i18nKey="components.campaignForm.secondStep.uploadDraggerText" />
            </RetailText>
            <RetailText
              size="xxs"
              weight="medium"
              className={cm.allowedFileText}
            >
              {t("components.campaignForm.secondStep.uploadDraggerFileType")}
            </RetailText>
          </div>
        </div>
      </Upload.Dragger>
    </div>
  );
};

export default CsvUploadDragger;
