import { OffsiteCampaignProvider } from "context/OffsiteCampaignProvider";
import RetailOffsiteCampaignForm from "components/Form/RetailOffsiteCampaignForm";

const CreateOffsiteCampaignPage = () => {
  return (
    <OffsiteCampaignProvider>
      <RetailOffsiteCampaignForm />
    </OffsiteCampaignProvider>
  );
};

export default CreateOffsiteCampaignPage;
