import Modal, { ModalProps } from "antd/lib/modal";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import cc from "classcat";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailPreVideoContainer from "components/Container/RetailPreVideoContainer";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
	Trans,
	useTranslation,
} from "react-i18next";

import cm from "./style.module.scss";

export interface CreativePreviewModalProps extends ModalProps {
  url: string;
  type: "ad" | "creative" | "video";
  name?: string;
  html?: string;
  size?: string;
}

const CreativePreviewModal = ({
  url,
  type,
  name,
  html,
  size,
  onCancel,
  ...modalProp
}: CreativePreviewModalProps) => {
  const { t } = useTranslation();

  const [width, height] = (size || "0x0").split("x").map(Number);

  const prepareHtmlWithStyles = (htmlContent: string) => {
    const styleTag = `
      <style>
       html {
         width: 100%;
         height: 100%;
      }
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }
      </style>
    `;

    // Insert the style tag into the head of the user's HTML
    if (htmlContent.includes("<head>")) {
      return htmlContent.replace("<head>", `<head>${styleTag}`);
    }

    // If <head> is missing, inject it before <body>
    if (htmlContent.includes("<body>")) {
      return htmlContent.replace("<body>", `<head>${styleTag}</head><body>`);
    }

    // As a fallback, wrap the HTML in a complete document
    return `
      <!DOCTYPE html>
      <html>
      <head>${styleTag}</head>
      <body>${htmlContent}</body>
      </html>
    `;
  };

  const switchModalContent = () => {
    if (type === "video" && url)
      return (
        <video controls={true}>
          <source src={url} type="video/mp4" />
        </video>
      );
    else if (type === "video" && !url) return <RetailPreVideoContainer />;
    else if (html)
      return (
        <iframe
          srcDoc={prepareHtmlWithStyles(html)}
          title={name}
          width={width}
          height={height}
          sandbox="allow-scripts allow-same-origin"
        />
      );
    return <img src={url} alt="" />;
  };

  return (
    <Modal
      closable
      closeIcon={<CloseOutlined />}
      centered
      onCancel={onCancel}
      className={cc(["campaign-modal", cm.modal])}
      destroyOnClose
      title={
        <RetailTitle level={4} noMargin>
          {t(`pages.admin.creatives.${type}ModalTitle`)}
        </RetailTitle>
      }
      footer={[
        <RetailMainButton
          hasBackground
          className={cm.modalBtn}
          onClick={onCancel}
        >
          {t("common.okay")}
        </RetailMainButton>,
      ]}
      {...modalProp}
    >
      <div className={`${cm.container} ${url ? cm.withURL : ""}`}>
        {switchModalContent()}
      </div>
      {name && (
        <div className={cm.creativeNameContainer}>
          <Trans>
            <RetailText size="s" weight="medium" className={cm.creativeName}>
              {t("components.campaignForm.firstStep.creativeName", {
                value: name,
              })}
            </RetailText>
          </Trans>
        </div>
      )}
    </Modal>
  );
};

export default CreativePreviewModal;
