import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TrackJS } from "trackjs";
import { Auth, AuthContext } from "context/AuthProvider";
import useApi from "api";

const useAdvertiserBalance = (enabled = true) => {
  const { api, baseURL, role, isLimitedUser } = useApi();

  const { authToken } = useContext(AuthContext) as Auth;

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(baseURL() !== undefined && authToken !== "" && enabled);
  }, [baseURL, authToken, enabled]);

  const { isLoading, error, data } = useQuery(
    "advertiser_balance",
    () => {
      const data = api.get("/balance");
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        TrackJS.track({
          message: "Get Advertiser Balance Error",
          metadata: {
            endpoint: "balance",
            role,
            isLimitedUser: `Is user limited? ${isLimitedUser}`,
            baseURL: baseURL(),
            responseBody: error?.response?.data,
            statusCode: error?.response?.status,
            errorMessage: error?.message,
            severity: "Medium",
          },
        });
      },
    }
  );

  return { isLoading, data, error };
};

export default useAdvertiserBalance;
