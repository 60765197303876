import Col from "antd/lib/col";
import Modal, { ModalProps } from "antd/lib/modal";
import Row from "antd/lib/row";
import cc from "classcat";
import { useTranslation } from "react-i18next";

import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { CampaignDetailsModalTypes } from "utils/types";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import cm from "./style.module.scss";

export interface CampaignDetailsModalProps extends ModalProps {
  type: CampaignDetailsModalTypes;
  disabled?: boolean;
  subtitle?: string;
}

const CampaignDetailsModal: React.FC<CampaignDetailsModalProps> = ({
  children,
  subtitle,
  type,
  disabled = false,
  className,
  ...modalProps
}) => {
  const { t } = useTranslation();

  const isCancelVisible = type !== "SETTINGS" && type !== "SHOW_VAST_TAG";

  return (
    <Modal
      closable
      closeIcon={<CloseOutlined />}
      centered
      title={
        <Row>
          <Col>
            <RetailTitle
              level={4}
              className={cc([cm.title, subtitle ? "" : cm.noSubtitle])}
            >
              {t(`components.modal.campaignDetails.${type.toLowerCase()}`)}
            </RetailTitle>
            {subtitle && (
              <RetailText
                size="xs"
                className={cc([
                  type === "SELECT_ADVERTISER" ? cm.advertiserText : "",
                  type === "CREATE_AGENCY" ? cm.agencyText : "",
                  cm.sub,
                ])}
                family="poppins"
              >
                {subtitle}
              </RetailText>
            )}
          </Col>
        </Row>
      }
      footer={[
        type !== "TRANSACTION_DETAILS" && (
          <Row
            className={
              type.includes("UPDATE") ||
              type.includes("CREATE") ||
              type.includes("SELECT")
                ? cm.updateBtnContainer
                : cm.btnContainer
            }
          >
            {isCancelVisible && (
              <RetailMainButton
                hasBackground={false}
                onClick={modalProps.onCancel}
                className={cc([cm.btn, cm.cancelBtn])}
              >
                {t("common.cancel")}
              </RetailMainButton>
            )}
            <RetailMainButton
              hasBackground
              onClick={modalProps.onOk}
              className={cc([cm.btn, cm.okBtn])}
              disabled={disabled}
            >
              {t(`components.modal.campaignDetails.${type.toLowerCase()}_btn`)}
            </RetailMainButton>
          </Row>
        ),
      ]}
      className={cc([
        "campaign-modal",
        cm.modal,
        type ? cm[type.toLowerCase()] : "",
        className || "",
      ])}
      destroyOnClose
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

export default CampaignDetailsModal;
