import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApi from "api";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNameColumn from "components/Column/RetailNameColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailNotification from "components/Notification";
import AdvertiserSelect from "components/Select/AdvertiserSelect";
import RetailTable from "components/Table/RetailTable";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import RetailDateColumn from "components/Column/RetailDateColumn";
import RetailOffsiteStatusColumn from "components/Column/RetailOffsiteStatusColumn";

const OffsiteCampaignsPage = () => {
  const { t } = useTranslation();

  const { adminInGeneral } = useApi();

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailOffsiteStatusColumn status={value} campaignId={records.id} />
        );
      case "name":
        return (
          <RetailNameColumn
            state={{
              breadcrumb: true,
              breadcrumbType: "offsiteCampaigns",
              value: value,
            }}
            to={
              admin
                ? `/offsite-campaign/${records.id}?adv=${records.advertiser_id}`
                : `/offsite-campaign/${records.id}`
            }
            value={`${value} (${records.id})`}
          />
        );
      case "advertiser_name":
        return (
          <RetailNameColumn
            to={`/offsite-campaigns?adv=${records.advertiser_id}`}
            value={`${value} (${records.advertiser_id})`}
          />
        );
      case "objective":
        return t(`pages.acc.offsiteCampaigns.${value}`);
      case "impressions":
      case "reach":
      case "link_click":
      case "RoAS":
      case "sale":
      case "frequency":
        return <RetailNumberColumn value={value} />;
      case "ACoS":
      case "CTR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "spend":
      case "daily_budget":
      case "sale_amount":
        return (
          <RetailMoneyColumn value={value} spendColumn={col === "spend"} />
        );
      case "meta_created_at":
        return <RetailDateColumn value={value} />;
      default:
        return value ? value : "-";
    }
  };

  const location = useLocation();

  const admin = location.pathname.includes("admin");

  let navigate = useNavigate();

  const [adminVisible, setAdminVisible] = useState(false);

  const [advertiser, setAdvertiser] = useState<any>(null);

  const tableConfig = {
    url: "meta/campaigns",
    isRelation: false,
    activeKey: "ACTIVE",
    to: "offsite-campaign",
    state: true,
    renderColumns,
  };

  const activeTabConfig = {
    ...tableConfig,
    defaultFilter: [{ key: "campaignStatus", op: "ne", value: "ARCHIVED" }],
  };

  useEffect(() => setAdvertiser(location.state), [location.state]);

  const handleAdvertiserNavigate = () =>
    navigate(
      { pathname: "/create-offsite-campaign", search: location.search },
      {
        state: {
          advertiser: advertiser,
        },
      }
    );

  const handleClick = () =>
    admin ? setAdminVisible(true) : handleAdvertiserNavigate();

  const handleNavigateAdmin = () => {
    if (advertiser !== null) {
      navigate("/admin/create-offsite-campaign", {
        state: {
          id: advertiser.id,
          name: advertiser.name,
        },
      });
    } else {
      RetailNotification.showNotification(
        "error",
        t("pages.acc.offsiteCampaigns.errorTitle"),
        t("pages.acc.offsiteCampaigns.errorText")
      );
    }
  };

  const closeAdminModal = () => {
    setAdminVisible(false);
    setAdvertiser(null);
  };

  const handleAdvertiser = (item: any) => setAdvertiser(item);

  const handleAdvertiserSelect = () => {
    if (!advertiser) {
      RetailNotification.showNotification(
        "error",
        t("pages.acc.offsiteCampaigns.errorTitle"),
        t("pages.acc.offsiteCampaigns.errorText")
      );
      return;
    }
    setAdminVisible(false);
    handleNavigateAdmin();
  };

  return (
    <RetailPageContainer>
      <div className="bordered-container no-margin">
        <RetailTable
          tableConfig={activeTabConfig}
          placeholder={t("pages.acc.offsiteCampaigns.placeholder")}
          button={{
            title: t("pages.acc.offsiteCampaigns.button"),
            onClick: handleClick,
          }}
          rowSelection={undefined}
        />
      </div>
      {adminInGeneral && (
        <CampaignDetailsModal
          type="SELECT_ADVERTISER"
          subtitle={t("pages.admin.advertisers.select_advertiser_subtitle")}
          visible={adminVisible}
          onCancel={closeAdminModal}
          onOk={handleAdvertiserSelect}
        >
          <AdvertiserSelect type="CAMPAIGN" onClick={handleAdvertiser} />
        </CampaignDetailsModal>
      )}
    </RetailPageContainer>
  );
};

export default OffsiteCampaignsPage;
