import Switch, { SwitchProps } from "antd/lib/switch";
import { Relations } from "utils/types";

import useTableStatusChange from "hooks/useTableStatusChange";
import { useCallback } from "react";

export interface RetailStatusColumnProps extends SwitchProps {
  records: any;
  url: string;
  relationType?: Relations;
}

const RetailStatusColumn = ({
  records,
  url,
  relationType,
  ...switchProps
}: RetailStatusColumnProps) => {
  const { changeValue, isLoading } = useTableStatusChange(
    url,
    relationType !== undefined,
    relationType
  );

  const handleChange = useCallback(() => {
    changeValue(records);
  }, [changeValue, records]);

  return (
    <Switch
      data-cy="switch"
      defaultChecked={
        records.status === "ACTIVE" || records.account_user_status === "ACTIVE"
      }
      checked={
        records.status === "ACTIVE" || records.account_user_status === "ACTIVE"
      }
      loading={isLoading}
      disabled={isLoading}
      onChange={handleChange}
      {...switchProps}
    />
  );
};

export default RetailStatusColumn;
