import { useTranslation } from "react-i18next";
import { Checkbox, Radio, Select } from "antd";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";

import RetailSelect from "components/Select/RetailSelect";
import RetailSuggestedTag from "components/Tag/RetailSuggestedTag";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";
interface MultiRegionProps {
  page: "form" | "details";
  regions: any[];
  selectedRegions: number[];
  multiRegion: string;
  error: boolean;
  handleFieldChange(e: any): void;
  setSelectedRegions(e: any): void;
}

const MultiRegion = ({
  page,
  regions,
  selectedRegions,
  multiRegion,
  error,
  handleFieldChange,
  setSelectedRegions,
}: MultiRegionProps) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language.toUpperCase();

  const isError =
    error && multiRegion === "SELECTED_REGIONS" && selectedRegions.length === 0;

  const handleChange = (value: number[]) => {
    setSelectedRegions(value);
  };

  return (
    <section className={cc([cm.col, "flex", cm[page]])}>
      {page === "form" && (
        <div>
          <RetailTitle className={cm.title}>
            {t("components.campaignForm.firstStep.multiRegion")}
          </RetailTitle>
          <RetailText className={cm.text} size="xs" family="poppins">
            {t("components.campaignForm.firstStep.multiRegionText")}
          </RetailText>
        </div>
      )}

      <Radio.Group
        name="multiRegion"
        onChange={handleFieldChange}
        className={cm.radioGroup}
        value={multiRegion}
      >
        <Radio
          value="ALL_REGIONS"
          className={cc(["form-radio", cm.radio, cm[page]])}
          data-test="campaign-form-targeting-auto"
        >
          <RetailTitle level={5} className="flex">
            {t("components.campaignForm.firstStep.allRegions")}
            <RetailSuggestedTag className={cm.suggestedTag} />
          </RetailTitle>
          <RetailText size="xxxs" weight="medium" className={cm.radioText}>
            {t("components.campaignForm.firstStep.allRegionsText")}
          </RetailText>
        </Radio>
        <Radio
          value="SELECTED_REGIONS"
          className={cc(["form-radio", cm.radio, isError ? cm.error : ""])}
          data-test="campaign-form-targeting-auto"
        >
          <RetailTitle level={5}>
            {t("components.campaignForm.firstStep.selectedRegions")}
          </RetailTitle>
          <RetailText size="xxxs" weight="medium" className={cm.radioText}>
            {t("components.campaignForm.firstStep.selectedRegionsText")}
          </RetailText>
          {multiRegion === "SELECTED_REGIONS" && (
            <RetailSelect
              value={selectedRegions}
              mode="multiple"
              onChange={handleChange}
              placeholder={t(
                "components.campaignForm.firstStep.regionPlaceholder"
              )}
              optionFilterProp="label"
              className={cm.select}
              menuItemSelectedIcon={false}
              onClick={(e) => e.preventDefault()}
              tagRender={(props) => {
                const { label, onClose } = props;
                return (
                  <div className={cm.tag}>
                    <RetailText size="xxxs" family="poppins" weight="medium">
                      {label}
                    </RetailText>
                    <div onClick={onClose} className="flex">
                      <CloseOutlined className={cm.closeIcon} />
                    </div>
                  </div>
                );
              }}
            >
              {regions?.map((region) => {
                const regionLabel =
                  region.translations[language] ||
                  Object.values(region.translations)[0];

                return (
                  <Select.Option
                    key={region.id}
                    value={region.id}
                    label={regionLabel}
                    className={cm.option}
                  >
                    <Checkbox
                      value={region.id}
                      className={cm.checkbox}
                      checked={selectedRegions?.includes(region.id)}
                    />
                    <span>{regionLabel}</span>
                  </Select.Option>
                );
              })}
            </RetailSelect>
          )}
        </Radio>
      </Radio.Group>
    </section>
  );
};

export default MultiRegion;
