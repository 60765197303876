import Select from "antd/lib/select";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
	Category,
	CategoryContext,
} from "../../../context/CategoryProvider";
import {
	Keyword,
	KeywordContext,
} from "../../../context/KeywordProvider";
import RetailMainButton from "../../Button/RetailMainButton";
import RetailSelect from "../../Select/RetailSelect";
import RetailFormTable from "../RetailFormTable";
import cm from "./style.module.scss";

const { Option } = Select;

export interface SelectNegativeTableProps {
  type: "NEGATIVE_KEYWORDS" | "NEGATIVE_CATEGORIES";
  alreadySelectedData?: any;
}

const SelectNegativeTable = ({
  type,
  alreadySelectedData,
}: SelectNegativeTableProps) => {
  const { t } = useTranslation();

  const { deleteNegativeKeyword } = useContext(KeywordContext) as Keyword;
  const { deleteNegativeCategory } = useContext(CategoryContext) as Category;

  const negativeKeywordsColumns = [
    {
      title: t("components.table.keywords.keyword"),
      dataIndex: "text",
    },
    {
      title: t("components.table.keywords.matchType"),
      dataIndex: "match_type",
      width: 200,
      render: (value: "EXACT" | "PHRASE" | "BROAD", records: any) => (
        <RetailSelect
          defaultValue={value}
          className={cm.select}
          onChange={(value) => {
            records.match_type = value;
          }}
        >
          <Option value="EXACT">{t("common.exact")}</Option>
          <Option value="PHRASE">{t("common.phrase")}</Option>
          <Option value="BROAD">{t("common.broad")}</Option>
        </RetailSelect>
      ),
    },
    {
      title: t("components.table.keywords.volume"),
      dataIndex: "volume",
    },
    //Low variable in localization is changed.
    /* {
      title: t("components.table.keywords.competition"),
      dataIndex: "competition",
      render: (value: string) => (
        <RetailTableTag mode="green">
          <RetailText family="poppins" weight="bold" size="xxs">
            {t(`components.table.keywords.low`)}
          </RetailText>
        </RetailTableTag>
      ),
    }, */
    {
      key: 7,
      title: "",
      dataIndex: "action",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          onClick={() => deleteNegativeKeyword(records.id)}
          className={cm.btn}
          data-test={`campaign-form-remove-${type.toLowerCase()}`}
        >
          {t("common.remove")}
        </RetailMainButton>
      ),
    },
  ];

  const negativeCategoriesColumns = [
    {
      title: t("common.table.category"),
      dataIndex: "text",
    },
    {
      title: t("common.table.category_volume"),
      dataIndex: "volume",
    },
    //Low variable in localization is changed.
    /* {
      title: t("components.table.keywords.competition"),
      dataIndex: "competition",
      render: (value: string) => (
        <RetailTableTag mode="green">
          <RetailText family="poppins" weight="bold" size="xxs">
            {t(`components.table.keywords.low`)}
          </RetailText>
        </RetailTableTag>
      ),
    }, */
    {
      key: 7,
      title: "",
      dataIndex: "action",
      render: (value: any, records: any) => (
        <RetailMainButton
          hasBackground={false}
          onClick={() =>
            type === "NEGATIVE_KEYWORDS"
              ? deleteNegativeKeyword(records.key)
              : deleteNegativeCategory(records.id)
          }
          className={cm.btn}
          data-test={`campaign-form-remove-${type.toLowerCase()}`}
        >
          {t("common.remove")}
        </RetailMainButton>
      ),
    },
  ];

  return (
    <>
      <RetailFormTable
        error={false}
        alreadySelectedData={alreadySelectedData}
        type={type}
        columns={
          type === "NEGATIVE_KEYWORDS"
            ? negativeKeywordsColumns
            : negativeCategoriesColumns
        }
      />
    </>
  );
};

export default SelectNegativeTable;
