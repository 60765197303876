import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useApi from "api";
import RetailInfoBar from "components/Bar/RetailInfoBar";
import RetailCampaignCardContainer from "components/Card/RetailCampaignWidget";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import RetailCampaignTable from "components/Table/RetailCampaignTable";
import { Auth, AuthContext } from "context/AuthProvider";

const CampaignsPage = () => {
  const { adminInGeneral } = useApi();

  const { authInfo, advValue, accountIndex, getUser } = useContext(
    AuthContext
  ) as Auth;
  const navigate = useNavigate();

  const page = adminInGeneral ? "marketplace" : "campaigns";

  const role = authInfo !== null ? authInfo[accountIndex]?.account_type : "";

  const sso = decodeURIComponent(window.location.href).split("token=")[1];

  useEffect(() => {
    if (sso) {
      localStorage.removeItem("auth_token");
      getUser(sso);
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role === "MARKETPLACE" && !advValue) navigate("/admin/campaigns");
  }, [role, advValue, navigate]);

  return (
    <RetailPageContainer>
      {!adminInGeneral && <RetailInfoBar type="campaign" />}
      <RetailCampaignCardContainer page={page} />
      <RetailCampaignTable />
    </RetailPageContainer>
  );
};

export default CampaignsPage;
