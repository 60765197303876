import useApi from "api";
import { TrackJS } from "trackjs";
import { Auth, AuthContext } from "context/AuthProvider";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

const useConfigSchema = (enabled: boolean, id = null, queryFilter = "") => {
  const { api, baseURL, roleForDashboard, role, isLimitedUser } = useApi();

  const { authToken } = useContext(AuthContext) as Auth;

  const [isEnabled, setIsEnabled] = useState(false);

  const marketplaceRequiresAdvertiserDataAccess =
    id && roleForDashboard === "MARKETPLACE";

  const url = marketplaceRequiresAdvertiserDataAccess
    ? `/advertisers/${id}/configs?group=${queryFilter}`
    : `/configs?group=${queryFilter}`;

  useEffect(() => {
    setIsEnabled(baseURL() !== undefined && authToken !== "" && enabled);
  }, [baseURL, authToken, enabled]);

  const { isLoading, error, data } = useQuery(
    ["configSchema", queryFilter],
    () => {
      const data = api.get(url);
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        TrackJS.track({
          message: `Config Schema Fetch Error For ${queryFilter}`,
          metadata: {
            endpoint: url,
            role,
            isLimitedUser: `Is user limited? ${isLimitedUser}`,
            baseURL: baseURL(),
            responseBody: error?.response?.data,
            statusCode: error?.response?.status,
            errorMessage: error?.message,
            severity: "High",
          },
        });
      },
    }
  );

  return { isLoading, data, error };
};

export default useConfigSchema;
