export const ACCEPTED_FILE_TYPES = {
  VIDEO: ".mp4",
  IMAGE: ".jpg, .svg, .jpeg, .png, .gif",
};

export const UPLOAD_FORMATS = {
  GIF: "GIF",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
};

export const DISPLAY_CONTEXT = {
  FORM: "FORM",
  DRAWER: "DRAWER",
};
