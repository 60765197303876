import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrency } from "context/CurrencyProvider";
import { Product, ProductContext } from "context/ProductProvider";
import {
  OffsiteCampaignContext,
  OffsiteCampaignContextType,
} from "context/OffsiteCampaignProvider";
import { validateNumber } from "utils/helpers";
import { ReactComponent as PencilOutlined } from "assets/icons/pencilOutlined.svg";
import RetailDrawer from "components/Drawer/RetailDrawer";
import SponsoredProducts from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/SponsoredProducts";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailFormRow from "components/Form/RetailFormRow";
import Input from "antd/lib/input";
import RetailText from "components/Typography/RetailText";

import cc from "classcat";
import cm from "./style.module.scss";
import RetailTitle from "components/Typography/RetailTitle";

interface RetailOffsiteCampaignFormFirstStepProps {
  advertiser: {
    id: number;
    name: string;
  };
  form: any;
}

const RetailOffsiteCampaignFormFirstStep = ({
  advertiser,
  form,
}: RetailOffsiteCampaignFormFirstStepProps) => {
  const { t } = useTranslation();

  const { currencySymbol } = useCurrency();

  const [editDrawerVisible, setEditDrawerVisible] = useState(false);

  const { selectedProducts } = useContext(ProductContext) as Product;

  const { formFields, setFormFields, handleFormFieldsChange, productError } =
    useContext(OffsiteCampaignContext) as OffsiteCampaignContextType;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    validateNumber(e);
  };

  useEffect(() => {
    const placeholderPrimaryText =
      selectedProducts[0]?.brand && selectedProducts[0]?.name
        ? `${selectedProducts[0]?.brand || ""} ${
            selectedProducts[0]?.name || ""
          }`
        : "-";

    setFormFields({
      ...formFields,
      adName: selectedProducts[0]?.name,
      primaryText: placeholderPrimaryText,
      description: selectedProducts[0]?.description,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  const disableDrawerOkButton =
    formFields.adName === "" || formFields.primaryText === "";

  return (
    <>
      <SponsoredProducts
        error={productError}
        isBidColonVisible={false}
        ad_type={"PRODUCT"}
        advertiserStateForLinks={advertiser}
        targeting={""}
        isOffsite={true}
      />

      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: "2",
            title: t("components.offsiteCampaignForm.firstStep.budget"),
            text: t("components.offsiteCampaignForm.firstStep.budgetText"),
          }}
        />

        <RetailFormInput
          isFocused={formFields.budget !== ""}
          label={t("components.offsiteCampaignForm.firstStep.budgetLabel")}
          className={cc([
            "floating inside show-message",
            formFields.budget === "" ? cm.hidePrefix : "",
          ])}
          name="daily_budget"
          rules={[
            {
              required: true,
              message: t(
                "components.offsiteCampaignForm.firstStep.budgetError"
              ),
            },
          ]}
        >
          <Input
            className="number-input floating"
            prefix={currencySymbol}
            onChange={(e) => handleFormFieldsChange("budget", e.target.value)}
            value={formFields.budget !== "" ? formFields.budget : ""}
            onKeyDownCapture={handleKeyDown}
          />
        </RetailFormInput>
      </RetailFormRow>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: "3",
            title: t("components.offsiteCampaignForm.firstStep.urlTitle"),
            text: t("components.offsiteCampaignForm.firstStep.urlText"),
          }}
        />

        <RetailFormInput
          isFocused={formFields.redirectUrl !== ""}
          name="url"
          label={t("components.offsiteCampaignForm.firstStep.urlTitle")}
          rules={[
            {
              required: true,
              message: t("components.offsiteCampaignForm.firstStep.urlError"),
              pattern:
                /^https:\/\/([\w\u0600-\u06FF-]+\.)*[\w\u0600-\u06FF-]{2,}\.[a-z\u0600-\u06FF]{2,}(\/[\w\u0600-\u06FF-.,@?^=%&:/~+#]*)?$/,
            },
          ]}
          className="floating"
        >
          <Input.TextArea
            className={cc(["floating", cm.textarea])}
            onChange={(e) =>
              handleFormFieldsChange("redirectUrl", e.target.value)
            }
            name="redirect_url"
          />
        </RetailFormInput>
      </RetailFormRow>
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number: "4",
            title: t("components.offsiteCampaignForm.firstStep.adPreview"),
            text: t("components.offsiteCampaignForm.firstStep.adPreviewText"),
          }}
        />
        <section className={cm.previewContainer}>
          <article className={cm.previewCard}>
            <div className={cm.previewHeader}>
              <RetailText weight="bold" size="xxxs" className={cm.previewTitle}>
                {formFields.adName ||
                  t(
                    "components.offsiteCampaignForm.firstStep.pleaseSelectProduct"
                  )}
              </RetailText>
            </div>

            <div className={cm.fieldWithImage}>
              <div className={cm.fieldLabelContainer}>
                <RetailText
                  weight="medium"
                  size="xxxs"
                  className={cm.fieldLabel}
                >
                  {`${t(
                    "components.offsiteCampaignForm.firstStep.productName"
                  )}:`}
                </RetailText>
                <RetailText
                  weight="medium"
                  size="xxxs"
                  className={cm.fieldValue}
                >
                  {selectedProducts[0]?.name || "-"}
                </RetailText>
              </div>
              {selectedProducts[0]?.image_url && (
                <img
                  src={selectedProducts[0].image_url}
                  alt="Product preview"
                  className={cm.thumbnailImage}
                />
              )}
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {`${t(
                  "components.offsiteCampaignForm.firstStep.destination"
                )}:`}
              </RetailText>
              <a
                href={formFields.redirectUrl}
                target="_blank"
                rel="noreferrer"
                className={cm.fieldLink}
                onClick={(e) =>
                  formFields.redirectUrl === "" && e.preventDefault()
                }
              >
                {formFields.redirectUrl || "-"}
              </a>
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {`${t(
                  "components.offsiteCampaignForm.firstStep.displayLink"
                )}:`}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.fieldValue}>
                {selectedProducts[0]?.name || "-"}
              </RetailText>
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {`${t(
                  "components.offsiteCampaignForm.firstStep.primaryText"
                )}:`}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.fieldValue}>
                {formFields.primaryText || "-"}
              </RetailText>
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {`${t("components.offsiteCampaignForm.firstStep.headline")}:`}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.fieldValue}>
                {formFields.headline || "-"}
              </RetailText>
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {`${t(
                  "components.offsiteCampaignForm.firstStep.description"
                )}:`}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.fieldValue}>
                {formFields.description || "-"}
              </RetailText>
            </div>

            <div className={cm.field}>
              <RetailText weight="medium" size="xxxs" className={cm.fieldLabel}>
                {t("components.offsiteCampaignForm.firstStep.cta")}
              </RetailText>
              <RetailText size="xxxs" weight="medium" className={cm.fieldValue}>
                {t("components.offsiteCampaignForm.firstStep.fixedCta")}
              </RetailText>
            </div>
            <div className={cm.previewFooter}>
              {selectedProducts?.length > 0 && (
                <button
                  className={cm.editButton}
                  type="button"
                  onClick={() => setEditDrawerVisible(true)}
                >
                  <PencilOutlined />
                </button>
              )}
            </div>
          </article>
        </section>
      </RetailFormRow>
      <RetailDrawer
        title={t("components.offsiteCampaignForm.firstStep.editAd")}
        visible={editDrawerVisible}
        onClose={() => setEditDrawerVisible(false)}
        onOk={() => setEditDrawerVisible(false)}
        width={700}
        disableOk={disableDrawerOkButton}
      >
        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("components.offsiteCampaignForm.firstStep.adName")}
        </RetailTitle>
        <RetailText className={cm.drawerText} size="xxxs">
          {t("components.offsiteCampaignForm.firstStep.adNameText")}
        </RetailText>
        <RetailFormInput
          isFocused={formFields.adName !== ""}
          label={t("components.offsiteCampaignForm.firstStep.adName")}
          className="floating"
        >
          <Input
            className={cc(["floating", cm.drawerInput])}
            onChange={(e) => handleFormFieldsChange("adName", e.target.value)}
            value={formFields.adName}
          />
        </RetailFormInput>
        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("components.offsiteCampaignForm.firstStep.primaryText")}
        </RetailTitle>
        <RetailText className={cm.drawerText} size="xxxs">
          {t("components.offsiteCampaignForm.firstStep.primaryTextText")}
        </RetailText>
        <RetailFormInput
          isFocused={formFields.primaryText !== ""}
          label={t("components.offsiteCampaignForm.firstStep.primaryText")}
          className="floating"
        >
          <Input
            className={cc(["floating", cm.drawerInput])}
            onChange={(e) =>
              handleFormFieldsChange("primaryText", e.target.value)
            }
            value={formFields.primaryText}
          />
        </RetailFormInput>

        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("components.offsiteCampaignForm.firstStep.headline")}
        </RetailTitle>
        <RetailText className={cm.drawerText} size="xxxs">
          {t("components.offsiteCampaignForm.firstStep.headlineText")}
        </RetailText>
        <RetailFormInput
          isFocused={formFields.headline !== ""}
          label={t("components.offsiteCampaignForm.firstStep.headline")}
          className="floating"
        >
          <Input
            className={cc(["floating", cm.drawerInput])}
            onChange={(e) => handleFormFieldsChange("headline", e.target.value)}
            value={formFields.headline}
          />
        </RetailFormInput>

        <RetailTitle level={5} className={cm.drawerTitle}>
          {t("components.offsiteCampaignForm.firstStep.description")}
        </RetailTitle>
        <RetailText className={cm.drawerText} size="xxxs">
          {t("components.offsiteCampaignForm.firstStep.descriptionText")}
        </RetailText>
        <RetailFormInput
          isFocused={formFields.description !== ""}
          label={t("components.offsiteCampaignForm.firstStep.description")}
          className="floating"
        >
          <Input
            className={cc(["floating", cm.drawerInput])}
            onChange={(e) =>
              handleFormFieldsChange("description", e.target.value)
            }
            value={formFields.description}
          />
        </RetailFormInput>
      </RetailDrawer>
    </>
  );
};

export default RetailOffsiteCampaignFormFirstStep;
