import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import Layout, { Content } from "antd/lib/layout/layout";
import { Auth, AuthContext } from "context/AuthProvider";
import { marketplaceName } from "utils/defaults";
import { CurrencyProvider } from "context/CurrencyProvider";
import { TeknosaCaseStudyTemplate } from "pages/auth/Teknosa/TeknosCaseStudyTemplate";
import useApi from "api";
import useConfigSchema from "hooks/useConfigSchema";
import RetailAdminRoutes from "components/Routes/RetailAdminRoutes";
import RetailUserRoutes from "components/Routes/RetailUserRoutes";
import AccountsPage from "pages/auth/AccountsPage";
import ForgetPasswordPage from "pages/auth/ForgetPasswordPage";
import LoginPage from "pages/auth/LoginPage";
import NewPasswordPage from "pages/auth/NewPasswordPage";
import OnboardingAPIPage from "pages/auth/OnboardingAPIPage";
import OnboardingPage from "pages/auth/OnboardingPage";
import SignUpPage from "pages/auth/SignUpPage";
import TeknosaCaseStudy from "pages/auth/Teknosa/TeknosaCaseStudy";
import TeknosaLanding from "pages/auth/Teknosa/TeknosaLanding";
import WelcomePage from "pages/auth/WelcomePage";
import RetailPageContainer from "../RetailPageContainer";
import RetailPageNavbar from "../RetailNavbar/RetailPageNavbar";
import RetailSider from "../RetailSider";

import cm from "./style.module.scss";

export interface RetailDashboardProps {
  steps: boolean;
  adminRoute: boolean;
  authInfo: any;
  navToOnboard: () => void;
}

const RetailDashboard = ({
  steps,
  adminRoute,
  authInfo,
  navToOnboard,
}: RetailDashboardProps) => {
  const { data: config, isLoading } = useConfigSchema(
    true,
    undefined,
    "channel_availability"
  );

  const isOffsiteAdsEnabled = config?.data?.configs?.some(
    (item: any) =>
      item.config_name === "default_offsite_ads" && item.value === true
  );

  useEffect(() => {
    authInfo && navToOnboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]);

  return (
    <CurrencyProvider>
      <Layout className={cm.layout}>
        {!steps && <RetailPageNavbar />}
        {!steps && (
          <RetailSider
            adminSider={adminRoute}
            isOffsiteAdsEnabled={isOffsiteAdsEnabled}
          />
        )}
        <Content className={cm.content}>
          {isLoading ? (
            <RetailPageContainer>
              <Spin
                spinning={true}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </RetailPageContainer>
          ) : (
            <>
              {adminRoute ? (
                <RetailAdminRoutes isOffsiteAdsEnabled={isOffsiteAdsEnabled} />
              ) : (
                <RetailUserRoutes isOffsiteAdsEnabled={isOffsiteAdsEnabled} />
              )}
            </>
          )}
        </Content>
      </Layout>
    </CurrencyProvider>
  );
};

const RetailLayout = () => {
  const { authToken, authInfo, onboardingRedirect } = useContext(
    AuthContext
  ) as Auth;

  const { role } = useApi();

  const { pathname } = useLocation();

  const account_index = JSON.parse(
    localStorage.getItem("account_index") || "0"
  );

  //Auth role can't be used because a marketplace can go to advertiser pages.
  const adminRoute = pathname.includes("admin") && role === "MARKETPLACE";

  const stepsRoutes = [
    "create-campaign",
    "create-report",
    "edit-campaign",
    "edit-report",
    "create-datasource",
    "create-offsite-campaign",
  ];

  //Check if a page should be rendered with a navbar and sider or not
  const steps = stepsRoutes.some((route) => pathname.includes(route));

  const navToOnboard = () => {
    const { onboarding_status, resource_id } = authInfo[account_index] || {};

    if (!onboarding_status) {
      console.warn("onboarding_status is undefined or missing");
      return;
    }

    switch (onboarding_status) {
      case "NO_CAMPAIGN":
      case "WAITING_FOR_TRAFFIC":
        return onboardingRedirect(onboarding_status, resource_id);
      default:
        break;
    }
  };

  const retailRoutesRender = () => {
    // Remove query parameters
    const routePath = pathname.split("?")[0];
    //Render Teknosa Case Study Page
    if (
      marketplaceName === "Teknosa" &&
      (pathname === "/case-study" || pathname === "/case-study/")
    )
      return <TeknosaCaseStudy />;
    //Render Teknosa Case Study Template Page
    if (marketplaceName === "Teknosa" && pathname.includes("/case-study/"))
      return <TeknosaCaseStudyTemplate />;
    //Render Teknosa Landing Page
    if (marketplaceName === "Teknosa" && !authToken && routePath === "/")
      return <TeknosaLanding />;
    //Render auth pages
    else {
      switch (routePath) {
        case "/welcome":
          return <WelcomePage />;
        case "/signup":
          return <SignUpPage />;
        case "/login":
          return <LoginPage />;
        case "/forget-password":
          return <ForgetPasswordPage />;
        case "/new-password":
          return <NewPasswordPage />;
        case "/accounts":
          return <AccountsPage />;
        case "/onboarding":
          return <OnboardingPage />;
        case "/onboarding/linkedin/callback":
          return <OnboardingAPIPage />;
        default:
          return (
            <RetailDashboard
              steps={steps}
              adminRoute={adminRoute}
              authInfo={authInfo}
              navToOnboard={navToOnboard}
            />
          );
      }
    }
  };

  /*  
  };

  useEffect(() => {
    authInfo && navToOnboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo]); */

  return retailRoutesRender();
};

export default RetailLayout;
