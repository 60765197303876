import useApi from "api";
import useSettings from "hooks/useSettings";
import { formatDateWithTimezone } from "utils/helpers";

export interface RetailDateColumnProps {
  value: string;
}

const RetailDateColumn = ({ value }: RetailDateColumnProps) => {
  const { roleForDashboard } = useApi();

  const { data } = useSettings(roleForDashboard);

  const emptyValue =
    !value ||
    value === "0001-01-01" ||
    value === "0001-01-01T00:00:00.000Z" ||
    value === "not set" ||
    value === "NaT";

  return (
    <>
      {emptyValue
        ? "-"
        : formatDateWithTimezone(value, data?.data?.time_zone || "UTC")}
    </>
  );
};

export default RetailDateColumn;
