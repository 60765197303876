import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TFunction, useTranslation } from "react-i18next";
import { TrackJS } from "trackjs";
import { AuthContext, Auth } from "context/AuthProvider";
import axios from "axios";
import useApi from "api";
import RetailNotification from "components/Notification";

interface CampaignUpdate {
  campaign?: {
    name?: string;
    status?: string;
    [key: string]: any;
  };
  ad_set?: {
    daily_budget?: string;
    [key: string]: any;
  };
  [key: string]: any;
}

const useTaskStatus = (
  taskId: string | null,
  authToken: string | null,
  onTaskSuccess: () => void,
  t: TFunction
) => {
  return useQuery(
    ["taskStatus", taskId],
    async () => {
      if (!taskId || !authToken) return null;

      const result = await axios.get(`/offsite/api/tasks/${taskId}/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return result.data;
    },
    {
      enabled: !!taskId && !!authToken,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      refetchInterval: (data) => {
        if (data?.state === "PENDING") return 2000;

        if (data?.state === "SUCCESS") {
          onTaskSuccess?.();
          return false;
        }

        return false;
      },

      onError: (error) => {
        RetailNotification.showNotification(
          "error",
          "",
          t("pages.acc.offsiteDetails.updateError")
        );
        console.error("Error fetching task status:", error);
      },
    }
  );
};

export const useOffsiteCampaignMutation = (
  id: string,
  isTableUpdate = false
) => {
  const { t } = useTranslation();

  const { authToken } = useContext(AuthContext) as Auth;

  const { api, role, baseURL, isLimitedUser } = useApi(true);

  const queryClient = useQueryClient();

  const [currentTaskId, setCurrentTaskId] = useState<string | null>(null);

  const taskStatusSuccess = () => {
    const queryKey = isTableUpdate
      ? ["table", { url: "meta/campaigns" }]
      : ["single_meta_campaign", id.toString()];

    queryClient.invalidateQueries(queryKey);
    RetailNotification.showNotification(
      "success",
      "",
      t("pages.acc.offsiteDetails.updateSuccess")
    );
    setCurrentTaskId(null);
  };

  const { data: taskStatus, isLoading: isTaskStatusLoading } = useTaskStatus(
    currentTaskId,
    authToken,
    taskStatusSuccess,
    t
  );

  const updateValue = async (value: CampaignUpdate) => {
    try {
      const response = await api.patch(`meta/campaigns/${id}/`, value);
      if (response?.data?.task_id) setCurrentTaskId(response.data.task_id);
      return response.data;
    } catch (error) {
      TrackJS.track({
        message: "Meta Campaign Patch Error",
        metadata: {
          endpoint: `meta/campaigns/${id}`,
          requestBody: value,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error.response?.data,
          statusCode: error.response?.status,
          errorMessage: error.message,
          severity: "Medium",
        },
      });
    }
  };

  const { mutateAsync, isLoading: isMutating } = useMutation(updateValue);

  const changeValue = async (value: CampaignUpdate) => {
    if (currentTaskId) setCurrentTaskId(null);

    try {
      await mutateAsync(value);
    } catch (error) {
      setCurrentTaskId(null);
    }
  };

  return {
    changeValue,
    isMutationLoading:
      isMutating || isTaskStatusLoading || taskStatus?.state === "PENDING",
    taskStatus,
    isTaskStatusLoading,
  };
};
