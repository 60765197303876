import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Menu from "antd/lib/menu";
import Tooltip from "antd/lib/tooltip";
import { ReactComponent as ChatOutlined } from "assets/icons/chatOutlined.svg";
import { Auth, AuthContext } from "context/AuthProvider";
import { retailUserRouteObject } from "./RetailUserRouteObject";
import { SMOContext, SMOContextType } from "context/SMOProvider";
import FilterContext from "context/FilterProvider";
import useSettings from "hooks/useSettings";
import RetailAdvertiserViewLink from "components/Link/RetailAdvertiserViewLink";
import RetailTooltip from "components/Tooltip/RetailTooltip";

import cc from "classcat";
import cm from "../style.module.scss";

const RetailUserSider = ({
  isOffsiteAdsEnabled,
}: {
  isOffsiteAdsEnabled: boolean;
}) => {
  const { t } = useTranslation();

  const { data } = useSettings("ADVERTISER");

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const location = useLocation();

  const { handleChange } = useContext(FilterContext);

  const { showAdminBar } = useContext(AuthContext) as Auth;

  const [selectedKey, setSelectedKey] = useState<string>("/campaigns");

  const navigate = useNavigate();

  const regex = /\d+/g;

  const { pathname } = location;

  useEffect(() => {
    if (pathname !== selectedKey) {
      setSelectedKey(
        pathname === "/" ||
          (pathname.includes("campaign") && !pathname.includes("offsite"))
          ? "/campaigns"
          : pathname
      );
    }

    if (pathname.includes("offsite-campaign"))
      setSelectedKey("/offsite-campaigns");

    if (pathname.includes("/report/")) setSelectedKey("/reports");

    if (pathname.includes("/academy/")) setSelectedKey("/academy");
  }, [pathname, selectedKey]);

  const handleDetailsPage = () =>
    navigate({
      pathname: selectedKey,
      search: location.search,
    });

  const isDetailsPage =
    pathname.match(regex) !== null || pathname.includes("academy");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const href = emailRegex.test(data?.data?.email)
    ? `mailto:${data?.data?.email}`
    : data?.data?.email;

  /*   const searchParams = new URLSearchParams(location.search);

  const queryString = searchParams.toString();

  const toWithQuery = (to: string) => {
    return { pathname: to, search: queryString };
  }; */

  const siderClassName = () => {
    if (showAdminBar && showDocs) return cm.advertiserMenuWithDocs;

    if (showAdminBar) return cm.advertiserMenu;

    if (showDocs) return cm.justDocs;

    return "";
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      selectedKeys={[selectedKey]}
      theme="light"
      className={cc([cm.menu, siderClassName()])}
      id="menu"
    >
      {retailUserRouteObject(isOffsiteAdsEnabled).map(
        (route: {
          link?: string;
          to?: string;
          key: string;
          iconFilled: JSX.Element;
          iconOutlined: JSX.Element;
        }) => (
          <Tooltip
            overlay={t(`sider.user.${route.key}`)}
            placement="right"
            arrowPointAtCenter
            overlayClassName={cm.popover}
            key={route.link}
            color="var(--primary-6)"
            overlayInnerStyle={{
              padding: "var(--size-xxxs) var(--size-s)",
              borderRadius: "8px",
              fontWeight: "600",
            }}
          >
            <Menu.Item
              key={route.link || "1"}
              className={cc([
                cm.listItem,
                cm.user,
                selectedKey === route.link ? cm.selected : "",
              ])}
              onClick={
                isDetailsPage && route.link === selectedKey
                  ? handleDetailsPage
                  : undefined
              }
            >
              <RetailAdvertiserViewLink
                to={route.link || ""}
                /*          to={"/repotrs"} */
                onClick={() => handleChange(null, "filtered")}
                id={route.link}
                replace={false}
              >
                {selectedKey === route.link
                  ? route.iconFilled
                  : route.iconOutlined}
              </RetailAdvertiserViewLink>
            </Menu.Item>
          </Tooltip>
        )
      )}
      <RetailTooltip
        title={t("sider.user.chat")}
        style={{ lineHeight: "12px" }}
        placement="right"
      >
        <Menu.Item className={cm.contact} key="chat">
          <a href={href} className="flex" target="_blank" rel="noreferrer">
            <ChatOutlined />
          </a>
        </Menu.Item>
      </RetailTooltip>
    </Menu>
  );
};

export default RetailUserSider;
