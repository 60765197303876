import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RetailMainButton from "../RetailMainButton";

import cm from "./style.module.scss";

interface RetailNotificationActionButtonProps {
  notificationType: string;
  campaignID: unknown;
  advertiserID: unknown;
  /* expanded: boolean; */
}

const RetailNotificationActionButton = ({
  notificationType,
  campaignID,
  advertiserID,
}: RetailNotificationActionButtonProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const switchTranslationKey = () => {
    if (notificationType?.includes("ProductsStopped")) return "productAction";
    if (notificationType?.includes("BalanceLow")) return "balanceAction";
    if (notificationType?.includes("CreativeRejected")) return "creativeAction";
    if (notificationType?.includes("CreativeCreated")) return "reviewAction";
    return "bidAction";
  };

  const handleClick = () => {
    const url = () => {
      switch (notificationType) {
        case "MarketplaceAdvertiserBalanceLowSubject":
          return `/invoices?adv=${advertiserID}`;
        case "AdvertiserBalanceLowSubject":
          return "/invoices";
        case "CreativeCreatedSubject":
          return "admin/creatives";
        default:
          return `/campaign/${campaignID}`;
      }
    };

    navigate(url());
  };

  return (
    <RetailMainButton
      className={cm.btn}
      hasBackground={true}
      onClick={handleClick}
    >
      {t(`components.notification.${switchTranslationKey()}`)}
    </RetailMainButton>
  );
};

export default RetailNotificationActionButton;
