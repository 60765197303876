//General equality values
export const containValues = [
  { name: "contains", value: "ct" },
  { name: "none_of", value: "ni" },
  { name: "equal", value: "eq" },
  { name: "not_equal", value: "ne" },
];

export const equalityValues = [
  { name: "equal", value: "eq" },
  { name: "not_equal", value: "ne" },
  { name: "less_than", value: "lt" },
  { name: "grater_than", value: "gt" },
  { name: "greater_or", value: "ge" },
  { name: "less_or", value: "le" },
];

export const isValues = [
  { name: "any_of", value: "in" },
  { name: "none_of", value: "notin" },
];

export const twoOptionedValues = [
  { name: "equal", value: "eq" },
  { name: "not_equal", value: "ne" },
];

export const reportValuesIn = [
  { name: "any_of", value: "in" },
  { name: "none_of", value: "ni" },
];

export const reportValuesEq = [...twoOptionedValues, ...reportValuesIn];

export const advertiserValues = [{ name: "contains", value: "ct" }];

export const advertiserValuesBrand = [
  ...advertiserValues,
  { name: "contains", value: "ct" },
];
