import Divider from "antd/lib/divider";
import cc from "classcat";
import { useTranslation } from "react-i18next";
import {
	Link,
	useLocation,
} from "react-router-dom";

import { LinkOutlined } from "@ant-design/icons";

import { ReactComponent as AddUserOutlined } from "../../../assets/icons/addUserOutlined.svg";
import { ReactComponent as ArchiveOutlined } from "../../../assets/icons/archiveOutlined.svg";
import { ReactComponent as CopyOutlined } from "../../../assets/icons/copyOutlined.svg";
import { ReactComponent as UsersOutlined } from "../../../assets/icons/menu/usersOutlined.svg";
import { ReactComponent as PencilOutlined } from "../../../assets/icons/pencilOutlined.svg";
import RetailText from "../../Typography/RetailText";
import cm from "../style.module.scss";

export interface RetailActionPopoverProps {
  to?: any;
  state?: boolean;
  onArchive?(data: any): void;
  records?: any;
  activeKey?: string;
  onThirdOption?(data: any): void;
}

const RetailActionPopover = ({
  state,
  to,
  onArchive,
  records,
  activeKey,
  onThirdOption,
}: RetailActionPopoverProps) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const showUserAdd =
    pathname.includes("advertiser") &&
    records.status !== "UNINVITED" &&
    activeKey !== "ARCHIVED";

  const showArchive = pathname !== "/admin/placement" || (records.status === "PAUSED" || records.status === "ARCHIVED");
  

  //Button Components
  const ArchiveSection = () =>
    showArchive ? (
      <>
        {to !== undefined && <Divider className={cm.divider} />}
        <RetailText
          family="poppins"
          size="xs"
          className={cc(["flex", cm.text, cm.archive])}
          onClick={() => onArchive && onArchive(records)}
        >
          <ArchiveOutlined />
          {activeKey === "ARCHIVED"
            ? t("common.removeArchive")
            : t("common.archive")}
        </RetailText>
      </>
    ) : (
      <></>
    );

  const AddUserSection = () => (
    <>
      <Divider className={cm.divider} />
      <RetailText
        family="poppins"
        size="xs"
        className={cc(["flex", cm.text, cm.invite])}
        onClick={() => onThirdOption!(records)}
      >
        <AddUserOutlined />
        {t("common.addUser")}
      </RetailText>
    </>
  );

  const ReInviteSection = () => {
    return (
      <>
        <RetailText
          family="poppins"
          size="xxs"
          className={cc(["flex", cm.text, cm.invite])}
          onClick={() => onThirdOption && onThirdOption(records)}
        >
          <LinkOutlined />
          {t("common.invite_url")}
        </RetailText>
        <RetailText
          family="poppins"
          size="xxs"
          className={cc(["flex", cm.text, cm.invite])}
          onClick={() => onArchive && onArchive(records)}
        >
          <AddUserOutlined />
          {t("common.reinvite")}
        </RetailText>
      </>
    );
  };

  const InviteSection = () => {
    return (
      <>
        <Divider className={cm.divider} />
        <RetailText
          family="poppins"
          size="xs"
          className={cc(["flex", cm.text, cm.invite])}
          onClick={() => onArchive && onArchive(records)}
        >
          <UsersOutlined />
          {t("common.invite")}
        </RetailText>
        <AddUserSection />
      </>
    );
  };

  const CopySection = () => {
    return (
      <>
        <Divider className={cm.divider} />
        <RetailText
          family="poppins"
          size="xs"
          className={cc(["flex", cm.text, cm.invite])}
          onClick={() => onThirdOption!(records)}
        >
          <CopyOutlined />
          {t("common.copy")}
        </RetailText>
      </>
    );
  };

  const VastTagSection = () => {
    return (
      <>
        <Divider className={cm.divider} />
        <RetailText
          family="poppins"
          size="xs"
          className={cc(["flex", cm.text, cm.invite])}
          onClick={() => onThirdOption!(records)}
        >
          <LinkOutlined />
          {t("common.vastTag")}
        </RetailText>
      </>
    );
  };

  //Switch Buttons according to page or active tab
  const SwitchButtons = () => {
    if (onArchive === undefined) return <></>;
    if (pathname.includes("campaign"))
      return (
        <>
          <ArchiveSection />
          <CopySection />
        </>
      );
    if (pathname.includes("invited")) return <ReInviteSection />;
    if (showUserAdd)
      return (
        <>
          <ArchiveSection />
          <AddUserSection />
        </>
      );
    if (records.status === "UNINVITED" && activeKey !== "ARCHIVED")
      return <InviteSection />;
    if (records.vast_tag) 
      return ( 
        <>
          <VastTagSection />
          <ArchiveSection />
        </>
      );
    else return <ArchiveSection />;
  };

  const handleClick = (e: any) => {
    if (typeof to === "function") {
      e.preventDefault();
      to(records);
    }
  };

  const path = () => {
    if (typeof to === "function" || typeof to === "undefined") return "#";
    if (to.includes("campaign")) return `${to}?adv=${records?.advertiser_id}`;
    return to;
  };

  return (
    <div>
      {to !== undefined && (
        <Link
          to={path()}
          onClick={handleClick}
          className={cc(["flex", cm.text, cm.link])}
          state={{ data: state }}
        >
          <PencilOutlined /> {t("common.edit")}
        </Link>
      )}
      <SwitchButtons />
    </div>
  );
};

export default RetailActionPopover;
