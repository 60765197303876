import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Spin, Form, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as FacebookFilled } from "assets/icons/facebookFilled.svg";
import useApi from "api";
import RetailSettingsLayout from "components/Layout/RetailSettingsLayout";
import RetailSettingsHeader from "components/Layout/RetailSettingsHeader";
import RetailNotification from "components/Notification";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailSelect from "components/Select/RetailSelect";

import cc from "classcat";
import cm from "./style.module.scss";

const enum Mode {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  PENDING = "PENDING",
}

interface AdAccount {
  id: string;
  name: string;
  business_account: {
    id: number;
    name: string;
    business_account_id: string;
  };
}

interface FacebookPage {
  id: string;
  page_id: string;
  name: string;
  category: string;
  followers_count: number;
}

interface InstagramPage {
  id: string;
  instagram_id: string;
  username: string;
  name: string;
  followers_count: number;
}

interface MetaPixel {
  id: number;
  name: string;
}

interface Location {
  country_code: string;
  name: string;
  is_active: boolean;
}

const OffsiteSettingsPage = () => {
  const { t } = useTranslation();

  const { api } = useApi(true);

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const handleOnErrorNotification = (push = false, error?: any) => {
    if (error.includes("select only one business account")) {
      RetailNotification.showNotification(
        "error",
        "",
        t("pages.admin.offsite.multipleAccounts")
      );
    } else {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
    if (push) {
      window.history.pushState({}, "", "/admin/offsite");
    }
  };

  const handleOnSuccessNotification = (
    push = false,
    translation = "pages.admin.offsite.success"
  ) => {
    RetailNotification.showNotification("success", "", t(translation));
    if (push) {
      window.history.pushState({}, "", "/admin/offsite");
    }
  };

  //Connection Status
  const { data: connectionStatus, isLoading: connectionStatusLoading } =
    useQuery({
      queryKey: "connectionStatus",
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const response = await api.get("/meta/oauth/status/");
        return response.data;
      },
      onError: () => {
        handleOnErrorNotification();
      },
    });

  const { mutate: metaOAuthMutation, isLoading: metaOAuthLoading } =
    useMutation({
      mutationFn: () =>
        api.get("meta/oauth/prepare/", {
          headers: {
            "X-Redirect-Location": `${window.location.origin}/admin/offsite?redirect=meta`,
          },
        }),
      onSuccess: (data) => {
        window.open(data.data.auth_url, "_blank");
      },
    });

  const metaEnabled =
    window.location.href.includes("redirect=meta") &&
    window.location.href.includes("taskid");

  const hasTaskId = window.location.href.includes("taskid");

  const { isLoading: taskLoading } = useQuery({
    queryKey: "metaTask",
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const id = window.location.href.split("taskid=")[1];
      if (hasTaskId && id) {
        const cleanId = id.split("#")[0];
        const response = await fetch(`/offsite/api/tasks/${cleanId}/`);
        return await response.json();
      }
      return null;
    },
    onSuccess: (data) => {
      localStorage.setItem("initialState", data?.state);
      switch (data?.state) {
        case Mode.FAILURE:
          handleOnErrorNotification(true, data?.error);
          break;
        case Mode.SUCCESS:
          handleOnSuccessNotification(true);
          queryClient.invalidateQueries({ queryKey: "connectionStatus" });
          localStorage.removeItem("initialState");
          break;
        case Mode.PENDING:
          break;
      }
    },
    onError: () => {
      handleOnErrorNotification(true);
      localStorage.removeItem("initialState");
    },
    enabled: metaEnabled,
    refetchInterval: 5000,
  });

  //Settings
  const { data: offsiteSettingsData, isLoading: offsiteSettingsLoading } =
    useQuery({
      queryKey: "offsiteSettings",
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const response = await api.get("/meta/settings/?fields=all");
        return response.data;
      },
      onSuccess: (data) => {
        form.setFieldsValue({
          location: data?.campaign?.marketplace_location?.[0] || null,
          ad_account_id: data?.identity?.ad_account?.id || null,
          facebook_page_id: data?.identity?.facebook_page?.id || null,
          instagram_account_id: data?.identity?.instagram_account?.id || null,
          meta_pixel_id: data?.identity?.meta_pixel?.id || null,
          /* click_through_days:
            data?.campaign?.click_through_days ?? 7,
          view_through_days: data?.campaign?.view_through_days ?? 1,
          engaged_view_days: data?.campaign?.engaged_view_days ?? 1, */
        });
      },
      enabled: connectionStatus?.status === "ACTIVE",
    });

  //Location
  const { data: locationData, isLoading: locationLoading } = useQuery<
    Location[]
  >({
    queryKey: "location",
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const response = await fetch("/offsite/api/targeting/countries/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      });
      return await response.json();
    },
    enabled: connectionStatus?.status === "ACTIVE",
  });

  //Ad Accounts
  const { data: adAccountsData } = useQuery<AdAccount[]>({
    queryKey: "adAccounts",
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: async () => {
      const response = await api.get("/meta/ad-accounts/?fields=all");
      return response.data?.results;
    },
    enabled: connectionStatus?.status === "ACTIVE",
  });

  //Ad Account Assets
  const { data: adAccountAssetsData, isLoading: adAccountAssetsLoading } =
    useQuery({
      queryKey: ["adAccountAssets", form.getFieldValue("ad_account_id")],
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      queryFn: async () => {
        const selectedAdAccountId = form.getFieldValue("ad_account_id");
        return await api.get(
          `/meta/ad-accounts/${selectedAdAccountId}/assets/`
        );
      },
      enabled:
        !!form.getFieldValue("ad_account_id") &&
        connectionStatus?.status === "ACTIVE",
    });

  //Patch Settings
  const { mutate: patchSettingsMutation, isLoading: patchSettingsLoading } =
    useMutation({
      mutationFn: async () => {
        const {
          ad_account_id,
          facebook_page_id,
          instagram_account_id,
          meta_pixel_id,
          location,
        } = form.getFieldsValue();

        const currentAdAccountId =
          offsiteSettingsData?.identity?.ad_account?.id;
        const isAdAccountChanged = ad_account_id !== currentAdAccountId;
        const response = await api.patch("/meta/settings/", {
          identity: {
            ad_account_id,
            facebook_page_id: isAdAccountChanged ? null : facebook_page_id,
            instagram_account_id: isAdAccountChanged
              ? null
              : instagram_account_id,
            meta_pixel_id: isAdAccountChanged ? null : meta_pixel_id,
          },
          ...(location && {
            campaign: {
              marketplace_location: [location],
            },
          }),
          /* campaign: {
              click_through_days,
              view_through_days,
              engaged_view_days,
              ...(location && { marketplace_location: [location] }),
            }, */
        });
        return {
          response,
        };
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "offsiteSettings" });
        handleOnSuccessNotification(
          false,
          "components.notification.settings_success"
        );
      },
      onError: () => handleOnErrorNotification(),
    });

  const handleMetaClick = () => metaOAuthMutation();

  //Revoke
  const { mutate: revokeMutation, isLoading: revokeLoading } = useMutation({
    mutationFn: async () => await api.post("/meta/oauth/revoke/"),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: "connectionStatus" });
      handleOnSuccessNotification(false, "pages.admin.offsite.revokeSuccess");
    },
    onError: () => handleOnErrorNotification(),
  });

  const handleRevoke = () => revokeMutation();

  const InitialMode = () => {
    return (
      <div className={cc(["flex", cm.justifyContent, cm.container])}>
        <article>
          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.offsite.connect")}
          </RetailTitle>
          <RetailText size="xxxs" family="poppins" className={cm.text}>
            {t("pages.admin.offsite.connectText")}
          </RetailText>
        </article>
        <button
          className={cm.facebookButton}
          onClick={handleMetaClick}
          disabled={metaOAuthLoading}
        >
          <FacebookFilled className={cm.facebookIcon} />
          <span className={cm.facebookText}>
            {t("pages.admin.offsite.loginWithFacebook")}
          </span>
        </button>
      </div>
    );
  };

  const generalLoading = offsiteSettingsLoading || patchSettingsLoading;

  const generalLoadingWithAdAccountAssets =
    generalLoading || adAccountAssetsLoading;

  const marketplaceLocationLoading =
    generalLoadingWithAdAccountAssets || locationLoading;

  const adAccountLoading =
    generalLoadingWithAdAccountAssets || adAccountAssetsLoading;

  const SuccessMode = () => {
    return (
      <Form form={form} layout="vertical" className={cm.form}>
        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <div>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.offsite.connect")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.offsite.connectText")}
            </RetailText>
          </div>
          <section className={cm.nameContainer}>
            <h3 className={cm.name}>
              {connectionStatus?.business_account?.name}
            </h3>
            <div className={cm.buttonContainer}>
              <RetailMainButton
                onClick={handleMetaClick}
                className={cm.reauthenticateButton}
                disabled={metaOAuthLoading}
              >
                {t("pages.admin.offsite.reauthenticate")}
              </RetailMainButton>
              <RetailMainButton
                onClick={handleRevoke}
                className={cm.revokeButton}
                disabled={revokeLoading}
              >
                {t("pages.admin.offsite.revoke")}
              </RetailMainButton>
            </div>
          </section>
        </div>

        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <article>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.offsite.location")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.offsite.locationText")}
            </RetailText>
          </article>
          <section>
            <Form.Item name="location">
              <RetailSelect
                placeholder={t("pages.admin.offsite.locationPlaceholder")}
                options={locationData
                  ?.filter((location: Location) => location.is_active)
                  .map((location: Location) => ({
                    label: location.name,
                    value: location.country_code,
                  }))}
                className={cm.select}
                disabled={marketplaceLocationLoading}
              />
            </Form.Item>
          </section>
        </div>

        <div className={cc(["flex", cm.justifyContent, cm.container])}>
          <article>
            <RetailTitle level={5} className={cm.title}>
              {t("pages.admin.offsite.adAccount")}
            </RetailTitle>
            <RetailText size="xxxs" family="poppins" className={cm.text}>
              {t("pages.admin.offsite.adAccountText")}
            </RetailText>
          </article>
          <section>
            <Form.Item name="ad_account_id">
              <RetailSelect
                placeholder={t("pages.admin.offsite.adAccountPlaceholder")}
                options={adAccountsData?.map((adAccount) => ({
                  label: adAccount?.name,
                  value: adAccount?.id,
                }))}
                className={cm.select}
                disabled={adAccountLoading}
              />
            </Form.Item>
          </section>
        </div>
        {adAccountAssetsData && (
          <>
            <div className={cc(["flex", cm.col, cm.container])}>
              <article className={cm.innerContainer}>
                <RetailTitle level={5}>
                  {t("pages.admin.offsite.identity")}
                </RetailTitle>
                <RetailText size="xxxs" family="poppins" className={cm.text}>
                  {t("pages.admin.offsite.identityText")}
                </RetailText>
              </article>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    Facebook
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="facebook_page_id">
                    <RetailSelect
                      placeholder={t("pages.admin.offsite.facebookPlaceholder")}
                      options={adAccountAssetsData?.data?.facebook_pages?.map(
                        (page: FacebookPage) => ({
                          label: page.name,
                          value: page.id,
                        })
                      )}
                      className={cm.select}
                      disabled={
                        adAccountAssetsLoading ||
                        patchSettingsLoading ||
                        offsiteSettingsLoading
                      }
                    />
                  </Form.Item>
                </section>
              </div>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    Instagram
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="instagram_account_id">
                    <RetailSelect
                      placeholder={t(
                        "pages.admin.offsite.instagramPlaceholder"
                      )}
                      options={adAccountAssetsData?.data?.instagram_accounts?.map(
                        (account: InstagramPage) => ({
                          label: account.username,
                          value: account.id,
                        })
                      )}
                      className={cm.select}
                      disabled={
                        adAccountAssetsLoading ||
                        patchSettingsLoading ||
                        offsiteSettingsLoading
                      }
                    />
                  </Form.Item>
                </section>
              </div>
            </div>

            <div className={cc(["flex", cm.col, cm.container])}>
              <article className={cm.innerContainer}>
                <RetailTitle level={5}>
                  {t("pages.admin.offsite.pixel")}
                </RetailTitle>
                <RetailText size="xxxs" family="poppins" className={cm.text}>
                  {t("pages.admin.offsite.pixelText")}
                </RetailText>
              </article>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    Pixel
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="meta_pixel_id">
                    <RetailSelect
                      placeholder={t("pages.admin.offsite.pixelPlaceholder")}
                      options={adAccountAssetsData?.data?.pixels?.map(
                        (pixel: MetaPixel) => ({
                          label: pixel.name,
                          value: pixel.id,
                        })
                      )}
                      className={cm.select}
                      disabled={adAccountAssetsLoading || patchSettingsLoading}
                    />
                  </Form.Item>
                </section>
              </div>
            </div>

            {/* <div className={cc(["flex", cm.col, cm.container])}>
              <article className={cm.innerContainer}>
                <RetailTitle level={5}>
                  {t("pages.admin.offsite.attribution")}
                </RetailTitle>
                <RetailText size="xxxs" family="poppins" className={cm.text}>
                  {t("pages.admin.offsite.attributionText")}
                </RetailText>
              </article>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.offsite.clickThrough")}
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="click_through_days">
                    <RetailSelect
                      placeholder={t(
                        "pages.admin.offsite.clickThroughPlaceholder"
                      )}
                      options={[
                        {
                          label: t("pages.admin.offsite.oneDay"),
                          value: 1,
                        },
                        {
                          label: t("pages.admin.offsite.sevenDays"),
                          value: 7,
                        },
                      ]}
                      className={cm.select}
                      disabled={adAccountAssetsLoading || patchSettingsLoading}
                    />
                  </Form.Item>
                </section>
              </div>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.offsite.viewThrough")}
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="view_through_days">
                    <RetailSelect
                      placeholder={t(
                        "pages.admin.offsite.viewThroughPlaceholder"
                      )}
                      options={[
                        {
                          label: t("pages.admin.offsite.none"),
                          value: 0,
                        },
                        {
                          label: t("pages.admin.offsite.oneDay"),
                          value: 1,
                        },
                      ]}
                      className={cm.select}
                      disabled={adAccountAssetsLoading || patchSettingsLoading}
                    />
                  </Form.Item>
                </section>
              </div>
              <div className={cc(["flex", cm.justifyContent])}>
                <article>
                  <RetailTitle level={5} className={cm.title}>
                    {t("pages.admin.offsite.engagedView")}
                  </RetailTitle>
                </article>
                <section>
                  <Form.Item name="engaged_view_days">
                    <RetailSelect
                      placeholder={t(
                        "pages.admin.offsite.engagedViewPlaceholder"
                      )}
                      options={[
                        {
                          label: t("pages.admin.offsite.none"),
                          value: 0,
                        },
                        {
                          label: t("pages.admin.offsite.oneDay"),
                          value: 1,
                        },
                      ]}
                      className={cm.select}
                      disabled={adAccountAssetsLoading || patchSettingsLoading}
                    />
                  </Form.Item>
                </section>
              </div>
            </div> */}
          </>
        )}
      </Form>
    );
  };

  const PendingMode = () => {
    return (
      <div className={cm.pendingContainer}>
        <div className={cm.loadingContainer}>
          <Spin
            spinning={true}
            indicator={<LoadingOutlined className={cm.loadingIcon} />}
          />
        </div>
        <RetailText weight="bold" className={cm.pendingTitle}>
          {t("pages.admin.offsite.pendingTitle")}
        </RetailText>
        <RetailText size="xs" weight="medium" className={cm.pendingText}>
          {t("pages.admin.offsite.pendingText")}
        </RetailText>
      </div>
    );
  };

  const initialState = localStorage.getItem("initialState");

  const isPending = initialState === Mode.PENDING;

  const ModeRenderer = () => {
    if (connectionStatusLoading) {
      return (
        <div
          className={cc([
            "flex",
            cm.justifyContent,
            cm.container,
            cm.skeletonContainer,
          ])}
        >
          <Skeleton
            active
            paragraph={{ rows: 1, width: "50%" }}
            title={{ width: "40%" }}
          />

          <Skeleton.Button
            active
            size="large"
            style={{
              width: "50%",
              height: "48px",
              minWidth: "200px",
              borderRadius: "14px",
            }}
          />
        </div>
      );
    }
    if (isPending) return <PendingMode />;
    if (connectionStatus?.status === "ACTIVE") return <SuccessMode />;
    return <InitialMode />;
  };

  return (
    <RetailSettingsLayout
      spinning={
        taskLoading ||
        connectionStatusLoading ||
        generalLoading ||
        revokeLoading ||
        adAccountAssetsLoading
      }
    >
      <RetailSettingsHeader
        type="offsite"
        onClick={
          connectionStatus?.is_connected ? patchSettingsMutation : undefined
        }
      />
      <ModeRenderer />
    </RetailSettingsLayout>
  );
};

export default OffsiteSettingsPage;
