import { createContext, useState } from "react";

export interface OffsiteCampaignContextType {
  formFields: {
    budget: string;
    redirectUrl: string;
    adName: string;
    primaryText: string;
    headline: string;
    description: string;
  };
  setFormFields: (value: {
    budget: string;
    redirectUrl: string;
    adName: string;
    primaryText: string;
    headline: string;
    description: string;
  }) => void;
  handleFormFieldsChange: (
    key: keyof OffsiteCampaignContextType["formFields"],
    value: string
  ) => void;
  productError: boolean;
  setProductError: (value: boolean) => void;
}

export const OffsiteCampaignContext =
  createContext<OffsiteCampaignContextType | null>(null);

export const OffsiteCampaignProvider: React.FC = ({ children }) => {
  const [formFields, setFormFields] = useState({
    budget: "",
    redirectUrl: "",
    adName: "",
    primaryText: "",
    headline: "",
    description: "",
  });

  const [productError, setProductError] = useState(false);

  const handleFormFieldsChange = (
    key: keyof OffsiteCampaignContextType["formFields"],
    value: string
  ) => {
    setFormFields((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <OffsiteCampaignContext.Provider
      value={{
        formFields,
        setFormFields,
        handleFormFieldsChange,
        productError,
        setProductError,
      }}
    >
      {children}
    </OffsiteCampaignContext.Provider>
  );
};

export default OffsiteCampaignProvider;
