import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useApi from "api";
import RetailNotification from "components/Notification";

import { Widgets } from "utils/types";

const useWidget = (type: Widgets, startDate: string, endDate: string) => {
  const { t } = useTranslation();

  const { api, baseURL, isLimitedUser, adminInGeneral } = useApi();

  const { id } = useParams();

  const [isEnabled, setIsEnabled] = useState(false);

  const queryClient = useQueryClient();

  const url = () => {
    switch (type) {
      case "campaigns":
      case "marketplace":
      case "tour":
        return `widgets?start_date=${startDate}&end_date=${endDate}`;
      case "campaign_details":
        return `campaigns/${id}/widgets?start_date=${startDate}&end_date=${endDate}`;
    }
  };

  useEffect(() => {
    if (baseURL() !== undefined && startDate !== "" && endDate !== "")
      setIsEnabled(true);
  }, [baseURL, startDate, endDate]);

  const { isLoading, error, data } = useQuery(
    ["widgets", startDate, endDate],
    () => {
      const data = api.get(url());
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      cacheTime: 0,
    }
  );

  const updateWidgetEndpoint = () => {
    if (isLimitedUser && adminInGeneral) return "settings/widgets";
    return type === "marketplace" ? "settings" : "";
  };

  const { isLoading: loading, data: selectedWidgets } = useQuery(
    "selected_widgets",
    () => {
      const data = api.get(updateWidgetEndpoint());
      return data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      cacheTime: 0,
    }
  );

  const dataType = useCallback(() => {
    if (type === "campaigns" || type === "marketplace") {
      return "dashboard_widgets";
    } else if (type === "campaign_details") {
      return "campaign_widgets";
    } else return "tutorial_status";
  }, [type]);

  const updateValue = async (value: any) => {
    const config = { [dataType()]: value };
    const response = await api.patch(updateWidgetEndpoint(), config);
    return response;
  };

  const { mutateAsync } = useMutation(updateValue);

  const changeValue = async (value: any) => {
    try {
      await mutateAsync(value);
      queryClient.refetchQueries("selected_widgets");
    } catch (err) {
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
    }
  };

  return {
    isLoading,
    data,
    error,
    loading,
    selectedWidgets,
    dataType,
    changeValue,
  };
};

export default useWidget;
