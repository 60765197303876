import "moment/locale/tr";
import "moment/locale/en-gb";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/zh-cn";
import "moment/locale/ru";

import localeEN from "antd/es/date-picker/locale/en_US";
import localeJP from "antd/es/date-picker/locale/ja_JP";
import localeKR from "antd/es/date-picker/locale/ko_KR";
import localeTR from "antd/es/date-picker/locale/tr_TR";
import localeCH from "antd/es/date-picker/locale/zh_CN";
import localeRU from "antd/es/date-picker/locale/ru_RU";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import ar from "antd/lib/locale/ar_EG";
import en from "antd/lib/locale/en_US";
import jp from "antd/lib/locale/ja_JP";
import kr from "antd/lib/locale/ko_KR";
import tr from "antd/lib/locale/tr_TR";
import ch from "antd/lib/locale/zh_CN";
import ru from "antd/lib/locale/ru_RU";
import RetailNotification from "components/Notification";
import RetailTableTag from "components/Tag/RetailTableTag";
import RetailText from "components/Typography/RetailText";
import { TFunction } from "react-i18next";

import { noLanguageSelected } from "./defaults";
import { AdType, DisplayNamesType, Schedule } from "./types";

//Render tag according to data value coming from table row data.
export const renderTag = (value: "ACTIVE" | "PAUSED", t: TFunction) => {
  return (
    <RetailTableTag mode={value === "ACTIVE" ? "green" : "unfilledYellow"}>
      <RetailText weight="bold" family="poppins" size="xxxs">
        {value === "ACTIVE" ? t("common.table.on") : t("common.table.stopped")}
      </RetailText>
    </RetailTableTag>
  );
};

//Validate if input keypress is number, delete or backspace. Otherwise prevent key event.
export const validateNumber = (e: any, limited = false) => {
  if (e.which === 8 || e.which === 46 || e.which === 190) return;
  if (limited && e.target.value.length > 4) e.preventDefault();
  if (isNaN(parseInt(e.key))) {
    e.preventDefault();
  }
};

// Truncate text to given length
export const truncate = (text: string) => {
  if (!text.includes(">") || text.length < 25) return text;
  const newText = text.split(">");
  return `... > ${newText[newText.length - 1]}`;
};

export const datePickerLocale = (lang: string) => {
  switch (lang) {
    case "en":
      return localeEN;
    case "jp":
      return localeJP;
    case "ch":
      return localeCH;
    case "kr":
      return localeKR;
    case "ru":
      return localeRU;
    default:
      return localeTR;
  }
};

//Divide given string between given character
export const getSubstring = (str: string, char1: string, char2: string) => {
  return str.substring(str.indexOf(char1) + 1, str.lastIndexOf(char2));
};

export const numFormatter = (value: number | string, spendColumn = false) => {
  const langForFormatter =
    localStorage.getItem("retail_lang") || noLanguageSelected;
  // Get decimal round value from marketplace config
  const decimalRound =
    (window as any).marketplaceConfig?.decimal_round || false;

  const formatter = () => {
    switch (langForFormatter) {
      case "tr":
        return "tr-TR";
      default:
        return "en-US";
    }
  };

  let num = (typeof value === "string" ? parseFloat(value) : value) || 0;

  const shouldBeRounded = decimalRound && spendColumn;

  if (shouldBeRounded) {
    num = Math.round(num);
  }

  return num.toLocaleString(formatter(), {
    maximumFractionDigits: shouldBeRounded ? 0 : 2,
  });
};

export const localCatalogValues = (
  type: DisplayNamesType,
  countryCode: string,
  lang: string
) => {
  // Create an Intl.DisplayNames object for the user's language
  const regionNamesInFrench = new Intl.DisplayNames(lang, { type });
  // Get the localized name for the given country code
  const localizedCountryName = regionNamesInFrench.of(countryCode);
  // Return the localized country name
  return localizedCountryName;
};

export const controlSelected = (id: number, arr: any[], iterableArr: any[]) =>
  arr
    .map((keyword) => keyword.id)
    .filter((keyword_id) =>
      iterableArr.map((key: any) => keyword_id === key.id)
    )
    .includes(id);

export const budgetErrorMessage = (
  value: number,
  t: TFunction,
  currencySymbol: string
) => {
  return Promise.reject(
    new Error(
      t("components.campaignForm.firstStep.budgetError", {
        value: `${value}${currencySymbol}`,
      })
    )
  );
};

export const settingsSuccessNotification = (t: TFunction) => {
  return RetailNotification.showNotification(
    "success",
    "",
    t("components.notification.settings_success")
  );
};

export const renderSettingsLinks = (
  link: string,
  el: {
    className: string;
    text: string;
  }
) => {
  if (link === "") return null;
  else
    return (
      <a href={link} className={el.className} target="_blank" rel="noreferrer">
        {el.text}
      </a>
    );
};

export const days = (t: TFunction) => [
  t("common.days.monday"),
  t("common.days.tuesday"),
  t("common.days.wednesday"),
  t("common.days.thursday"),
  t("common.days.friday"),
  t("common.days.saturday"),
  t("common.days.sunday"),
];

export const daysMap = (t: TFunction): { [key: string]: string } => ({
  [t("common.days.monday")]: "monday",
  [t("common.days.tuesday")]: "tuesday",
  [t("common.days.wednesday")]: "wednesday",
  [t("common.days.thursday")]: "thursday",
  [t("common.days.friday")]: "friday",
  [t("common.days.saturday")]: "saturday",
  [t("common.days.sunday")]: "sunday",
});

export const generateDayPartingObject = (
  picker: string,
  schedule: Schedule,
  t: TFunction
) => {
  if (picker === "ALL_TIMES") {
    return {
      dayparting: {
        all_week: true,
        all_day: null,
        schedule: null,
      },
    };
  }

  const allDay: { [key: string]: boolean } = {};
  const scheduleObj: { [key: string]: boolean[] } = {};

  days(t).forEach((day, index) => {
    const dayKey = daysMap(t)[day];
    if (schedule[index].every((hour) => hour)) {
      allDay[dayKey] = true;
    } else if (schedule[index].some((hour) => hour)) {
      scheduleObj[dayKey] = schedule[index];
    }
  });

  return {
    dayparting: {
      all_week: false,
      all_day: Object.keys(allDay).length > 0 ? allDay : null,
      schedule: Object.keys(scheduleObj).length > 0 ? scheduleObj : null,
    },
  };
};

export const getLanguageCode = (lang: string) => {
  switch (lang) {
    case "en":
      return "en-US";
    case "tr":
      return "tr";
    case "ar":
      return "ar";
    case "jp":
      return "ja";
    case "ch":
      return "zh";
    case "kr":
      return "ko";
    case "ru":
      return "ru";
    default:
      return "en-US";
  }
};

export const localeLanguages = (lang: string) => {
  switch (lang) {
    case "en":
      return en;
    case "tr":
      return tr;
    case "ar":
      return ar;
    case "jp":
      return jp;
    case "ch":
      return ch;
    case "kr":
      return kr;
    case "ru":
      return ru;
    default:
      return en;
  }
};

export const cleanOnboardingData = () => {
  const localStorageKeysForCleaning = [
    "linkedin",
    "marketplace_id",
    "visited_steps",
    "onboarding_status",
  ];
  localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
};

const cleanAuthData = () => {
  const localStorageKeysForCleaning = [
    "auth_token",
    "auth_email",
    "auth_info",
    "account_index",
    "advertiser_id",
    "user_settings",
    "_grecaptcha",
  ];
  localStorageKeysForCleaning.forEach((key) => localStorage.removeItem(key));
};

export const cleanAllData = () => {
  cleanAuthData();
  cleanOnboardingData();
};

export const switchFlag = (lang: string | CheckboxValueType) => {
  switch (lang) {
    case "ENGLISH":
      return "🇺🇸";
    case "TURKISH":
      return "🇹🇷";
    case "ARABIC":
      return "🇸🇦";
    case "JAPANESE":
      return "🇯🇵";
    case "CHINESE":
      return "🇨🇳";
    case "KOREAN":
      return "🇰🇷";
    default:
      return "🇺🇸";
  }
};

export const renderFlagOrItem = (languages: string[] | CheckboxValueType[]) => {
  if (languages?.length > 1) return languages?.length;
  else return languages?.map((lang) => switchFlag(lang)).join(" ");
};

export const convertEnumToFormattedString = (enumValue: string): string => {
  if (!enumValue) return "";
  const [number, unit] = enumValue?.split("_");
  return `${unit?.toLowerCase()}_${number?.toLowerCase()}`;
};

export const switchMethod = (selected: string | null) => {
  switch (selected) {
    case "googleFeedUrl":
      return "GOOGLE_FEED_URL_SCHEDULER";
    case "googleFeedFile":
      return "GOOGLE_FEED_FILE";
    case "googleFeedDirectXml":
      return "GOOGLE_FEED_BODY";
    case "metaFeedUrl":
      return "META_FEED_URL_SCHEDULER";
    case "api":
      return "API";
    default:
      return "";
  }
};

/**
 * Formats a date based on the given timezone and format options.
 * @param date - The date to format, as a Date object or ISO string.
 * @param timezone - The timezone identifier, e.g., 'Europe/Istanbul'.
 * @param format - The desired date format, e.g., 'DD/MM/YYYY'. Default is 'DD/MM/YYYY'.
 * @returns The formatted date string.
 */

export const formatDateWithTimezone = (
  date: Date | string,
  timezone: string,
  format: "DD/MM/YYYY" | "MM/DD/YYYY" | "YYYY-MM-DD" | "full" = "DD/MM/YYYY"
): string => {
  const dateObj = typeof date === "string" ? new Date(date) : date;

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: timezone,
  };

  const formatter = new Intl.DateTimeFormat("en-GB", options); // 'en-GB' for DD/MM/YYYY default

  type DatePartType =
    | "day"
    | "month"
    | "year"
    | "hour"
    | "minute"
    | "second"
    | "literal";

  const parts = formatter.formatToParts(dateObj).reduce(
    (acc, part) => {
      if (part.type in acc) {
        acc[part.type as DatePartType] = part.value;
      }
      return acc;
    },
    {
      day: "",
      month: "",
      year: "",
      hour: "",
      minute: "",
      second: "",
    } as Record<DatePartType, string>
  );

  const formats: Record<string, string> = {
    "DD/MM/YYYY": `${parts.day}/${parts.month}/${parts.year}`,
    "MM/DD/YYYY": `${parts.month}/${parts.day}/${parts.year}`,
    "YYYY-MM-DD": `${parts.year}-${parts.month}-${parts.day}`,
    full: `${parts.day}/${parts.month}/${parts.year}, ${parts.hour}:${parts.minute}:${parts.second}`,
  };

  return formats[format] || formats["DD/MM/YYYY"];
};

//Localize relative time
export const getLocalizedRelativeTime = (timestamp: string, lang: string) => {
  const now = new Date();
  const createdAt = new Date(Number(timestamp) / 1_000_000); // Convert from microseconds to milliseconds
  const diffInSeconds = Math.floor(
    (now.getTime() - createdAt.getTime()) / 1000
  );

  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });

  if (diffInSeconds < 60) return rtf.format(-diffInSeconds, "seconds");
  if (diffInSeconds < 3600)
    return rtf.format(-Math.floor(diffInSeconds / 60), "minutes");
  if (diffInSeconds < 86400)
    return rtf.format(-Math.floor(diffInSeconds / 3600), "hours");
  if (diffInSeconds < 172800) return rtf.format(-1, "days"); // "Yesterday"
  return rtf.format(-Math.floor(diffInSeconds / 86400), "days"); // For days
};

// Convert connected with product type to usable type for some data fetching and rendering
export const selectCampaignType = (type: AdType) => {
  if (type === "DISPLAY" || type === "DISPLAY_WITH_PRODUCT") return "DISPLAY";

  if (type === "VIDEO" || type === "VIDEO_WITH_PRODUCT" || type === "IN_STORE")
    return "VIDEO";

  return type;
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, "");
};

export const calculateLineCount = (text: string, charsPerLine: number) => {
  if (!text || charsPerLine <= 0) return 0;
  const totalChars = text.length;
  return Math.ceil(totalChars / charsPerLine);
};

export const parseInput = (input: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");
  const imgTags = doc.getElementsByTagName("img");

  if (imgTags.length > 0) {
    const rawSrc = imgTags[0].getAttribute("src");
    return rawSrc
      ? rawSrc.replace(/^['"]|['"]$/g, "").replace(/\\+$/, "")
      : input;
  }
  return input;
};
