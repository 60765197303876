import {
	useMutation,
	useQueryClient,
} from "react-query";
import { TrackJS } from "trackjs";

import useApi from "../api";

export const useCampaignMutation = (parameter: string, id: any) => {
  const queryClient = useQueryClient();
  const { api, role, baseURL, isLimitedUser } = useApi();

  const updateValue = async (value: any) => {
    const config = { [parameter]: value };

    try {
      const response = await api.patch(`campaigns/${id}`, config);
      return response;
    } catch (error) {
      TrackJS.track({
        message: "Campaign Patch Error",
        metadata: {
          endpoint: `campaigns/${id}`,
          requestBody: config,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error.response?.data,
          statusCode: error.response?.status,
          errorMessage: error.message,
          severity: "Medium"
        },
      });
    }
  };

  const { mutateAsync, isLoading } = useMutation(updateValue);

  const changeValue = async (value: any) => {
    await mutateAsync(value);
    queryClient.invalidateQueries("single_campaign", id);
  };
  return { changeValue, isLoading };
};
