import { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrency } from "context/CurrencyProvider";
import { Col, Form, Row, Switch, Table } from "antd";
import { validateNumber } from "utils/helpers";
import { useOffsiteCampaignMutation } from "hooks/useOffsiteCampaignMutation";
import { ReactComponent as EditOutlined } from "assets/icons/editWhiteOutlined.svg";
import { ReactComponent as StateOutlined } from "assets/icons/stateOutlined.svg";
import { ReactComponent as WalletOutlined } from "assets/icons/walletOutlined.svg";
import useOffsiteCampaignFetch from "hooks/useOffsiteCampaignFetch";
import Input from "antd/lib/input/Input";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailDateColumn from "components/Column/RetailDateColumn";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import RetailNotification from "components/Notification";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";

import cc from "classcat";
import cm from "./style.module.scss";

const OffsiteCampaignDetailsPage = () => {
  const { t } = useTranslation();

  const { isLoading, data, isError } = useOffsiteCampaignFetch();

  const { changeValue, isMutationLoading } = useOffsiteCampaignMutation(
    data?.data?.id
  );

  const { currencySymbol } = useCurrency();

  const navigate = useNavigate();

  const location = useLocation();

  const [tableData, setTableData] = useState<any[]>([]);

  const [visible, setVisible] = useState(false);

  const [activeKey, setActiveKey] = useState("");

  const [formFields, setFormFields] = useState<{
    status: string | null;
    name: string;
    daily_budget: string;
  }>({
    status: null,
    name: "",
    daily_budget: "",
  });

  const [form] = Form.useForm();

  const handleOnClose = () => setVisible(false);

  const onFinish = async () => {
    const values = await form.validateFields();

    if (activeKey === "name") {
      await changeValue({
        campaign: {
          name: values.name,
        },
      });
    } else if (activeKey === "daily_budget") {
      await changeValue({
        ad_set: {
          daily_budget: values.daily_budget,
        },
      });
    }

    setVisible(false);
  };

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(
        () => navigate({ pathname: "/", search: location.search }),
        100
      );
      RetailNotification.showNotification(
        "error",
        "",
        t("components.notification.statusError")
      );
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (data?.data) {
      setTableData([data?.data]);
      setFormFields({
        status: data?.data?.status,
        name: data?.data?.name,
        daily_budget: data?.data?.daily_budget,
      });
      form.setFieldsValue({
        name: data?.data?.name,
        daily_budget: data?.data?.daily_budget,
        status: data?.data?.status,
      });
    }
  }, [data, form]);

  const columns = (t: TFunction) => [
    {
      title: t("common.table.ACoS"),
      dataIndex: "ACoS",
      render: (value: number) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("common.table.impressions"),
      dataIndex: "impressions",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.link_click"),
      dataIndex: "link_click",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.CPM"),
      dataIndex: "CPM",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.CPC"),
      dataIndex: "CPC",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.CTR"),
      dataIndex: "CTR",
      render: (value: number) => <RetailPercentageColumn value={value} />,
    },
    {
      title: t("marketplaceDependentTable.RoAS"),
      dataIndex: "RoAS",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.spend"),
      dataIndex: "spend",
      render: (value: number) => (
        <RetailMoneyColumn value={value} spendColumn={true} />
      ),
    },
    {
      title: t("common.table.sale"),
      dataIndex: "sale",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.sale_amount"),
      dataIndex: "sale_amount",
      render: (value: number) => <RetailMoneyColumn value={value} />,
    },
    {
      title: t("common.table.reach"),
      dataIndex: "reach",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.frequency"),
      dataIndex: "frequency",
      render: (value: number) => <RetailNumberColumn value={value} />,
    },
    {
      title: t("common.table.meta_created_at"),
      dataIndex: "meta_created_at",
      render: (value: string) => <RetailDateColumn value={value} />,
    },
  ];

  const open = (key: string) => {
    setVisible(true);
    setActiveKey(key);
  };

  return (
    <RetailPageContainer loading={isLoading || isMutationLoading}>
      <header
        className={cc([
          cm.header,
          formFields.status !== "ACTIVE" ? cm.paused : "",
        ])}
      >
        <RetailTitle level={2} className={cm.title}>
          {data?.data?.name}
          <span
            className={cc([
              cm.bigIcon,
              cm.iconContainer,
              formFields.status === "FAILED" ? cm.disabled : "",
            ])}
            onClick={() => open("name")}
          >
            <EditOutlined />
          </span>
        </RetailTitle>
        <Row className={cm.bottomBar}>
          <Col>
            <RetailText className={cc(["flex", cm.label])} size="xs">
              <StateOutlined />
              {t("common.onAndOff")}
            </RetailText>
            {formFields.status && (
              <RetailText size="xs">
                {t(`common.table.${formFields.status}`)}
              </RetailText>
            )}
            <Switch
              checked={formFields.status === "ACTIVE"}
              disabled={formFields.status === "FAILED"}
              onChange={(value) =>
                changeValue({
                  campaign: {
                    status: value ? "ACTIVE" : "PAUSED",
                  },
                })
              }
            />
          </Col>

          <RetailText className={cc(["flex", cm.label])} size="xs">
            <span className="flex">
              <WalletOutlined />
              {`${t("common.table.daily_budget")}:`}
            </span>

            <RetailMoneyColumn value={formFields.daily_budget} />

            <span
              className={cc([
                cm.smallIcon,
                cm.iconContainer,
                formFields.status === "FAILED" ? cm.disabled : "",
              ])}
              onClick={() => {
                open("daily_budget");
              }}
            >
              <EditOutlined />
            </span>
          </RetailText>
        </Row>
        <CampaignDetailsModal
          type="UPDATE"
          visible={visible}
          onCancel={handleOnClose}
          onOk={form.submit}
          subtitle={t("pages.acc.campaignDetails.subtext")}
          destroyOnClose
        >
          <Form
            form={form}
            autoComplete="off"
            colon={false}
            onFinish={onFinish}
            requiredMark={false}
          >
            <Row className={cm.row}>
              <RetailFormInput
                isFocused={formFields?.name !== ""}
                label={t("components.offsiteCampaignForm.firstStep.adName")}
                name="name"
                className="floating"
              >
                <Input className="floating" autoFocus={activeKey === "name"} />
              </RetailFormInput>
            </Row>
            <Row className={cm.row}>
              <Col className={cm.budgetContainer}>
                <RetailFormInput
                  isFocused={formFields?.daily_budget !== ""}
                  label={t(
                    "components.offsiteCampaignForm.firstStep.budgetLabel"
                  )}
                  className={cc([
                    "floating inside show-message",
                    formFields?.daily_budget === ""
                      ? cm.hidePrefix
                      : cm.showPrefix,
                  ])}
                  name="daily_budget"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "components.offsiteCampaignForm.firstStep.budgetError"
                      ),
                    },
                  ]}
                >
                  <Input
                    className="number-input floating"
                    prefix={currencySymbol}
                    autoFocus={activeKey === "daily_budget"}
                    onKeyDownCapture={(e) => validateNumber(e)}
                    value={formFields?.daily_budget}
                    onChange={({ target }) =>
                      setFormFields({
                        ...formFields,
                        daily_budget: target.value,
                      })
                    }
                  />
                </RetailFormInput>
              </Col>
            </Row>
          </Form>
        </CampaignDetailsModal>
      </header>
      {formFields.status === "FAILED" && (
        <div className={cm.failedContainer}>
          <RetailText size="xs" className={cm.failedText}>
            {t("pages.acc.offsiteCampaigns.failedText")}
          </RetailText>
        </div>
      )}
      <div className="bordered-container">
        <Table
          dataSource={tableData || []}
          columns={columns(t)}
          scroll={{
            x: true,
          }}
        />
      </div>
    </RetailPageContainer>
  );
};

export default OffsiteCampaignDetailsPage;
