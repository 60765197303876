import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { TrackJS } from "trackjs";
import useApi from "api";

const useOffsiteCampaignFetch = (enabled = true) => {
  const { pathname } = useLocation();

  const { api, baseURL, role, isLimitedUser } = useApi(true);

  const id = pathname.substring(pathname.lastIndexOf("/") + 1);

  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(enabled && !isNaN(parseInt(id)));
  }, [enabled, id, baseURL]);

  const { data, isLoading, isError, error } = useQuery(
    ["single_meta_campaign", id],
    () => {
      const data = api(`meta/campaigns/${parseInt(id)}/`);
      return data;
    },
    {
      enabled: isEnabled,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        TrackJS.track({
          message: `Meta Campaign Fetch Error for ID: ${id}`,
          metadata: {
            endpoint: `meta/campaigns/${parseInt(id)}/`,
            role,
            isLimitedUser: `Is user limited? ${isLimitedUser}`,
            baseURL: baseURL(),
            responseBody: error?.response?.data,
            statusCode: error?.response?.status,
            errorMessage: error?.message,
            severity: "High",
          },
        });
      },
    }
  );

  return { isLoading, isError, data, error };
};

export default useOffsiteCampaignFetch;
