import RetailText from "components/Typography/RetailText";
import { useTranslation } from "react-i18next";

import { AdType } from "utils/types";
import cm from "./style.module.scss";

export interface CreativeUploadInfoProps {
  adType: AdType;
}

const CreativeUploadInfo = ({ adType }: CreativeUploadInfoProps) => {
  const { t } = useTranslation();

  const videoInfo = [
    {
      text: t("components.campaignForm.firstStep.videoFormat"),
      className: cm.allowedFileText,
      onClick: undefined,
    },
    {
      text: t("components.campaignForm.firstStep.maxVideoSize"),
      className: cm.maxFileSizeText,
    }
  ];

  const displayInfo = [
    {
      text: t("components.campaignForm.firstStep.listWarningType"),
      className: cm.allowedFileText,
    },
    {
      text: t("components.campaignForm.firstStep.listWarningSize"),
      className: cm.maxFileSizeText,
    }
  ];

  const info = adType === "VIDEO" ? videoInfo : displayInfo;

  return (
    <>
      <RetailText
        size="xxxs"
        weight="medium"
        className={info[0].className}
      >
        {t("components.campaignForm.firstStep.allowedFileTypes")} {info[0].text}
      </RetailText>
      <RetailText
        size="xxxs"
        weight="medium"
        className={info[1].className}
      >
        {t("components.campaignForm.firstStep.maximumFileSize")} {info[1].text}
      </RetailText>
    </>
  );
};

export default CreativeUploadInfo;
