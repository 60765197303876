import { useContext, useRef, useState } from "react";
import Col from "antd/lib/col";
import cc from "classcat";
import { Trans, useTranslation } from "react-i18next";
import { Card, Checkbox, Row, Tabs } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import SelectCategoriesTable from "../../../Table/SelectCategoriesTable";
import SelectKeywordsTable from "../../../Table/SelectKeywordsTable";
import SelectNegativeTable from "../../../Table/SelectNegativeTable";
import RetailText from "../../../Typography/RetailText";
import RetailTitle from "../../../Typography/RetailTitle";
import RetailFormInfo from "../../RetailFormInfo";
import RetailFormRow from "../../RetailFormRow";
import CsvTemplateInfoCard from "./CsvTemplateInfo";
import CsvUploadDragger from "./CsvUploadDragger";
import cm from "../style.module.scss";

import RetailMainButton from "components/Button/RetailMainButton";
import { Keyword, KeywordContext } from "context/KeywordProvider";
import RetailDrawer from "components/Drawer/RetailDrawer";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailNotification from "components/Notification";
import { ReactComponent as AlertFilled } from "assets/icons/alertFilled.svg";
import { ReactComponent as UploadOutlined } from "assets/icons/uploadOutlined.svg";
import { ReactComponent as DeleteOutlined } from "assets/icons/deleteOutlined.svg";
import { AdType } from "utils/types";
import useApi from "api";

export interface RetailCampaignFormSecondStepProps {
  requiredFields: any;
  errorStates: any;
}

const RetailCampaignFormSecondStep = ({
  requiredFields,
  errorStates,
}: RetailCampaignFormSecondStepProps) => {
  const { t } = useTranslation();
  const { keywords, setKeywords } = useContext(KeywordContext) as Keyword;
  const { api } = useApi();

  const location = useLocation();

  const ad_type = location.state as { ad_type: AdType };

  const title =
    requiredFields.placement === "IN_SEARCH"
      ? t("components.campaignForm.secondStep.wordTargeting")
      : t("components.campaignForm.secondStep.categoryTargeting");

  const text =
    requiredFields.placement === "IN_SEARCH"
      ? t("components.campaignForm.secondStep.wordTargetingText")
      : t("components.campaignForm.secondStep.categoryTargetingTitle");

  const [activeTab, setActiveTab] = useState("manual");
  const [drawerInfo, setDrawerInfo] = useState<{
    visible: boolean;
    uploadedKeywords: any[];
  }>({
    visible: false,
    uploadedKeywords: [],
  });
  const [switchTabModalInfo, setSwitchTabModalInfo] = useState<{
    switchTabModalVisible: boolean;
    tabKey: string;
  }>({
    switchTabModalVisible: false,
    tabKey: "",
  });

  const [confirmation, setConfirmation] = useState(false);

  const handleTabChange = (key: string) => {
    if (keywords.length > 0) {
      setSwitchTabModalInfo({
        switchTabModalVisible: true,
        tabKey: key,
      });
      return;
    }

    setActiveTab(key);
  };

  const handleConfirmModal = () => {
    setActiveTab(switchTabModalInfo.tabKey);
    setKeywords([]);
    resetSwitchModal();
  };

  const openDrawer = () => setDrawerInfo({ visible: true, uploadedKeywords: [] });

  const resetSwitchModal = () => {
    setSwitchTabModalInfo({
      switchTabModalVisible: false,
      tabKey: "",
    });
    setConfirmation(false);
    setDrawerInfo({ visible: false, uploadedKeywords: [] });
  };

  const updateKeywords = () => {
    setKeywords(drawerInfo.uploadedKeywords);
    resetSwitchModal();
  };

  const csvFile = useRef<File>();

  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    setDrawerInfo({ visible: true, uploadedKeywords: [] });

    const fileRef = csvFile;
    if (!info || !info.file || info.fileList.length === 0) {
      fileRef.current = undefined;
      return;
    }
    fileRef.current = info.file as unknown as File;

    const formData = new FormData();
    formData.append("keywordsCSV", fileRef.current);

    try {
      const response = await api.post(
        `/keywords/csv?bidType=${requiredFields.dataBidType}&campaignType=${ad_type.ad_type}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const keywordList = response.data;

      const parsedKeywords = keywordList.map((keyword: any) => ({
        id: uuidv4(),
        match_type: keyword.match_type,
        text: keyword.text,
        bid: keyword.bid,
      }));

      setDrawerInfo({ visible: true, uploadedKeywords: parsedKeywords });
    } catch (error) {
      handleError(error);
    }
  };

  const ERROR_KEY_MAPPING: { substring: string; key: string }[] = [
    { substring: "error reading csv file", key: "csvFileNotRead" },
    { substring: "invalid csv header", key: "invalidCSVHeader" },
    { substring: "invalid csv file", key: "invalidCSVFile" },
    { substring: "invalid csv bid value", key: "invalidCSVBidValue" },
    {
      substring: "bid value is not greater than minimum bid",
      key: "bidNotGreaterThanMin",
    },
    { substring: "invalid bid type", key: "invalidBidType" },
  ];

  const handleError = (error: any) => {
    let generalErrKey = "generalErr";

    const responseText = error?.request?.responseText;
    const mapping =
      responseText &&
      ERROR_KEY_MAPPING.find((m) => responseText.includes(m.substring));
    const errKey = mapping ? mapping.key : generalErrKey;

    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.secondStep.errorStates.${errKey}`)
    );
  };

  return (
    <div className={cm.wrapper}>
      <RetailFormRow
        className={cc([cm.keywords, "form-row", cm.unroundedKeywords])}
      >
        <RetailFormInfo
          column={{
            number: "1",
            title: title,
            text: text,
          }}
        />
      </RetailFormRow>

      {requiredFields.placement === "IN_SEARCH" ? (
        <RetailFormRow className={cm.notAutoKeyword}>
          <Col span={24}>
            <RetailTitle level={5} className={cm.selectedTitle}>
              {t("components.campaignForm.secondStep.keywordTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.keywordText")}
            </RetailText>
          </Col>
          <Tabs
            defaultActiveKey="manual"
            activeKey={activeTab}
            onChange={handleTabChange}
            moreIcon={false}
            className={cm.tabs}
          >
            <Tabs.TabPane
              tab={t("components.campaignForm.secondStep.addManualTabText")}
              key="manual"
              className={cm.tabPane}
            >
              <SelectKeywordsTable
                suggestKeywords
                isDynamic={requiredFields.dataBidType === "FIXED"}
                error={errorStates.keywordError}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("components.campaignForm.secondStep.bulkUploadTabText")}
              key="bulk"
              className={cm.tabPane}
            >
              {keywords.length > 0 ? (
                <>
                  <div className={cm.uploadedInfoContainer}>
                    <RetailText
                      size="xxxs"
                      weight="medium"
                      className={cm.uploadedInfoText}
                    >
                      <Trans
                        i18nKey="components.campaignForm.secondStep.uploadInfoText"
                        values={{ count: keywords.length }}
                      />
                    </RetailText>
                    <Row className={cm.uploadedButtonsContainer}>
                      <RetailMainButton
                        className={cc([
                          cm.uploadedActionsButton,
                          cm.reuploadButton,
                        ])}
                        onClick={openDrawer}
                      >
                        <UploadOutlined />
                        {t("components.campaignForm.secondStep.reUpload")}
                      </RetailMainButton>
                      <RetailMainButton
                        className={cc([
                          cm.uploadedActionsButton,
                          cm.clearAllButton,
                        ])}
                        onClick={() => setKeywords([])}
                      >
                        <DeleteOutlined />
                        {t("common.clearAll")}
                      </RetailMainButton>
                    </Row>
                  </div>
                  <SelectKeywordsTable
                    suggestKeywords
                    isDynamic={requiredFields.dataBidType === "FIXED"}
                    error={errorStates.keywordError}
                    isForBulkUpload
                  />
                </>
              ) : (
                <Card className={cm.bulkUploadCard}>
                  <div className={cm.bulkUploadCardBody}>
                    <div className={cm.bulkUploadTextContainer}>
                      <RetailTitle level={4} className={cm.bulkUploadCardTitle}>
                        {t(
                          "components.campaignForm.secondStep.bulkUploadCardTitle"
                        )}
                      </RetailTitle>
                      <RetailText type="secondary" size="xs">
                        {t(
                          "components.campaignForm.secondStep.bulkUploadCardSubtitle"
                        )}
                      </RetailText>
                    </div>
                    <RetailMainButton
                      hasBackground
                      className={cm.button}
                      onClick={openDrawer}
                    >
                      {t("common.add")}
                    </RetailMainButton>
                  </div>
                </Card>
              )}
            </Tabs.TabPane>
          </Tabs>
          <Col>
            <RetailTitle
              level={5}
              className={cc([cm.negativeTitle, cm.selectedTitle])}
            >
              {t("components.campaignForm.secondStep.negativeTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.negativeText")}
            </RetailText>
            <SelectNegativeTable type="NEGATIVE_KEYWORDS" />
          </Col>
        </RetailFormRow>
      ) : (
        <RetailFormRow className={cm.notAutoKeyword}>
          <Col span={24}>
            <RetailTitle level={5} className={cm.selectedTitle}>
              {t("components.campaignForm.secondStep.category")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.categoryTargetingText")}
            </RetailText>
          </Col>
          <SelectCategoriesTable
            suggestCategories
            isDynamic={requiredFields.dataBidType === "FIXED"}
            error={errorStates.categoryError}
          />
          <Col>
            <RetailTitle
              level={5}
              className={cc([cm.negativeTitle, cm.selectedTitle])}
            >
              {t("components.campaignForm.secondStep.negativeCategoryTitle")}
            </RetailTitle>
            <RetailText className={cm.selectedText}>
              {t("components.campaignForm.secondStep.negativeCategoryText")}
            </RetailText>
            <SelectNegativeTable type="NEGATIVE_CATEGORIES" />
          </Col>
        </RetailFormRow>
      )}
      <RetailDrawer
        visible={drawerInfo.visible}
        onOk={updateKeywords}
        onClose={() => setDrawerInfo({ visible: false, uploadedKeywords: [] })}
        title={t("components.campaignForm.secondStep.bulkUpload")}
        type="add"
        width={800}
        disableOk={drawerInfo.uploadedKeywords.length === 0}
      >
        <CsvTemplateInfoCard dataBidType={requiredFields.dataBidType} />
        <CsvUploadDragger handleOnChange={handleFileChange} />
      </RetailDrawer>
      <CampaignDetailsModal
        type="CONFIRM_KEYWORD_TAB"
        visible={switchTabModalInfo.switchTabModalVisible}
        onOk={handleConfirmModal}
        onCancel={resetSwitchModal}
        disabled={!confirmation}
      >
        <div className={cm.switchTabModalContainer}>
          <Card className={cm.switchTabWarningCard}>
            <AlertFilled />
            <div className={cm.switchTabWarningTextContainer}>
              <RetailText
                size="s"
                weight="bold"
                className={cm.switchTabWarningTitle}
              >
                {t("components.campaignForm.secondStep.switchTabModal.title." + switchTabModalInfo.tabKey)}
              </RetailText>
              <RetailText
                size="xxs"
                weight="regular"
                className={cm.switchTabWarningText}
              >
                {t("components.campaignForm.secondStep.switchTabModal.warning." + switchTabModalInfo.tabKey)}
              </RetailText>
              <RetailText
                size="xxs"
                weight="regular"
                className={cm.switchTabWarningText}
              >
                {t("components.campaignForm.secondStep.switchTabModal.proceedQuestion")}
              </RetailText>
            </div>
          </Card>
          <Card className={cm.checkboxContainer}>
            <div className={cm.checkboxInnerContainer}>
              <Checkbox
                onChange={(e) => setConfirmation(e.target.checked)}
                value={confirmation}
                className={cm.keywordCheckbox}
              >
                <RetailText
                  size="xs"
                  weight="medium"
                  className={cm.switchTabConfirmationText}
                >
                  {switchTabModalInfo.tabKey === "manual"
                    ? t(
                        "components.campaignForm.secondStep.switchTabConfirmation.deletion.manual"
                      )
                    : t(
                        "components.campaignForm.secondStep.switchTabConfirmation.deletion.bulk"
                      )}
                </RetailText>
              </Checkbox>
            </div>
          </Card>
        </div>
      </CampaignDetailsModal>
    </div>
  );
};

export default RetailCampaignFormSecondStep;
