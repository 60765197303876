import { Route, Routes } from "react-router-dom";
import SingleCampaignPage from "pages/shared/CampaignDetailsPage";
import CampaignsPage from "pages/shared/CampaignsPage";
import CreateCampaignPage from "pages/shared/CreateCampaignPage";
import CreateReportPage from "pages/shared/CreateReportPage";
import EditReportPage from "pages/shared/EditReportPage";
import NoMatch from "pages/shared/NoMatch";
import ReportDetailsPage from "pages/shared/ReportDetailsPage";
import ReportsPage from "pages/shared/ReportsPage";
import InvoicesPage from "pages/user/InvoicesPage";
import RetailAcademyPage from "pages/user/RetailAcademyPage";
import RetailAcademyPost from "pages/user/RetailAcademyPost";
import RetailAcademyPostCategory from "pages/user/RetailAcademyPostCategory";
import OffsiteCampaignsPage from "pages/shared/OffsiteCampaignsPage";
import CreateOffsiteCampaignPage from "pages/shared/CreateOffsiteCampaignPage";
import OffsiteCampaignDetailsPage from "pages/shared/OffsiteCampaignDetailsPage";

const RetailUserRoutes = ({
  isOffsiteAdsEnabled,
}: {
  isOffsiteAdsEnabled: boolean;
}) => (
  <Routes>
    {["campaigns", "/"].map((path, index) => (
      <Route path={path} element={<CampaignsPage />} key={index} />
    ))}
    <Route path="reports" element={<ReportsPage />} />
    <Route path="invoices" element={<InvoicesPage />} />
    <Route path="create-campaign" element={<CreateCampaignPage />} />
    {isOffsiteAdsEnabled && (
      <>
        <Route
          path="create-offsite-campaign"
          element={<CreateOffsiteCampaignPage />}
        />
        <Route path="offsite-campaigns" element={<OffsiteCampaignsPage />} />
        <Route
          path="offsite-campaign/:id"
          element={<OffsiteCampaignDetailsPage />}
        />
      </>
    )}
    <Route path="campaign/:id" element={<SingleCampaignPage />} />
    <Route path="create-report" element={<CreateReportPage />} />
    <Route path="edit-report/:id" element={<EditReportPage />} />
    <Route path="report/:id" element={<ReportDetailsPage />} />
    <Route path="academy" element={<RetailAcademyPage />} />
    <Route path="academy/blog/:title" element={<RetailAcademyPostCategory />} />
    <Route path="academy/blog/:title/:slug" element={<RetailAcademyPost />} />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export default RetailUserRoutes;
