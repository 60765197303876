import { Tabs } from "antd";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import { RowSelectionType } from "antd/lib/table/interface";
import { AxiosError } from "axios";
import cc from "classcat";
import { ChangeEvent, useContext, useState } from "react";
import { TFunction, Trans, useTranslation } from "react-i18next";

import { InfoCircleFilled } from "@ant-design/icons";

import useApi from "api";
import { ReactComponent as BlockFilled } from "assets/icons/blockFilled.svg";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as PlusCircleOutlined } from "assets/icons/statsAddOutlined.svg";
import { ReactComponent as WarnFilled } from "assets/icons/warningFilled.svg";
import { Product, ProductContext } from "context/ProductProvider";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import useTableFetch from "hooks/useTableFetch";
import { marketplaceName } from "utils/defaults";
import { truncate } from "utils/helpers";
import RetailSearchBar from "components/Bar/RetailSearchBar";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailDrawer from "components/Drawer/RetailDrawer";
import RetailNotification from "components/Notification";
import RetailSelect from "components/Select/RetailSelect";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import RetailTable from "../RetailTable";
import cm from "./style.module.scss";

export interface SelectProductsTableProps {
  ad_type: "PRODUCT" | "STORE" | "DISPLAY";
  fetchAll: boolean;
  error?: boolean;
  isOffsite?: boolean;
}

const SelectProductsTable = ({
  ad_type,
  fetchAll,
  error = false,
  isOffsite = false,
}: SelectProductsTableProps) => {
  const { t, i18n } = useTranslation();

  const { api } = useApi();

  const [visible, setVisible] = useState(false);

  const [activeKey, setActiveKey] = useState("ALL");

  const [category, setCategory] = useState<
    | [
        {
          key: "productRetailerTaxonomy";
          op: "ct";
          value: string;
        }
      ]
    | []
  >([]);

  const open = () => setVisible(true);

  const close = () => {
    setVisible(false);
    setInputValue("");
  };

  const { data, isLoading, setSearch, setSort, changePageSize, config } =
    useTableFetch(
      "products",
      false,
      { product: "desc" },
      undefined,
      category,
      [],
      fetchAll
    );


  const {
    options,
    handleScroll,
    setSearch: setInfiniteSearch,
  } = useInfiniteScroll("categories", true, true, isOffsite ? "PRODUCT" : undefined);

  const { selectedProducts, setSelectedProducts, handleAdd, handleDelete } =
    useContext(ProductContext) as Product;

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const formattedValue = value.split(" ").join("\n");
    setInputValue(formattedValue);
  };

  const tableConfig = {
    url: "products",
    isRelation: false,
    fetchAll,
    activeKey,
  };

  const isRatingBlocked = (record: any) =>
    !record?.rating_valid && record?.rating_behaviour === "BLOCK";
  const isStockBlocked = (record: any) =>
    !record?.stock_valid && record?.stock_behaviour === "BLOCK";
  const isBuyboxBlocked = (record: any) =>
    !record?.buybox_valid && record?.buybox_behaviour === "BLOCK";

  const isProductValid = (record: any) => {
    return (
      isRatingBlocked(record) ||
      isStockBlocked(record) ||
      isBuyboxBlocked(record)
    );
  };

  const rowSelectionConfig: {
    type: RowSelectionType;
    preserveSelectedRowKeys: boolean;
    selectedRowKeys: number[];
    getCheckboxProps: (record: any) => { disabled: boolean };
  } = {
    type: "checkbox",
    preserveSelectedRowKeys: true,
    selectedRowKeys: selectedProducts.map((product) => product?.id),
    getCheckboxProps: (record: any) => ({
      disabled: isProductValid(record),
    }),
  };

  const handleRowSelection = (
    selectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    if (isOffsite && selectedRows.length > 1) {
      const latestProduct = selectedRows[selectedRows.length - 1];
      handleAdd([latestProduct.id], [latestProduct]);
    } else {
      handleAdd(selectedRowKeys, selectedRows);
    }
  };

  const onOk = async () =>
    await api
      .post("/products/table-by-sku-list", {
        sku_list: inputValue.split("\n"),
        product_ids_to_exclude: selectedProducts.map(
          (product: any) => product.id
        ),
      })
      .then(({ data }) => {
        setSelectedProducts([...selectedProducts, ...data?.products]);
        close();
      })
      .catch((err: AxiosError) => {
        if (err.request.responseText.includes("at most 250 items")) {
          RetailNotification.showNotification(
            "error",
            "",
            t("components.campaignForm.firstStep.errorStates.productLimitErr")
          );
        } else
          RetailNotification.showNotification(
            "error",
            "",
            t("components.notification.statusError")
          );
      });

  const buyboxBehaviour = (product: any) => {
    const tooltipText = `pages.admin.campaignSettings.buybox_${product?.buybox_behaviour?.toLowerCase()}`;

    //Define buybox variable
    let buybox: {
      label: "winner" | "loser";
      value: "WARN" | "BLOCK" | null;
    } = {
      label: "winner",
      value: null,
    };

    //If buybox is not valid, assign buybox_behaviour to buybox variable
    if (!product?.buybox)
      buybox = {
        label: "loser",
        value: i18n.exists(tooltipText) ? t(tooltipText) : null,
      };

    return buybox;
  };

  const selectableBehaviour = (product: any) => {
    const behaviour: {
      label: "rating" | "stock" | "buybox";
      value: "WARN" | "BLOCK";
    }[] = [];
    const ratingBehaviour = product?.rating_valid === false;

    const stockBehaviour = product?.stock_valid === false;

    const buyboxBehaviour = product?.buybox_valid === false;

    if (ratingBehaviour)
      behaviour.push({
        value: product?.rating_behaviour,
        label: "rating",
      });

    if (stockBehaviour)
      behaviour.push({ value: product?.stock_behaviour, label: "stock" });

    if (buyboxBehaviour)
      behaviour.push({ value: product?.buybox_behaviour, label: "buybox" });

    return behaviour;
  };

  const choseValue = (product: any) => {
    return selectableBehaviour(product).length > 0
      ? selectableBehaviour(product)
          .map((item) => item.value)
          .sort()[0]
      : "";
  };

  const choseLabel = (product: any) => {
    if (
      selectableBehaviour(product)
        .map((item) => item.value)
        .includes("BLOCK")
    ) {
      return selectableBehaviour(product).filter(
        (item) => item.value === "BLOCK"
      )[0].label;
    } else {
      return selectableBehaviour(product)[0]?.label;
    }
  };

  const buyboxColumn = (t: TFunction) => [
    {
      title: t("common.table.buybox"),
      dataIndex: "buybox",
      render: (value: boolean, records: any) => (
        <RetailTooltip
          title={buyboxBehaviour(records)?.value}
          className={cc(["flex", cm[buyboxBehaviour(records)?.label]])}
        >
          <RetailText size="xxxs" family="poppins" weight="medium">
            {t(`pages.acc.campaignDetails.${buyboxBehaviour(records)?.label}`)}
          </RetailText>
          <InfoCircleFilled />
        </RetailTooltip>
      ),
    },
  ];

  const generalProductColumns = (t: TFunction) => [
    {
      title: t("components.table.products.price"),
      dataIndex: "price",
      sorter: true,
      render: (value: number) =>
        value === -1 ? (
          t("components.table.products.variable")
        ) : (
          <RetailMoneyColumn value={value} />
        ),
    },
    {
      title: t("components.table.products.stock"),
      dataIndex: "stock",
      sorter: true,
      render: (value: number) =>
        value === -1 ? t("components.table.products.variable") : value || "-",
    },
    {
      title: t("components.table.products.rating"),
      dataIndex: "rating",
      sorter: true,
      render: (value: number) =>
        value === -1 ? t("components.table.products.variable") : value || "-",
    },
    {
      title: "",
      dataIndex: "id",
      render: (value: number, record: any) => (
        <>
          {selectableBehaviour(record)?.length > 0 && (
            <RetailTooltip
              title={t(
                `pages.admin.campaignSettings.${choseLabel(
                  record
                )}_${choseValue(record)?.toLowerCase()}`
              )}
            >
              {choseValue(record) === "BLOCK" ? (
                <BlockFilled />
              ) : (
                <WarnFilled />
              )}
            </RetailTooltip>
          )}
        </>
      ),
    },
  ];

  const productColumns = (t: TFunction) => [
    ...(marketplaceName !== "carrefoursa" && !isOffsite ? buyboxColumn(t) : []),
    ...generalProductColumns(t),
  ];

  const columns = (t: TFunction) => [
    {
      title: t("common.table.product"),
      dataIndex: "name",
      width: 300,
      render: (value: any, record: any) => (
        <div className={cc(["flex", cm.imgContainer])}>
          <img src={record.image_url} alt={value} />
          <RetailText family="poppins" size="xxxs" weight="medium">
            {value}
          </RetailText>
        </div>
      ),
    },
    ...(ad_type === "PRODUCT" ? productColumns(t) : []),
  ];

  const handleSelect = (value: string) =>
    setCategory([
      {
        key: "productRetailerTaxonomy",
        op: "ct",
        value: value,
      },
    ]);

  const handleKey = (key: string) => setActiveKey(key);

  const handleClearCategory = () => setCategory([]);

  const tableRowClassName = (record: any) => {
    return cc([
      cm.tableRow,
      ad_type === "PRODUCT" ? cm[choseValue(record)?.toLowerCase()] : "",
    ]);
  };

  return (
    <Row className={cm.row}>
      <Row className={cm.selectContainer}>
        <Col className={isOffsite ? cm.offsiteSearch : cm.search}>
          <RetailSearchBar
            onChange={({ target }) => setSearch(target.value)}
            productSearch
            placeholder={t(
              `components.table.products.${ad_type?.toLowerCase()}Search`
            )}
            data-cy="products-search"
          />
        </Col>
        <Col className={isOffsite ? cm.offsiteCategories : cm.categories}>
          <RetailSelect
            data-cy="product-sort"
            className={cm.select}
            onSelect={handleSelect}
            placeholder={t("components.table.products.categories")}
            showSearch
            onSearch={setInfiniteSearch}
            value={category}
            options={options.map((opt) => ({
              label: opt.text 
                ? (opt.text.length > 25 
                    ? <RetailTooltip title={opt.text}>{truncate(opt.text)}</RetailTooltip> 
                    : opt.text)
                : "",
              value: opt.text,
            }))}
            allowClear
            onClear={handleClearCategory}
            clearIcon={<CloseOutlined className={cm.clearSearchIcon} />}
            onPopupScroll={handleScroll}
            dropdownMatchSelectWidth={530}
          />
        </Col>
        {!isOffsite && (
          <Col className={cc(["flex", cm.openBtn])} onClick={open}>
            <PlusCircleOutlined />
            <RetailText size="xs">
              {t("components.table.products.bulk")}
            </RetailText>
          </Col>
        )}
      </Row>
      <Tabs
        className={cm.productTabContainer}
        activeKey={activeKey}
        onChange={handleKey}
      >
        <Tabs.TabPane tab={t("components.table.products.all")} key="ALL">
          <RetailText size="xxxs" className={cm.infoText}>
            <Trans
              i18nKey={t("components.table.products.total", {
                value: data?.data?.total_records,
              })}
            />
          </RetailText>
          <RetailTable
            tableConfig={tableConfig}
            dataSource={data?.data?.records}
            pagination={{
              current: config?.page,
              pageSize: config?.page_size,
              total: data?.data.total_records,
            }}
            onChange={(pagination, filters, sorter: any) => {
              sorter.order !== undefined && sorter.order !== null
                ? setSort({
                    sortValue: sorter.field,
                    order: sorter.order === "ascend" ? "asc" : "desc",
                  })
                : setSort(null);
              changePageSize(pagination.current!, pagination.pageSize!);
            }}
            columnsForAdditionalRendering={() => columns(t)}
            placeholder=""
            className={cc([
              cm.productTable,
              error && selectedProducts?.length === 0 ? cm.error : "",
            ])}
            rowClassName={(record: any) => tableRowClassName(record)}
            rowSelection={{
              ...rowSelectionConfig,
              onChange: handleRowSelection,
              columnTitle: isOffsite ? "" : undefined,
              hideSelectAll: isOffsite,
            }}
            loading={isLoading}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={t("components.table.products.selected")}
          key="SELECTED"
        >
          <RetailText size="xxxs" className={cm.infoText}>
            <Trans
              i18nKey={t("components.table.products.total", {
                value: selectedProducts?.length,
              })}
            />
          </RetailText>
          <RetailTable
            tableConfig={tableConfig}
            dataSource={selectedProducts}
            columnsForAdditionalRendering={() => columns(t)}
            placeholder=""
            className={cm.productTable}
            rowClassName={(record: any) => tableRowClassName(record)}
            rowSelection={{
              ...rowSelectionConfig,
              onChange: handleDelete,
              columnTitle: isOffsite ? "" : undefined,
              hideSelectAll: isOffsite,
            }}
            pagination={{
              total: selectedProducts.length,
            }}
          />
        </Tabs.TabPane>
      </Tabs>

      <RetailDrawer
        title={t("components.table.products.drawerTitle")}
        visible={visible}
        onOk={onOk}
        onClose={close}
        type="products"
      >
        <RetailTitle level={5} className={cm.title}>
          {t("components.table.products.sku")}
        </RetailTitle>
        <RetailText className={cm.text} family="poppins" size="xxxs">
          {t("components.table.products.skuText")}
        </RetailText>
        <Input.TextArea
          value={inputValue}
          onChange={handleInputChange}
          className={cm.textarea}
        />
      </RetailDrawer>
    </Row>
  );
};

export default SelectProductsTable;
