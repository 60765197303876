import { useTranslation } from "react-i18next";
import { Switch } from "antd";
import { useOffsiteCampaignMutation } from "hooks/useOffsiteCampaignMutation";
import RetailTableTag from "components/Tag/RetailTableTag";
import RetailText from "components/Typography/RetailText";

interface RetailOffsiteStatusColumnProps {
  status: string;
  campaignId: string;
}

const RetailOffsiteStatusColumn = ({
  status,
  campaignId,
}: RetailOffsiteStatusColumnProps) => {
  const { t } = useTranslation();
  const { changeValue, isMutationLoading } = useOffsiteCampaignMutation(
    campaignId,
    true
  );

  const renderStatus = (status: string) => {
    const className = () => {
      switch (status) {
        case "ACTIVE":
          return "green";
        case "PAUSED":
        case "ARCHIVED":
          return "unfilledYellow";
        case "DELETED":
        case "FAILED":
          return "unfilledRed";
        default:
          return "unfilledYellow";
      }
    };

    return (
      <RetailTableTag mode={className()}>
        <RetailText weight="bold" family="poppins" size="xxxs">
          {t(`common.table.${status}`)}
        </RetailText>
      </RetailTableTag>
    );
  };

  const handleChange = (checked: boolean) => {
    const newStatus = checked ? "ACTIVE" : "PAUSED";
    changeValue({ campaign: { status: newStatus } });
  };

  return (
    <div className="flex">
      <Switch
        checked={status === "ACTIVE"}
        loading={isMutationLoading}
        disabled={status === "FAILED"}
        onChange={handleChange}
      />
      {renderStatus(status)}
    </div>
  );
};

export default RetailOffsiteStatusColumn;
