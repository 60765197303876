import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import Card from "antd/lib/card";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { DatePicker } from "antd";
import moment from "moment";
import useApi from "api";
import useConfigSchema from "hooks/useConfigSchema";
import useWidget from "hooks/useWidget";
import { ReactComponent as CampaignSettings } from "assets/icons/campaignSettings.svg";
import { ReactComponent as CheckOutlined } from "assets/icons/checkOutlined.svg";
import { ReactComponent as UpOutlined } from "assets/icons/upOutlined.svg";
import { numFormatter } from "utils/helpers";
import { Widgets } from "utils/types";
import RetailLineChart from "components/Charts/RetailLineChart";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import RetailCampaignSelectableCard from "./RetailCampaignSelectableCard";
import RetailCampaignWidgetCard from "./RetailCampaignWidgetCard";
import RetailCurrency from "components/Column/RetailCurrency";

import cc from "classcat";
import cm from "./style.module.scss";

interface SelectedOption {
  [key: string]: any;
}

interface DateRange {
  startDate: string;
  endDate: string;
  days: number;
}

export interface RetailCampaignWidgetProps {
  page: Widgets;
}

const RetailCampaignWidget = ({ page }: RetailCampaignWidgetProps) => {
  const { t, i18n } = useTranslation();

  /* const [dateRange, setDateRange] = useState<DateRange>(() => {
    const saved = localStorage.getItem(`${page}_widget_range`);
    if (saved) {
      return JSON.parse(saved);
    }
    return {
      startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  }); */

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    days: 30,
  });

  const { data, error, loading, selectedWidgets, changeValue, dataType } =
    useWidget(page, dateRange.startDate, dateRange.endDate);

  const { data: config, isLoading } = useConfigSchema(
    true,
    undefined,
    "available_widgets"
  );

  const { RangePicker } = DatePicker;

  const rowRef = useRef<HTMLDivElement>(null);

  const [hasScroll, setHasScroll] = useState(false);

  const [visible, setVisible] = useState(false);

  const [selectable, setSelectable] = useState(false);

  const [selected, setSelected] = useState<any>([]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>([]);

  const { roleForDashboard } = useApi();

  const isMetricExist = (key: string) => i18n.exists(`common.table.${key}`);

  const renderMetric = (key: string) =>
    isMetricExist(key)
      ? t(`common.table.${key}`)
      : t(`marketplaceDependentTable.${key}`);

  const shouldDisplaySkeleton =
    loading ||
    selectedOptions === undefined ||
    selectedOptions.length === 0 ||
    isLoading ||
    !data?.data?.result;

  const handleOpen = (title: string) => {
    const selected = selectedOptions.find(
      (widget: any) => widget.key === title
    )!;
    setSelected([
      {
        id: renderMetric(selected?.key),
        data: selected?.value?.data?.map((item: any) => ({
          x: moment(item.date).format("DD/MM/YYYY"),
          y: item.value,
        })),
      },
    ]);
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setSelected([]);
  };

  const handleSelect = (title: string) => {
    if (selectedWidgets?.data[dataType()] === null) changeValue([title]);
    changeValue([...selectedWidgets?.data[dataType()], title]);
  };

  const handleDelete = (title: string) => {
    changeValue(
      selectedWidgets?.data[dataType()].filter((item: string) => item !== title)
    );
  };

  const handleClick = () => {
    setSelectable((prev) => !prev);
    handleClose();
  };

  const whichWidgetsToDisplay = useCallback(() => {
    const configs = config?.data?.configs || null;
    const widgetValues = configs?.[0]?.value || null;
    const availableWidgets =
      widgetValues ||
      (data?.data?.result && Object.keys(data?.data?.result)) ||
      [];
    return availableWidgets;
  }, [config?.data?.configs, data?.data?.result]);

  useEffect(() => handleClose(), [i18n.language, data?.data]);

  useEffect(() => {
    if (selectedWidgets?.data[dataType()] && data?.data?.result) {
      const selectedAndDisplayableWidgets = selectedWidgets?.data[
        dataType()
      ].filter((item: string) => whichWidgetsToDisplay().includes(item));

      const filteredResult = Object.entries(data?.data?.result)
        .filter(([key]) => selectedAndDisplayableWidgets.includes(key))
        .map(([key, value]) => ({ key, value }));

      setSelectedOptions(filteredResult);
    }
  }, [data?.data, selectedWidgets?.data, dataType, whichWidgetsToDisplay]);

  const handleDateRangeChange = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      const newRange = {
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD"),
        days: moment(end).diff(moment(start), "days") + 1,
      };
      setDateRange(newRange);
    }
  };

  //Graph Component
  const graph = (
    <Row className={cm.container}>
      <Col span={24} className={cm.header}>
        <Col>
          <RetailTitle className={cm.graphTitle}>
            {selected.length > 0 ? selected[0].id : ""}
          </RetailTitle>
          <RetailText size="xs" className={cm.text}>
            {t("sider.user.campaign")}
          </RetailText>
        </Col>
        <UpOutlined className={cm.up} onClick={handleClose} />
      </Col>
      <Col span={24}>
        {selected && (
          <RetailLineChart height={116} data={selected} areaBaselineValue={0} />
        )}
      </Col>
    </Row>
  );

  const switchPrefix = (title: string, amount: string) => {
    switch (title) {
      case "sum_spend":
      case "sum_sale_amount":
      case "sum_direct_sale_amount":
      case "CPC":
      case "CPM":
        return <RetailCurrency amount={amount} />;
      case "CTR":
        return `%${amount}`;
      default:
        return amount;
    }
  };

  const LoadingSkeleton = () => (
    <div
      className={
        roleForDashboard === "ADVERTISER" && page === "campaigns"
          ? cm.advertiserRow
          : ""
      }
    >
      <Row
        justify="space-between"
        align="middle"
        className={cm.pickerContainer}
      >
        <Col className={cm.rangeTextContainer}>
          <RetailTitle level={5} noMargin className={cm.rangeTitle}>
            {t("components.widget.title")}
          </RetailTitle>
          <RetailText className={cm.rangeDescription} size="xxxs">
            {t("components.widget.description")}
          </RetailText>
        </Col>

        <Skeleton.Input
          active
          style={{
            width: 250,
            height: 32,
            borderRadius: 8,
          }}
          className={cm.picker}
        />
      </Row>

      <Row className={cm.skeletonRow}>
        {[1, 2, 3, 4, 5].map((key) => (
          <Card className={cm.skeletonCard} key={key}>
            <Skeleton.Input
              active
              style={{
                width: 180,
                height: 22,
                marginBottom: 6,
              }}
            />
            <Skeleton.Input
              active
              style={{
                minWidth: 120,
                width: 120,
                height: 18,
                marginBottom: 4,
                backgroundColor: "var(--secondary-1)",
              }}
            />
            <Skeleton.Input
              active
              style={{
                minWidth: 150,
                width: 150,
                height: 14,
                lineHeight: 1,
                backgroundColor: "var(--secondary-1)",
              }}
            />
          </Card>
        ))}
      </Row>
    </div>
  );

  /* const ErrorDisplay = () => (
    <Alert
      message={t("components.notification.statusError")}
      type="error"
      showIcon
    />
  ); */

  const handleScroll = () => {
    const element = rowRef.current;
    if (element) {
      const isNotAtEnd =
        Math.abs(
          element.scrollWidth - element.clientWidth - element.scrollLeft
        ) > 1;
      setHasScroll(isNotAtEnd);
    }
  };

  useLayoutEffect(() => {
    if (selectedOptions && selectedOptions.length > 0) {
      const scrollable =
        window.innerWidth / (selectedOptions?.length * 234) < 0.7;
      setHasScroll(scrollable);
    }
  }, [selectedOptions]);

  if (shouldDisplaySkeleton) return <LoadingSkeleton />;

  if (error) return <></>;

  return (
    <div
      className={
        roleForDashboard === "ADVERTISER" && page === "campaigns"
          ? cm.advertiserRow
          : ""
      }
    >
      <Row
        justify="space-between"
        align="middle"
        className={cm.pickerContainer}
      >
        <Col className={cm.rangeTextContainer}>
          <RetailTitle level={5} noMargin className={cm.rangeTitle}>
            {t("components.widget.title")}
          </RetailTitle>
          <RetailText className={cm.rangeDescription} size="xxxs">
            {t("components.widget.description")}
          </RetailText>
        </Col>

        <RangePicker
          value={[moment(dateRange.startDate), moment(dateRange.endDate)]}
          onChange={handleDateRangeChange}
          format="DD/MM/YYYY"
          disabledDate={(current) => current && current > moment().endOf("day")}
          className={cm.picker}
        />
      </Row>
      <Row
        className={cc([cm.row, hasScroll ? cm.scroll : ""])}
        ref={rowRef}
        onScroll={handleScroll}
        id="widgets"
      >
        {selectable && whichWidgetsToDisplay
          ? Object.entries(data?.data?.result)
              ?.filter(([key]) => whichWidgetsToDisplay().includes(key))
              ?.map((widget: any, index: number) => (
                <RetailCampaignSelectableCard
                  title={widget[0]}
                  text={
                    widget[1].amount !== undefined
                      ? switchPrefix(
                          widget[0],
                          numFormatter(
                            widget[1].amount,
                            widget[0] === "sum_spend"
                          )
                        )
                      : "0"
                  }
                  key={index}
                  handleSelect={() => handleSelect(widget[0])}
                  handleDelete={handleDelete}
                  selectedOptions={selectedOptions}
                  days={dateRange.days}
                />
              ))
          : selectedOptions?.map((widget: any, index: number) => (
              <RetailCampaignWidgetCard
                widget={widget}
                text={
                  widget.value?.amount !== undefined
                    ? switchPrefix(
                        widget.key,
                        numFormatter(
                          widget.value?.amount,
                          widget.key === "sum_spend"
                        )
                      )
                    : "0"
                }
                selected={selected}
                key={index}
                handleOpen={() => handleOpen(widget.key)}
                handleClose={handleClose}
                days={dateRange.days}
              />
            ))}
        <Card
          className={cc([cm.card, selectable ? cm.selectable : ""])}
          onClick={handleClick}
        >
          {selectable ? (
            <CheckOutlined className={cm.icon} />
          ) : (
            <CampaignSettings className={cm.icon} />
          )}
        </Card>
      </Row>
      {visible ? graph : null}
    </div>
  );
};

export default RetailCampaignWidget;
