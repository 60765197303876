import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Breadcrumb from "antd/lib/breadcrumb";
import { ReactComponent as RightOutlined } from "assets/icons/rightOutlined.svg";
import useAcademyCategoryFetch from "hooks/useAcademyCategoryFetch";
import usePostFetch from "hooks/usePostFetch";
import { truncate } from "utils/helpers";
import { bg_mode } from "utils/defaults";
import RetailAdvertiserViewLink from "components/Link/RetailAdvertiserViewLink";
import RetailTooltip from "components/Tooltip/RetailTooltip";
import cm from "../style.module.scss";

const RetailBreadcrumb = () => {
  const { t, i18n } = useTranslation();

  const { post } = usePostFetch("academy");

  const { category } = useAcademyCategoryFetch();

  const location = useLocation();

  const userType = location.pathname.includes("admin") ? "admin" : "user";

  const breadcrumb = location.state as {
    breadcrumbType: string;
    value: string | { category: string; value: string };
  };

  const switchLink = () => {
    switch (breadcrumb.breadcrumbType) {
      case "finance":
        return "/admin/transactions";
      case "settings":
        return "/admin/general";
      case "academy":
        return "/academy";
      case "report":
        return "reports";
      case "admin_report":
        return "/admin/reports";
      case "access":
        return "/admin/users";
      case "console":
      case "dev":
        return "/admin/console";
      case "offsiteCampaigns":
        return "/offsite-campaigns";
      default:
        return "";
    }
  };

  const returnToBreadcrumb = () => {
    const baseBreadcrumb = {
      breadcrumb: true,
      breadcrumbType: breadcrumb.breadcrumbType,
    };

    switch (breadcrumb.breadcrumbType) {
      case "finance":
        return {
          ...baseBreadcrumb,
          value: "transactions",
        };
      case "settings":
        return {
          ...baseBreadcrumb,
          value: "general",
        };
      case "access":
        return {
          ...baseBreadcrumb,
          value: "users",
        };
      case "dev":
        return {
          ...baseBreadcrumb,
          value: "console",
        };
      default:
        return null;
    }
  };

  const renderLastBreadcrumb = useCallback(() => {
    const breadcrumbKey = `sider.${userType}.${breadcrumb?.value?.toString()}`;

    const doesKeyExist = i18n.exists(breadcrumbKey);

    if (typeof breadcrumb.value === "object") {
      return breadcrumb.value.value;
    } else if (breadcrumb.breadcrumbType === "academy") {
      return category?.title;
    }
    return doesKeyExist ? t(breadcrumbKey) : breadcrumb.value;
  }, [breadcrumb, category, t, i18n, userType]);

  return (
    <Breadcrumb
      separator={<RightOutlined className={cm[`${bg_mode}Separator`]} />}
    >
      <Breadcrumb.Item className={cm.breadcrumb}>
        <RetailAdvertiserViewLink
          to={switchLink()}
          state={returnToBreadcrumb()}
        >
          {t(`sider.${userType}.${breadcrumb.breadcrumbType}`)}
        </RetailAdvertiserViewLink>
      </Breadcrumb.Item>
      {typeof breadcrumb.value === "object" && (
        <Breadcrumb.Item className={cm.breadcrumb}>
          <RetailAdvertiserViewLink
            to={`/academy/blog/${breadcrumb.value.category}`}
            state={{
              breadcrumb: true,
              breadcrumbType: "academy",
              value: breadcrumb.value.category,
            }}
          >
            {post?.categories[0].title}
          </RetailAdvertiserViewLink>
        </Breadcrumb.Item>
      )}
      <Breadcrumb.Item className={cm.titleCrumb}>
        {renderLastBreadcrumb() &&
          (renderLastBreadcrumb()?.length < 25 ? (
            renderLastBreadcrumb()
          ) : (
            <RetailTooltip title={renderLastBreadcrumb()}>
              {truncate(renderLastBreadcrumb()?.toString())}
            </RetailTooltip>
          ))}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default RetailBreadcrumb;
