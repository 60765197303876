import {
  Input,
  RadioChangeEvent,
  Tabs,
} from "antd";
import { useForm } from "antd/es/form/Form";
import {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/lib/upload/interface";
import useApi from "api";
import cc from "classcat";
import { TrackJS } from "trackjs";
import RetailCreativeImgColumn from "components/Column/RetailCreativeImgColumn";
import RetailCreativeLanguageColumn from "components/Column/RetailCreativeLanguageColumn";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import RetailStateColumn from "components/Column/RetailStateColumn";
import RetailStatusColumn from "components/Column/RetailStatusColumn";
import RetailDrawer from "components/Drawer/RetailDrawer";
import CreativeNameForm from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/CreativeNameForm";
import CreativeStepOptions from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/CreativeStepOptions";
import SecondDrawerUploadInner from "components/Form/RetailCampaignForm/RetailCampaignFormFirstStep/CreativeStep/SecondDrawerUploadInner";
import CreativePreviewModal from "components/Modal/CreativePreviewModal";
import RetailNotification from "components/Notification";
import RetailTable from "components/Table/RetailTable";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import {
  Creative,
  CreativeContext,
} from "context/CreativeProvider";
import {
  ChangeEvent,
  ClipboardEvent,
  useContext,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  useMutation,
  useQueryClient,
} from "react-query";
import { useParams } from "react-router";
import { ACCEPTED_FILE_TYPES } from "utils/constants";
import { selectCampaignType } from "utils/helpers";
import {
  AdType,
  Img,
} from "utils/types";

import cm from "./style.module.scss";

interface CreativesTableProps {
  allowHtml: boolean;
  languages: string[];
  type: AdType;
}

const CreativesTable = ({
  allowHtml,
  languages,
  type,
}: CreativesTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { api, role, baseURL, isLimitedUser } = useApi();

  const [openUpload, setOpenUpload] = useState(true);

  const [firstDrawerStates, setFirstDrawerStates] = useState({
    visible: false,
    selected: "",
  });

  const [secondDrawerStates, setSecondDrawerStates] = useState<{
    visible: boolean;
    name: string;
    showCreative: boolean;
    isLoading: boolean;
    textValue?: string;
    size?: string;
  }>({
    visible: false,
    name: "",
    showCreative: false,
    isLoading: false,
    textValue: "",
    size: "",
  });

  const [inputError, setInputError] = useState(false);

  const validateInput = (input: string): { isValid: boolean; width?: number; height?: number } => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, "text/html");

    for (const meta of Array.from(doc.getElementsByTagName("meta"))) {
      const content = meta.getAttribute("content");
      const match = content?.match(/width=(\d+),height=(\d+)/);
      if (match) {
        const width = parseInt(match[1], 10);
        const height = parseInt(match[2], 10);
        return { isValid: true, width, height };
      }
    }

    return { isValid: false };
  };

  const [form] = useForm();

  const [visible, setVisible] = useState(false);

  const [url, setURL] = useState("");

  const [html, setHtml] = useState({
    html: "",
    size: "",
  });

  const { img, setImg, deleteCreative } = useContext(
    CreativeContext
  ) as Creative;

  const mainImgFile = useRef<File>();

  const queryClient = useQueryClient();

  const close = () => setVisible(false);

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType="CREATIVES"
          />
        );
      case "state":
        return (
          <RetailStateColumn
            value={value}
            type="creatives"
            review_note={records?.review_note}
          />
        );
      case "image_url":
        return (
          <RetailCreativeImgColumn
            onClick={() => {
              setURL(value);
              setHtml({
                html: records?.html,
                size: records?.size,
              });
              setVisible(true);
            }}
            value={value}
            html={records?.html}
          />
        );
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      case "languages":
        return <RetailCreativeLanguageColumn value={value} />;
      default:
        return value ? value : "-";
    }
  };

  const resetUploadStates = (success?: boolean) => {
    setFirstDrawerStates({ visible: false, selected: "" });
    setSecondDrawerStates({
      visible: false,
      name: "",
      showCreative: false,
      isLoading: false,
      textValue: "",
    });
    form.resetFields();
    setInputError(false);

    if (!success && img.length > 0) deleteCreative(img[img.length - 1].uuid);
  };

  const updateValue = async () => {
    const config = {
      creatives: [img[img.length - 1].uuid],
    };

    try {
      const response = await api.patch(`campaigns/${id}`, config);
      return response;
    } catch (error) {
      TrackJS.track({
        message: "Campaign Patch Error",
        metadata: {
          endpoint: `campaigns/${id}`,
          requestBody: config,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error.response?.data,
          statusCode: error.response?.status,
          errorMessage: error.message,
          severity: "Medium"
        },
      });
    }

  };

  const { mutateAsync } = useMutation(updateValue);

  const addCreatives = async () => {
    try {
      await mutateAsync();
      await new Promise((resolve) => setTimeout(resolve, 250));
      await queryClient.refetchQueries("table");
    } catch (e) {
      console.error(e);
    }
  };

  const uploadCreative = async (
    info: UploadChangeParam<UploadFile<any>> | null,
    options: {
      type: "image" | "video" | "vast" | "script";
      name?: string;
      textValue?: string;
      size?: string;
      onSuccess?: (response: any) => void;
      onError?: (error: any) => void;
    }
  ) => {
    const { type, onSuccess, onError, name, textValue, size } = options;

    const file = mainImgFile;
    if (type !== "script") {
      if (!info || !info.file || info.fileList.length === 0) {
        file.current = undefined;
        return;
      }
      file.current = info.file as unknown as File;
    }

    const formData = new FormData();

    formData.append(
      "name",
      type === "script" ? name! : file?.current?.name.replace(/\.[^/.]+$/, "")!
    );
    formData.append("status", "ACTIVE");
    formData.append(
      "format",
      info?.file?.type === "image/gif" ? "GIF" : "IMAGE"
    );

    if (type === "image") formData.append("image", file.current!);
    else if (type === "script") {
      formData.append("html", textValue!);
      formData.append("size", size!);
    }

    const config = {
      ...(type === "image" && { image: formData.get("image") }),
      body: JSON.stringify({
        name: formData.get("name"),
        status: formData.get("status"),
        format: formData.get("format"),
        ...(type === "script" && {
          html: formData.get("html"),
          size: formData.get("size"),
        }),
      }),
    };

    try {
      const response = await api.post("/creatives", config, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await handleSuccess(response, type.toLowerCase() as "image" | "script");
      if (onSuccess) onSuccess(response);
    } catch (error) {
      TrackJS.track({
        message: "Creative Upload Error",
        metadata: {
          endpoint: "/creatives",
          requestBody: config,
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error.response?.data,
          statusCode: error.response?.status,
          errorMessage: error.message,
          severity: "Medium"
        },
      });
      if (onError) onError(error);
    }
  };

  const handleFileChange = async (
    info: UploadChangeParam<UploadFile<any>>,
    type: "image" | "video" | "vast" | "script"
  ) => {
    await uploadCreative(info, {
      type,
      onSuccess: () => {
        setSecondDrawerStates((prev) => ({
          ...prev,
          showCreative: true,
          isLoading: false,
        }));
      },
      onError: (error) => handleError(error, type),
    });
  };

  const handleError = (
    error: any,
    type: "image" | "video" | "vast" | "script"
  ) => {
    console.error(error);
    setSecondDrawerStates((prev) => ({ ...prev, isLoading: false }));

    let errKey = "generalErr";

    if (type === "image" && error?.request?.responseText) {
      const responseText = error.request.responseText;
      errKey = responseText.includes("maximum number of creatives for SMO")
        ? "creativeLimitErr"
        : responseText.includes("file size is too large")
          ? "creativeSizeErr"
          : "generalErr";
    }

    RetailNotification.showNotification(
      "error",
      "",
      t(`components.campaignForm.firstStep.errorStates.${errKey}`)
    );
  };

  const props: UploadProps = {
    name: "files",
    multiple: false,
    disabled: secondDrawerStates.isLoading,
    className: cc(["dragger", cm.creativeUploadDragger]),
    beforeUpload: () => false,
    itemRender: () => <></>,
    onChange: (f) => handleFileChange(f, "image"),
    openFileDialogOnClick: openUpload,
    accept: ACCEPTED_FILE_TYPES["IMAGE"],
  };

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: "CREATIVES",
    /* filters: productFilters(t), */
    renderColumns,
  };

  const handleSuccess = async (
    response: any,
    type: "image" | "video" | "vast" | "script"
  ) => {
    if (type === "image") {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = response.data.image_url;
        img.onload = () => {
          setImg((prev: Img[]) => [
            ...prev,
            {
              url: img.src,
              size: `${img.width}x${img.height}`,
              creative_name: response.data.creative_name,
              uuid: response.data.id,
              size_valid: response.data.size_valid,
              languages: response.data.languages,
            },
          ]);
          resolve();
        };
      });
    }
    if (type === "script") {
      return setImg((prev: Img[]) => [
        ...prev,
        {
          url: response?.data?.html,
          size: response?.data?.size,
          creative_name: response?.data?.creative_name,
          uuid: response?.data?.id,
          languages: response?.data?.languages,
          submitted: true,
        },
      ]);
    }
  };

  const openFirstDrawer = () =>
    setFirstDrawerStates({ visible: true, selected: "" });

  const cancelAllDrawers = () => resetUploadStates(false);

  const closeSecondDrawer = () => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: false,
    });
    setFirstDrawerStates({ ...firstDrawerStates, selected: "" });
  };
  const openSecondDrawer = () =>
    setSecondDrawerStates({
      ...secondDrawerStates,
      visible: true,
    });

  const changeUploadType = ({ target }: RadioChangeEvent) => {
    setFirstDrawerStates({ ...firstDrawerStates, selected: target.value });
    openSecondDrawer();
  };

  const updateCreativeName = async () => {
    let success = false;
    const uploadedDisplayID = img[img.length - 1]?.uuid;

    try {
      const response = await api
        .patch(`/creatives/${uploadedDisplayID}`, {
          name: form.getFieldValue("creative_name"),
          ...(form.getFieldValue("creative_languages").length > 0 && {
            languages: form.getFieldValue("creative_languages"),
          }),
        })
        .then((response) => {
          setImg((allImgs: Img[]) =>
            allImgs.map((obj: Img) => {
              if (obj.uuid === uploadedDisplayID) {
                return {
                  ...obj,
                  creative_name: response.data.creative_name,
                  languages: response.data.languages,
                };
              }
              return obj;
            })
          );
        });
      success = true;
      RetailNotification.showNotification(
        "success",
        "",
        t("components.notification.creativeUpload"),
        "bottomLeft"
      );
      return response;
    } catch (error) {
      console.error(error);
      success = false;
    } finally {
      resetUploadStates(success);
    }
  };

  const changeShowCreative = () =>
    setSecondDrawerStates({ ...secondDrawerStates, showCreative: false });

  const postCreativeWithScript = async () => {
    await uploadCreative(null, {
      type: "script",
      name: secondDrawerStates.name,
      textValue: secondDrawerStates.textValue,
      size: secondDrawerStates.size,
      onSuccess: () => {
        resetUploadStates(true);
        RetailNotification.showNotification(
          "success",
          "",
          t("components.notification.creativeUpload"),
          "bottomLeft"
        );
        addCreatives();
      },
      onError: (error) => handleError(error, "script"),
    });
  };

  const onOk = () => {
    form.submit();
  };

  const validateTextInput = (input: string) => {
    const isValidHtml = validateInput(input).isValid;
    setInputError(!isValidHtml);
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setSecondDrawerStates({
      ...secondDrawerStates,
      textValue: target.value,
      showCreative: target.value !== "",
    });
    validateTextInput(target.value);
  };

  const handleInputPaste = (event: ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData("text");
    setSecondDrawerStates((prevState) => {
      const newState = {
        ...prevState,
        showCreative: pastedText !== "",
      };
      validateInput(pastedText);
      return newState;
    });
  };

  const switchOnFinish = () => {
    if (firstDrawerStates.selected === "DISPLAY_UPLOAD") {
      updateCreativeName();
      addCreatives();
    } else {
      postCreativeWithScript();
    }
  };

  const prepareHtmlWithStyles = (htmlContent: string) => {
    const styleTag = `
      <style>
      html {
         width: 100%;
         height: 100%;
      }
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          margin: 0 auto;
        }
      </style>
    `;

    // Insert the style tag into the head of the user's HTML
    if (htmlContent.includes("<head>")) {
      return htmlContent.replace("<head>", `<head>${styleTag}`);
    }

    // If <head> is missing, inject it before <body>
    if (htmlContent.includes("<body>")) {
      return htmlContent.replace("<body>", `<head>${styleTag}</head><body>`);
    }

    // As a fallback, wrap the HTML in a complete document
    return `
      <!DOCTYPE html>
      <html>
      <head>${styleTag}</head>
      <body>${htmlContent}</body>
      </html>
    `;
  };

  return (
    <>
      <RetailTable
        button={{
          title: t("pages.acc.campaignDetails.creativesBtn"),
          onClick: openFirstDrawer,
        }}
        placeholder={t("pages.acc.campaignDetails.creativesPlaceholder")}
        tableConfig={tableConfig}
      />
      <RetailDrawer
        visible={firstDrawerStates.visible}
        onOk={openSecondDrawer}
        onClose={cancelAllDrawers}
        width={820}
        title={t("components.campaignForm.firstStep.firstDrawerTitle")}
        type="steps"
        disableOk={firstDrawerStates.selected === ""}
        footer={null}
      >
        <RetailTitle level={5} noMargin>
          {t("components.campaignForm.firstStep.creativeTypeTitle")}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t("components.campaignForm.firstStep.creativeTypeText")}
        </RetailText>
        <CreativeStepOptions
          t={t}
          changeUploadType={changeUploadType}
          firstDrawerStates={firstDrawerStates}
          adType={selectCampaignType(type)}
          allowHtml={allowHtml}
        />
      </RetailDrawer>
      <RetailDrawer
        title={t(
          `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_second_drawer_title`
        )}
        visible={secondDrawerStates.visible}
        onOk={onOk}
        onClose={closeSecondDrawer}
        onCloseAll={cancelAllDrawers}
        width={772}
        type="add"
        cancelText={t("common.goBack")}
        disableOk={
          secondDrawerStates.name === "" ||
          (firstDrawerStates.selected === "SCRIPT" &&
            !secondDrawerStates.size) ||
          !secondDrawerStates.showCreative ||
          inputError ||
          img[img.length - 1]?.size_valid === false
        }
      >
        <CreativeNameForm
          onFinish={switchOnFinish}
          form={form}
          formStates={{
            name: secondDrawerStates.name,
            size: secondDrawerStates.size,
          }}
          handleSize={(value) =>
            setSecondDrawerStates({ ...secondDrawerStates, size: value })
          }
          languages={languages}
          selected={firstDrawerStates.selected}
          onChange={(e) =>
            setSecondDrawerStates({
              ...secondDrawerStates,
              name: e.target.value,
            })
          }
        />
        <RetailTitle level={5} noMargin>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}`
          )}
        </RetailTitle>
        <RetailText size="xxxs" weight="medium" className={cm.drawerText}>
          {t(
            `components.campaignForm.firstStep.${firstDrawerStates.selected.toLowerCase()}_drawer`
          )}
        </RetailText>

        {firstDrawerStates.selected === "DISPLAY_UPLOAD" ? (
          <SecondDrawerUploadInner
            showCreative={secondDrawerStates.showCreative}
            video={[]}
            changeShowCreative={changeShowCreative}
            img={img}
            adType={selectCampaignType(type)}
            setOpenUpload={setOpenUpload}
            loading={secondDrawerStates.isLoading}
            error={img[img.length - 1]?.size_valid === false}
            {...props}
          />
        ) : (
          <>
            {inputError && (
              <article className={`flex ${cm.errorContainer}`}>
                <RetailText size="xxxs" weight="medium">
                  {t(
                    `components.campaignForm.firstStep.errorStates.${firstDrawerStates.selected.toLowerCase()}`
                  )}
                </RetailText>
              </article>
            )}
            <Tabs className={cm.tabs}>
              <Tabs.TabPane
                tab={t("components.campaignForm.firstStep.code")}
                key="CODE"
              >
                <Input.TextArea
                  value={secondDrawerStates.textValue}
                  onChange={handleInputChange}
                  onPaste={handleInputPaste}
                  className={cc([
                    "floating",
                    cm.textarea,
                    inputError ? cm.errorInput : "",
                  ])}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t("components.campaignForm.firstStep.preview")}
                key="PREVIEW"
              >
                <iframe
                  srcDoc={prepareHtmlWithStyles(
                    secondDrawerStates.textValue || ""
                  )}
                  title={secondDrawerStates.name}
                  sandbox="allow-scripts allow-same-origin"
                  width="100%"
                  height={validateInput(secondDrawerStates.textValue || "").isValid ? validateInput(secondDrawerStates.textValue || "").height : 300}
                  className={cm.iframe}
                  allowFullScreen={true}
                />
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </RetailDrawer>
      <CreativePreviewModal
        type="creative"
        url={url}
        html={html.html}
        size={html.size}
        visible={visible}
        onCancel={close}
      />
    </>
  );
};

export default CreativesTable;
