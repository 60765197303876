import { Checkbox, Progress } from "antd";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import { ReactComponent as AddCircleOutlinedThick } from "assets/icons/addCircleOutlinedThick.svg";
import { ReactComponent as BlockFilled } from "assets/icons/blockFilled.svg";
import { ReactComponent as DownOutlined } from "assets/icons/downOutlined.svg";
import { ReactComponent as NotificationIconFilled } from "assets/icons/notificationIconFilled.svg";
import { ReactComponent as QuestionCircleFilled } from "assets/icons/questionCircleFilled.svg";
import { ReactComponent as RemoveCircleOutlined } from "assets/icons/removeCircleOutlined.svg";
import { ReactComponent as UpOutlined } from "assets/icons/upOutlined.svg";
import { ReactComponent as WarningCircleFilled } from "assets/icons/warningCircleFilled.svg";
import cc from "classcat";
import RetailFormInfo from "components/Form/RetailFormInfo";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailFormRow from "components/Form/RetailFormRow";
import RetailSuggestedTag from "components/Tag/RetailSuggestedTag";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { marketplaceName } from "utils/defaults";
import {
  budgetErrorMessage,
  selectCampaignType,
  validateNumber,
} from "utils/helpers";
import { AdPlacement, AdType } from "utils/types";

import cm from "../style.module.scss";
import DateInput from "./DateInput";
import DayParting from "./DayParting";
import MultiRegion from "./MultiRegion";
import NameInput from "./NameInput";
import SpendingDistribution from "./SpendingDistribution";
import SponsoredProducts from "./SponsoredProducts";
import WithProductsWrapper from "./WithProductsWrapper";
import { useCurrency } from "context/CurrencyProvider";

export interface RetailCampaignFormFirstStepProps {
  requiredFields: any;
  handleChange(value: any, name: string): void;
  handleFieldChange(e: any): void;
  handleErrorChange(value: any, name: string): void;
  closeDatePicker(): void;
  errorStates: any;
  advertiserInfo: any;
  bidError: boolean;
  form: any;
  schedule: boolean[][];
  setSchedule: (schedule: boolean[][]) => void;
  data: any;
}

export const RetailCampaignFormFirstStep = ({
  requiredFields,
  handleChange,
  handleFieldChange,
  handleErrorChange,
  closeDatePicker,
  errorStates,
  advertiserInfo,
  bidError,
  form,
  schedule,
  setSchedule,
  data,
}: RetailCampaignFormFirstStepProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const { currencySymbol } = useCurrency();

  const ad_type = location.state as {
    ad_type: AdType;
  };

  const advertiserStateForLinks = advertiserInfo?.data;

  const [visible, setVisible] = useState(false);

  const [totalBudgetVisible, setTotalBudgetVisible] = useState(false);

  const [campaignDuration, setCampaignDuration] = useState<{
    days: number | string;
    color: string | null;
    percentage: number;
    className: string;
  }>({
    days: 0,
    color: null,
    percentage: 0,
    className: "",
  });

  const {
    name,
    date_type,
    start_date,
    end_date,
    budget_type,
    budgetDaily,
    budget,
    bid,
    acos,
    targeting,
    dataBidType,
    placements,
    redirect_url,
    connected_with_products,
  } = requiredFields;

  const {
    acosError,
    startError,
    dailyBudgetError,
    totalBudgetError,
    redirectURLError,
  } = errorStates;

  const isCampaignDurationCalculatetable = budget !== "" && budgetDaily !== "";

  const calculateCampaignDuration = useCallback(() => {
    const days = Math.ceil(+budget / +budgetDaily);
    if (budgetDaily === "0" || budget === "0") {
      setCampaignDuration({
        days: 0,
        color: "var(--red-5)",
        percentage: 0,
        className: cm.red,
      });
    } else if (days <= 1)
      setCampaignDuration({
        days,
        color: "var(--red-5)",
        percentage: 15,
        className: cm.red,
      });
    else if (days <= 3)
      setCampaignDuration({
        days: days,
        color: "var(--yellow-6)",
        percentage: 35,
        className: cm.yellow,
      });
    else if (days <= 6)
      setCampaignDuration({
        days: days,
        color: "var(--blue-10)",
        percentage: 75,
        className: cm.blue,
      });
    else if (days >= 7)
      setCampaignDuration({
        days: days,
        color: "var(--green-9)",
        percentage: 100,
        className: cm.green,
      });
  }, [budget, budgetDaily]);

  useEffect(() => {
    if (budgetDaily !== "" && budget !== "")
      handleChange("FLEXIBLE", "budget_type");
    else if (budgetDaily !== "" && budget === "")
      handleChange("DAILY", "budget_type");
    else if (budgetDaily === "" && budget !== "")
      handleChange("TOTAL", "budget_type");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budget, budgetDaily]);

  useEffect(() => {
    if (!isCampaignDurationCalculatetable) return;
    else calculateCampaignDuration();
  }, [
    calculateCampaignDuration,
    isCampaignDurationCalculatetable,
    budget,
    budgetDaily,
  ]);

  const isErrorForBorder = (
    error: boolean,
    firstCondition: boolean,
    secondCondition: boolean
  ) => {
    if (error && firstCondition && secondCondition) {
      return cm.errorBorder;
    } else return "";
  };

  const isAutoTargetingInputErrorBorder = () => {
    const isInitialError = errorStates.bidError && bidError;
    const isTypeError =
      dataBidType === "FIXED" && targeting === "AUTO_TARGETING";
    const isBidError = bid < chosenMinBid();

    if (isInitialError) return cm.errorBorder;
    else {
      return isErrorForBorder(isInitialError, isTypeError, isBidError);
    }
  };

  const bidTypes = data?.data.available_bidding_types?.sort();

  const bidInputs = () => {
    if (ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE") {
      return bidTypes?.length === 1
        ? switchBetweenBids()
        : switchBetweenTwoBids();
    } else return <Fixed />;
  };

  const chooseMinimum = () => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return data?.data?.sponsored_display_price_setting;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
      case "IN_STORE":
        return data?.data?.sponsored_video_price_setting;
      default:
        return data?.data?.sponsored_product_price_setting;
    }
  };

  const chosenMinBid = () => {
    if (chooseMinimum() === "CPM") return data?.data?.minimum_cpm;
    else return data?.data?.minimum_bid;
  };

  const handleTotalBudgetVisibility = () => {
    setTotalBudgetVisible(!totalBudgetVisible);
    handleChange("", "budget");
    form.setFieldsValue({ total_budget: "" });
  };

  const acosInput = () => {
    return (
      <>
        {(ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE") && (
          <Radio
            value="MAX_ACOS"
            className={cc([
              "form-radio",
              cm.marginTop,
              cm.maxAcos,
              isErrorForBorder(
                acosError,
                dataBidType === "MAX_ACOS",
                acos === ""
              ),
            ])}
            onChange={({ target }) => handleChange(target.value, "bid_type")}
            data-test="campaign-form-bid-type-max-acos"
          >
            <RetailTitle level={5} className="flex">
              {t("components.campaignForm.firstStep.targetAcosTitle")}
            </RetailTitle>
            <RetailText className={cm.dynamicText} size="xxs" family="poppins">
              {t("components.campaignForm.firstStep.targetAcosText")}
            </RetailText>
            {dataBidType === "MAX_ACOS" && (
              <RetailFormInput
                isFocused={acos > 0}
                label={t("common.table.acos")}
                tooltip={{
                  title: (
                    <RetailText
                      family="poppins"
                      size="xxxs"
                      style={{ color: "white" }}
                    >
                      {t("components.campaignForm.firstStep.acosTooltip")}
                    </RetailText>
                  ),
                  trigger: ["hover"],
                  className: cm.tooltip,
                  icon: <QuestionCircleFilled />,
                  overlayInnerStyle: {
                    borderRadius: "8px",
                    width: "21rem",
                    padding: "10px 12px",
                  },
                  color: "var(--primary-6)",
                }}
                className={cc([
                  "floating",
                  cm.acos,
                  acos > 0 ? cm.showPrefix : cm.hidePrefix,
                ])}
                name="acos"
                rules={[
                  {
                    required: true,
                    pattern:
                      /^(?!0(\.0{1,4})?$)(0*\d{0,2}(\.\d{1,4})?|100(\.0{1,4})?)$/,
                    message: t("components.campaignForm.firstStep.acosError"),
                  },
                ]}
              >
                <Input
                  prefix="%"
                  className="number-input floating"
                  onChange={({ target }) => handleChange(target.value, "acos")}
                  onKeyDownCapture={(e) => validateNumber(e)}
                  data-test="campaign-form-bid-type-max-acos-amount"
                />
                {/* {isErrorForBorder(
                  acosError,
                  bid_type === "MAX_ACOS",
                  acos === ""
                ) !== "" && <WarningCircleFilled />} */}
              </RetailFormInput>
            )}
          </Radio>
        )}
      </>
    );
  };

  const Fixed = () => {
    return (
      <Radio
        value="FIXED"
        className={cc([
          "form-radio",
          cm.marginTop,
          isErrorForBorder(
            errorStates.bidError,
            dataBidType === "FIXED" && targeting === "AUTO_TARGETING",
            bid < chosenMinBid()
          ),
        ])}
        onChange={({ target }) => handleChange(target.value, "bid_type")}
        data-test="campaign-form-bid-type-fixed"
      >
        <RetailTitle level={5}>
          {t("components.campaignForm.firstStep.fixedBid")}
        </RetailTitle>
        <RetailText className={cm.radioText} size="xxs" family="poppins">
          {t("components.campaignForm.firstStep.fixedBidText")}
        </RetailText>
      </Radio>
    );
  };

  const Standart = () => {
    return (
      <Radio
        value="STANDART"
        className="form-radio"
        onChange={({ target }) => handleChange(target.value, "bid_type")}
        data-test="campaign-form-bid-type-standart"
      >
        <RetailTitle level={5} className="flex">
          {t("components.campaignForm.firstStep.dynamicBid")}
          <RetailSuggestedTag />
        </RetailTitle>
        <RetailText className={cm.dynamicText} size="xxs" family="poppins">
          {t("components.campaignForm.firstStep.dynamicBidText")}
        </RetailText>
      </Radio>
    );
  };

  const switchBetweenBids = () => {
    switch (bidTypes[0]) {
      case "ACOS":
        return acosInput();
      case "AUTO":
      case "FIXED":
        return null;
      default:
        break;
    }
  };

  const switchBetweenTwoBids = () => {
    switch (bidTypes.toString()) {
      case "AUTO,FIXED":
        return (
          <>
            <Standart />
            <Fixed />
          </>
        );
      case "ACOS,AUTO":
        return (
          <>
            <Standart />
            {acosInput()}
          </>
        );
      case "ACOS,FIXED":
        return (
          <>
            {acosInput()}
            <Fixed />
          </>
        );
      case "ACOS,AUTO,FIXED":
        return (
          <>
            <Standart />
            {acosInput()}
            <Fixed />
          </>
        );
      default:
        break;
    }
  };

  const isBidColonVisible = () => {
    const isCreatives =
      selectCampaignType(ad_type?.ad_type) === "DISPLAY" ||
      selectCampaignType(ad_type?.ad_type) === "VIDEO";
    if (bidTypes === undefined || isCreatives) return false;
    if (bidTypes.includes("ACOS") || bidTypes.length > 1) return true;
    if (bidTypes.includes("ACOS") || bidTypes?.length > 1) return true;
    return false;
  };

  const minDailyBudgetFromSettings = () => {
    if (ad_type?.ad_type === "DISPLAY_WITH_PRODUCT")
      return data?.data?.min_sponsored_display_daily_budget;
    if (
      ad_type?.ad_type === "VIDEO_WITH_PRODUCT" ||
      ad_type?.ad_type === "IN_STORE"
    )
      return data?.data?.min_sponsored_video_daily_budget;
    else
      return data?.data?.[
        `min_sponsored_${ad_type?.ad_type?.toLowerCase()}_daily_budget`
      ];
  };

  const totalBudgetAmount = () => {
    if (start_date !== "" && end_date !== "")
      return (
        Number(moment(end_date).diff(moment(start_date), "days") + 1) *
        minDailyBudgetFromSettings()
      );
    else return minDailyBudgetFromSettings();
  };

  const targetingType = () => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return data?.data?.available_display_targeting_types;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
      case "IN_STORE":
        return data?.data?.available_video_targeting_types;
      default:
        return data?.data?.available_product_targeting_types;
    }
  };

  const onlyManualTargeting =
    Array.isArray(targetingType()) &&
    targetingType()?.length === 1 &&
    targetingType()?.[0] === "MANUAL";

  const notTargeting =
    targetingType()?.length === 0 ||
    (targetingType()?.length === 1 && targetingType()[0] === "AUTO");

  const switchCampaignTypeStart = () => {
    switch (ad_type?.ad_type) {
      case "PRODUCT":
      case "STORE":
        return (
          <SponsoredProducts
            error={errorStates.productError}
            targeting={targeting}
            isBidColonVisible={isBidColonVisible()}
            ad_type={ad_type?.ad_type}
            advertiserStateForLinks={advertiserStateForLinks}
          />
        );
      default:
        return;
    }
  };

  const switchCampaignTypePart = () => {
    switch (ad_type?.ad_type) {
      case "PRODUCT":
      case "STORE":
        return;
      default:
        return (
          <WithProductsWrapper
            targeting={targeting}
            redirect_url={redirect_url}
            languages={data?.data?.creative_languages}
            allowHtml={data?.data?.allow_html_creatives}
            redirectURLError={redirectURLError}
            connected_with_products={connected_with_products}
            type={ad_type?.ad_type}
            displayError={errorStates.displayError}
            videoError={errorStates.videoError}
            productError={errorStates.productError}
            handleFieldChange={handleFieldChange}
          />
        );
    }
  };

  return (
    <>
      {/**
       * NAME INPUT
       */}
      {switchCampaignTypeStart()}
      <NameInput
        name={name}
        columnNumber={
          ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
            ? "2"
            : "1"
        }
        handleFieldChange={handleFieldChange}
      />
      {/**
       * DATE INPUT
       */}
      <DateInput
        start_date={start_date}
        end_date={end_date}
        date_type={date_type}
        columnNumber={
          ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
            ? "3"
            : "2"
        }
        startError={startError}
        closeDatePicker={closeDatePicker}
        handleChange={handleChange}
      />
      {/**
       * BUDGET INPUT
       */}
      <RetailFormRow className="form-row">
        <RetailFormInfo
          column={{
            number:
              ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
                ? "4"
                : "3",
            title: t("components.campaignForm.firstStep.budget"),
            text: t("marketplaceDependent.campaign_form_budget_text"),
          }}
          /* article={{
            text: t("components.articles.budget"),
            type: "budget",
          }} */
        />
        <Col className={cm.budgetContainer}>
          <RetailFormInput
            isFocused={budgetDaily !== ""}
            label={t("components.campaignForm.firstStep.dailyBudgetLabel")}
            className={cc([
              "floating inside show-message",
              budgetDaily === "" ? cm.hidePrefix : cm.showPrefix,
            ])}
            name="daily_budget"
            dependencies={["total_budget"]}
            extra={
              <RetailText
                family="poppins"
                size="xxxs"
                className={cm.budgetHelp}
              >
                {t("components.campaignForm.firstStep.dailyBudgetExtra")}
              </RetailText>
            }
            rules={[
              {
                required: budget_type !== "TOTAL",
                message: t("components.campaignForm.firstStep.budgetError", {
                  value: `${minDailyBudgetFromSettings()}${currencySymbol}`,
                }),
              },
              () => ({
                validator(_, value) {
                  if (!value || value >= minDailyBudgetFromSettings()) {
                    return Promise.resolve();
                  } else {
                    handleErrorChange(true, "dailyBudgetError");
                    return budgetErrorMessage(
                      minDailyBudgetFromSettings(),
                      t,
                      currencySymbol
                    );
                  }
                },
              }),
            ]}
          >
            <Input
              className="number-input floating"
              prefix={currencySymbol}
              onChange={({ target }) =>
                handleChange(target.value, "budgetDaily")
              }
              value={budgetDaily > 0 ? budgetDaily : ""}
              onKeyDownCapture={(e) => validateNumber(e)}
              data-test="campaign-form-budget-type-daily-amount"
              suffix={
                isErrorForBorder(
                  dailyBudgetError,
                  budgetDaily === "",
                  budget_type === "DAILY"
                ) !== "" ? (
                  <WarningCircleFilled className={cm.errorIcon} />
                ) : null
              }
            />
          </RetailFormInput>
          <div className={cm.totalBudgetWrapper}>
            <div
              className={cc([
                cm.totalBudgetContainer,
                totalBudgetVisible ? cm.open : "",
              ])}
            >
              <RetailText
                size="xs"
                weight="medium"
                className={cm.totalBudgetTitle}
              >
                {t("components.campaignForm.firstStep.totalBudget")}
              </RetailText>
              <span
                onClick={handleTotalBudgetVisibility}
                className={cc([
                  cm.totalBudgetButton,
                  totalBudgetVisible ? cm.open : "",
                ])}
              >
                {t(`common.${totalBudgetVisible ? "remove" : "add"}`)}
                <span>
                  {totalBudgetVisible ? (
                    <RemoveCircleOutlined />
                  ) : (
                    <AddCircleOutlinedThick />
                  )}
                </span>
              </span>
            </div>
            <div
              className={cc([
                cm.totalBudgetSection,
                totalBudgetVisible ? cm.open : "",
              ])}
            >
              <RetailFormInput
                isFocused={budget !== ""}
                label={t("components.campaignForm.firstStep.totalBudgetLabel")}
                className={cc([
                  "floating inside",
                  cm.totalBudgetInput,
                  budget !== "" ? cm.showPrefix : cm.hidePrefix,
                ])}
                name="total_budget"
                validateFirst
                extra={
                  <RetailText
                    family="poppins"
                    size="xxxs"
                    className={cm.budgetHelp}
                  >
                    {t("components.campaignForm.firstStep.totalBudgetExtra")}
                  </RetailText>
                }
                rules={[
                  {
                    required: budget_type !== "DAILY",
                    message: t(
                      "components.campaignForm.firstStep.budgetErrorMessage"
                    ),
                  },
                  () => ({
                    validator(_, value) {
                      if (budget_type === "TOTAL") {
                        if (date_type === "STOPPED") {
                          if (!value || value >= minDailyBudgetFromSettings()) {
                            return Promise.resolve();
                          }
                          handleErrorChange(true, "totalBudgetError");
                          return budgetErrorMessage(
                            minDailyBudgetFromSettings(),
                            t,
                            currencySymbol
                          );
                        } else {
                          if (!value || value >= totalBudgetAmount())
                            return Promise.resolve();
                          handleErrorChange(true, "totalBudgetError");
                          return budgetErrorMessage(
                            totalBudgetAmount(),
                            t,
                            currencySymbol
                          );
                        }
                      } else if (budget_type === "FLEXIBLE") {
                        if (!value || value >= +budgetDaily)
                          return Promise.resolve();
                        else {
                          handleErrorChange(true, "totalBudgetError");
                          return budgetErrorMessage(
                            budgetDaily,
                            t,
                            currencySymbol
                          );
                        }
                      } else return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  prefix={currencySymbol}
                  className="number-input floating"
                  onChange={({ target }) =>
                    handleChange(target.value, "budget")
                  }
                  onKeyDownCapture={(e) => validateNumber(e)}
                  value={budget}
                  data-test="campaign-form-budget-type-total-amount"
                  suffix={
                    isErrorForBorder(
                      totalBudgetError,
                      budget === "",
                      budget_type === "TOTAL"
                    ) !== "" ? (
                      <WarningCircleFilled className={cm.errorIconTotal} />
                    ) : null
                  }
                />
              </RetailFormInput>
              {isCampaignDurationCalculatetable && (
                <div
                  className={cc(["flex", cm.totalBudgetCalculationContainer])}
                >
                  <Progress
                    type="circle"
                    trailColor="var(--secondary-3)"
                    width={52}
                    strokeColor={campaignDuration.color || "teal"}
                    format={() => (
                      <div className={cc(["flex", cm.progressText])}>
                        <RetailText size="xxxs" className={cm.durationInfo}>
                          <strong>{campaignDuration.days}</strong>
                        </RetailText>
                        <RetailText className={cm.day} weight="bold">
                          {t("common.day")}
                        </RetailText>
                      </div>
                    )}
                    percent={campaignDuration.percentage}
                  />
                  <RetailText
                    size="xxxs"
                    weight="medium"
                    className={cc([
                      cm.durationText,
                      campaignDuration.className,
                    ])}
                  >
                    <Trans
                      i18nKey={t(
                        "components.campaignForm.firstStep.durationText",
                        {
                          value: campaignDuration.days,
                        }
                      )}
                    />
                  </RetailText>
                </div>
              )}
            </div>
          </div>
        </Col>
      </RetailFormRow>
      <SpendingDistribution
        fields={{
          spending_type: requiredFields.spending_type,
          front_loaded_percentage: requiredFields.front_loaded_percentage,
        }}
        columnNumber={
          ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
            ? "5"
            : "4"
        }
        error={errorStates.spendingError}
        handleFieldChange={handleFieldChange}
      />
      {isBidColonVisible() && (
        <RetailFormRow className="form-row">
          <RetailFormInfo
            column={{
              number:
                ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE"
                  ? "6"
                  : "5",
              title: t("components.campaignForm.firstStep.bid"),
              text: t("components.campaignForm.firstStep.bidText"),
            }}
            article={{
              text: t("components.articles.bid"),
              type: "bid",
            }}
            advertiserStateForLinks={advertiserStateForLinks}
          />
          <Col className={cm.relative}>
            <Form.Item name="bid_type" className="flex reversed">
              <Radio.Group
                name="bid_type"
                onChange={handleFieldChange}
                data-test="campaign-form-bid-type"
              >
                {bidInputs()}
              </Radio.Group>
            </Form.Item>
          </Col>
        </RetailFormRow>
      )}

      <RetailFormRow className={cc([cm.placementRow, "form-row"])}>
        <RetailFormInfo
          column={{
            number:
              isBidColonVisible() &&
              (ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE")
                ? "7"
                : !isBidColonVisible() &&
                  (ad_type?.ad_type === "PRODUCT" ||
                    ad_type?.ad_type === "STORE")
                ? "6"
                : "5",
            title: t("components.campaignForm.secondStep.placement"),
            text: t("marketplaceDependent.campaign_form_targeting_text"),
          }}
          article={{
            text: t("components.articles.targeting"),
            type: "targeting",
          }}
          advertiserStateForLinks={advertiserStateForLinks}
        />
        <Form.Item
          name="targeting"
          validateStatus={errorStates.placementError ? "error" : undefined}
        >
          <Radio.Group
            name="targeting"
            className={cm.placement}
            onChange={handleFieldChange}
            data-test="campaign-form-targeting"
          >
            {!onlyManualTargeting && (
              <Radio
                value="AUTO_TARGETING"
                className={cc([
                  "form-radio",
                  cm.placementCheckbox,
                  isAutoTargetingInputErrorBorder(),
                ])}
                data-test="campaign-form-targeting-auto"
              >
                <RetailTitle
                  level={5}
                  className={cc(["flex", cm.marginBottom])}
                >
                  {t("components.campaignForm.firstStep.autoTargeting")}
                  <RetailSuggestedTag className={cm.suggestedTag} />
                </RetailTitle>
                <RetailText className={cm.radioText} family="poppins">
                  {t("components.campaignForm.firstStep.autoTargetingText")}
                </RetailText>
                {dataBidType === "FIXED" && targeting === "AUTO_TARGETING" && (
                  <>
                    <RetailFormInput
                      isFocused={requiredFields.bid !== ""}
                      label={t("components.campaignForm.secondStep.defaultBid")}
                      name="bid"
                      className={cc([
                        "floating",
                        cm.fixedBid,
                        requiredFields.bid !== ""
                          ? cm.showPrefix
                          : cm.hidePrefix,
                      ])}
                      validateStatus={
                        errorStates.bidError && bid < chosenMinBid()
                          ? "error"
                          : undefined
                      }
                      rules={[{ required: true }]}
                      help=""
                      tooltip={
                        errorStates.bidError && bid < chosenMinBid()
                          ? {
                              title: (
                                <RetailText
                                  family="poppins"
                                  size="xxxs"
                                  style={{ color: "white" }}
                                >
                                  {t("components.table.keywords.warning", {
                                    value: `${currencySymbol}${chosenMinBid()}`,
                                  })}
                                </RetailText>
                              ),
                              /*  trigger: ["hover"], */
                              className: cm.tooltip,
                              icon: <BlockFilled />,
                              overlayInnerStyle: {
                                borderRadius: "8px",
                                padding: "10px 12px",
                              },
                              color: "var(--red-5)",
                            }
                          : null
                      }
                    >
                      <Input
                        type="tel"
                        className="number-input floating"
                        prefix={currencySymbol}
                        onChange={({ target }) => {
                          handleChange(target.value, "bid");
                          bidError && handleErrorChange(true, "bidError");
                        }}
                        onKeyDownCapture={(e) => validateNumber(e)}
                        value={requiredFields.bid > 0 ? requiredFields.bid : ""}
                        data-test="campaign-form-bid-amount"
                      />
                    </RetailFormInput>
                    <RetailText
                      size="xs"
                      family="poppins"
                      className={cc([
                        cm.suggestedText,
                        errorStates.bidError && bid < chosenMinBid()
                          ? cm.error
                          : cm.success,
                      ])}
                    >
                      <>
                        {t(
                          "components.campaignForm.firstStep.suggestedBidText"
                        )}
                        {currencySymbol}
                        {chosenMinBid()}
                      </>
                    </RetailText>
                    {/* {isCreativeType && (
                      <div className={cm.connectedWarning}>
                        <BlockFilled className={cm.warningIcon} />
                        <Trans>
                          <RetailText
                            size="xxxs"
                            weight="medium"
                            className={cm.connectedWarningText}
                          >
                            {t(
                              "components.campaignForm.firstStep.autoTargetingProductWarning"
                            )}
                          </RetailText>
                        </Trans>
                      </div>
                    )} */}
                  </>
                )}
              </Radio>
            )}
            {!notTargeting && (
              <Radio
                value="MANUAL_TARGETING"
                className={cc(["form-radio", cm.keywordsRadio])}
                data-test="campaign-form-targeting-manual"
              >
                <RetailTitle level={5} className={cm.marginBottom}>
                  {t("components.campaignForm.firstStep.manualTargeting")}
                </RetailTitle>
                <RetailText className={cm.radioText} family="poppins">
                  {t("components.campaignForm.firstStep.manualTargetingText")}
                </RetailText>
              </Radio>
            )}
          </Radio.Group>
        </Form.Item>
      </RetailFormRow>
      {targeting === "MANUAL_TARGETING" && (
        <RetailFormRow className={cc([cm.placementRow, "form-row"])}>
          <RetailFormInfo
            column={{
              number:
                isBidColonVisible() &&
                (ad_type?.ad_type === "PRODUCT" || ad_type?.ad_type === "STORE")
                  ? "8"
                  : !isBidColonVisible() &&
                    (ad_type?.ad_type === "PRODUCT" ||
                      ad_type?.ad_type === "STORE")
                  ? "7"
                  : "6",
              title: t("components.campaignForm.secondStep.placementTitle"),
              text: t("components.campaignForm.secondStep.placementText"),
            }}
          />
          <Form.Item
            name="placement"
            validateStatus={errorStates.placementError ? "error" : undefined}
          >
            <Radio.Group
              className={cm.placement}
              name="placement"
              onChange={handleFieldChange}
              data-test="campaign-form-targeting-type"
            >
              <Radio
                value="IN_CATEGORY"
                className={cc(["form-radio", cm.placementCheckbox])}
                data-test="campaign-form-targeting-type-category"
              >
                <RetailTitle level={5} className="flex">
                  {t("components.campaignForm.secondStep.categories")}
                  <RetailSuggestedTag className={cm.suggestedTag} />
                </RetailTitle>
                <RetailText className={cm.radioText} family="poppins">
                  {t("components.campaignForm.secondStep.categoriesText")}
                </RetailText>
              </Radio>
              <Radio
                value="IN_SEARCH"
                className={cc(["form-radio", cm.keywordsRadio])}
                data-test="campaign-form-targeting-type-search"
              >
                <RetailTitle level={5} className={cm.marginBottom}>
                  {t("components.campaignForm.secondStep.search")}
                </RetailTitle>
                <RetailText className={cm.radioText} family="poppins">
                  {t("components.campaignForm.secondStep.searchText")}
                </RetailText>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </RetailFormRow>
      )}
      {switchCampaignTypePart()}
      {marketplaceName !== "beymen" && (
        <Col
          className={cc([
            cm.placementContainer,
            visible ? "" : cm.containerHidden,
          ])}
        >
          <RetailText
            className={cc([
              "flex",
              cm.placementContainerText,
              visible ? cm.placementTextActive : "",
            ])}
            weight="bold"
            onClick={() => setVisible(!visible)}
          >
            {t("components.campaignForm.firstStep.advancedOptions")}
            {visible ? (
              <UpOutlined className={cm.iconUp} />
            ) : (
              <DownOutlined className={cm.iconDown} />
            )}
          </RetailText>
          <div
            className={cc([
              cm.placementBorder,
              visible ? cm.placementBorderActive : "",
              placements?.length === 0 ? cm.placementError : "",
            ])}
          >
            <RetailText
              className={cc(["flex", cm.placementContainerText])}
              weight="bold"
            >
              {t("components.campaignForm.firstStep.placements")}
            </RetailText>
            <RetailText
              family="poppins"
              size="xs"
              className={cc(["flex", cm.placementContainerText])}
            >
              {t("components.campaignForm.firstStep.placementsText")}
            </RetailText>
            {data?.data?.available_ad_placement_types ? (
              <Form.Item
                name="placements"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                className={cm.placementFormItem}
              >
                <Checkbox.Group
                  className={cm.placementOptions}
                  onChange={(value) => handleChange(value, "placements")}
                >
                  {data?.data?.available_ad_placement_types.map(
                    (item: AdPlacement) => (
                      <Checkbox
                        value={item}
                        className={cc(["form-radio", cm.placementLine])}
                        key={item}
                      >
                        <RetailTitle level={5}>
                          {t(
                            `components.campaignForm.firstStep.${item.toLowerCase()}`
                          )}
                        </RetailTitle>
                        <p>
                          {t(
                            `components.campaignForm.firstStep.${item.toLowerCase()}_text`
                          )}
                        </p>
                      </Checkbox>
                    )
                  )}
                </Checkbox.Group>
              </Form.Item>
            ) : null}

            {placements?.length === 0 ? (
              <RetailText
                className={cc(["flex", cm.placementErrorText])}
                size="xxxs"
                weight="medium"
              >
                <NotificationIconFilled />
                {t(
                  "components.campaignForm.firstStep.errorStates.placementsError"
                )}
              </RetailText>
            ) : null}

            <DayParting
              page="form"
              handleFieldChange={handleFieldChange}
              isDayParting={requiredFields.dayParting === "SCHEDULED"}
              isVisible={visible}
              schedule={schedule}
              setSchedule={setSchedule}
            />
            {data?.data?.is_multi_region && (
              <MultiRegion
                page="form"
                regions={data?.data?.regions}
                selectedRegions={requiredFields.selectedRegions}
                multiRegion={requiredFields.multiRegion}
                error={errorStates.regionError}
                handleFieldChange={handleFieldChange}
                setSelectedRegions={(value: any) =>
                  handleChange(value, "selectedRegions")
                }
              />
            )}
          </div>
        </Col>
      )}
    </>
  );
};
