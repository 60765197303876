import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Steps from "antd/lib/steps";
import useApi from "api";
import { ReactComponent as CloseOutlined } from "assets/icons/closeOutlined.svg";
import { ReactComponent as LeftOutlined } from "assets/icons/leftOutlined.svg";
import { ReactComponent as RightWhiteOutlined } from "assets/icons/rightWhiteOutlined.svg";
import axios, { AxiosError, AxiosResponse } from "axios";
import cc from "classcat";
import RetailAdminBar from "components/Bar/RetailAdminBar";
import RetailMainButton from "components/Button/RetailMainButton";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailCampaignModal, {
  RetailCampaignModalHandles,
} from "components/Modal/RetailCampaignModal";
import RetailWarningModal from "components/Modal/RetailWarningModal";
import RetailNotification from "components/Notification";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import RetailCurrency from "components/Column/RetailCurrency";
import { Auth, AuthContext } from "context/AuthProvider";
import { Product, ProductContext } from "context/ProductProvider";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { TrackJS } from "trackjs";
import { CheckOutlined } from "@ant-design/icons";
import cm from "./style.module.scss";
import RetailOffsiteCampaignFormFirstStep from "./RetailOffsiteCampaignFormFirstStep";
import {
  OffsiteCampaignContext,
  OffsiteCampaignContextType,
} from "context/OffsiteCampaignProvider";

const RetailOffsiteCampaignForm = () => {
  const { t } = useTranslation();

  const {
    api,
    adminInGeneral,
    id: marketplace_id,
    role,
    isLimitedUser,
    baseURL,
  } = useApi(true);

  const { authToken, showAdminBar, advValue } = useContext(AuthContext) as Auth;

  const { setProductError } = useContext(
    OffsiteCampaignContext
  ) as OffsiteCampaignContextType;

  const { formFields } = useContext(
    OffsiteCampaignContext
  ) as OffsiteCampaignContextType;

  const [form] = Form.useForm();

  const [current, setCurrent] = useState(0);

  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [navigateData, setNavigateData] = useState({
    campaign_id: "",
    campaign_name: "",
    advertiser_id: "",
  });

  const location = useLocation();

  const advertiser = location.state as { id: number; name: string };

  const navigate = useNavigate();

  useEffect(() => {
    if (!advertiser) {
      const isAdvertiserView = location.search.includes("adv");
      isAdvertiserView
        ? navigate(`/offsite-campaigns${location.search}`)
        : navigate("/admin/offsite-campaigns");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalRef = useRef<RetailCampaignModalHandles>(null);

  const { selectedProducts, handleDeleteAllProducts } = useContext(
    ProductContext
  ) as Product;

  const resetGlobalStates = () => handleDeleteAllProducts();

  const success = (res: AxiosResponse) => {
    modalRef.current?.open();
    setNavigateData({
      campaign_id: res.data.campaign_id,
      campaign_name: formFields.adName,
      advertiser_id: advertiser?.id?.toString() || advValue || "",
    });
  };

  const switchErrorKey = (err: AxiosError) => {
    if (err?.request?.responseText?.includes("No ad account"))
      return "noAdAccountErr";
    else {
      return "generalErr";
    }
  };

  const onFinishFailed = () => setProductError(selectedProducts.length === 0);

  const error = (err: AxiosError) => {
    TrackJS.track({
      message: "Meta Campaign Creation Error",
      metadata: {
        endpoint: "/meta/campaigns/",
        requestBody: offsiteCampaign(),
        role,
        isLimitedUser: `Is user limited? ${isLimitedUser}`,
        baseURL: baseURL(),
        responseBody: err?.response?.data,
        statusCode: err?.response?.status,
        errorMessage: err?.message,
        severity: "High",
      },
    });

    return RetailNotification.showNotification(
      "error",
      "",
      t(`components.offsiteCampaignForm.${switchErrorKey(err)}`)
    );
  };

  const offsiteCampaign = () => {
    return {
      ...(adminInGeneral && {
        advertiser_id: advertiser.id,
      }),
      product_id: selectedProducts[0]?.id,
      campaign: {
        name: formFields.adName || selectedProducts[0]?.name,
        status: "ACTIVE",
      },
      ad_set: {
        daily_budget: formFields.budget,
      },
      ad_creative: {
        headline: formFields.headline,
        description: formFields.description || selectedProducts[0]?.description,
        image_data: selectedProducts[0]?.image_url,
        website: formFields.redirectUrl,
        message:
          formFields.primaryText ||
          `${selectedProducts[0]?.brand} ${selectedProducts[0]?.name}`,
      },
    };
  };

  const createOffsiteCampaignMarketplace = async () => {
    try {
      const res = await axios.post(
        `/offsite/api/marketplaces/${marketplace_id}/meta/campaigns/`,
        offsiteCampaign(),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 300;
          },
        }
      );
      success(res);
    } catch (err) {
      if (err?.response?.status >= 400) {
        error(err);
      }
    }
  };

  const createOffsiteCampaignAdvertiser = async () => {
    try {
      const res = await api.post(`/meta/campaigns/`, offsiteCampaign());
      success(res);
    } catch (err) {
      if (err?.response?.status >= 400) {
        error(err);
      }
    }
  };
  const createOffsiteCampaign = () => {
    setInfoModalVisible(false);
    adminInGeneral
      ? createOffsiteCampaignMarketplace()
      : createOffsiteCampaignAdvertiser();
  };

  const onCancelInfoModal = () => setInfoModalVisible(false);

  const onFinish = () => setInfoModalVisible(true);

  const stepInformation = [
    {
      title: `1. ${t("components.offsiteCampaignForm.campaignInfoTitle")}`,
      sub:
        current === 0 ? (
          <span className={cm.currentSubtitle}>{t("common.now")}</span>
        ) : (
          <span className={cc([cm.finishedSubtitle, "flex"])}>
            {t("common.completed")} <CheckOutlined />
          </span>
        ),
    },
  ];

  const switchStep = () => {
    switch (current) {
      case 0:
        return (
          <RetailOffsiteCampaignFormFirstStep
            form={form}
            advertiser={advertiser}
          />
        );
    }
  };

  const openWarningModal = () => setWarningModalVisible(true);

  const onCancel = () => openWarningModal();

  const onWarningModalOk = () => {
    adminInGeneral
      ? navigate("/admin/offsite-campaigns")
      : navigate({ pathname: "/offsite-campaigns", search: location.search });
    resetGlobalStates();
  };

  const onWarningModalCancel = () => setWarningModalVisible(false);

  const chooseSteps = () => [stepInformation[0]];

  const mode = location.pathname.includes("create") ? "create" : "edit";

  const isLastStep = current + 1 === chooseSteps().length;

  const prev = () => {
    setCurrent(current - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const savedState = window.history.state;

    const alteredState = {
      ...savedState,
      newState: true,
    };

    window.history.pushState(alteredState, "", window.location.href);

    const handlePopState = () => {
      openWarningModal();
      window.history.pushState(alteredState, "", window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      {showAdminBar && <RetailAdminBar />}
      <nav
        className={cc(["flex", cm.nav, showAdminBar ? cm.advertiserNav : ""])}
      >
        <div className="flex">
          <span className={cc(["flex", cm.close])} onClick={onCancel}>
            <CloseOutlined />
          </span>
          <div>
            <RetailTitle level={5} className={cm.name}>
              {formFields.adName}
            </RetailTitle>
            {adminInGeneral && (
              <RetailText
                weight="medium"
                size="s"
                className={cc(["flex", cm.text])}
              >
                {t("components.stepsNavbar.advertiser")}
                <strong>{advertiser?.name}</strong>
              </RetailText>
            )}
          </div>
        </div>
        <div
          className={cc([
            cm.container,
            chooseSteps().length === 1 ? cm.small : cm.wide,
          ])}
        >
          <Steps current={current} type="navigation" className="nav-steps">
            {chooseSteps().map((item: any) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                subTitle={item.sub}
              />
            ))}
          </Steps>
        </div>
        <div className="steps-action">
          <RetailMainButton
            hasBackground
            className={cc(["flex", cm.btn])}
            onClick={form.submit}
          >
            <p className={cm.title}>
              <span className={cm.span}>
                {!isLastStep
                  ? t("common.continue")
                  : t(`components.stepsNavbar.campaign_${mode}`)}
              </span>
              {!isLastStep && (
                <span className={cm.span}>
                  {t("components.stepsNavbar.step", {
                    value: `${current + 2} / ${stepInformation.length}`,
                  })}
                </span>
              )}
            </p>
            <RightWhiteOutlined />
          </RetailMainButton>
        </div>
      </nav>

      {/* Content */}
      <Form
        className={`${
          !adminInGeneral && advertiser ? cm.advertiser : ""
        } steps-content`}
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
        colon={false}
        requiredMark={false}
      >
        {switchStep()}
        <div>
          <Divider className={cm.footerDivider} />
          <div className={cc(["flex", cm.footer])}>
            <RetailMainButton
              className={cm.cancelBtn}
              hasBackground={false}
              onClick={onCancel}
            >
              {t("common.cancel")}
            </RetailMainButton>
            {current !== 0 && (
              <RetailMainButton
                className={cc(["flex", cm.prevBtn])}
                hasBackground={false}
                onClick={prev}
              >
                <LeftOutlined /> {t("common.goBack")}
              </RetailMainButton>
            )}
            {current === chooseSteps().length - 1 && (
              <RetailMainButton
                htmlType="button"
                hasBackground
                className={cm.nextBtn}
                onClick={form.submit}
              >
                {t("common.create")}
              </RetailMainButton>
            )}
          </div>
        </div>
      </Form>
      <RetailCampaignModal
        navigateData={navigateData}
        ref={modalRef}
        onClick={resetGlobalStates}
        isAdmin={adminInGeneral}
        isOffsite={true}
      />
      <CampaignDetailsModal
        type="AD_SUMMARY"
        subtitle={t("components.offsiteCampaignForm.adSummary")}
        visible={infoModalVisible}
        onOk={createOffsiteCampaign}
        onCancel={onCancelInfoModal}
      >
        <section className={cm.summaryContainer}>
          <h6>{t("components.offsiteCampaignForm.info")}</h6>
          {/**
           * Advertiser Name and Campaign Name
           */}
          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.offsiteCampaignForm.productName")}
              </p>
              <p className={cm.summaryColText}>{selectedProducts[0]?.name}</p>
            </div>
            <img
              src={selectedProducts[0]?.image_url}
              alt="product"
              className={cm.summaryImg}
            />
          </article>

          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.offsiteCampaignForm.adName")}
              </p>
              <p className={cm.summaryColText}>{formFields.adName}</p>
            </div>
          </article>

          <article className="flex">
            <div>
              <p className={cm.summaryColTitle}>
                {t("components.offsiteCampaignForm.budget")}
              </p>
              <p className={cm.summaryColText}>
                <RetailCurrency amount={formFields.budget} />
              </p>
            </div>
          </article>
        </section>
      </CampaignDetailsModal>
      <RetailWarningModal
        type="campaign"
        visible={warningModalVisible}
        onOk={onWarningModalCancel}
        onCancel={onWarningModalOk}
      />
    </>
  );
};

export default RetailOffsiteCampaignForm;
