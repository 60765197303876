const en = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text:
      "You can set your targeting strategy for your campaign.",
    campaign_form_budget_text:
      "Select the budget type of the campaign and set the budget. Once your spend reaches the budget, the campaign will automatically stop.",
    campaign_form_creative_text:
      "You can upload the creatives you want to use in your campaign.",
    invoice_modal_help_text:
      "If you continue by clicking the Choose a payment method button, you are deemed to have confirmed that the amount to be collected will not be refunded.",
    invoice_general_error_modal_title: "Failed to Load Balance!",
    invoice_general_error_modal_text: "An error has occurred.",
    invoice_unknown_error_modal_text: "An unexpected error occurred.",
    invoice_product_id_error_modal_text: "The product id field is required.",
    invoice_signature_error_modal_text: "Signature verification failed.",
    invoice_transaction_error_modal_text:
      "The transaction id has already been taken.",
    invoice_store_error_modal_text:
      "The store does not belong to the given company.",
    invoice_balance_error_modal_text: "Store doesn't have enough balance.",
    invoice_error_modal_title: "Failed to Load Balance!",
    invoice_error_modal_text: "An error has occurred.",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "Campaigns",
      report: "Reports",
      campaigns: "Campaigns",
      reports: "Reports",
      invoices: "Invoices",
      academy: "Academy",
      chat: "Support via E-mail",
      offsiteCampaigns: "Offsite Campaigns",
    },
    admin: {
      campaign: "Campaigns",
      advertisers: "Advertisers",
      agencies: "Agencies",
      invoices: "Invoices",
      finance: "Financial",
      transactions: "Transactions",
      coupons: "Coupons",
      access: "Access",
      users: "Users",
      invited: "Invited",
      synchronization: "Synchronization History",
      activity: "Activity History",
      report: "Reports",
      settings: "Settings",
      general: "General Information",
      integration: "Integration Information",
      advertiser: "Advertiser Settings",
      auction: "Auction Settings",
      single: "Campaign Settings",
      campaigns: "Campaigns",
      reports: "Reports",
      admin_report: "Reports",
      ad: "Ad",
      placement: "Ad Placement",
      creatives: "Creatives",
      review: "Ad Review",
      restrictions: "Restriction Settings",
      console: "Data Sources",
      dev: "Dev. Tools",
      auctions: "Auctions",
      offsite: "Off-Site Settings",
      offsiteCampaigns: "Off-Site Campaigns",
      insights: "Insights",
    },
  },
  components: {
    adminBar: {
      rn: "You are currently viewing",
      visible: "advertiser screen.",
      back: "Back to Admin View",
    },
    advertiserSelect: {
      placeholder: "Select An Advertiser",
    },
    articles: {
      date: "When Does Your Campaign Start and End?",
      budget: "What is a Budget? What to Consider When Choosing a Budget?",
      bid: "What is bidding and what are bid types?",
      targeting: "Targeting types and how to target correct audience?",
      frequency: "What is delivery type in reporting?",
      type: "How to create a custom report?",
      product: "What should be considered while choosing a product?",
      store: "What should be considered while choosing a store?",
    },
    breadcrumb: {
      finance: "Transactions",
      settings: "General Information",
      access: "Users",
    },
    bulkUpdate: {
      list: "List Selected",
      archive: "Archive Selected",
      pause: "Pause Selected",
      activate: "Activate Selected",
      selected: "{{value}} units selected",
      bid: "Edit Selected Bids",
      bidLabel: "Bid",
      bidHelp: "The bid of the selected rows will be updated.",
      row: "{{value}} Row",
      count: "Selected:",
      updateAllBids: "Edit Bids",
      updateAllBidsText: "You can edit bids for all rows shown in the table.",
      updatingAllBids: "You are editing the bids of all row.",
      updateAllBidsHelp:
        "The bids for all rows displayed in the table will be updated.",
    },
    datasource: {
      entity: "What is your entity?",
      method: "What is your import method?",
      configurations: "Set your configurations",
      mapping: "Field mapping",
      emptyEntity: "What is your <strong>Entity?</strong>",
      emptyEntityText:
        "Select the type of entity you are adding data for. This choice will define the structure of your data and determine which fields are required for import. Entities could include products or advertisers. Once selected, you will be able to configure the data fields specific to this entity.",
      productEntityText:
        "Product entity represents data related to individual products. It includes fields such as product ID, name, description, price, filters, and stock information. This entity is designed to capture and manage detailed information for each product.",
      advertiserEntityText:
        "Advertiser entity represents data related to individual advertisers. It includes fields such as advertiser ID, name, and status. This entity is designed to capture and manage detailed information for each advertiser.",
      selectEntity: "Select Entity",
      selectEntityDescription:
        "Select the type of entity you are adding data for. This choice will define the structure of your data and determine which fields are required for import. Entities could include products or advertisers. Once selected, you will be able to configure the data fields specific to this entity.",
      product: "Product",
      productText:
        "Product entity represents data related to individual products. It includes fields such as product ID, name, description, price, filters, and stock information. This entity is designed to capture and manage detailed information for each product.",
      seeFields: "See {{value}} Data Schema",
      how: "See How to Use",
      advertiser: "Advertiser",
      advertiserText:
        "Advertiser entity represents data related to advertising entities. It includes fields such as advertiser ID, name, and other relevant details. Required fields include 'id' and 'name' to ensure correct integration. This entity helps organize and maintain essential information about advertisers.",
      soon: "Soon",
      schema: "{{value}} Data Schema",
      required: "Required",
      showFields: "See Additional Fields",
      emptyMethod: "What is your <strong>Import Method?</strong>",
      emptyMethodText:
        "Choose the method by which you want to import your data from the selected source. Depending on the method you choose, the system will automatically adapt the import process to handle the data correctly. Each method has its advantages, so select the one that best suits your needs.",
      filledMethod: "Import Method: <strong>{{value}}</strong>",
      googleFeedUrl: "Google Feed URL",
      googleFeedFile: "Google Feed File Upload (XML)",
      googleFeedDirectXml: "Direct XML Upload (Request Body)",
      filledMethodText:
        "Use this option to import data from your {{value}} by providing the feed URL or uploading files in supported formats.",
      selectMethod: "Select Import Method",
      googleFeedText:
        "Google Feed is used to import data directly from your Google Feed by providing the feed URL or uploading files in supported formats. This feature simplifies the process by automatically managing advertiser creation during the product feed integration.",
      feedUrl: "Feed URL",
      feedUrlText:
        "The specific URL where Google Feed data is retrieved directly from the source",
      fileUpload: "File Upload (XML)",
      fileUploadText:
        "Google Feed files can be manually imported using XML format.",
      requestBody: "Direct XML Upload (Request Body)",
      requestBodyText:
        "A direct integration method that allows for importing Google Feed data via XML within the request body, bypassing traditional file uploads. This feature is coming soon.",
      configTitle: "Set your <strong>Configurations</strong>",
      configText:
        "Define the essential settings for your data source. Correct configuration of these settings ensures that the data import process runs smoothly and that your data remains up to date in the system. These settings cannot be changed after the data source is saved.",
      name: "Data Source Name",
      nameHelp:
        "Enter a unique name for this data source configuration. This will help you identify the source later.",
      url: "Feed URL",
      urlHelp:
        "Provide the full URL where the feed can be fetched. The system will pull data from this link regularly.",
      cycle: "Select Cycle",
      cycleHelp:
        "Specify how often the system should fetch updates from the provided feed URL.",
      fetch: "Fetch & Map",
      mappingTitle: "Field <strong>Mapping</strong>",
      mappingText:
        "In this section, you will map the fields from your data source to the corresponding fields in our system. The system fields have specific requirements, and any required fields must be mapped correctly to ensure successful import. For some required fields where there is no matching data in your source, you can choose to set a default value. This helps ensure that no critical information is missing during the import process. After you have completed mapping the fields, you must validate the mapping before proceeding to ensure all required fields are correctly matched.",
      validate: "Validate Mapping",
      system: "System Fields",
      imported: "Imported Fields",
      preview: "Preview",
      hideFields: "Hide Additional Fields",
      feedUrlLink: "Google Product Data Specification",
      upload: "Upload .XML File",
      uploadText: "or drag and drop here",
      uploaded: "File uploaded!",
      metaFeedText:
        "Meta Feed supports data imports using various formats, including feed URLs and file uploads. Additional supported formats and field configurations will be introduced soon.",
      metaFeedUrlText:
        "A dedicated URL source used to directly retrieve data for Meta Feed integration.",
      metaFeedUrl: "Meta Feed URL",
      xml: "Direct XML Code",
      xmlHelp: "Enter the XML code directly to import data from your source.",
      api: "API Integration",
      apiText:
        "The API integration provides a connection to specified API endpoints, enabling data synchronization for continuous information flow into your system.",
      apiButtonText:
        "Provides a setup for retrieving data by connecting to specified API endpoints. This integration enables data synchronization through requests sent to our API, ensuring updated information flows directly into your system as changes occur.",
      impression: "Impression",
      impressionText:
        "Impression entity represents data related to ad views. This includes fields such as ad id, session id, and other relevant details. This entity helps measure the visibility of your ads by recording every instance an ad appears on screen.",
      click: "Click",
      clickText:
        "Click entity captures data associated with user interactions when they click on an ad. Fields include ad id, session id, and other relevant details to provide insights into user engagement. This entity enables detailed analysis of user actions by recording every ad click.",
      sale: "Sale",
      saleText:
        "The Sale entity encompasses data for all conversions or purchases resulting from ad interactions. It is essential that all sale events are sent to us to ensure accurate tracking and reporting. This data enables us to provide detailed insights into customer types, such as category buyers, new customers, and existing customers, as well as direct and indirect conversions.",
      productSchema: {
        id: "The id of the product",
        name: "The name of the product",
        advertiser_id: "The ID of the advertiser",
        image_url: "The URL of the product image",
        description: "The description of the product",
        price: "The price of the product (returns -1 for multiple locations)",
        filters: "Filters applied to the product",
        buybox: "Indicates if the product has a buybox",
        sub_class_names: "Subclass names of the product",
        brand: "The brand of the product",
        retailer_taxonomy: "Retailer taxonomy of the product",
        google_taxonomy: "Google taxonomy of the product",
        product_type: "Type of the product",
        global_identifier: "Global identifier of the product",
        global_identifier_type: "Type of global identifier (GTIN or ASIN)",
        rating: "Rating of the product",
        stock: "Stock of the product (returns -1 for multiple locations)",
        sku: "SKU of the product",
        barcode: "Barcode of the product",
        price_range: "Price range of the product",
        status: "Status of the product",
        retailer_taxonomy_id_structure: "Retailer taxonomy id structure",
        process_id: "The id of the process",
      },
      advertiserSchema: {
        id: "The ID of the advertiser",
        name: "The name of the advertiser",
        time_zone: "The time zone of the advertiser",
        email: "The email address of the advertiser",
        language: "The language of the advertiser",
        status: "The status of the advertiser",
        process_id: "The process id of the advertiser",
      },
    },
    download: {
      campaigns: "Campaigns",
      advertisers: "Advertisers",
      agencies: "Agencies",
      transactions: "Transactions",
      coupons: "Coupons",
      users: "Users",
      sync_logs: "Sync Logs",
      reports: "Reports",
      query: "Performance Report",
      PRODUCTS: "Products",
      KEYWORDS: "Keywords",
      NEGATIVE_KEYWORDS: "Negative Keywords",
      AGE_RANGES: "Age",
      CITIES: "City",
      PLATFORMS: "Platform",
      GENDERS: "Gender",
      PLACEMENTS: "Ad Placement",
      CUSTOMERS: "Customer",
      CATEGORIES: "Categories",
      NEGATIVE_CATEGORIES: "Negative Categories",
      CREATIVES: "Creatives",
    },
    inbox: {
      title: "Inbox",
      empty: "You have no notifications.",
      unread: "Unread only",
      default: "All",
      archived: "Archived",
      readAll: "Mark all as read",
      archiveAll: "Archive all",
      archiveRead: "Archive read",
      preferences: "Notification Preferences",
      global: "Global Preferences",
      balance: "Low Balance",
      categories: "Low Bid (Categories)",
      keywords: "Low Bid (Keywords)",
      products: "Products Stopped",
      creative: "Rejected Creative",
      review: "Creative Created",
    },
    info: {
      firstTipCreate:
        "You can easily create successful campaigns by choosing the recommended settings. Recommended settings optimize your ad performance for you.",
      secondTipCreate:
        "In order to increase performance of your campaigns you can select products with sufficient stock, high scores and buybox winners to your campaigns.",
      thirdTipCreate:
        "Increasing advertising budget help you to boost your clicks and sales by making your products more visible to your customers.",
      forthTipCreate:
        "By placing high bids on your campaigns, you can ensure that your products are displayed in better position.",
      survey:
        "We'd love to hear your thoughts! Please help us improve by taking a quick survey.",
    },
    invite: {
      selected: "{{value}} units selected",
      invite: "Invite Selected",
      reinvite: "Re-Invite Selected",
    },
    modal: {
      campaign: {
        create: "Create New Campaign",
        success: "Campaign Created Successfully",
        successText:
          "You can continue to the campaign details page by clicking the 'OK' button.",
      },
      campaignDetails: {
        products: "Product Selection",
        keywords: "Add Keyword",
        update: "Update Campaign",
        negative_keywords: "Add Negative Keyword",
        products_btn: "Add Products",
        keywords_btn: "Add Keywords",
        negative_keywords_btn: "Add Negative Keywords",
        update_btn: "Update",
        create_agency: "Add New Agency",
        update_agency: "Update Agency",
        create_agency_btn: "Add",
        update_agency_btn: "Update",
        create_user: "Add New User",
        create_user_marketplace: "Add Admin User",
        create_user_marketplace_btn: "Add",
        create_user_advertiser: "Add Advertiser User",
        create_user_advertiser_btn: "Add",
        select_advertiser: "Select Advertiser",
        select_advertiser_btn: "Continue",
        update_advertiser: "Update Advertiser",
        update_advertiser_btn: "Update",
        invoice: "Add Balance",
        invoice_btn: "Choose Payment Method",
        coupons: "Define Coupon",
        coupons_btn: "Define Coupon",
        transactions: "Add Balance",
        transactions_btn: "Add Balance",
        categories: "Add New Category",
        categories_btn: "Add Categories",
        negative_categories: "Add Negative Category",
        negative_categories_btn: "Add Categories",
        transaction_details: "Transaction Details",
        settings: "Save Changes",
        settings_btn: "Save",
        creatives: "Add Creative",
        creatives_btn: "Add Creative",
        approve_balance: "Add Balance",
        approve_balance_btn: "Confirm Transaction",
        approve_coupon: "Add Coupon",
        approve_coupon_btn: "Confirm Coupon",
        payment_options: "Add Balance",
        payment_options_btn: "Load",
        add_user: "Invite New User",
        add_user_btn: "Send invitation",
        targeting_settings: "Advertiser Selection",
        targeting_settings_btn: "Select",
        update_transaction: "Edit Invoice Number",
        update_transaction_btn: "Save",
        select_user: "Select User Type",
        select_user_btn: "Continue",
        select_language: "Change Language",
        select_language_btn: "Change",
        campaign_form: "Campaign Summary",
        campaign_form_btn: "Create Campaign",
        update_bid: "Edit Bid",
        update_bid_btn: "Edit",
        show_vast_tag: "Get VAST Tag",
        show_vast_tag_btn: "Okay",
        update_dayparting: "Day Parting",
        update_dayparting_btn: "Update",
        reject_creative: "Reject Creative",
        reject_creative_btn: "Confirm",
        refund: "Refund",
        refund_btn: "Request Refund",
        confirm_refund: "Confirm Refund",
        confirm_refund_btn: "Confirm Refund",
        archive_confirmation: "Archive Confirmation",
        archive_confirmation_btn: "Confirm & Archive",
        ad_summary: "Ad Summary",
        ad_summary_btn: "Create Ad",
        confirm_keyword_tab: "Confirm Process",
        confirm_keyword_tab_btn: "Continue & Delete Keywords",
      },
      selectCampaign: {
        title: "Choose Your Campaign Type",
        text: "Please select your campaign type.",
        soon: "Soon",
        sponsored_product_availability_title: "Sponsored Products",
        sponsored_product_availability_text:
          "Promote to shoppers that search for related keywords or view similar products.",
        sponsored_brand_availability_title: "Sponsored Brands",
        sponsored_brand_availability_text:
          "Help shoppers discover your brand and products with rich, engaging creatives.",
        sponsored_display_availability_title: "Sponsored Display",
        sponsored_display_availability_text:
          "Grow your business by reaching relevant audiences on and off site.",
        sponsored_store_availability_title: "Sponsored Store",
        sponsored_store_availability_text:
          "Amplify your store’s reach and help shoppers discover your stores. Deliver a targeted shopping experience.",
        sponsored_video_availability_title: "Sponsored Video",
        sponsored_video_availability_text:
          "Reach your target audience and increase sales using engaging elements with video ads.",
        koctasLink: "What is a Display Ad?",
        sponsored_display_with_product_availability_title:
          "Sponsored Brand Display",
        sponsored_display_with_product_availability_text:
          "Advertise your products effectively by targeting shoppers with relevant interests both on and off the site.",
        sponsored_video_with_product_availability_title:
          "Sponsored Brand Video",
        sponsored_video_with_product_availability_text:
          "Enhance your product visibility and captivate audiences using dynamic and engaging video content.",
        in_store_availability_title: "In Store",
        in_store_availability_text:
          "Engage shoppers who are physically visiting your store locations",
      },
      success: {
        advertisers_success_title: "Invitation Sent",
        advertisers_success_text:
          "An invitation has been sent to the email address you entered. Users need to create an account by clicking the invitation link in order to access their accounts.",
        reports_success_title: "Report Created Successfully",
        reports_success_text:
          "You can continue to the report detail page by clicking the “OK” button.",
        invited_success_title: "User Invited",
        invited_success_text:
          "In order for the user to appear in the list, they must click on the invitation e-mail and create a password.",
        agencies_success_title: "Agency Created Successfully",
        agencies_success_text: "",
        multiple_advertisers_success_title: "Invitation Sent",
        multiple_advertisers_success_text: "Selected advertisers were invited.",
        invoice_success_title: "Balance Load Successful!",
        invoice_success_text:
          "Your balance has been loaded. You can create new campaigns.",
        create_advertiser_success_title: "Add Advertiser Successful!",
        create_advertiser_success_text:
          "You need to invite the advertiser to activate it.",
        payment_success_title:
          "Your Balance Loading Request Has Been Received!",
        payment_success_text:
          "After your money transfer or EFT payments are approved, they will be transferred to your account. The approval process can take 2-3 business days.",
        craftgate_success_title: "Your Balance Loading Request Received!",
        craftgate_success_text:
          "Your balance will be transferred to your account promptly.",
        onboarding_success_title: "Congratulations!",
        onboarding_success_text:
          "You’ve successfully completed the onboarding process and now have full access to all features of the dashboard! Explore the new interface, create impactful campaigns, and manage your ads effortlessly. Click “Ok” to get started!",
        file_success_title: "File Uploaded Successfully!",
        file_success_text:
          "Your file has been uploaded and processed successfully. You can now proceed with the next steps.",
        xml_success_title: "XML Processed Successfully!",
        xml_success_text:
          "The XML data has been processed successfully. You can now proceed with the next steps.",
      },
      error: {
        craftgate_title: "The Balance Could Not Be Loaded!",
        craftgate_text: "Credit card loading failed.",
        file_title: "XML File Upload Error",
        file_text:
          "There was an issue processing your XML file. Please ensure that the file format is correct and meets the required structure. If the issue persists, contact support for further assistance.",
        xml_title: "XML Processing Error",
        xml_text:
          "There was an issue processing the XML data. Please ensure the XML structure and content are correct. If the problem persists, contact support for further assistance.",
      },
      warning: {
        campaign_warning_title: "Are you sure you want to leave this page?",
        campaign_warning_text: "Your changes will not be saved!",
      },
    },
    navbar: {
      search: "Type to search...",
      help: "Help & Support",
      site: "Return to Seller Panel",
    },
    navbarPopover: {
      account: "Change Account",
      language: "Change Language",
      logout: "Logout",
    },
    notification: {
      formTitle: "The form is not complete!",
      formText: "Please fill in the blanks and required fields!",
      paused: "Stopping {{value}} was successful.",
      active: "Activation of {{value}} was successful.",
      campaigns: "the campaign",
      reports: "the report",
      products: "the product",
      keywords: "the keyword",
      negative_keywords: "the negative keyword",
      categories: "the category",
      negative_categories: "the negative category",
      placements: "the ad placement",
      users: "the user",
      advertisers: "the advertiser",
      agencies: "the agency",
      ad_placements: "the ad placement",
      creatives: "the creative",
      account_users: "the user",
      statusError: "An error has occurred.",
      activeReport: "Archiving the report was successful.",
      archivedReport: "Unarchiving the report was successful.",
      activeCampaign: "Archiving the campaign was successful.",
      pausedCampaign: "Archiving the campaign was successful.",
      archivedCampaign: "Unarchiving the campaign was successful.",
      activeAgency: "Archiving the agency was successful.",
      archivedAgency: "Unarchiving the agency was successful.",
      invitedAdvertiser: "Archiving the advertiser was successful.",
      activeAdvertiser: "Unarchiving the advertiser was successful.",
      archivedAdvertiser: "Archiving the advertiser was successful.",
      archived_ad_placement: "Archiving the ad placement was successful.",
      archived_reports_bulk: "Archiving the report was successful.",
      archived_campaigns_bulk: "Archiving the campaign was successful.",
      archived_users_bulk: "Archiving the user was successful.",
      archived_advertisers_bulk: "Archiving the advertiser was successful.",
      archived_agencies_bulk: "Archiving the agency was successful.",
      archived_account_users_bulk: "Archiving the user was successful.",
      keyword: "Please add at least 1 keyword.",
      category: "Please add at least 1 category.",
      invite: "{{value}} invitation(s) sent successfully.",
      full_invite: "All invitations sent successfully.",
      rejected: "Creative rejection successfully completed.",
      accepted: "Creative validation successfully completed.",
      uninvitedAdvertiser: "Activation the advertiser was successful.",
      settings_success: "Settings saved successfully.",
      previous_invite_sent_too_recently:
        "{{value}} invite(s) sent too recently. Wait to try again.",
      user_email_is_already_registered:
        "{{value}} email address(es) is/are already registered.",
      copiedCampaign: "The campaign was successfully copied.",
      copyError: "An error occurred while copying the campaign.",
      editableFieldError:
        "Please enter a value greater than the minimum, or it will be adjusted accordingly.",
      active_account_user: "Archiving the user was successful.",
      archived_account_user: "Unarchiving the user was successful.",
      bid: "Bids of {{value}} rows has been updated.",
      newBid: "New bid: {{value}}.",
      creativeUpload: "Creative added successfully!",
      advertiserBalanceLowSubject: "Your Balance is Running Low!",
      advertiserBalanceLowBody:
        "Your advertiser balance has dropped to a critical level. Top up now to ensure your ads run without interruption.",
      campaignKeywordsBelowMinBidSubject:
        "Keyword Bids Too Low in Campaign #{{value}}",
      campaignKeywordsBelowMinBidBody:
        "All keywords in your campaign (ID: {{value}}) have bids below the marketplace's minimum bid requirement. Update your bids to improve visibility.",
      campaignCategoriesBelowMinBidSubject:
        "Category Bids Too Low in Campaign #{{value}}",
      campaignCategoriesBelowMinBidBody:
        "All categories in your campaign (ID: {{value}}) have bids below the minimum bid requirement. Update your bids to improve visibility.",
      campaignProductsStoppedSubject:
        "Products in Campaign #{{value}} Are Inactive",
      campaignProductsStoppedBody:
        "Products in your campaign (ID: {{value}}) are paused. Consider adding new products to keep your campaign running effectively.",
      creativeRejectedSubject: "Creative Rejected in Campaign #{{value}}",
      creativeRejectedBody:
        "A creative in your campaign (ID: {{value}}) has been rejected. Please review the feedback and update the creative to meet the platform's guidelines.",
      marketplaceAdvertiserBalanceLowSubject: "{{value}} Has a Low Balance",
      marketplaceAdvertiserBalanceLowBody:
        "{{value}}'s balance has dropped to a critical level. Top up now to ensure their ads continue running without interruption.",
      creativeCreatedSubject: "A new creative has been added by {{value}}",
      creativeCreatedBody:
        "{{value}} has just created a new creative. You can review and approve this creative in the marketplace dashboard.",
      balanceAction: "Top Up Balance",
      productAction: "Add New Products",
      bidAction: "Update Bids",
      creativeAction: "Update Creative",
      reviewAction: "Review",
      segments: "segment",
    },
    suggestedTag: {
      text: "RECOMMENDED",
    },
    campaignCard: {
      graph: "Show in Graph",
      hide: "Hide",
      message: "{{value}} Days",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "Keyword Information",
        categoryStepTitle: "Category Information",
        targetingStepTitle: "Audience Information",
        campaignInfoTitle: "Campaign Information",
        campaignInfoText:
          "Enter a name to be displayed in the campaigns table. This name is not shown to customers. We recommend you to choose a name related to the products you want to advertise.",
        campaignLabel: "Campaign Name",
        campaignSub: "Enter your campaign name.",
        dateInfoTitle: "Campaign Date",
        dateInfoText: "Set the duration of your campaign.",
        startDateLabel: "Start Date",
        startDateSub: "Select the campaign start date.",
        stopped: "Continuous",
        stoppedText:
          "Your campaign will start today and continue until you stop it.",
        stoppedSub: "Start Date: {{value}}",
        defined: "Start - End Date",
        definedText:
          "You can select the date range in which your campaign will run.",
        budget: "Budget",
        totalBudget: "Total Budget Limit",
        totalBudgetLabel: "Total Budget",
        dailyBudgetLabel: "Daily Budget",
        dailyBudgetExtra:
          "The daily expenditure of your campaign will be the maximum amount you specify.",
        dailyBudgetFilter: "Daily Budget",
        dailyBudgetText: "You can set your daily budget.",
        totalBudgetExtra:
          "Your campaign will spend the maximum amount you specify every day.",
        flexibleBudgetLabel: "Daily Budget",
        totalBudgetFilter: "Total Budget",
        totalBudgetText: "You can set your total budget.",
        bid: "Bid",
        bidText:
          "Set the bid type for your campaign. Your ad spend is per click based on the bid type you choose.",
        dynamicBid: "Automatic Bid",
        dynamicBidText:
          "Your spend per click will be determined automatically.",
        targetAcosTitle: "Target ACoS",
        targetAcosText:
          "Choose how much of your sales coming from ads you want to spend on advertising.",
        default: "Default",
        advanced: "Advanced",
        fixedBid: "Fixed Bid",
        fixedBidText:
          "Set a fixed cost per click for keywords and categories. Your campaign is shown to customers based on your targeting preference.",
        productTitle: "Product Selection",
        productText: "Select the products you want to advertise.",
        acosTooltip:
          "ACoS stands for the advertising cost of a sale. It is the ratio that shows how much of your earnings from sales you want to spend on advertising. For example, in a campaign with 10% ACoS value, you spend 1 TL on advertising for every 10 TL sales.",
        autoTargeting: "Auto Targeting",
        autoTargetingText:
          "The most appropriate keywords or categories for your campaign will be targeted.",
        manualTargeting: "Manual Targeting",
        manualTargetingText:
          "You can target the keywords or categories you want your campaign to display.",
        suggestedBidText: "Suggested Bid: ",
        acosError: "ACoS should be between 0.0001-100.",
        creativeTitle: "Creative Information",
        videoText:
          "You can upload the creatives you want to use in your campaign.",
        urlTitle: "Redirect URL",
        urlText:
          "It is the page where users who click on your ad will be directed. You need to redirect users to a page within the marketplace.",
        displayDraggerTitle:
          "<strong>Drag and drop an image, or </strong> <0>Browse</0>",
        creativeLabel: "Creative Name",
        size: "Size:",
        listWarningSize: "5120KB(5MB)",
        listWarningType: "JPG, PNG, GIF",
        recommendedSizes: "RECOMMENDED SIZES",
        allSizeText: "If you want to see all sizes in the platform",
        visit: "Visit",
        allowedFileTypes: "Allowed file types:",
        maximumFileSize: "Maximum file size:",
        modalTitle: "Creative Dimensions",
        modalText:
          "You can upload creative in all listed sizes. Uploading creative at recommended sizes improves your ad performance.",
        recommended: "Recommended Size",
        accepted: "Accepted Sizes",
        creativeError: "Please enter a creative name.",
        urlError: "Please enter a URL.",
        budgetError: "Your budget must be over {{value}}.",
        budgetErrorMessage: "Please enter your budget.",
        invalid: "INVALID SIZE",
        advancedOptions: "Advanced Options",
        placements: "Ad Placement",
        placementsText:
          "Decide in which areas you want your ad to be displayed.",
        in_search: "Search Results",
        in_search_text:
          "Your ads are displayed in the relevant search results.",
        in_product_detail_page: "Product Detail Pages",
        in_product_detail_page_text:
          "Your ads are displayed on the relevant product detail pages.",
        in_category: "Categories",
        in_category_text:
          "Your ads are displayed on the relevant category pages.",
        in_home_page: "Home page",
        in_home_page_text: "Your ads are displayed on the homepage.",
        in_collection: "Collection",
        in_collection_text:
          "Your ads are displayed on relevant collection pages.",
        storeTitle: "Store Selection",
        storeText: "Select the stores you want to advertise.",
        info: "GENERAL INFORMATION",
        advertiser: "Advertiser Name:",
        budgetType: "Budget Type:",
        bidType: "Bid Type:",
        standart: "Automatic Bid",
        fixed: "Fixed Bid",
        max_acos: "Target ACoS",
        fixed_info: "Bid:",
        max_acos_info: "ACoS:",
        targeting: "TARGETING",
        custom: "Custom",
        durationText:
          "If your campaign reaches the daily budget target every day, it will stay active for <strong>{{value}} days</strong>.",
        dayParting: "Day Parting",
        dayPartingText:
          "Determine the days and hours that your campaign will remain active.",
        allTimes: "All Times",
        allTimesText: "Your campaign is active all day and at all hours.",
        scheduled: "Select Date Range",
        scheduledText:
          "Your campaign is active on the days and hours you specify.",
        dayPartingTableText:
          "Please tick the appropriate <strong>boxes</strong> below to select the day and time interval you want. If you want to select all time intervals during a day, you can use the <strong>'All Day'</strong> option.",
        allDay: "All Day",
        clear: "Clear Selections",
        videoSideTitle: "Add Creative",
        videoSideText: "Select creative types and determine their formats.",
        firstDrawerTitle: "Step 1: Select Creative Type",
        creativeTypeTitle: "Creative Type",
        creativeTypeText: "Select the creative type suitable for your ad.",
        mp4Title: "Create via Video File",
        mp4Text: "You can create your creative with a .MP4 video file.",
        vastTitle: "Create using a VAST Tag",
        vastText: "You can create your creative with the data-backed VAST Tag.",
        video_upload_second_drawer_title: "Step 2: Add via Video File",
        creativeNameTitle: "Creative Name",
        creativeNameText: "Enter a name for your creative.",
        creativeVideoLabel: "Enter creative name.",
        video_upload: "Upload Video",
        video_upload_drawer: "Upload the video file to show in your ads.",
        videoDraggerTitle:
          "<strong>Drag and drop an video, or </strong> <0>Browse</0>",
        maxVideoSize: "10240KB(10MB)",
        videoFormat: "MP4",
        changeVideo: "Change Video",
        creativeName: "<strong>Name:</strong> {{value}}",
        updateDrawerTitle: "Update Creative",
        videoAd: "Sponsored Video",
        vast: "VAST Tag",
        vast_drawer:
          "Add the VAST tag for the video that will appear in your ads.",
        videoInnerTitle: "We're processing your video!",
        videoInnerText:
          "Once the system has finished processing, your video will be ready to view!",
        creativeLanguageTitle: "Creative Language",
        creativeLanguageText: "Select the languages for your creative.",
        creativeLanguageSearch: "Search for a language",
        autoTargetingProductWarning:
          "In order to enable automatic targeting, at least <strong>“1”</strong> product must be selected from the <strong>connected products</strong> step.",
        connectedProductsTitle: "Connected Products",
        connectedProductsText:
          "Products linked to the creatives above, specifically curated for you.",
        showProducts: "Show Connected Products",
        showProductsText:
          "Activate this option to include products associated with your creatives. Associating relevant products with your creatives helps enhance ad relevance.",
        promoteProduct: "Promote Products",
        promoteProductText:
          "This feature ensures that the products added to your campaign are displayed in a prominent position.",
        promoteProductWarning:
          "Please note that once selected, this option cannot be modified.",
        closeProducts: "Close Connected Products",
        closeProductsText:
          "Disabling the use of products linked to your creatives is not recommended, as it may reduce the relevance of your ads.",
        spendingTitle: "Spending Distribution",
        spendingText:
          "Define the time distribution and intensity of how your advertising budget will be spent.",
        spendingSub: "Determine your spending distribution.",
        asap: "As fast as possible",
        front_loaded: "Frontloaded",
        evenly: "Evenly",
        frontPercentage: "Percentage",
        multiRegion: "Region Selection",
        multiRegionText:
          "You can run your campaign in multiple or specific regions.",
        allRegions: "All Regions",
        allRegionsText: "Your campaign will be published in all regions.",
        selectedRegions: "Selected Regions",
        selectedRegionsText:
          "Your campaign will only be published in the selected regions.",
        regionPlaceholder: "Select regions",
        evenlyText:
          "The campaign progresses steadily, with equal focus given to all time periods.",
        asapText:
          "The budget is spent quickly to get fast results early in the campaign.",
        front_loadedText:
          "A significant portion of the effort is concentrated at the beginning, tapering off gradually to maintain presence over time.",
        displayUploadTitle: "Creative via Image File",
        displayUploadText:
          "You can create your creative with a .JPEG, .PNG or .GIF file.",
        display_upload_second_drawer_title: "Step 2: Add via Image File",
        display_upload: "Upload Image",
        display_upload_drawer: "Upload the image file to show in your ads.",
        changeDisplay: "Change Image",
        displayErrorTitle: "There is an error in the image!",
        displayErrorText:
          "The image you are trying to upload has incorrect dimensions.",
        newDisplay: "Upload a New Image",
        bulk: "Bulk Upload",
        scriptUploadTitle: "Creative via using Script Code",
        scriptUploadText: "You can create your creative with the Script Code.",
        script: "Script Code",
        script_drawer: "Enter the code to show in your ads.",
        creativeSizeTitle: "Ad Size",
        creativeSizeText: "Select ad size for your creative.",
        creativeSizeSearch: "Select Size",
        vast_second_drawer_title: "Step 2: Add via using VAST Tag",
        script_second_drawer_title: "Step 2: Add via using Script Code",
        code: "Edit Code",
        preview: "Preview",
        errorStates: {
          name: "Please enter the campaign name.",
          keywordErr: "Your keyword must be unique.",
          advertiserErr: "Advertiser is not active.",
          placementsError: "Please select at least one ad placement.",
          productLimitErr: "You can add up to 250 products.",
          sameKeyword:
            "Cannot include same keyword in target keywords and negative keywords.",
          duplicateKeyword: "Keyword already added to campaign",
          duplicateNegativeKeyword:
            "Negative keyword already added to campaign",
          generalErr: "An error has occurred.",
          campaignLimitErr:
            "You have reached the campaign limit for SMO marketplaces.",
          creativeLimitErr:
            "The creative limit for SMO marketplaces is 100, and this limit has been reached.",
          creativeSizeErr:
            "The file size exceeds the limit for SMO marketplaces. Please upload a file smaller than 150 KB.",
          vast: "Please enter a valid VAST tag.",
          keywordLimitErr: "At most 100 keywords can be added to a campaign.",
          categoryLimitErr: "At most 100 categories can be added to a campaign",
          frontPercentage: "Frontloaded percentage must be between 0 and 100.",
          creativeDimensionErr:
            "Please upload a creative with the correct dimensions.",
          languageErr: "Please add a language.",
          sizeErr: "Please select a size.",
          script:
            "Invalid HTML creative. Ensure it includes a <meta> tag with a content attribute in the format width={value},height={value}, e.g., <meta content='width=300,height=250'>.",
        },
      },
      secondStep: {
        wordTargeting: "Keyword Targeting",
        wordTargetingText:
          "Determine in which keywords your ads will be displayed.",
        categorySub:
          "Decide in which categories you want your ad to be displayed.",
        autoTargeting: "Auto Targeting",
        autoTargetingText:
          "Your ad will be displayed in the most relevant keywords and categories. You can stop the keywords from the campaign detail page.",
        manualTargeting: "Manual Targeting",
        manualTargetingText: "You can set keywords for your ad.",
        defaultBid: "Default Bid",
        keywordTitle: "Target Keywords",
        keywordText: "Add the keywords which your ad will be displayed.",
        negativeTitle: "Negative Keywords",
        negativeText:
          "Add the keywords that you do not want your ad to be displayed.",
        placementTitle: "Targeting Type",
        placementText:
          "You can target specific keywords or categories in which your campaign will be displayed.",
        search: "Keyword Targeting",
        searchText:
          "Target the keywords that you want your campaign to appear in.",
        categories: "Category Targeting",
        categoriesText:
          "Target the categories that you want your campaign to display on.",
        categoryTargeting: " Targeting",
        categoryTargetingTitle:
          "Determine in which categories your ads will be displayed.",
        categoryTargetingText:
          "Add the categories which your ads will be displayed.",
        category: "Target Categories",
        negativeCategoryTitle: "Negative Categories",
        negativeCategoryText:
          "Add the categories that you do not want your ad to be displayed.",
        autoCategoryText:
          "Your ad will be displayed in the most appropriate categories.",
        manualCategoryText:
          "You can set the categories you target for your ad.",
        placement: "Targeting",
        targetingText: "You can set your targeting strategy for your campaign.",
        bulkUploadCardTitle: "Add Bulk Keywords",
        bulkUploadCardSubtitle:
          "Select your file and add all keywords in one click.",
        bulkUploadTabText: "Bulk Upload",
        addManualTabText: "Add Manual",
        bulkUpload: "Bulk Keyword Upload",
        reUpload: "Re-upload",
        uploadInfoText:
          "There are <strong>{{count}} keywords</strong> in total.",
        generalInformations: "General Informations",
        csvTemplateInstruction:
          "Please use the <strong>CSV template</strong> to ensure your information uploads accurately.",
        downloadCsvButton: "Download CSV Template",
        formattingRules: "Formatting Rules:",
        ruleAlphanumeric:
          "Use <strong>alphanumeric</strong> characters only (no special characters).",
        ruleMatchType:
          "Match Type must be <strong>EXACT</strong>, <strong>PHRASE</strong>, or <strong>BROAD</strong>.",
        ruleBidValue:
          "Bid Value must be <strong>higher</strong> than the minimum bid.",
        minimumBidValue: "Minimum Bid Value:",
        uploadFileTitle: "Upload File",
        uploadFileSubtitle: "Upload the file to add your keywords.",
        uploadDraggerText:
          "Drag and drop your file, or <strong>Browse</strong>",
        uploadDraggerFileType: "Allowed File Types: CSV",
        switchTabModal: {
          title: {
            manual: "Switch to Manual Entry?",
            bulk: "Switch to Bulk Upload?",
          },
          warning: {
            manual:
              "You are about to switch from Bulk Upload to Manual Keyword Entry. If you continue, all bulk-uploaded keywords will be deleted and cannot be recovered.",
            bulk: "You are about to switch from Manual Keyword Entry to Bulk Upload. If you continue, all manually added keywords will be deleted and cannot be recovered.",
          },
          proceedQuestion: "Are you sure you want to proceed?",
        },
        switchTabConfirmation: {
          deletion: {
            manual:
              "I understand that all bulk-uploaded keywords will be deleted.",
            bulk: "I understand that all manually added keywords will be deleted.",
          },
        },
        errorStates: {
          csvFileNotRead: "Error reading CSV file.",
          invalidCSVHeader: "Invalid CSV header.",
          invalidCSVFile: "Invalid CSV file.",
          invalidCSVBidValue: "Invalid csv bid value.",
          bidNotGreaterThanMin:
            "Bid value must be greater than the minimum bid.",
          invalidBidType: "Invalid bid type.",
          generalErr: "An error has occurred.",
        },
      },
      thirdStep: {
        targeting: "Audience Selection",
        targetingText:
          "Determine which audiences you want your ad to be displayed.",
        autoTargeting: "Automatic Targeting",
        autoTargetingText:
          "Your ad will be displayed in the most relevant keywords and categories.",
        manualTargeting: "Manual Targeting",
        manualTargetingText:
          "You can determine which audiences your products will be displayed.",
        platform: "Platform Targeting",
        platformText:
          "Determine which platform users you want your ad to be displayed.",
        tablet: "Tablet",
        mobile: "Mobile",
        app: "Mobile Application",
        desktop: "Desktop",
        age: "Age Range Targeting",
        ageText: "Determine which age group you want your ad to be displayed.",
        city: "City Targeting",
        cityText: "Determine which cities you want your ad to be displayed.",
        allCities: "All Cities",
        selectedCities: "Selected Cities",
        cityPlaceholder: "Select City",
        customer: "Customer Targeting",
        customerText:
          "Determine which customers you want your ad to be displayed based on their shopping history.",
        presentCustomers: "Existing Customers",
        presentCustomersText:
          "Target customers who have already ordered the products you advertise.",
        newCustomers: "New Customers",
        newCustomersText:
          "Target customers who have never ordered the products you advertise",
        gender: "Gender Targeting",
        genderText: "Determine which gender you want your ad to be displayed.",
        female: "Female",
        male: "Male",
        audience: "Audience Selection",
        audienceText:
          "Determine which audiences you want your ad to be displayed.",
        segment: "Segment Targeting",
        segmentText:
          "Determine which segments you want your ad to be displayed.",
        allSegments: "All Segments",
        selectedSegments: "Selected Segments",
        segmentPlaceholder: "Select Segment",
      },
    },
    columnPopover: {
      title: "Edit Columns",
    },
    empty: {
      product_title: "Please make a product selection.",
      store_title: "Please make a store selection.",
      dimensions_title: "You haven't added dimension yet.",
      product_text:
        "You can add the products you want to advertise by clicking the add button in the product list on the left.",
      store_text: "You can add the stores you want to advertise.",
      dimensions_text:
        "You can add dimensions to customize your campaign results' level of detail.",
      null_title: "No Data",
      null_text: "There is no data in the table.",
      categories_title: "Please add the categories you will target.",
      categories_text:
        "Specify the categories you want your ad to appear in by entering a category or clicking the add button in the suggested categories table.",
      keywords_title: "Please add the keywords you will target.",
      keywords_text:
        "Specify the keywords you want your ad to show by entering a word or clicking the add button in the suggested keywords table.",
      negative_keywords_title: "You haven't added any negative keywords yet.",
      negative_keywords_text:
        "You can specify negative keywords that you do not want your ad to show by entering a word.",
      negative_categories_title:
        "You have not added any negative categories yet.",
      negative_categories_text:
        "You can specify the negative categories that you do not want your ad to show.",
      campaigns_table_title: "You Haven't Created Any Campaign",
      campaigns_table_text:
        "You can create your first campaign by clicking the Add New Campaign button.",
      reports_table_title: "You Haven't Created Any Report",
      reports_table_text:
        "You can create your first report by clicking the Add New Report button.",
      invoices_table_title: "You Haven't Loaded Any Balance",
      invoices_table_text:
        "You can add an ad balance by contacting your account manager.",
      advertisers_table_title: "There is no advertiser linked to your account",
      advertisers_table_text: "Please contact your account manager.",
      agencies_table_title: "You Haven't Created An Agency",
      agencies_table_text:
        "You can create an agency by clicking the Add New Agency button.",
      transactions_table_title: "You Haven't Loaded Any Balance",
      transactions_table_text:
        "You can add ad balance to your list of advertisers by clicking the Load Balance button.",
      coupons_table_title: "You Haven't Created Any Coupons",
      coupons_table_text:
        "You can define coupons for your advertisers by clicking the Define Coupon button.",
      account_users_table_title: "You Haven't Created A User",
      account_users_table_text:
        "You can create a user by clicking the Add new user button under the Invited tab.",
      archived_table_reports_title: "No Archived Reports",
      archived_table_campaigns_title: "No Archived Campaigns",
      archived_table_agencies_title: "No Archived Agencies",
      archived_table_account_users_title: "No Archived Users",
      archived_table_advertisers_title: "No Archived Advertisers",
      archived_table_ad_placements_title: "No Archived Ad Placements",
      invited_table_users_title: "No Invited Users",
      searched_table_title: "No records were found matching your search.",
      searched_table_text: "",
      searched_title: "No records were found matching your search.",
      searched_text: "",
      products_table_title: "You haven’t added any products yet.",
      products_table_text:
        "You can add the products you want to advertise by clicking the add products button.",
      keywords_table_title: "You haven't added any keywords yet.",
      keywords_table_text:
        "You can specify keywords that you want your ad to show by entering a word.",
      categories_table_title: "You haven't added any categories yet.",
      categories_table_text:
        "You can specify categories that you want your ad to show by entering a word.",
      negative_keywords_table_title:
        "You haven't added any negative keywords yet.",
      negative_keywords_table_text:
        "You can specify negative keywords that you do not want your ad to show by entering a word.",
      negative_categories_table_title:
        "You haven't added any negative categories yet.",
      negative_categories_table_text:
        "You can specify negative categories that you do not want your ad to show.",
      sync_logs_table_title: "No Sync History Data",
      sync_logs_table_text: "No sync history data found.",
      ad_placements_table_title: "You haven't added any ad placements yet.",
      ad_placements_table_text:
        "You can add ad placements by clicking the add ad placement button.",
      creatives_table_title: "No creatives have been added yet!",
      creatives_table_text: "Uploaded creatives will appear here.",
      creatives_relations_table_title: "You haven't created a creative yet.",
      creatives_relations_table_text:
        "You can create new creative from the campaign form.",
      creativeForm_title: "You haven't added any creatives yet.",
      creativeForm_text: "Suggested creative sizes:",
      invites_table_title: "You Haven’t Invited a User",
      invites_table_text:
        "You can invite users by clicking the Add New User button.",
      review_title: "There are currently no creatives to review.",
      review_text:
        "All creatives have been approved, or no creatives have been uploaded. Creatives pending approval will appear on this screen.",
      accepted_table_creatives_title: "No creatives approved yet!",
      accepted_table_creatives_text:
        "All creatives have been rejected, or no creatives have been uploaded.",
      rejected_table_creatives_title: "No creatives rejected yet!",
      rejected_table_creatives_text:
        "All creatives have been approved, or no creatives have been uploaded.",
      pending_table_creatives_title: "No creatives added yet!",
      pending_table_creatives_text:
        "All creatives have been reviewed, or no creatives have been uploaded.",
      blacklist_table_title: "No keyword restriction found.",
      blacklist_table_text:
        "You can add a restriction by clicking the Add New Keyword button.",
      cities_table_title: "You haven't added any cities yet.",
      cities_table_text: "",
      age_ranges_table_title: "You haven't added any age ranges yet.",
      age_ranges_table_text: "",
      genders_table_title: "You haven't added any genders yet.",
      genders_table_text: "",
      platforms_table_title: "You haven't added any platforms yet.",
      platforms_table_text: "",
      customers_table_title: "You haven't added any customers yet.",
      customers_table_text: "",
      report_loading_title: "Report Generation in Progress",
      report_loading_text:
        "Your report is currently being created. This process may take some time depending on the complexity and size of the report.",
      data_sources_table_title: "No Data Sources Found",
      data_sources_table_text:
        "There are no data sources at the moment. Add one to start seeing import details and history.",
      logs_title: "No Logs Found",
      logs_text:
        "There are currently no logs recorded under this category. Once relevant events occur, the logs will be displayed here.",
      segments_table_title: "You haven't added any segments yet.",
      segments_table_text: "",
      meta_campaigns_table_title: "You haven't created any campaigns yet.",
      meta_campaigns_table_text:
        "You can create your first campaign by clicking the Add New Campaign button.",
    },
    endDatePicker: {
      label: "End Date",
      campaigns: "Select the campaign end date.",
      reports: "Select the report end date.",
      button: "No End Date",
    },
    reportForm: {
      title: "Report Information",
      nameTitle: "Report name",
      nameText: "Enter a name to be displayed in the reports table.",
      nameSub: "Enter your report name.",
      reportTypeTitle: "Delivery",
      reportTypeText:
        "Select the delivery type of your report. Reports that you have generated will be delivered according to your preferences.",
      static: "Fixed",
      staticText: "Report runs once and displayed in reporting.",
      scheduled: "Scheduled",
      scheduledText: "Select frequency and receivers of your report.",
      frequency: "Choose the frequency of your report.",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      sendDate: "Choose the date you want to receive your first report.",
      firstDay: "First Day of the Month",
      lastDay: "Last Day of the Month",
      exportType: "Choose in which format you want to receive your report.",
      mail: "Enter the e-mail addresses to which your report will be sent.",
      dateTitle: "Report Date",
      dateText: "Select the start and end date of your report.",
      startDateSub: "Select the report start date.",
      reportKindTitle: "Report Type",
      reportKindText:
        "You can easily measure performance with the predefined reports. With the customized report, you can create customized reports by adding dimensions, metrics and filters.",
      predefined: "Predefined Report",
      predefinedPlaceholder: "Select Predefined Report Type",
      special: "Customized Report",
      breaks: "Dimensions",
      breaksText: "Select the dimensions you want to include in your report.",
      breaksPlaceholder: "Search dimensions",
      maxWarning: "*You can add up to 4 dimensions to your report.",
      clear: "CLEAR",
      metrics: "Metrics",
      metricsText: "Select the metrics you want to include in your report.",
      filters: "Filters:",
      filtersText:
        "Select the filters you want to apply to the dimensions and metrics.",
      product: "Product Performance",
      product_category: "Category Performance",
      product_brand: "Brand Performance",
      keyword: "Keyword Performance",
      campaign: "Campaign Performance",
      advertiser: "Advertiser Performance",
      agency: "Agency Performance",
      errorStates: {
        date: "Please select a date.",
        mail: "Please enter an email.",
        type: "Please select report type.",
        metric: "Please select at least one metric.",
      },
      dimensions: {
        campaignId: "Campaign ID",
        campaignName: "Campaign Name",
        campaignBidType: "Bid Type",
        campaignBudgetType: "Budget Type",
        product: "Product",
        categoryName: "Category Pages",
        keywordText: "Keyword",
        productBrand: "Product Brand",
        productName: "Product",
        day: "Day",
        week: "Week",
        month: "Month",
        year: "Year",
        hour: "Hour",
        advertiser: "Advertiser ID",
        advertiserName: "Advertiser",
        agency: "Agency ID",
        agencyName: "Agency",
        customerDeviceType: "Customer Device Type",
        saleProductName: "Indirect Sale Product",
        placementId: "Placement ID",
        customerType: "Customer Type",
        productRetailerTaxonomy: "Product Category",
        adPlacementCustomKey: "Ad Placement ID",
        adPlacementName: "Ad Placement Name",
        placementText: "Placement Text",
        in_search: "Search Results",
        in_category: "Category Pages",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Homepage",
        campaignAdType: "Campaign Type",
        campaignBudget: "Budget",
        campaignFixedBid: "Campaign Fixed Bid",
        keywordBid: "Keyword Bid",
        categoryBid: "Category Bid",
        advertiserResourceID: "Seller ID",
        productSKU: "Product ID",
        customerDeviceEnvironmentType: "Customer Device Environment Type",
        campaignDailyBudget: "Daily Budget",
        campaignTotalBudget: "Total Budget",
      },
      metric: {
        sumImpression: "Impressions",
        sumViewableImpression: "Viewable Impression",
        sumClick: "Clicks",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "Sale",
        ACoS: "ACoS",
        sumDirectSale: "Direct Sale",
        sumIndirectSale: "Indirect Sale",
        sumDirectSaleAmount: "Direct Sale Amount",
        sumIndirectSaleAmount: "Indirect Sale Amount",
        sumSaleAmount: "Sale Amount",
        sumSpend: "Spend",
        sale: "Sale",
        sumVideoStart: "Video Starts",
        sumVideoComplete: "Video Completes",
        VCR: "VCR",
        viewabilityRate: "Viewability Rate",
        deliveryRate: "Delivery Rate",
        adResponseYieldRate: "Ad Response Yield Rate",
        fillRate: "Fill Rate",
        sumPostClickSale: "Post-Click Sale",
        sumPostViewSale: "Post-View Sale",
        sumPostClickSaleAmount: "Post-Click Sale Amount",
        sumPostViewSaleAmount: "Post-View Sale Amount",
        sumPostClickDirectSale: "Post-Click Direct Sale",
        sumPostClickIndirectSale: "Post-Click Indirect Sale",
        sumPostViewDirectSale: "Post-View Direct Sale",
        sumPostViewIndirectSale: "Post-View Indirect Sale",
        sumPostClickDirectSaleAmount: "Post-Click Direct Sale Amount",
        sumPostClickIndirectSaleAmount: "Post-Click Indirect Sale Amount",
        sumPostViewDirectSaleAmount: "Post-View Direct Sale Amount",
        sumPostViewIndirectSaleAmount: "Post-View Indirect Sale Amount",
        ACoSByClick: "ACoS By Click",
        ACoSByView: "ACoS By View",
        RoASByClick: "RoAS By Click",
        RoASByView: "RoAS By View",
        CVRByClick: "CVR By Click",
        CVRByView: "CVR By View",
      },
    },
    selectFilter: {
      title: "Filter Options",
      placeholder: "Select Filter",
      budgetOpt: "Remaining Budget",
      budgetAdvertiser: "Advertiser Name",
      placeholderCondition: "Choose Condition",
      bigger: "is greater",
      smaller: "is smaller",
      equal: "Equals",
      notEqual: "Not Equal",
      any: "contains",
      add: "Add Filter",
      placeholderValue: "Select Value",
      enter: "Enter Value",
      date: "Select Date",
      reportsText: "Select the filter you want to apply to your report.",
    },
    searchBar: {
      keywords: "Add keywords...",
      negative_keywords: "Add keywords...",
      categories: "Add categories...",
      negative_categories: "Add categories...",
    },
    settingsHeader: {
      general: "General Information",
      generalText: "General information about the marketplace",
      integration: "Integration Information",
      integrationText:
        "Integration information required to perform data transfer",
      advertiser: "Advertiser Settings",
      advertiserText: "Settings related to advertisers.",
      auction: "Auction Settings",
      auctionText: "Auction related settings",
      campaign: "Campaign Settings",
      campaignText: "Settings related to campaign setup",
      bid: "Bid Restrictions",
      bidText: "Settings for platform-wide bidding rules",
      conversion: "Conversion",
      conversionText:
        "Settings related to your conversion goals on the platform",
      category: "Category Restrictions",
      categoryText: "Restrictions on categories displayed on your platform",
      keyword: "Keyword Restrictions",
      keywordText: "Restrictions on keywords searched on your platform",
      offsite: "Off-Site Settings",
      offsiteText: "",
    },
    stepsNavbar: {
      report_create: "Create Report",
      campaign_create: "Create Campaign",
      report_edit: "Update Report",
      step: "The next step is {{value}}",
      advertiser: "Advertiser:",
    },
    suggestionCard: {
      addAll: "Add All",
    },
    table: {
      keywords: {
        keyword: "Keyword",
        matchType: "Match Type",
        suggested: "Suggested Bid",
        dynamic: "Auto",
        volume: "Search Volume",
        competition: "Competition",
        search: "Add keywords...",
        suggestTitle: "Suggested Keywords",
        suggestText: "Recommended keywords for your products.",
        negativePlaceholder: "Add negative keywords...",
        exact: "Search terms that exactly match your keyword.",
        phrase:
          "Search terms that contain components of your keyword in the same order.",
        broad:
          "Search terms that contain components of your keyword in any order.",
        warning: "Minimum value must be {{value}}.",
      },
      products: {
        productSearch: "Search for product, sku or barcode.",
        storeSearch: "Search for store",
        loading: "Loading",
        title: "Please make a product selection.",
        text: "You can add the products you want to advertise by clicking the add button in the product list on the left.",
        productSelected: "Selected Products",
        storeSelected: "Selected Stores",
        remove: "Remove All",
        price: "Price",
        stock: "Stock",
        rating: "Rating",
        drawerTitle: "Bulk Product Selection",
        sku: "SKU or Barcode List",
        skuText:
          "Add the product SKU or Barcode you want to add, one per line, in the field below.",
        categories: "All Categories",
        bulk: "Bulk Select",
        all: "Show All",
        selected: "Show Selected Products",
        total: "There are <strong>{{value}} products</strong> in total.",
        variable: "Variable",
      },
      categories: {
        suggestTitle: "Recommended Categories",
        suggestText: "Recommended categories for your products",
      },
      result: {
        campaignId: "Campaign ID",
        campaignName: "Campaign Name",
        campaign: "Campaign ID",
        campaignBidType: "Bid Type",
        campaignBudgetType: "Budget Type",
        advertiserName: "Advertiser Name",
        agencyName: "Agency Name",
        product: "Product",
        productName: "Product",
        categoryText: "Category",
        keyword: "Keyword ID",
        keywordText: "Keyword Text",
        keywordMatchType: "Match Type",
        productBrand: "Product Brand",
        day: "Day",
        week: "Week",
        month: "Month",
        year: "Year",
        hour: "Hour",
        sumImpression: "Impressions",
        sumViewableImpression: "Viewable Impression",
        sumClick: "Clicks",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "Sale",
        ACoS: "ACoS",
        sumDirectSale: "Direct Sale",
        sumIndirectSale: "Indirect Sale",
        sumDirectSaleAmount: "Direct Sale Amount",
        sumIndirectSaleAmount: "Indirect Sale Amount",
        sumSaleAmount: "Sale Amount",
        sumSpend: "Spend",
        campaignStartDate: "Start Date",
        campaignEndDate: "End Date",
        campaignStatus: "Status",
        campaignBudget: "Budget",
        advertiser: "Advertiser",
        agency: "Agency",
        weekOf: "Week of {{value}}",
        customerDeviceType: "Customer Device Type",
        saleProductName: "Indirect Sale Product",
        placementId: "Placement ID",
        customerType: "Customer Type",
        categoryName: "Category Pages",
        productRetailerTaxonomy: "Product Category",
        adPlacementCustomKey: "Ad Placement ID",
        adPlacementName: "Ad Placement Name",
        placementText: "Placement Text",
        in_search: "Search Results",
        in_category: "Category Pages",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Homepage",
        in_collection: "Collection",
        campaignAdType: "Campaign Type",
        campaignFixedBid: "Campaign Fixed Bid",
        keywordBid: "Keyword Bid",
        categoryBid: "Category Bid",
        advertiserResourceID: "Seller ID",
        productSKU: "Product ID",
        customerDeviceEnvironmentType: "Customer Device Environment Type",
        campaignDailyBudget: "Daily Budget",
        campaignTotalBudget: "Total Budget",
        sumVideoStart: "Video Starts",
        sumVideoComplete: "Video Completes",
        VCR: "VCR",
        viewabilityRate: "Viewability Rate",
        deliveryRate: "Delivery Rate",
        adResponseYieldRate: "Ad Response Yield Rate",
        fillRate: "Fill Rate",
        sumPostClickSale: "Post-Click Sale",
        sumPostViewSale: "Post-View Sale",
        sumPostClickSaleAmount: "Post-Click Sale Amount",
        sumPostViewSaleAmount: "Post-View Sale Amount",
        sumPostClickDirectSale: "Post-Click Direct Sale",
        sumPostClickIndirectSale: "Post-Click Indirect Sale",
        sumPostViewDirectSale: "Post-View Direct Sale",
        sumPostViewIndirectSale: "Post-View Indirect Sale",
        sumPostClickDirectSaleAmount: "Post-Click Direct Sale Amount",
        sumPostClickIndirectSaleAmount: "Post-Click Indirect Sale Amount",
        sumPostViewDirectSaleAmount: "Post-View Direct Sale Amount",
        sumPostViewIndirectSaleAmount: "Post-View Indirect Sale Amount",
        ACoSByClick: "ACoS By Click",
        ACoSByView: "ACoS By View",
        RoASByClick: "RoAS By Click",
        RoASByView: "RoAS By View",
        CVRByClick: "CVR By Click",
        CVRByView: "CVR By View",
      },
    },
    states: {
      campaigns_not_started: "Campaign hasn't started yet.",
      campaigns_date_passed: "Campaign past its scheduled end date.",
      campaigns_status_paused: "You paused the campaign.",
      campaigns_out_of_daily_budget:
        "Your campaign’s daily budget has been exhausted.",
      campaigns_out_of_total_budget:
        "Your campaign has reached the total budget limit.",
      campaigns_products_stopped: "Products are not active.",
      campaigns_keywords_stopped: "Keywords are not active.",
      campaigns_categories_stopped: "Categories are not active.",
      campaigns_status_archived: "You archived the campaign.",
      campaigns_creatives_stopped: "There is no active creatives.",
      campaigns_insufficient_balance: "Your balance is insufficient.",
      campaigns_low_bid: "Bid is below the minimum bid.",
      campaigns_advertiser_paused: "Advertiser is not active.",
      campaigns_regions_stopped: "Regions are not active.",
      products_status_paused: "You paused the product.",
      products_low_stock: "Status stopped due to stock constraints.",
      products_low_rating: "Status stopped due to rating constraints.",
      products_buybox_loser: "Status stopped due to buybox constraint.",
      products_product_paused: "Product is not active.",
      products_product_deleted: "Product is not active.",
      keywords_status_paused: "You paused the keyword status.",
      keywords_low_bid: "Bid is below the minimum bid.",
      categories_status_paused: "Category targeting paused.",
      categories_low_bid: "Bid is below the minimum bid.",
      creatives_paused: "You paused the creative status.",
      creatives_pending: "Creative is waiting for review.",
      creatives_rejected: "Creative is rejected.",
      creatives_note: "Rejection Reason:",
    },
    payment: {
      amount: "Amount to Charge:",
      title: "Payment Method",
      subtitle:
        "The advertising balance will be invoiced to you at a price including VAT.",
      wire_transfer: "Wire Transfer / EFT",
      beymen_milestone_payment: "Milestone Payment",
      koctas_milestone_payment: "Milestone Payment",
      modanisa_milestone_payment: "Milestone Payment",
      bank: "Bank Information",
      helpUpper:
        "After your payments are approved by wire transfer or EFT, will be transferred. Approval process can last",
      helpSecondary: "2-3 business days.",
      ref: "Reference No:",
      info: "Entering your reference number correctly in the description of the transaction is very important for tracking the transaction.",
      tax: "VAT:",
      verifyAmount: "Amount To Be Withdrawn From Your Account:",
      toters_milestone_payment: "Transfer from Wallet",
      product_placeholder: "Search for Store",
      craftgate: "Credit Card",
    },
    tour: {
      prev: "Back",
      next: "Next",
      done: "Finish the tour",
      title: "Welcome to the Ad Platform!",
      intro:
        "To learn how to use the platform, click “Start” and continue the product tour. You can skip the tour by clicking the exit button",
      menu: "Menu Bar",
      menuText: "You can use the Menu Bar in the left to switch between pages.",
      campaigns: "Campaigns",
      campaignsText:
        "You can manage your advertising campaigns from the “Campaigns” page. Here, you can view the amount of clicks and sales you get from the ads.",
      reports: "Reports",
      reportsText:
        "You can create reports picking different dimensions on the performance of your ads. With these reports, you can view how many times your ads were shown, in which search results or categories they were displayed at, or how many sales were made.",
      reportsTextSub:
        "While creating reports, you can choose predefined reports or choose customized reports that you will create yourself.",
      invoices: "Invoices",
      invoicesText:
        "In this section, you can view the balances you have uploaded in order to spend on your campaigns, the gift coupons earned, and all the ad spend.",
      academy: "Academy",
      academyText:
        "Here you can find the necessary information about the use of the platform.",
      widgets: "Statistics",
      widgetsText:
        "You can view the performance of the campaigns you create gradually. You can also examine your ad performance in detail on a daily basis by clicking “Show in Chart” button.",
      table: "Campaigns Table",
      tableText:
        "In this section, you can view the campaigns you have created and their performance. You can pause your campaigns at your wish. To view the details of your campaign, simply click the campaign name.",
      button: "Add New Campaign",
      buttonText:
        "Click the “Add New Campaign” button to start creating your first ad campaign.",
      modal: "Campaign Type",
      modalText: "Here you can see the types of ad campaigns you can choose.",
      type: "Sponsored Product",
      typeText:
        "You can create a Sponsored Product campaign where you can highlight your products on the top spots of search results or category pages.",
    },
    widget: {
      title: "Metrics Date Range",
      description:
        "Select a start and end date to view your metrics. The default range is the past 30 days, and future dates cannot be selected.",
    },
    offsiteCampaignForm: {
      campaignInfoTitle: "Ad Information",
      adSummary: "Review the selections in your ad.",
      info: "General Information",
      productName: "Product Name & Cover:",
      adName: "Ad Name:",
      budget: "Budget:",
      noAdAccountErr: "No meta ad account found.",
      generalErr: "An error occurred while creating the campaign.",
      firstStep: {
        budget: "Budget",
        budgetText:
          "Select the budget type of the campaign and set the budget. Once your spend reaches the budget, the campaign will automatically stop.",
        budgetLabel: "Daily Budget",
        budgetError: "Please enter a valid budget.",
        urlTitle: "Redirect URL",
        urlText:
          "It is the page where users who click on your ad will be directed. You need to redirect users to a page within the marketplace.",
        urlLabel: "Redirect URL",
        urlError: "Please enter a valid URL.",
        adPreview: "Ad Preview",
        adPreviewText: "Your ad details will be displayed here.",
        pleaseSelectProduct: "Please select a product to preview the ad.",
        productName: "Product Name & Cover",
        destination: "Destination",
        displayLink: "Display Link",
        primaryText: "Primary Text",
        primaryTextText: "Enter the primary text of your ad.",
        headline: "Headline",
        headlineText: "Enter the headline of your ad.",
        description: "Description",
        descriptionText: "Enter the description of your ad.",
        cta: "CTA:",
        fixedCta: "Shop Now",
        editAd: "Edit Ad",
        adName: "Ad Name",
        adNameText: "Enter the name of your ad.",
      },
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "Add New Campaign",
        placeholder: "Search in Campaigns",
        errorTitle: "No Advertiser Selected",
        errorText: "Please select an advertiser.",
        table: {
          standart: "Auto",
          max_acos: "Auto",
          fixed: "Fixed",
          daily: "Daily",
          flexible: "Daily",
          total: "Total",
          budgetLimit: "Limited to {{value}}",
          stopped: "You've stopped the campaign.",
          product: "Sponsored Product",
          display: "Sponsored Display",
          store: "Sponsored Store",
          default: "Default View",
          checkUp: "Checkup View",
          video: "Sponsored Video",
          display_with_product: "Sponsored Brand Display",
          video_with_product: "Sponsored Brand Video",
        },
      },
      campaignDetails: {
        subtext: "You can edit the campaign information.",
        products: "Products",
        on: "Active",
        keywords: "Keywords",
        negativeKeywords: "Negative Keywords",
        targeting: "Audiences",
        city: "City",
        age: "Age",
        gender: "Gender",
        platform: "Platform",
        customers: "Customers",
        placements: "Ad Placements",
        in_search: "Search Results",
        in_category: "Categories",
        placementPlaceholder: "Search in Ad Placement",
        date: "Start-End Date:",
        bid: "Bid:",
        maxAcos: "% Maximum ACoS",
        fixed: "Fixed",
        standart: "Auto",
        daily: "Daily",
        total: "Total",
        flexible: "Daily",
        none: "None",
        keywordsBtn: "Add Keyword",
        keywordsPlaceholder: "Search for Keywords",
        keywordsSub: "Add the keywords you want your ad to be displayed.",
        negative_keywords_btn: "Add Negative Keyword",
        negative_keywords_placeholder: "Search in Negative Keywords",
        negative_keywords_modal_subtitle:
          "Add the keywords you don't want your ad to be displayed.",
        negative_categories_btn: "Add Negative Category",
        negative_categories_placeholder: "Search in Negative Categories",
        negative_categories_modal_subtitle:
          "Add the categories you don't want your ad to be displayed.",
        productsBtn: "Add Products",
        productsPlaceholder: "Search Products",
        productsSub: "Select the products you want to advertise.",
        cities_placeholder: "Search Cities",
        age_ranges_placeholder: "Search Age Ranges",
        genders_placeholder: "Search Genders",
        platforms_placeholder: "Search Platforms",
        customers_placeholder: "Search Customer Types",
        new: "New Customers",
        present: "Existing Customers",
        keywordTitle: "Keyword Targeting",
        keywordText:
          "Keywords are automatically targeted for your ad. You can stop the keywords you don't want to target.",
        maxAcosLabel: "Max ACoS",
        fixedBidLabel: "Fixed Bid",
        productsError: "At least 1 product must be active.",
        keywordsError: "At least 1 keyword must be active.",
        categoriesError: "At least 1 category must be active.",
        categories: "Categories",
        categoriesBtn: "Add Category",
        categoriesPlaceholder: "Search in Categories",
        categoriesSub: "You can add categories to target.",
        negativeCategories: "Negative Categories",
        buyboxError: "Product is not buybox winner.",
        ratingError: "Product rating is below min rating.",
        stockError: "Product stock is below minimum stock.",
        notActiveError: "Product is not active.",
        creatives: "Creatives",
        creativesBtn: "Add Creatives",
        creativesPlaceholder: "Search Creatives",
        creativesSub: "Add the creatives you want to show in your ad.",
        creativesLinkTitle: "Redirect URL",
        creativesLinkText:
          "Users who click on your ad are directed to {{value}}.",
        winner: "Winner",
        loser: "Loser",
        creativesError: "At least 1 creative must be active.",
        pendingError: "Pending creatives can’t be active.",
        rejectedError: "Rejected creatives can’t be active.",
        in_product_detail_page: "Product Detail Pages",
        in_home_page: "Home Page",
        in_collection: "Collection",
        allTimes: "All Times",
        scheduled: "Scheduled",
        dayParting:
          "You can choose the days and hours that your campaign will remain active.",
        video: "Videos",
        vastTag: "VAST Tag",
        videoFile: "Video File",
        region: "Region:",
        regionLength: "{{value}} Regions",
        regionsDrawerTitle: "Update Regions",
        languages: "{{value}} Languages",
        segments: "Segments",
        segments_placeholder: "Search Segments",
        categoryLimitError:
          "Maximum limit reached. You can add up to 100 categories. Pause an existing one to add a new one.",
        keywordLimitError:
          "Maximum limit reached. You can add up to 100 keywords. Pause an existing one to add a new one.",
        in_store_entrance: "Entrance",
        in_store_register: "Register",
        in_store_basket: "Bathroom",
        in_store_shelf: "Self Checkout",
        in_store_garden: "Garden",
        in_store_paint: "Paint",
      },
      noMatch: {
        title: "Sorry, we can't find that page.",
        btn: "Return Home",
      },
      reports: {
        button: "Add New Report",
        placeholder: "Search in Reports",
        name: "Report Name",
        table: {
          type: "Report Type",
        },
        form: {
          title: "Report 1",
          goBack: "Go back",
          create: "Create Report",
        },
      },
      reportDetails: {
        result: "Report Result",
        static: "Fixed",
        scheduled: "Scheduled",
        ready: "Predefined Report",
        startDate: "Start Date",
        endDate: "End Date",
        frequency: "Frequency",
        firstDate: "First Post Date",
        month: "First Day of the Month",
        emails: "E-mails",
        export: "File Type",
        predefined: "Predefined Report Type",
        total: "Get Total Stats",
      },
      invoice: {
        balance: "Balance",
        coupon: "Coupon",
        lastPayment: "Last Loaded Balance",
        searchPlaceholder: "Search in Invoices",
        duplicateCouponsError:
          "Multiple coupon definition detected. Please wait.",
        multipleTopUpsError: "Multiple balance upload detected. Please wait.",
        limitError:
          "You are trying to add balance with a high amount. Please check the amount.",
        minAmountError: "Minimum payment amount should be {{value}}.",
        data: {
          typeBudget: "Balance Payment",
          typeAd: "Advertising Cost",
          typeBudgetExplanation: "Ad balance is loaded.",
          typeAdExplanation: "Advertising cost is spent.",
        },
        table: {
          type: "Transaction Type",
        },
        modal: {
          button: "Add Balance",
          title: "Add Balance",
          subtitle: "Enter the amount you want to transfer to your ad balance.",
          loadingAmount: "Amount",
          differentAmount: "Enter an amount.",
          label: "Amount",
        },
      },
      academy: {
        more: "Learn More",
        copy: "Copy Link",
        helpTitle: "Still can't find answers to your questions?",
        helpText:
          "For your questions, you can reach the support team by sending an e-mail.",
        contact: "Contact Us",
        highlights: "Highlights",
        read: "Read The Rest of The Article",
        description:
          "With the helpful articles of {{value}} Advertising Guide, you can learn everything you need to know about the platform and start publishing your ads easily.",
      },
      offsiteCampaigns: {
        button: "Add New Campaign",
        placeholder: "Search in Campaigns",
        errorTitle: "No Advertiser Selected",
        errorText: "Please select an advertiser.",
        OUTCOME_TRAFFIC: "Outcome Traffic",
        OUTCOME_ENGAGEMENT: "Outcome Engagement",
        OUTCOME_SALES: "Outcome Sales",
        OUTCOME_LEADS: "Outcome Leads",
        OUTCOME_AWARENESS: "Outcome Awareness",
        failedText:
          "The campaign has failed. Please check the campaign details and create a new campaign.",
      },
      offsiteDetails: {
        updateError: "An error occurred while updating the campaign.",
        updateSuccess: "The campaign has been updated successfully.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext:
          "You can group advertisers by assigning them to agencies.",
        modalPlaceholder: "Search agency...",
        search: "Search in Advertisers",
        select_advertiser_subtitle:
          "Please choose which advertiser you want to create a campaign for.",
        userSubtext:
          "You can invite users who will use the advertiser account.",
        help: "Add the user's email address.",
        deleteSuccess: "The agency was successfully removed.",
        create: "Add New Advertiser",
        update: "Update Advertiser",
        type: "Advertiser Type:",
        custom: "Custom",
        customText:
          "It is the type of advertiser created with the products of the vendors that are set to be shareable within the platform.",
        name: "Advertiser Name",
        nameHelp: "The name of your advertiser that will be displayed by you.",
        agency: "Agency",
        agencyHelp: "It is the agency that the advertiser is affiliated with.",
        email: "E-mail",
        emailHelp:
          "Enter the e-mail address to which the invitation will be sent. The seller uses the e-mail address you entered when logging into the platform.",
        product: "Product Name",
        brand: "Brand",
        retailer_taxonomy: "Product Category",
        advertiser_id: "Advertiser ID",
        filters: "Filters:",
        filterWarning: "Please add at least one filter.",
        typeTitle: "Advertiser Type",
        typeSubtext:
          "Which of the following do you want to change the type of advertiser selected above?",
        default: "Default",
        defaultText:
          "It is used for seller accounts that sell directly under their own name within the platform.",
        shareable: "Shareable",
        shareableText:
          "It is used for seller accounts that sell on behalf of others within the platform and must be distributed to main sellers.",
        generalTitle: "General Information",
        generalSubtext: "General information about the advertiser",
        successText:
          "All products matching the brand of the seller you selected have been added.",
        advertiser: "Advertiser:",
        drawerFilterWarning: "The filters you add cannot be undone!",
        filterTitle: "Filter",
        filterText: "Select the filter you want to apply to advertisers.",
        keyLabel: "Select Filter",
        opLabel: "Select Condition",
        valueLabel: "Enter Value",
        categorySelectLabel: "Select Category",
        filterKeyError: "Please select a filter.",
        filterOpError: "Please select a condition.",
        filterValueError: "Please enter a value.",
        filterCategoryError: "Please select a category.",
      },
      advertiserSettings: {
        title: "Advertiser Settings",
        subtext: "Settings related to advertisers.",
        status: "Default Advertiser Status",
        statusText:
          "If the default status of advertisers added to the platform is closed, you must activate the status on the advertisers page so that they can log into the system.",
        welcome: "Welcome Coupon",
        welcomeText:
          "You can define a welcome coupon that the advertiser can use when they come to the platform for the first time.",
        warning:
          "If you enter “0”, the welcome coupon will not be defined for the advertisers.",
        label: "Coupon Balance",
        language: "Language",
        languageText:
          "It is the default interface language for advertisers. Advertisers can change the language selection through their own interface.",
        balance: "Balance Load Authorization",
        balanceText:
          "It is the authority of advertisers to transfer their progress payments to their ad balances via the interface.",
        open: "Open",
        closed: "Closed",
        error_title: "You must enter a value.",
        error_text:
          "If you don't want to define a coupon, you can enter “0” and click the save button.",
        error_text_input: "You must enter a value.",
      },
      agencies: {
        label: "Agency Name",
        subtext: "Enter an agency name",
        subtitle: "You can add agency to group advertisers",
        search: "Search in Agencies",
        edit: "Update the agency name",
      },
      auctionSettings: {
        title: "Auction Settings",
        subtext: "Auction related settings",
        type: "Auction Type",
        typeText:
          "Choose which auction type the advertisers' bids will be evaluated for. You can find detailed information about auction types here.",
        warning: "Changes will affect advertising spending on the platform.",
        firstOpt: "First Price",
        firstOptText: "Winning advertisers pay the bid placed.",
        secondOpt: "Second Price",
        secondOptText:
          "Winning advertisers pay 1 cent more than the second highest bid.",
        link: "Auction Types",
      },
      campaignSettings: {
        title: "Campaign Settings",
        subtext: "Settings related to campaign setup",
        conversionRange: "Conversion Range",
        conversionRangeText:
          "You can specify the time period during which the user's purchasing activity will be attributed to the ad click. When you set the conversion range to 7 days, if the user clicks on the ad and buys the product within 7 days, the campaign balance is spent.",
        product: "Product Rating",
        productText:
          "You can add a minimum rating requirement for the products that advertisers will add to their campaigns, and customize the behavior for products that do not meet this condition.",
        minimum: "Minimum Product Rating",
        minimumText:
          "Enter the minimum product rating for the products to be added to the campaigns.",
        behaviour: "Set Behavior",
        behaviourText:
          "Enter the minimum product rating for the products to be added to the campaigns.",
        placeholder: "Select Behavior",
        stock: "Product Stock",
        stockText:
          "You can add a minimum stock requirement for the products that advertisers will add to their campaigns, and customize the behavior for products that do not meet this condition.",
        minimumStock: "Minimum Product Stock",
        minimumStockText:
          "Enter the minimum stock for the products to be added to the campaigns.",
        stockBehaviourText:
          "Determine the behavior that will occur when the number of stocks falls below the minimum stock you have determined.",
        alert: "Allow in Campaign, and Show Ads",
        block: "Don’t Allow in Campaign",
        targeting: "Demographic Targeting",
        targetingText:
          "In campaign setup, choose whether the demographic targeting step should be enabled or not. When enabled, an additional step is added to the campaign setup, allowing advertisers to set their targeting preferences.",
        all: "All Advertisers",
        none: "No Advertiser",
        selected: "Selected Advertisers",
        format: "Ad Formats",
        sponsored: "Sponsored Product",
        brand: "Sponsored Brand",
        ad: "Visual Advertising",
        rating_warn:
          "Your product rating is too low! Adding this product to your campaign will reduce performance.",
        rating_block:
          "Your product rating is too low! You cannot add this product to your campaign!",
        stock_warn:
          "Your stock is too low! Adding this product to your campaign will reduce performance.",
        stock_block:
          "Your stock is too low! You cannot add this product to your campaign!",
        buybox_warn:
          "The product is not a buy box winner. Adding this product to your campaign will reduce performance.",
        buybox_block:
          "The product is not a buy box winner. You cannot add this product to your campaign!",
        buyBoxTitle: "Buy Box Condition",
        buyBoxText:
          "You can add a Buy Box winner condition for the products that advertisers will add to their campaigns.",
        buyBoxSubtext:
          "Determine the behavior that will occur when the products to be added to the campaigns are not buy box winners.",
        noWorning: "None",
        warn: "The change will affect all active campaigns.",
        rangeError: "You must enter a value. The recommended value is 7 days.",
        rangeErrorInput: "Only a value between 1-14 days can be entered.",
        chooseBehaviour: "You must choose a behavior.",
        stockError: "You must enter a minimum stock.",
        allStockError: "You must enter a minimum stock and choose a behavior.",
        ratingError: "You must enter a minimum rating.",
        allRatingError:
          "You must enter a minimum rating and choose a behavior.",
        serverBlock: "Allow in Campaign, but Block Ads",
        advertisers: "Selected Advertisers",
        advertisersText:
          "Demographic targeting will only be available to advertisers you choose.",
        advertisersBtn: "Add Advertiser",
        advertisersSub: "Select advertisers who can use demographic targeting.",
        advertisersPlaceholder: "Choose Advertiser",
        advertisersLength: "{{value}} advertisers were selected.",
        advertisersTitle: "Advertisers",
        general: "General",
        bid: "Bid Restrictions",
        conversion: "Conversion",
        conversionSubtext:
          "Settings related to your conversion goals on the platform",
        biddingTypes: "Sponsored Product Available Bid Types",
        biddingTypesText:
          "In the campaign creation step, you can specify the types of bids that advertisers can use.",
        biddingTypesWarning: "At least one option must remain open.",
        auto: "Automatic Bid",
        autoText:
          "A bid type where bid is automatically determined. Expenditures are calculated using the price of the product sold and the target ACoS you set.",
        acos: "Target ACoS",
        acosText:
          "Bid is automatically calculated based on the rate of ACoS advertisers enter and the price of products sold.",
        fixed: "Fixed Bid",
        fixedText:
          "Bid is determined by the advertiser. Advertisers can set a campaign-wide bid per keyword and category.",
        bidWarning: "At least one bid type must be selected.",
        bidText: "Settings for platform-wide bidding rules",
        bidLabelTitle: "Overall Minimum CPC",
        bidLabelText:
          "The minimum bid amount per click that must be entered for all categories and keywords.",
        bidLabel: "Minimum CPC",
        cpmTitle: "Overall Minimum CPM",
        cpmText:
          "The minimum bid amount per impression that must be entered for all categories and keywords.",
        cpmLabel: "Minimum CPM",
        targetAcosTitle: "General Target ACoS",
        targetAcosText:
          "You can set the maximum percentage of ACoS to target by default in auctions.",
        targetAcosLabel: "Max. ACoS",
        categoryRelevanceTitle: "General Category Relevance",
        categoryRelevanceText:
          "It determines the level of relevance that should be between sponsored products and organic listed products in ad impressions.",
        categoryRelevanceLabel: "Category Relevance",
        bidTabWarning:
          "Changes made will affect advertising spend and impressions on the platform.",
        inputErrorZero: "You must enter a value greater than zero.",
        inputErrorAcos: "You must enter a value between 0-100.",
        inputErrorRelevance: "You must enter an integer greater than zero.",
        inputError: "You must enter a value.",
        productLink: "Product Rate Restriction",
        stockLink: "Product Stock Restriction",
        targetingLink: "Demographic Targeting Feature",
        bidLink: "Bid Types",
        minThreshold: "Minimum Sale Threshold",
        minThresholdText:
          "This is the minimum sale threshold for products to be included in the auction.",
        minThresholdLabel: "Minimum Sale Threshold",
        minThresholdError: "Please enter an integer.",
        formatTitle: "Attribution Settings by Format",
        formatText:
          "Touchpoints are measured to determine their effectiveness across different campaign formats. Depending on the format, selecting impressions tracks visibility, while clicks focus on measuring conversions through direct engagement.",
        sponsoredProductTitle: "Sponsored Product",
        sponsoredProductText:
          "For product ads, conversions are evaluated based on how effectively users interact with the ad, focusing on whether engagement or exposure drives the desired outcome.",
        sponsoredDisplayTitle: "Sponsored Display",
        sponsoredDisplayText:
          "Display ads measure conversions by analyzing whether visibility alone influences user actions or if deeper interaction is required for success.",
        sponsoredVideoTitle: "Sponsored Video",
        sponsoredVideoText:
          "When it comes to video ads, the measurement of conversions revolves around determining if simply viewing the content or active engagement contributes more to achieving the goal.",
        postView: "Post-View",
        postClick: "Post-Click",
        reportTitle: "Attribution Settings by Reporting",
        reportText:
          "This option defines how attribution is applied for reporting purposes, shaping how data is organized and presented based on the selected approach.",
        marketplaceReport: "Separate Metrics in Marketplace Report",
        marketplaceReportText:
          "Enabling this option divides metrics in the marketplace report based on attribution, providing clearer insights into the performance of each touchpoint.",
        advertiserReport: "Separate Metrics in Advertiser Report",
        advertiserReportText:
          "Turning on this option separates metrics in the advertiser report by attribution, helping you better understand the impact of different campaign interactions.",
        widgetTitle: "Set Attribution for Widget",
        widgetText:
          "Widget attribution evaluates user interactions throughout the conversion journey. Choosing to track impressions captures ad visibility, while tracking clicks measures engagement as a conversion indicator.",
      },
      console: {
        add: "Add New Data Source",
        advertiser: "Advertiser",
        product: "Product",
        google_feed_url_scheduler: "Google Feed URL",
        google_feed_file: "Google Feed File Upload (XML)",
        google_feed_body: "Google Feed Direct XML",
        meta_feed_url_scheduler: "Meta Feed URL",
        minutes_15: "15 Minutes",
        minutes_30: "30 Minutes",
        hour_1: "1 Hour",
        hours_2: "2 Hours",
        hours_3: "3 Hours",
        hours_4: "4 Hours",
        hours_6: "6 Hours",
        hours_12: "12 Hours",
        hours_24: "24 Hours",
        history: "Show History",
        logs: "Show Logs",
        drawer: "Log View",
        error: "Error",
        warning: "Warning",
        success: "Success",
        info: "Info",
        prev: "Previous 50 Logs",
        next: "Next 50 Logs",
        api: "API",
        impression: "Impression",
        click: "Click",
        sale: "Sale",
        test: "Test",
        curlTest: "Testing",
        curlWarning:
          "Important: Avoid executing this process in the production environment!",
        request: "Request",
        run: "Run",
        response: "Response",
        hide: "Hide Raw",
        show: "Show Raw",
        auctionLogs: "Auction Logs",
      },
      coupons: {
        add: "Define Coupon",
        title: "Advertiser Information",
        subtext:
          "You can define coupons for advertisers to use for campaign spending.",
        label: "Expiration Date",
        warning:
          "Advertisers must redeem the coupon by the entered date. If you don't enter an expiration date, the coupon will be added to the advertisers balance permanently and advertisers can use the coupon whenever they want.",
        gift_coupon: "Gift Coupon",
        welcome_coupon: "Welcome Coupon",
        gift_coupon_desc: "Gift coupon is defined.",
        welcome_coupon_desc: "Welcome coupon is defined.",
        approve: "Would you like to continue to define coupon?",
        note: "Coupon Description",
        noteHelp:
          "Include the reason for defining the coupon. Ex. Black Friday Coupon",
      },
      createDatasource: {
        title: "Add a Data Source",
        save: "Save & Run",
        existsErr:
          "A data source with this URL already exists. Please use a different URL.",
        feedErr:
          "The feed URL cannot be empty for this type of integration. Please provide a valid URL.",
        cycleErr:
          "The cycle interval cannot be empty for this type of integration. Please provide a valid interval.",
        permissionErr:
          "Unable to access the provided URL. Please check the URL or permissions.",
        generalErr: "An error has occurred.",
        validateSuccess: "Validation Successful",
        validateSuccessText:
          "All required fields have been mapped correctly. Ready to continue.",
      },
      creatives: {
        all: "All",
        accepteds: "Accepted",
        rejecteds: "Rejected",
        pendings: "Pending",
        pending: "Pending",
        accepted: "Accepted",
        rejected: "Rejected",
        adModalTitle: "Ad Preview",
        creativeModalTitle: "Creative Preview",
        creative: "Creative",
        name: "Creative Name",
        search: "Search in Creatives",
        videoModalTitle: "Video Preview",
        rejectSubtitle:
          "You can enter the reason for rejecting the creative or leave it blank and continue.",
        note: "Enter the reason for rejecting the creative.",
      },
      datasourceDetails: {
        entity: "Entity:",
        import: "Import Type:",
        cycle: "Interval Cycle:",
        url: "Feed URL:",
        success: "Success",
        failure: "Failure",
        in_progress: "In Progress",
        partial_success: "Partial Success",
      },
      generalInfo: {
        title: "General Information",
        subtext: "General information about the marketplace",
        market: "Marketplace Name",
        marketText:
          "The name of the store that appears on the interface. You need to contact your account manager to change this information.",
        url: "URL",
        urlText:
          "It is the link of the store to be used in the interface and for routing. You need to contact your account manager to change this information.",
        country: "Country",
        countryText: "The country to be used for city targeting.",
        zone: "Time Zone",
        zoneText:
          "It is the default time zone to be used in interfaces. You need to contact your account manager to change this information.",
        currency: "Currency",
        currencyText:
          "Currency to be used in interfaces. This information cannot be changed.",
        zoneIstanbul: "Europe/Istanbul",
        email: "Support Email Address",
        emailText: "It is the e-mail address that your sellers will contact.",
        emailLabel: "E-mail",
        emailError: "You must enter an e-mail.",
        seller: "Seller Panel",
        sellerText:
          "It is the address that will allow your sellers to be directed directly to your panel through the interface.",
        sellerLabel: "Seller Panel",
        marketError: "You need to enter the marketplace name.",
        urlError: "You must enter an URL.",
      },
      inspect: {
        campaign: "Campaign:",
        advertiser: "Advertiser:",
        url: "URL:",
        size: "Size:",
        status: "Status:",
        keywords: "Keywords",
        categories: "Categories",
        vastTag: "VAST Tag:",
        languages: "Language:",
        regions: "Regions:",
      },
      integrationInfo: {
        title: "Integration Information",
        subtext: "Integration information required to perform data transfer",
        api: "API Security Code",
        apiText:
          "It is the required security code to access the platform via API.",
        ftp: "FTP Security Certificate",
        ftpText: "It is the required certificate to access the FTP server.",
        download: "Download",
        copy: "Click to copy.",
        copied: "Copied",
        auto: "Auto Impression",
        autoText:
          "It is a setting that allows all impressions to be recorded automatically without any event integration. When the setting is turned on, a view metric is recorded for each product, assuming that all products returned in the ad response are shown on your site. When the setting is off, an event request should be sent for each display in accordance with the desired format.",
        link: "Impression Event",
        apiLink: "API Endpoints",
      },
      transactions: {
        subtext:
          "You can define balances for advertisers to use in their campaign spending.",
        warning: "You can add the invoice number.",
        balance_updated: "Balance Payment",
        gift_coupon: "Gift Coupon",
        welcome_coupon: "Welcome Coupon",
        refund: "Refund",
        spend: "Advertising Cost",
        ad_balance_loaded_desc: "Ad balance loaded.",
        spend_desc: "Advertising cost spent.",
        gift_coupon_desc: "Gift coupon earned.",
        welcome_coupon_desc: "Welcome coupon earned.",
        ad_balance_refunded_desc: "Ad balance refunded.",
        credit_card_desc: "Balance updated via credit card.",
        detailsSubtext: "You can view details about your ad spend.",
        spendTab: "Advertising Costs",
        budgetTab: "Budget Resources",
        advertiser_budget: "Balance",
        approve: "Would you like to continue to add balance?",
        advertiser: "Advertiser:",
        balance: "Balance to be loaded:",
        update: "You can add or edit the invoice number.",
        title: "Invoice Information",
        payment: "Payment",
        success: "Success",
        waiting: "Waiting",
        failure: "Failure",
        invoiceWarning: "You can enter the invoice number up to 30 characters.",
        note: "Balance Upload Note",
        noteHelp:
          "Include the reason for uploading this balance. Ex. August Balance",
        coupon_expired: "Coupon Expired",
        coupon_expired_desc: "Coupon has expired.",
        risk: "This action is high risk!",
        riskDesc:
          "Refunding the balance may impact the advertiser’s daily spending and could result in a negative balance. Before proceeding, make sure all campaigns of the advertiser have been paused.",
        refundTitle: "This action is irreversible!",
        refundDescription:
          "The specified amount will be immediately deducted from the advertiser’s balance. Refunding the balance without verifying the advertiser’s daily spend may result in a negative balance, leading to potential issues.",
        selectAdvertiserHelp:
          "Choose the advertiser who will receive the refund.",
        refundAmount: "Amount",
        verifyRefundAmount: "Verify the amount",
        refundAmountHelp:
          "Specify the exact amount to refund. Use caution as this action is irreversible.",
        verifyRefundAmountHelp: "Verify the amount to refund",
        refundNote: "Notes",
        refundNoteHelp:
          " This field allows you to add a note about why the refund is being issued. This could be useful for tracking purposes and clarifying the reason for the refund in your records.",
        amountToRefund: "Amount to be refunded:",
        check1:
          "I confirm that the advertiser has not spent any balance in the last 24 hours.",
        check2:
          "I have verified that all of the advertiser’s campaigns have been paused for at least 24 hours.",
        check3:
          "I understand that this refund will take immediate effect and cannot be reversed.",
        check4:
          "I acknowledge that if this refund results in a negative balance, I take full responsibility for the consequences.",
        check5:
          "I confirm that I have the necessary authorization to process this refund on behalf of the advertiser.",
        refundSuccessTitle: "Refund Processed Successfully!",
        refundSuccessText:
          "The balance has been successfully deducted from the advertiser’s account. If you notice any issues, please double-check the advertiser’s account activity or contact support.",
        refundErrorTitle: "Refund Failed",
        refundErrorText:
          "The refund could not be processed at this time. This may be due to an unexpected issue.",
        negativeRefundErrorTitle: "Refund Failed",
        negativeRefundErrorText:
          "The refund amount exceeds your available balance. Please enter an amount equal to or less than your current balance.",
      },
      users: {
        label: "E-Mail",
        subtext: "A confirmation mail will be sent for the invitation.",
        invite:
          "Enter the e-mail address to which the invitation will be sent.",
        warn: "You cannot stop your own account.",
        error: "You have added an existing email address.",
        search: "Search in Users",
        select: "Select the type of user you want to add.",
        advertiser: "Advertiser",
        advertiserText:
          "Only the advertiser account you choose can access, it is the person who will run the advertising activities.",
        marketplace: "Admin",
        marketplaceText:
          "It has all the competencies in the advertising platform, can grant or manage access to users.",
        limited: "Limited Access",
        full: "Full Access",
      },
      invited: {
        error:
          "An invite can be sent to the same email for the same account at most every 10 minutes.",
        success: "The user has been reinvited.",
        search: "Search in Invited Users",
        title: "Invitation Details",
        info: "General Information",
        mail: "Email:",
        advertiser: "Advertiser:",
        urlTitle: "Invitation Link",
        link: "How to add an advertiser user?",
        single: "Single User",
        multiple: "Bulk User",
        advertiserText: "Select advertiser.",
        draggerTitle:
          "Select a <strong>.CSV</strong> file to upload Bulk Email",
        draggerText: "or drag and drop here.",
        csvError: "An error occurred while uploading the file.",
        csvInfo:
          "You can bulk invite users to different advertiser accounts using a CSV file. You can access information about the format of the CSV file using the link below.",
        restriction: "Limited Access",
        restrictionText:
          "When you turn on this option, the user is given only limited access.",
        edit: "Edit",
        role: "Role:",
        MARKETPLACE: "Admin",
        MARKETPLACE_REPORTER: "Admin Reporter",
        ADVERTISER: "Advertiser",
        ADVERTISER_REPORTER: "Advertiser Reporter",
        archived: "Cannot send invitations to archived advertisers.",
      },
      sync: {
        file: "File",
        api: "API",
        feed: "Feed",
        products_created: "Products are created.",
        products_updated: "Products are updated.",
        products_deactivated: "Products are deactivated.",
        products_activated: "Products are activated.",
        missing_advertisers: "Advertisers are missing.",
        advertisers_created: "Advertisers are created.",
        advertisers_updated: "Advertisers are updated.",
        advertisers_deactivated: "Advertisers are deactivated.",
        advertisers_activated: "Advertisers are activated.",
        categories_created: "Categories are created.",
      },
      placement: {
        add: "Add New Ad Placement",
        display: "Display",
        product: "Sponsored Product",
        update: "Update Ad Placement",
        settings: "General Settings",
        settingsText: "General settings of your ad placement",
        name: "Ad Placement Name",
        nameText: "This name is visible only to you.",
        format: "Ad Format",
        formatText: "The format in which your ad placement will be displayed",
        sponsoredText: "Product display in related keywords and categories",
        displayText: "Visual ad display in related keywords and categories",
        suggestedTitle: "Recommended Sizes",
        suggestedText:
          "The actual size of your ad placement. This size will be displayed by advertisers.",
        suggestedError: "You can add up to 1 size.",
        acceptedTitle: "Accepted Sizes",
        acceptedText:
          "Other ad sizes that can appear within your ad placement. Dimensions will be displayed by advertisers.",
        acceptedError: "Please, select an ad size.",
        formatError: "Please, choose a format.",
        cross_sell: "Cross-Sell",
        crossText:
          "Displaying advertisements on the relevant product detail pages and displaying the listed products as advertisements are prevented.",
        maxAds: "Maximum Number of Ads",
        maxAdsText: "The maximum number of ads the placement can have.",
        crossTitle: "Ad Blocking Behavior",
        crossSub:
          "The actual size of your ad placement. This size will be displayed by advertisers.",
        crossPlaceholder: "Choose a type.",
        crossProduct: "Block Listed Product",
        crossBlock: "Don’t Block",
        crossError: "Choose a behavior.",
        adPlacementType: "Ad Placement Type",
        adPlacementTypeText:
          "Select the type of page or placement where your ad will appear. This selection will also be used in the ad placement targeting phase of created campaigns.",
        typeError: "Please select a page type.",
        adPlacementTypePlaceholder: "Select page type",
        search: "Search Results",
        category: "Category Pages",
        homepage: "Homepage",
        detail: "Product Detail Pages",
        collection: "Collection",
        video: "Sponsored Video",
        videoText:
          "Sponsored video display in relevant keywords and categories",
        minAds: "Minimum Number of Ads",
        minAdsText: "The minimum number of ads the placement can have.",
        minBid: "Minimum Bid",
        minBidText: "This is the minimum bid amount for this placement.",
        videoWarning:
          "The maximum number of ads for video ads is <strong>“1”</strong>.",
        promoteText: "You can only add 1 ad when product promotion is enabled.",
        displayWithProduct: "Sponsored Brand Display",
        videoWithProduct: "Sponsored Brand Video",
        display_with_product: "Sponsored Brand Display",
        video_with_product: "Sponsored Brand Video",
        archive_confirmation_text:
          "Archiving {{name}} will remove it from the active list. It will be excluded from ad serving.",
        archive_checkbox_text: "I understand and want to proceed.",
      },
      restrictions: {
        category: "Category",
        keyword: "Keyword",
        categoryTitle: "Category Based Bid & ACoS",
        categoryText:
          "You can enter a minimum bid and target ACoS that will be valid only in the categories you specify, and customize the category relevance. The settings you make in this field take priority over the general settings.",
        keywordTitle: "Keyword Blacklist",
        keywordText:
          "You can restrict the display of ads on the keywords you specify. In the search results restricted with the Exclusive type, only products containing this word are advertised. No ads are displayed in the search results restricted by the block type.",
        placeholder: "Search for keywords",
        add: "Add New Keyword",
        info: "General Information",
        label: "Keyword",
        help: "The keyword you want to restrict the search results for",
        type: "Restriction Type",
        typeText:
          "Type of restriction to be applied to search results with your keyword",
        exclusive: "Exclusive",
        exclusiveText:
          "In the search results, only the products with your keyword are advertised.",
        block: "Block",
        blockText: "All ads are blocked in search results.",
        all: "Show All",
        edited: "Show Edited Categories",
        expand: "Expand All",
        collapse: "Collapse All",
      },
      offsite: {
        connect: "Connect Meta Account",
        connectText:
          "Connect your Meta account to start creating and managing your off-site ads effortlessly.",
        loginWithFacebook: "Login with Facebook",
        success: "Successfully connected to Meta",
        reauthenticate: "Reauthenticate",
        revoke: "Revoke Access",
        adAccount: "Ad Account",
        adAccountText:
          "Facebook campaigns require an ad account. Objects like tracking pixels and custom audiences are tied to that account.  This is an account asset, so it will be part of account settings.",
        adAccountPlaceholder: "Select Ad Account",
        identity: "Identity",
        identityText:
          "Users must select a Facebook or Instagram account to represent their business in ads. This prevents identity conflicts and applies both at the account level.",
        facebookPlaceholder: "Select Facebook Page",
        instagramPlaceholder: "Select Instagram Account",
        pixel: "Meta Pixel",
        pixelText:
          "Before launching a sales campaign, integrating the Meta Pixel or SDK is essential. Ensure it tracks the right events and is set up with the correct conversion event. Always verify the client’s pixel setup, even if using Meta’s standard events.",
        pixelPlaceholder: "Select Pixel",
        pendingTitle: "Connecting Meta Account",
        pendingText:
          "Your Meta account is currently being connected. This process may take some time depending on the account settings and connection steps.",
        attribution: "Attribution Settings",
        attributionText:
          "An attribution window is the number of days between when someone views or clicks an ad and takes an action. Since a single setting is typically used across campaigns, it makes sense to have this at the account level.",
        none: "None",
        oneDay: "1 Day",
        sevenDays: "7 Days",
        clickThrough: "Click-Through",
        viewThrough: "View-Through",
        engagedView: "Engaged View",
        clickThroughPlaceholder: "Select Click-Through",
        viewThroughPlaceholder: "Select View-Through",
        engagedViewPlaceholder: "Select Engaged View",
        revokeSuccess: "Successfully revoked access to Meta",
        location: "Marketplace Location",
        locationText: "Select business location",
        locationPlaceholder: "Select Location",
        multipleAccounts:
          "Multiple business accounts found. Please select only one business account.",
      },
    },
    auth: {
      title: "{{value}} Ad Platform",
      accounts: {
        title: "Select Account",
        text: "Select the account you want to log into.",
        logout: "Log Out",
        noAccount: "Account  Not  Active!",
        noAccountText:
          "It looks like you're not connected to any active accounts. For assistance, please contact your account manager via <strong>{{value}}</strong>",
        noAccountTextNoEmail:
          "It looks like you're not connected to any active accounts. For assistance, please contact your account manager.",
        contact: "Contact",
        login: "Return to Home Page",
      },
      login: {
        title: "Login",
        text: "Welcome again! Please enter your information.",
        remember: "Remember Me",
        forget: "Forgot My Password?",
        button: "Log In",
        mail: "Email",
        password: "Password",
        mailErr: "Please enter your email.",
        passwordErr: "Please enter your password.",
        incorrectPassword: "Your password is incorrect!",
        incorrectPasswordText: "Please try again.",
        recaptcha: "reCAPTCHA validation error.",
        recaptchaText: "Please try again.",
        notActive: "Your account has been suspended.",
        notActiveText: "Please contact the support team.",
        multipleAccountErr: "An error occurred while creating a new account.",
        notHaveAnAccount: "Don't have an existing account?",
        signup: "Sign Up",
        linkButton: "Send Login Link",
        success: "A login link has been sent to your email.",
        successText:
          "Click the link in the email you received to log in to your account.",
        error: "An error occurred while logging in.",
        errorText: "Please try again.",
        invalidEmail: "Invalid email address.",
        invalidEmailText: "Please enter a valid email address.",
        userNotFound: "A user with this email not found.",
        userNotFoundText: "Please check your email address and try again.",
        verificationError: "We couldn’t verify your request. Please try again.",
      },
      onboarding: {
        help: "Book a Demo",
        login: "Log in",
        to: "to",
        retail: "get a retail media",
        infrastructure:
          "infrastructure set up in <strong>just a few minutes.</strong>",
        linkedInButton: "Sign up with Linkedin",
        linkedInTextAccept:
          "By signing up, you agree to our <1>Terms and Conditions</1>",
        firstStepText:
          "You can add retail media capability to your platform within minutes, without the need for technical development or months-long integrations. In just a few minutes, your vendors will be able to advertise on your website.",
        changeAccount: "Change Account",
        createTitle: "Create Your Dashboard",
        createSubtitle: "Enter platform information and complete the setup!",
        websiteLabel: "Company Website URL",
        websiteHelp:
          "Enter a valid website URL without paths or parameters. Example: www.gowit.com",
        passwordLabel: "Password",
        passwordHelp: "Password needs to be at least 8 characters.",
        typeLabel: "Sample Data Type",
        typeHelp: "Select the type of sample data to be added to the platform.",
        clothing: "Clothing",
        tech: "Tech",
        grocery: "Grocery",
        continue: "Continue",
        thirdStepText:
          "Set up your campaign-specific targeting, bidding, and budget settings with an easy setup and launch your campaign!",
        campaignTitle: "Create Your First Campaign",
        campaignText:
          "Create your first retail media campaign by uploading an image. If you don’t have one, you can use a sample image.",
        autoTitle: "Auto Placement",
        autoText:
          "Ads opens automatically at the top on your website, no coding required.",
        autoTag: "Suitable for non-technical users.",
        customTitle: "Custom Placement",
        customText: "Choose ad placement and size. Requires basic coding.",
        customTag: "Suitable for technical users.",
        sampleBtn: "Use Sample Image",
        uploadBtn: "Use Custom Image",
        sampleText:
          "The following image will be used as an example in your ad preference.",
        uploadText: "This image only size for 970x50px is allowed!",
        uploadInnerTitle: "Upload a <strong>.JPG, .PNG</strong> file!",
        uploadInnerText: "or drop a file here.",
        errorTitle: "There's an error in the image!",
        errorText: "Please upload an image that fits the specified dimensions!",
        errorBtn: "Upload a New Image",
        errorWarning: "The image you uploaded must be size 970x50px!",
        create: "Create Campaign",
        fourthStepText:
          "Effortlessly display ads on your website by embedding the generated script into your site's code!",
        embedCodeTitle: "Embed code into your website",
        embedCodeText:
          "Integrate the code below into your website’s HTML or through a tag manager to run your ad.",
        pending: "Waiting for Traffic",
        live: "Live",
        activityTitle: "Script Activity:",
        connectionText:
          "If the connection is not established automatically, click the button!",
        checkConnection: "Check Connection",
        finishOnboarding: "Go to Dashboard",
        redirectURL: "Your Ad’s Redirect URL",
        redirectHelp:
          "Please provide the URL where users will be redirected when they click on your ad.",
        sampleBanner: "Use Sample Image",
        sampleBannerText: "Your ad will appear on your website like this.",
        account: "Already have an account?",
        link: "Login",
        uploading: "Uploading...",
        custom: "My Own Provider",
        gtm: "Google Tag Manager",
        mobile: {
          title: "Use on Desktop <br>for the best experience!",
          text: "You'll be directed to the GoWit website in <strong>15 seconds...</strong>",
          link: "Go to Website",
        },
      },
      signUp: {
        title: "Sign Up",
        text: "Welcome, {{value}}",
        password: "Password",
        passwordErr: "Please enter your password.",
        warning:
          "Enter a password of at least 8 characters including numbers, punctuation marks, uppercase and lowercase letters.",
        already: "Do you have an existing account?",
        successBtn: "Log In",
        success: "Registration Successful!",
        successText:
          "Your registration with {{value}} mail has been successfully completed. Click the login button to continue to the panel.",
        help: "How Can We Help?",
        err: "Please enter a valid password.",
        userExistErr:
          "This user already exists. Please log in to see the accounts you can access.",
        privacyErr:
          "To complete your signup, please accept all required agreements.",
        error: "Invitation not found.",
        errorText:
          "You need an invitation to register. Please contact your account manager via {{value}}.",
        errorTextNoEmail:
          "You need an invitation to register. Please contact your account manager.",
        errorBtn: "Return to Home Page",
      },
      password: {
        title: "Did you forget your password?",
        text: "We'll send you a link so you can reset your password. Please enter the email address you use on the platform.",
        mail: "E-mail",
        mailInputErr: "Please enter your e-mail address.",
        reset: "Reset Password",
        return: "Go to Login Page",
        error:
          "Please enter the e-mail address whose password you want to reset.",
        recaptchaError:
          "Forgot password attempt unsuccessful for security reasons, please refresh the page and try again.",
        check: "Check Your Email",
        message: "We sent a password reset link to {{value}}",
      },
      newPassword: {
        title: "Set New Password",
        text: "Your new password must be different from the old passwords you used.",
        help: "Enter a password of at least 8 characters including numbers, punctuation marks, uppercase and lowercase letters.",
        password: "Password",
        passwordInputErr: "Please enter your password.",
        again: "Repeat Password",
        check: "Password Reset Successful",
        message: "You can log in with the new password you have set.",
        errTitle: "Please enter a valid password.",
      },
      welcome: {
        title: "Welcome!",
        loginText:
          "If you have an account, you can enter your information by clicking the login button.",
        loginBtn: "Login",
        signupText:
          "If you do not have an account yet, you can click the button below to register, set your password and then log in to your account.",
        signupBtn: "Sign Up",
      },
    },
  },
  common: {
    save: "Save",
    selectAll: "Select All",
    search: "Search",
    edit: "Edit",
    export: "Export",
    remove: "Remove",
    add: "Add",
    column: "Column",
    archive: "Archive",
    removeArchive: "Unarchive",
    delete: "Delete",
    filter: "Filter",
    continue: "Continue",
    cancel: "Cancel",
    cancellation: "Cancel",
    okay: "OK",
    create: "Create",
    goBack: "Go Back",
    listed: "Listed",
    archived: "Archived",
    invited: "Invitees",
    exact: "Exact Match",
    phrase: "Phrase Match",
    broad: "Broad Match",
    turkey: "Turkey",
    abd: "USA",
    try: "Turkish Liras",
    usd: "US Dollars",
    iqd: "The Iraqi Dinar",
    twd: "New Taiwan Dollar",
    hkd: "Hong Kong Dollar",
    krw: "South Korean Won",
    jpy: "Japanese Yen",
    zone: "Europe/İstanbul",
    clear: "Clear",
    clearAll: "Clear All",
    completed: "Completed",
    notCompleted: "Not Completed",
    now: "Now",
    checkAll: "Select All",
    keyword: "Keyword",
    negative: "Negative Keyword",
    tr: "Turkish",
    en: "English",
    day: "Day",
    invite: "Invite",
    accept: "Accept",
    reject: "Reject",
    pageTotal: "Number of rows to show:",
    addUser: "Add User",
    reinvite: "Invite Again",
    advertiser: "Advertiser",
    admin: "Admin",
    new_customer: "New Customers",
    existing_customer: "Existing Customers",
    category_buyer: "Category Buyers",
    invite_url: "See Invite Link",
    copy: "Create a Copy",
    vastTag: "Get VAST Tag",
    next: "Next",
    watch: "Watch",
    or: "or",
    exit: "Exit",
    docs: "Check out our API documentation for information about integration and ad serving!",
    apply: "Apply",
    reset: "Reset",
    read: "Mark as Read",
    unread: "Mark as Unread",
    view: "View",
    onAndOff: "On/Off",
    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
    },
    languages: {
      arabic: "Arabic",
      english: "English",
    },
    table: {
      name: "Name",
      status: "Active",
      state: "Status",
      agency_name: "Agency",
      type: "Type",
      resource_id: "Seller ID",
      last_login: "Last Login Date",
      balance: "Balance",
      sum_coupons_amount: "Coupon",
      total_balance: "Total Balance",
      impressions: "Impression",
      viewable_impressions: "Viewable Impression",
      clicks: "Click",
      CTR: "CTR",
      spend: "Ad Spend",
      sale_amount: "Sale Amount",
      direct_sale_amount: "Direct Sale Amount",
      indirect_sale_amount: "Indirect Sale Amount",
      sale: "Sale",
      direct_sale: "Direct Sale",
      indirect_sale: "Indirect Sale",
      ACoS: "ACoS",
      CVR: "CVR",
      campaign_name: "Name",
      advertiser_name: "Advertiser",
      ad_type: "Ad Type",
      start_date: "Start Date",
      end_date: "End Date",
      budget_type: "Budget",
      bid_type: "Bid Type",
      fixed_bid: "Bid",
      custom_key: "ID",
      ad_format: "Ad Format",
      max_ads: "Max. Number of Ads",
      recommended_size: "Recommended Size",
      sizes: "Sizes",
      image_url: "Creative",
      policy_status: "Status",
      transaction_date: "Date",
      invoice_number: "Invoice No",
      transaction_type: "Transaction Type",
      description: "Description",
      created_by: "Creator",
      amount: "Amount",
      spend_details: "Detail",
      note: "Note",
      expire_date: "Expiration Date",
      account_user_status: "Active",
      user_email: "E-Mail",
      account_user_role: "Auth",
      user_last_login: "Last Login Date",
      account_user_created_at: "Creation Date",
      email: "E-Mail",
      account_role: "Auth",
      created_at: "Invitation Date",
      report_name: "Name",
      report_start_date: "Start Date",
      report_end_date: "End Date",
      report_type: "Report Type",
      report_delivery_frequency: "Frequency",
      report_created_at: "Creation Date",
      text: "Name",
      minimum_bid: "Min. CPC",
      minimum_cpm: "Min. CPM",
      relevance: "Relevance",
      restriction_type: "Restriction Type",
      row_count: "Row Count",
      message: "Operation Message",
      sync_type: "Sync. Type",
      file_name: "File Name",
      product: "Product",
      sku: "SKU",
      buybox: "Buybox",
      city: "City",
      age_range: "Age",
      gender: "Gender",
      platform: "Platform",
      customer: "Customers",
      category: "Category",
      keyword: "Keyword",
      match_type: "Match Type",
      bid: "Bid",
      category_volume: "Impression Volume",
      active: "Active",
      paused: "Paused",
      invited: "Invited",
      uninvited: "Uninvited",
      accepted: "Accepted",
      archived: "Archived",
      on: "Active",
      stopped: "Paused",
      open: "Open",
      closed: "Closed",
      coupon: "Coupon",
      sync_log_created_at: "Date",
      category_text: "Category",
      keyword_text: "Keyword",
      creative_name: "Name",
      invoice: "Invoice No",
      acos: "ACoS",
      sum_click: "Clicks",
      sum_spend: "Spend",
      sum_impression: "Impressions",
      sum_viewable_impression: "Viewable Impression",
      sum_sale: "Sale",
      sum_direct_sale: "Direct Sale",
      sum_indirect_sale: "Indirect Sale",
      sum_direct_sale_amount: "Direct Sale Amount",
      sum_indirect_sale_amount: "Indirect Sale Amount",
      sum_sale_amount: "Sale Amount",
      invite_status: "Invite Status",
      spending_type: "Spending Distribution",
      min_ads: "Min. Number of Ads",
      min_bid: "Min. Bid",
      languages: "Language",
      video_start: "Video Starts",
      video_complete: "Video Completes",
      VCR: "VCR",
      entity: "Entity",
      import_method: "Import Method",
      schedule_interval: "Cycle",
      finish_date: "Finish Date",
      id: "ID",
      uuid: "UUID",
      updated_by: "Updated By",
      objective: "Objective",
      daily_budget: "Daily Budget",
      reach: "Reach",
      frequency: "Frequency",
      link_click: "Link Click",
      meta_created_at: "Creation Date",
      ACTIVE: "Active",
      PAUSED: "Paused",
      DELETED: "Deleted",
      ARCHIVED: "Archived",
      FAILED: "Failed",
      PENDING: "Pending",
    },
    equality: {
      contains: "Contains",
      not_contains: "Does Not Contains",
      equal: "Equal",
      not_equal: "Not Equal",
      less_than: "Less Than",
      grater_than: "Greater Than",
      greater_or: "Greater Or",
      less_or: "Less Or",
      any_of: "Any Of It",
      none_of: "None Of It",
    },
    filters: {
      campaignName: "Name",
      campaignStartDate: "Start Date",
      campaignEndDate: "End Date",
      campaignStatus: "Active",
      campaignState: "Status",
      campaignBudget: "Budget",
      campaignBudgetType: "Budget Type",
      campaignBidType: "Bid Type",
      campaignMaxAcos: "Maximum ACoS",
      campaignFixedBid: "Fixed Bid",
      campaignAdType: "Ad Type",
      sumSale: "Sale",
      sumSpend: "Spend",
      ACoS: "ACoS",
      productName: "Product",
      productCampaignStatus: "Status",
      sumClick: "Click",
      CVR: "CVR",
      keywordCampaignStatus: "Status",
      keywordText: "Keyword",
      keywordMatchType: "Match Type",
      negativeKeywordCampaignStatus: "Status",
      negativeKeywordText: "Negative Keyword",
      negativeKeywordMatchType: "Match Type",
      cityName: "City",
      cityCampaignStatus: "Status",
      ageRangeText: "Age",
      ageRangeCampaignStatus: "Status",
      genderText: "Gender",
      genderCampaignStatus: "Status",
      platformText: "Platform",
      platformCampaignStatus: "Status",
      customerTargetingText: "Customer",
      customerTargetingCampaignStatus: "Status",
      placementText: "Ad Placement",
      placementCampaignStatus: "Status",
      reportName: "Report Name",
      reportStartDate: "Start Date",
      reportEndDate: "End Date",
      reportType: "Report Type",
      reportDeliveryFrequency: "Frequency",
      reportCreatedAt: "Creation Date",
      agencyName: "Agency Name",
      advertiserName: "Advertiser",
      advertiserStatus: "Advertiser Status",
      sumSaleAmount: "Sale Amount",
      sumDirectSale: "Direct Sale",
      sumIndirectSale: "Indirect Sale",
      sumDirectSaleAmount: "Direct Sale Amount",
      sumIndirectSaleAmount: "Indirect Sale Amount",
      sumImpression: "Impressions",
      productBrand: "Product Brand",
      CTR: "CTR",
      categoryName: "Category",
      advertiserId: "Advertiser ID",
      agencyId: "Agency ID",
      name: "Product Name",
      brand: "Brand",
      retailer_taxonomy: "Product Category",
      advertiser_id: "Advertiser ID",
      transaction_type: "Transaction Type",
      advertiser_name: "Advertiser",
      created_by: "Creator",
      campaignDailyBudget: "Daily Budget",
      campaignTotalBudget: "Total Budget",
      campaignId: "Campaign ID",
    },
    equalityValues: {
      ct: "Contains",
      nc: "Does Not Contain",
      eq: "Equal",
      ne: "Not Equal",
      in: "Any Of It",
      ni: "None Of It",
      notin: "None Of It",
      lt: "Less Than",
      gt: "Greater Than",
      ge: "Greater Or",
      le: "Less Or",
    },
    values: {
      ACTIVE: "Active",
      PAUSED: "Paused",
      DAILY: "Daily",
      TOTAL: "Total",
      STANDART: "Auto",
      FIXED: "Fixed",
      MAX_ACOS: "Max ACoS",
      WEEKLY: "Weekly",
      MONTHLY: "Monthly",
      EXACT: "Exact",
      PHRASE: "Phrase",
      BROAD: "Broad",
      BALANCE_UPDATED: "Balance Payment",
      GIFT_COUPON: "Gift Coupon",
      WELCOME_COUPON: "Welcome Coupon",
      SPEND: "Advertising Cost",
      NOT_STARTED: "Campaign hasn't started yet.",
      DATE_PASSED: "Campaign past its scheduled end date.",
      STATUS_PAUSED: "You paused the campaign.",
      OUT_OF_DAILY_BUDGET: "Your campaign’s daily budget has been exhausted.",
      OUT_OF_TOTAL_BUDGET: "Your campaign has reached the total budget limit.",
      PRODUCTS_STOPPED: "Products are not active.",
      KEYWORDS_STOPPED: "Keywords are not active.",
      CATEGORIES_STOPPED: "Categories are not active.",
      STATUS_ARCHIVED: "You archived the campaign.",
      CREATIVES_STOPPED: "There is no active creatives.",
      INSUFFICIENT_BALANCE: "Your balance is insufficient.",
      LOW_BID: "Bid is below the minimum bid.",
      ADVERTISER_PAUSED: "Advertiser is not active.",
      REGIONS_STOPPED: "Regions are not active.",
      PRODUCT: "Sponsored Products",
      BRAND: "Sponsored Brands",
      DISPLAY: "Sponsored Display",
      STORE: "Sponsored Store",
      VIDEO: "Sponsored Video",
      DISPLAY_WITH_PRODUCT: "Sponsored Brand Display",
      VIDEO_WITH_PRODUCT: "Sponsored Brand Video",
    },
    month: {
      may: "May",
      june: "Jun",
      september: "September",
      october: "October",
      december: "December",
    },
  },
};

export default en;
