import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Popover from "antd/lib/popover";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";
import cc from "classcat";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Checkbox } from "antd";
import { TrackJS } from "trackjs";
import { MoreOutlined, InfoCircleFilled } from "@ant-design/icons/lib/icons";
import Tooltip from "antd/lib/tooltip";
import useApi from "api";
import { ReactComponent as AdvertiserOutlined } from "assets/icons/menu/advertisersOutlined.svg";
import { ReactComponent as FinancialOutlined } from "assets/icons/financialOutlined.svg";
import { ReactComponent as PencilOutlined } from "assets/icons/pencilOutlined.svg";
import { ReactComponent as SearchOutlined } from "assets/icons/searchOutlined.svg";
import { ReactComponent as BlockFilled } from "assets/icons/blockFilled.svg";
import RetailDateColumn from "components/Column/RetailDateColumn";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNameColumn from "components/Column/RetailNameColumn";
import RetailFormInput from "components/Form/RetailFormInput";
import RetailPageContainer from "components/Layout/RetailPageContainer";
import CampaignDetailsModal from "components/Modal/CampaignDetailsModal";
import RetailInvoiceModal from "components/Modal/RetailInvoiceModal";
import RetailTable from "components/Table/RetailTable";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { transactionFilters } from "utils/filters";
import { validateNumber } from "utils/helpers";
import cm from "./style.module.scss";
import RetailMainButton from "components/Button/RetailMainButton";
import AdvertiserSelect from "components/Select/AdvertiserSelect";
import { useCurrency } from "context/CurrencyProvider";
import axios from "axios";
import { Auth, AuthContext } from "context/AuthProvider";
import RetailNotification from "components/Notification";

const TransactionsPage = () => {
  const { t } = useTranslation();

  const { api, baseURL, isLimitedUser } = useApi();

  const { currencySymbol } = useCurrency();

  const { authToken } = useContext(AuthContext) as Auth;

  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const [detailsVisible, setDetailsVisible] = useState(false);

  const [updateVisible, setUpdateVisible] = useState(false);

  const [refundVisible, setRefundVisible] = useState(false);

  const [confirmRefundVisible, setConfirmRefundVisible] = useState(false);

  const [details, setDetails] = useState<any>({ spend: null, budget: null });

  const [activeKey, setActiveKey] = useState("SPENDING");

  const [invoiceNo, setInvoiceNo] = useState<string | undefined>();

  const [id, setID] = useState<string | undefined>();

  const [selectedAdvertiser, setSelectedAdvertiser] = useState<any>(null);

  const [refundAmount, setRefundAmount] = useState<string>("");

  const [verifyAmount, setVerifyAmount] = useState<string>("");

  const [refundNote, setRefundNote] = useState<string>("");

  const [checkboxes, setCheckboxes] = useState({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
  });

  const areAllChecked = () => {
    return Object.values(checkboxes).every((value) => value === true);
  };

  useEffect(() => {
    if (verifyAmount && refundAmount) {
      form.validateFields(["verify_refund_amount"]);
    }
  }, [refundAmount, verifyAmount, form]);

  const openDetails = (records: any) => {
    setDetailsVisible(true);
    setDetails({
      spend: records.spend_details,
      budget: records.budget_details,
    });
  };

  const openUpdate = (records: any) => {
    setUpdateVisible(true);
    setInvoiceNo(records.invoice_number);
    setID(records.id);
  };

  const invoiceNumberCharacterLimit = 30;

  const amountColInner = (value: number, transaction_type: string) => {
    switch (transaction_type) {
      case "SPEND":
      case "REFUND":
      case "COUPON_EXPIRED":
        return (
          <span className={`${cm.amount} ${cm.red}`}>
            - <RetailMoneyColumn value={value} />
          </span>
        );
      default:
        return (
          <span className={`${cm.amount} ${cm.green}`}>
            + <RetailMoneyColumn value={value} />
          </span>
        );
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "transaction_date":
        return <RetailDateColumn value={value} />;
      case "advertiser_name":
        return (
          <RetailNameColumn
            to={`/campaigns?adv=${records.advertiser_id}`}
            value={`${value} (${records.advertiser_id})`}
          />
        );
      case "transaction_type":
        return t(`pages.admin.transactions.${value?.toLowerCase()}`);
      case "description":
        return t(`pages.admin.transactions.${`${value?.toLowerCase()}_desc`}`);
      case "amount":
        return value ? amountColInner(value, records.transaction_type) : "-";
      case "spend_details":
        return (
          value && (
            <SearchOutlined
              className={cm.icon}
              onClick={() => openDetails(records)}
            />
          )
        );
      default:
        return value ? value : "-";
    }
  };

  const columns = (t: any) => [
    {
      title: "",
      dataIndex: "transaction_type",
      unsortable: true,
      render: (value: any, records: any) =>
        value === "BALANCE_UPDATED" && (
          <Popover
            content={
              <span
                className={cc(["flex", cm.text, cm.link])}
                onClick={() => openUpdate(records)}
              >
                <PencilOutlined /> {t("common.edit")}
              </span>
            }
            placement="leftTop"
            trigger="hover"
            arrowPointAtCenter
            overlayClassName={cm.popover}
          >
            <MoreOutlined style={{ cursor: "pointer", height: "1px" }} />
          </Popover>
        ),
    },
  ];

  const open = () => setVisible(true);

  const close = () => setVisible(false);

  const closeDetails = () => setDetailsVisible(false);

  const closeUpdate = () => {
    setUpdateVisible(false);
    setInvoiceNo("");
  };

  const openRefund = () => setRefundVisible(true);

  const resetRefundForm = () => {
    setSelectedAdvertiser(null);
    setRefundAmount("");
    setVerifyAmount("");
    setRefundNote("");
    form.resetFields();
  };

  const closeRefund = () => {
    setRefundVisible(false);
    resetRefundForm();
  };

  const openConfirmRefund = () => {
    form.submit();
    setRefundVisible(false);
    setConfirmRefundVisible(true);
  };

  const closeConfirmRefund = () => {
    setConfirmRefundVisible(false);
    resetRefundForm();
    setCheckboxes({
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
    });
  };

  const onConfirmRefund = async () => {
    try {
      await axios.post(
        `/api/advertisers/${selectedAdvertiser?.id}/payments/refund`,
        {
          refund_amount: parseFloat(refundAmount),
          explanation: refundNote,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      queryClient.refetchQueries("table");
      RetailNotification.showNotification(
        "success",
        t("pages.admin.transactions.refundSuccessTitle"),
        t("pages.admin.transactions.refundSuccessText")
      );
    } catch (error) {
      console.error("Error processing refund:", error);
      if (error?.request?.responseText?.includes("negative is not allowed")) {
        RetailNotification.showNotification(
          "error",
          t("pages.admin.transactions.negativeRefundErrorTitle"),
          t("pages.admin.transactions.negativeRefundErrorText")
        );
      } else {
        RetailNotification.showNotification(
          "error",
          t("pages.admin.transactions.refundErrorTitle"),
          t("pages.admin.transactions.refundErrorText")
        );
      }
      TrackJS.track({
        message: "Refund Error",
        metadata: {
          endpoint: `/api/advertisers/${selectedAdvertiser?.id}/payments/refund`,
          requestBody: {
            refund_amount: parseFloat(refundAmount),
            explanation: refundNote,
          },
          role: "MARKETPLACE",
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error?.response?.data,
          statusCode: error?.response?.status,
          errorMessage: error?.message,
          severity: "Medium",
        },
      });
    } finally {
      closeConfirmRefund();
    }
  };

  const onOk = async () => {
    try {
      await api.patch(`transactions/${id}`, {
        invoice_number: invoiceNo,
      });
      closeUpdate();
      queryClient.refetchQueries("table");
    } catch (e) {
      console.log(e);
    }
  };

  const isRefundFormValid = () => {
    return selectedAdvertiser && refundAmount && refundAmount === verifyAmount;
  };

  const tableConfig = {
    url: "transactions",
    isRelation: false,
    /* to: openUpdate, */
    filters: transactionFilters(t),
    renderColumns,
  };

  return (
    <RetailPageContainer>
      <Row className="bordered-container no-margin">
        <RetailTable
          placeholder={t("common.search")}
          columnsForAdditionalRendering={() => columns(t)}
          tableConfig={tableConfig}
          rowSelection={undefined}
          button={{
            title: t("components.modal.campaignDetails.transactions"),
            onClick: open,
          }}
          secondaryButton={
            <RetailMainButton onClick={openRefund} className={cm.refundButton}>
              {t("pages.admin.transactions.refund")}
              <Tooltip
                title={
                  <div className={cm.tooltipTextContainer}>
                    <RetailText
                      size="xs"
                      weight="bold"
                      className={cm.tooltipTitle}
                    >
                      <BlockFilled />
                      {t("pages.admin.transactions.risk")}
                    </RetailText>
                    <RetailText
                      size="xxxs"
                      family="poppins"
                      className={cm.tooltipDesc}
                    >
                      {t("pages.admin.transactions.riskDesc")}
                    </RetailText>
                  </div>
                }
                overlayClassName={cm.tooltip}
                overlayInnerStyle={{
                  minWidth: "336px",
                  width: "fit-content",
                  padding: "16px",
                  textAlign: "center",
                  borderRadius: "6px",
                  boxShadow:
                    "0px 0px 2px 0px rgba(223, 60, 109, 0.20) inset, 0px 38px 11px 0px rgba(0, 0, 0, 0.00), 0px 24px 10px 0px rgba(0, 0, 0, 0.01), 0px 14px 8px 0px rgba(0, 0, 0, 0.02), 0px 6px 6px 0px rgba(0, 0, 0, 0.03), 0px 2px 3px 0px rgba(0, 0, 0, 0.04)",
                }}
                color="var(--red-1)"
              >
                <InfoCircleFilled />
              </Tooltip>
            </RetailMainButton>
          }
        />
        <RetailInvoiceModal
          type="TRANSACTIONS"
          subtitle={t("pages.admin.transactions.subtext")}
          visible={visible}
          setVisible={setVisible}
          onCancel={close}
        />
        <CampaignDetailsModal
          type="TRANSACTION_DETAILS"
          subtitle={t("pages.admin.transactions.detailsSubtext")}
          visible={detailsVisible}
          onCancel={closeDetails}
        >
          <Tabs activeKey={activeKey} onChange={(value) => setActiveKey(value)}>
            <Tabs.TabPane
              key="SPENDING"
              tab={t("pages.admin.transactions.spendTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.spend.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {item.campaign}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane
              key="BUDGET"
              tab={t("pages.admin.transactions.budgetTab")}
              className={cm.tab}
            >
              {details.spend &&
                details.budget.map((item: any, index: number) => (
                  <article
                    key={index}
                    className={cc(["flex", cm.spaceBetween, cm.line])}
                  >
                    <RetailText weight="bold" size="xs" className={cm.item}>
                      {t(
                        `pages.admin.transactions.${item.resource?.toLowerCase()}`
                      )}
                    </RetailText>
                    <RetailText
                      weight="medium"
                      size="xs"
                      family="poppins"
                      className={cm.item}
                    >
                      - <RetailMoneyColumn value={item?.amount} />
                    </RetailText>
                  </article>
                ))}
            </Tabs.TabPane>
          </Tabs>
        </CampaignDetailsModal>
      </Row>
      <CampaignDetailsModal
        type="UPDATE_TRANSACTION"
        subtitle={t("pages.admin.transactions.update")}
        visible={updateVisible}
        disabled={
          invoiceNo === undefined ||
          invoiceNo.length > invoiceNumberCharacterLimit
        }
        onCancel={closeUpdate}
        onOk={onOk}
      >
        <Form
          initialValues={{
            invoice_no: invoiceNo,
          }}
          autoComplete="off"
        >
          <RetailTitle level={5} className={cm.title}>
            {t("pages.admin.transactions.title")}
          </RetailTitle>
          <RetailFormInput
            isFocused={invoiceNo !== undefined}
            label={t("pages.admin.transactions.title")}
            name="invoice_no"
            className="floating"
            rules={[
              {
                max: invoiceNumberCharacterLimit,
                message: t("pages.admin.transactions.invoiceWarning"),
              },
            ]}
          >
            <Input
              onChange={({ target }) => setInvoiceNo(target.value)}
              className={cc(["floating", cm.input])}
              value={invoiceNo}
              onKeyDownCapture={(e) => validateNumber(e)}
            />
          </RetailFormInput>
        </Form>
      </CampaignDetailsModal>
      <CampaignDetailsModal
        type="REFUND"
        visible={refundVisible}
        onCancel={closeRefund}
        onOk={openConfirmRefund}
        disabled={!isRefundFormValid()}
      >
        <div className={cm.modalInnerWarning}>
          <BlockFilled />
          <article>
            <RetailTitle level={5} noMargin className={cm.modalInnerTitle}>
              {t("pages.admin.transactions.refundTitle")}
            </RetailTitle>
            <RetailText
              size="xxxs"
              weight="medium"
              className={cm.modalInnerDescription}
            >
              {t("pages.admin.transactions.refundDescription")}
            </RetailText>
          </article>
        </div>

        <Form autoComplete="off" layout="vertical" form={form}>
          <Form.Item
            className={cm.formItem}
            name="advertiser"
            help={t("pages.admin.transactions.selectAdvertiserHelp")}
            rules={[{ required: true }]}
          >
            <AdvertiserSelect
              type="BALANCE"
              onClick={(advertiser: any) => setSelectedAdvertiser(advertiser)}
            />
          </Form.Item>

          <div className={`${cm.formRow} ${cm.flex}`}>
            <RetailFormInput
              name="refund_amount"
              className={`${cm.formItem} floating ${
                +refundAmount > 0 ? cm.showPrefix : cm.hidePrefix
              }`}
              label={t("pages.admin.transactions.refundAmount")}
              isFocused={refundAmount !== ""}
              help={t("pages.admin.transactions.refundAmountHelp")}
            >
              <Input
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                onKeyDownCapture={(e) => validateNumber(e)}
                className="number-input floating"
                prefix={currencySymbol}
              />
            </RetailFormInput>

            <RetailFormInput
              name="verify_refund_amount"
              className={`${cm.formItem} floating ${
                +verifyAmount > 0 ? cm.showPrefix : cm.hidePrefix
              }`}
              label={t("pages.admin.transactions.verifyRefundAmount")}
              isFocused={verifyAmount !== ""}
              help={t("pages.admin.transactions.verifyRefundAmountHelp")}
              rules={[
                {
                  validateTrigger: ["onBlur", "onChange"],
                  validator(_, value) {
                    if (value === refundAmount) return Promise.resolve();
                    else
                      return Promise.reject(
                        t("pages.admin.transactions.verifyRefundAmountHelp")
                      );
                  },
                },
              ]}
            >
              <Input
                value={verifyAmount}
                onChange={(e) => setVerifyAmount(e.target.value)}
                onKeyDownCapture={(e) => validateNumber(e)}
                className="number-input floating"
                prefix={currencySymbol}
              />
            </RetailFormInput>
          </div>

          <RetailFormInput
            className={`${cm.formItem} floating`}
            label={t("pages.admin.transactions.refundNote")}
            isFocused={refundNote !== ""}
            help={t("pages.admin.transactions.refundNoteHelp")}
          >
            <Input.TextArea
              value={refundNote}
              onChange={(e) => setRefundNote(e.target.value)}
              className={`${cm.textarea} floating`}
            />
          </RetailFormInput>
        </Form>
      </CampaignDetailsModal>

      <CampaignDetailsModal
        type="CONFIRM_REFUND"
        visible={confirmRefundVisible}
        onCancel={closeConfirmRefund}
        onOk={onConfirmRefund}
        disabled={!areAllChecked()}
      >
        <section className={cm.summaryContainer}>
          <div className={cm.summaryItem}>
            <AdvertiserOutlined />
            <div>
              <RetailText
                weight="medium"
                size="xs"
                className={cm.summaryItemTitle}
              >
                {t("pages.admin.transactions.advertiser")}
              </RetailText>
              <RetailText weight="bold" className={cm.summaryItemText}>
                {selectedAdvertiser?.name}
              </RetailText>
            </div>
          </div>
          <div className={cm.summaryItem}>
            <FinancialOutlined />
            <div>
              <RetailText
                weight="medium"
                size="xs"
                className={cm.summaryItemTitle}
              >
                {t("pages.admin.transactions.amountToRefund")}
              </RetailText>
              <RetailText weight="bold" className={cm.summaryItemText}>
                {currencySymbol}
                {refundAmount}
              </RetailText>
            </div>
          </div>
        </section>

        <section className={cm.checklistContainer}>
          {[
            "pages.admin.transactions.check1",
            "pages.admin.transactions.check2",
            "pages.admin.transactions.check3",
            "pages.admin.transactions.check4",
            "pages.admin.transactions.check5",
          ].map((key, index) => (
            <Checkbox
              checked={
                checkboxes[`check${index + 1}` as keyof typeof checkboxes]
              }
              onChange={(e) => {
                setCheckboxes({
                  ...checkboxes,
                  [`check${index + 1}`]: e.target.checked,
                });
              }}
              key={index}
              className={cm.checkboxItem}
            >
              {t(key)}
            </Checkbox>
          ))}
        </section>
      </CampaignDetailsModal>
    </RetailPageContainer>
  );
};

export default TransactionsPage;
