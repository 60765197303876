import RetailPageContainer from "components/Layout/RetailPageContainer";
import useConfigSchema from "hooks/useConfigSchema";

const InsightsPage = () => {
  const { data: configDatas } = useConfigSchema(true);

  const metabaseDashboardLink = configDatas?.data?.configs?.find(
    (c: any) => c.config_schema_name === "metabase_dashboard_link"
  )?.value;

  return (
    <RetailPageContainer>
      <iframe
        title="Insights"
        src={metabaseDashboardLink || ""}
        width="100%"
        height="100%"
        frameBorder={0}
      />
    </RetailPageContainer>
  );
};

export default InsightsPage;
