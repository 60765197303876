import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TrackJS } from "trackjs";
import { SettingTypes } from "utils/types";
import useApi from "api";

const useSettings = (type: SettingTypes, enabled = true) => {
  const { api, baseURL, role, isLimitedUser } = useApi();

  const [isEnabled, setIsEnabled] = useState<boolean | undefined>(false);

  const url = () => {
    switch (type) {
      case "MARKETPLACE":
        return "settings";
      case "ADVERTISER":
        return "marketplace-settings";
      case "INTEGRATION":
        return "integration-information";
      case "PAYMENTS":
        return "marketplace-payments-info";
      case "ADMIN_VIEW":
        return "";
    }
  };

  useEffect(() => {
    if (baseURL() !== undefined) setIsEnabled(enabled);
  }, [baseURL, enabled]);

  const { isLoading, error, data } = useQuery(
    ["settings", { type }],
    () => {
      const data = api.get(url());
      return data;
    },
    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchIntervalInBackground: true,
      enabled: isEnabled,
      onError: (error: any) => {
        TrackJS.track({
          message: "Get Settings Error",
          metadata: {
            endpoint: url(),
            role,
            isLimitedUser: `Is user limited? ${isLimitedUser}`,
            baseURL: baseURL(),
            responseBody: error?.response?.data,
            statusCode: error?.response?.status,
            errorMessage: error?.message,
            severity: "Medium",
          },
        });
      },
    }
  );
  return {
    isLoading,
    data,
    error,
  };
};

export default useSettings;
