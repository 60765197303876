import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  ageFilters,
  citiesFilters,
  customerFilters,
  genderFilters,
  platformFilters,
} from "utils/filters";
import { renderTag } from "utils/helpers";
import { Relations } from "utils/types";
import RetailMoneyColumn from "components/Column/RetailMoneyColumn";
import RetailNumberColumn from "components/Column/RetailNumberColumn";
import RetailPercentageColumn from "components/Column/RetailPercentageColumn";
import RetailStatusColumn from "components/Column/RetailStatusColumn";
import RetailTable from "components/Table/RetailTable";

export interface TargetingTableProps {
  activeKey: Relations;
}

const TargetingTable = ({ activeKey }: TargetingTableProps) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const switchFilters = () => {
    switch (activeKey) {
      case "CITIES":
        return citiesFilters(t);
      case "AGE_RANGES":
        return ageFilters(t);
      case "GENDERS":
        return genderFilters(t);
      case "PLATFORMS":
        return platformFilters(t);
      case "CUSTOMERS":
        return customerFilters(t);
      default:
        break;
    }
  };

  const renderColumns = (col: string, value: any, records: any) => {
    switch (col) {
      case "status":
        return (
          <RetailStatusColumn
            records={records}
            url={`campaigns/${id}/relations`}
            relationType={activeKey}
          />
        );
      case "gender":
      case "platform":
        return (
          <span>
            {t(`components.campaignForm.thirdStep.${value?.toLowerCase()}`)}
          </span>
        );
      case "text":
        return t(`pages.acc.campaignDetails.${value?.toLowerCase()}`);
      case "impressions":
      case "clicks":
      case "viewable_impressions":
      case "RoAS":
      case "sale":
      case "direct_sale":
      case "indirect_sale":
        return <RetailNumberColumn value={value} />;
      case "CTR":
      case "ACoS":
      case "CVR":
        return <RetailPercentageColumn value={value} />;
      case "CPM":
      case "CPC":
      case "sale_amount":
      case "direct_sale_amount":
      case "indirect_sale_amount":
        return <RetailMoneyColumn value={value} />;
      case "spend":
        return <RetailMoneyColumn value={value} spendColumn={true} />;
      default:
        return value ? value : "-";
    }
  };

  const columns = (t: any) => [
    {
      title: t("common.table.status"),
      dataIndex: "status",
      render: (value: "ACTIVE" | "PAUSED") => renderTag(value, t),
    },
  ];

  const tableConfig = {
    url: "campaigns",
    isRelation: true,
    relationType: activeKey,
    filters: switchFilters(),
    renderColumns,
  };

  return (
    <RetailTable
      placeholder={t(
        `pages.acc.campaignDetails.${activeKey.toLowerCase()}_placeholder`
      )}
      tableConfig={tableConfig}
      columnsForAdditionalRendering={() => columns(t)}
      addBtnVisible={false}
    />
  );
};

export default TargetingTable;
