import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toCamelCase } from "utils/helpers";
import { ReactComponent as ArchiveOutlined } from "assets/icons/archiveOutlined.svg";
/* import { ReactComponent as DownOutlined } from "assets/icons/downOutlined.svg"; */
import { ReactComponent as ReadNotification } from "assets/icons/readNotificationOutlined.svg";
import { ReactComponent as UnreadNotification } from "assets/icons/unreadNotificationOutlined.svg";
import moment from "moment";
import RetailNotificationActionButton from "components/Button/RetailNotificationActionButton";
import RetailText from "components/Typography/RetailText";
import RetailTooltip from "components/Tooltip/RetailTooltip";

import cm from "./style.module.scss";

interface RetailNotificationContainerProps {
  notification: {
    id: string;
    subject?: string;
    body?: string;
    to: {
      id: string;
      subscriberId: string;
    };
    isRead: boolean;
    isArchived: boolean;
    createdAt: string;
    channelType: string;
    tags?: string[];
    isLoading?: boolean;
    unread: () => void;
    read: () => void;
    archive: () => void;
    unarchive: () => void;
  };
  campaignID: unknown;
  advertiserID: unknown;
  advertiserName?: unknown;
  notif?: boolean;
  setNotif?: (arg0: boolean) => void;
}

const RetailNotificationContainer = ({
  notification,
  campaignID,
  advertiserID,
  advertiserName,
}: RetailNotificationContainerProps) => {
  const { t } = useTranslation();

  const textRef = useRef<HTMLSpanElement>(null);

  const { subject, body, isRead, isArchived, createdAt, isLoading } =
    notification;

  /* const [expanded, setExpanded] = useState(false); */

  /*   useEffect(() => {
    const storedValue = sessionStorage.getItem(`notification-expanded-${id}`);
    const isExpanded = storedValue === "true";
    if (isExpanded !== expanded) {
      setExpanded(isExpanded);
    }
  }, [id, expanded]); */

  /*  const toggleExpanded = useCallback(() => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    sessionStorage.setItem(
      `notification-expanded-${id}`,
      newExpanded.toString()
    );
  }, [expanded, id]); */

  const getNotificationTranslation = (text: string) => {
    const camelCaseSubject = toCamelCase(text);

    const translationKey = `components.notification.${camelCaseSubject}`;

    const params =
      text.includes("Campaign") ||
      text.includes("Marketplace") ||
      text.includes("Creative")
        ? { value: campaignID || advertiserName }
        : undefined;

    return t(translationKey, params);
  };

  const handleRead = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      isRead ? notification.unread() : notification.read();
    },
    [isRead, notification]
  );

  const handleArchive = () =>
    isArchived ? notification.unarchive() : notification.archive();

  const translatedSubject = getNotificationTranslation(subject || "");

  const translatedBody = getNotificationTranslation(body || "");

  const readClassName = isRead ? cm.read : "";

  /* const expandClassName = expanded ? cm.expanded : ""; */

  return (
    <section
      className={`${cm.customNotificationWrapper} ${readClassName}`}
      onClick={handleRead}
    >
      {/* <DownOutlined className={`${cm.expandIcon} ${expandClassName}`} /> */}
      <article className={`${cm.textContainer}`}>
        <RetailText
          size="xxxs"
          weight="bold"
          className={`${cm.subject} ${readClassName}`}
        >
          <span className={`${cm.blueDot} ${isRead ? cm.hidden : ""}`}></span>
          {translatedSubject}
        </RetailText>
        <span
          ref={textRef}
          className={`${cm.body}`}
          /* style={{
            height: expanded ? calculateLineCount(translatedBody, 75) * 17 : 17,
          }} */
        >
          {translatedBody}
        </span>
        <div className={`${cm.iconsWrapper} ${isLoading ? cm.disabled : ""}`}>
          <RetailNotificationActionButton
            notificationType={subject || ""}
            campaignID={campaignID}
            advertiserID={advertiserID}
            /* expanded={expanded} */
          />
          <RetailText className={`${cm.createdAt}`}>
            {moment(createdAt).format("HH:mm - DD.MM.YYYY")}
          </RetailText>
          <RetailTooltip title={t(`common.${isRead ? "unread" : "read"}`)}>
            <div className={`flex ${cm.actionIcon}`} onClick={handleRead}>
              {isRead ? <UnreadNotification /> : <ReadNotification />}
            </div>
          </RetailTooltip>
          <RetailTooltip
            title={t(`common.${isArchived ? "removeArchive" : "archive"}`)}
          >
            <div className="flex" onClick={handleArchive}>
              <ArchiveOutlined
                className={`${cm.archiveIcon} ${readClassName}`}
              />
            </div>
          </RetailTooltip>
        </div>
      </article>
    </section>
  );
};

export default RetailNotificationContainer;
