import { useState } from "react";
import { Divider, Tag } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import cc from "classcat";

import RetailText from "components/Typography/RetailText";
import CreativeDimensionsModal from "components/Modal/CreativeDimensionsModal";

import cm from "./style.module.scss";
import useCreativeSize from "hooks/useCreativeSize";
import { DISPLAY_CONTEXT } from "utils/constants";

export interface CreativeSizeInfoProps {
  setOpenUpload?: (value: boolean) => void;
  displayContext?: string;
}

const CreativeSizeInfo = ({
  setOpenUpload,
  displayContext = DISPLAY_CONTEXT.FORM,
}: CreativeSizeInfoProps) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const { data } = useCreativeSize();

  const modalActions = (value: boolean) => {
    setVisible(value);
    setOpenUpload && setOpenUpload(!value);
  };

  const open = (e: any) => {
    e.stopPropagation();
    setOpenUpload && setOpenUpload(false);
    setVisible(true);
  };

  const close = () => modalActions(false);

  const uniqueSizes: string[] = Array.from(
    new Set(data?.data?.records?.map((x: any) => String(x.recommended_size)))
  );

  return (
    <div
      className={cc([
        cm.container,
        displayContext === DISPLAY_CONTEXT.DRAWER && cm.containerDrawer,
      ])}
    >
      <Divider
        className={cm.divider}
        orientation={
          displayContext === DISPLAY_CONTEXT.DRAWER ? "left" : "center"
        }
      >
        <RetailText size="xxxs" className={cm.creativeRecommendedSizeTitle}>
          {t("components.campaignForm.firstStep.recommendedSizes")}
        </RetailText>
      </Divider>
      <div
        className={cc([
          cm.sizesTagContainer,
          displayContext === DISPLAY_CONTEXT.FORM && cm.sizesTagFormContainer,
        ])}
      >
        {uniqueSizes.map((uniqueSize, index) => (
          <Tag
            className={cc([
              cm.tag,
              displayContext === DISPLAY_CONTEXT.DRAWER
                ? cm.tagDrawerText
                : cm.tagFormText,
            ])}
          >
            <RetailText size="xxxs" family="poppins">
              {uniqueSize}
            </RetailText>
          </Tag>
        ))}
      </div>
      <div
        className={cc([
          cm.infoContainer,
          displayContext === DISPLAY_CONTEXT.DRAWER && cm.infoDrawerContainer,
        ])}
      >
        <RetailText
          size="xxxs"
          weight="medium"
          className={cm.creativeRecommendedSizeText}
        >
          {t("components.campaignForm.firstStep.allSizeText")}
        </RetailText>
        <div className={cm.linkContainer} onClick={open}>
          <RetailText size="xxxs" weight="medium">
            {t("components.campaignForm.firstStep.visit")}
          </RetailText>
          <LinkOutlined />
        </div>
      </div>
      <CreativeDimensionsModal
        visible={visible}
        close={close}
        sizeData={data}
      />
    </div>
  );
};

export default CreativeSizeInfo;
