import { Card } from "antd";
import useApi from "api";
import RetailMainButton from "components/Button/RetailMainButton";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { useCurrency } from "context/CurrencyProvider";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { AdType } from "utils/types";
import cm from "./style.module.scss";

export interface CsvTemplateInfoCardProps {
  dataBidType: string;
}

const CsvTemplateInfoCard = ({ dataBidType }: CsvTemplateInfoCardProps) => {
  const { t } = useTranslation();
  const { roleForDashboard } = useApi();
  const { currencySymbol } = useCurrency();

  const location = useLocation();
  const queryClient = useQueryClient();

  const ad_type = location.state as { ad_type: AdType };

  const bidData: any = queryClient.getQueryData([
    "settings",
    {
      type: roleForDashboard,
    },
  ]);

  const chooseMinimum = useCallback(() => {
    switch (ad_type?.ad_type) {
      case "DISPLAY":
      case "DISPLAY_WITH_PRODUCT":
        return bidData?.data?.sponsored_display_price_setting;
      case "VIDEO":
      case "VIDEO_WITH_PRODUCT":
      case "IN_STORE":
        return bidData?.data?.sponsored_video_price_setting;
      default:
        return bidData?.data?.sponsored_product_price_setting;
    }
  }, [ad_type?.ad_type, bidData]);

  const chosenMinBid = useCallback(() => {
    if (chooseMinimum() === "CPM") return bidData?.data?.minimum_cpm;
    else return bidData?.data?.minimum_bid;
  }, [bidData, chooseMinimum]);

  const downloadFile = () => {
    const downloadURL =
      dataBidType === "FIXED"
        ? "https://keyword-upload-template.s3.eu-central-1.amazonaws.com/keyword_template_fixed.csv"
        : "https://keyword-upload-template.s3.eu-central-1.amazonaws.com/keyword_template_auto.csv";
    const link = document.createElement("a");
    link.href = downloadURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <RetailTitle level={5}>
        {t("components.campaignForm.secondStep.generalInformations")}
      </RetailTitle>
      <Card className={cm.infoCard}>
        <div className={cm.templateContainer}>
          <RetailText size="xxs" className={cm.templateText}>
            <Trans i18nKey="components.campaignForm.secondStep.csvTemplateInstruction" />
          </RetailText>
          <RetailMainButton onClick={downloadFile}>
            {t("components.campaignForm.secondStep.downloadCsvButton")}
          </RetailMainButton>
        </div>
        <div className={cm.formatRulesContainer}>
          <RetailText size="xxs" weight="bold" className={cm.templateText}>
            {t("components.campaignForm.secondStep.formattingRules")}
          </RetailText>
          <ul className={cm.templateRulesList}>
            <li>
              <RetailText size="xxs" className={cm.templateRuleText}>
                <Trans i18nKey="components.campaignForm.secondStep.ruleAlphanumeric" />
              </RetailText>
            </li>
            <li className={cm.templateRuleText}>
              <RetailText size="xxs" className={cm.templateRuleText}>
                <Trans i18nKey="components.campaignForm.secondStep.ruleMatchType" />
              </RetailText>
            </li>
            <li className={cm.templateRuleText}>
              <RetailText size="xxs" className={cm.templateRuleText}>
                <Trans i18nKey="components.campaignForm.secondStep.ruleBidValue" />{" "}
                <strong>
                  ({t("components.campaignForm.secondStep.minimumBidValue")}{" "}
                  {currencySymbol}
                  {chosenMinBid()})
                </strong>
              </RetailText>
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default CsvTemplateInfoCard;
