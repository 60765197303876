import { CloseOutlined } from "@ant-design/icons";
import { Modal, ModalProps } from "antd";
import { t } from "i18next";
import cc from "classcat";

import RetailMainButton from "components/Button/RetailMainButton";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";

import cm from "./style.module.scss";

export interface CreativeDimensionsModalProps extends ModalProps {
  visible: boolean;
  close: () => void;
  sizeData?: any;
}

const CreativeDimensionsModal = ({
  visible,
  close,
  sizeData,
  ...modalProp
}: CreativeDimensionsModalProps) => {
  return (
    <Modal
      closable
      closeIcon={<CloseOutlined />}
      centered
      visible={visible}
      onCancel={close}
      className={cc(["campaign-modal", cm.modal])}
      destroyOnClose
      title={
        <>
          <RetailTitle level={4} className={cm.modalTitle}>
            {t("components.campaignForm.firstStep.modalTitle")}
          </RetailTitle>
          <RetailText size="xs" family="poppins" className={cm.modalText}>
            {t("components.campaignForm.firstStep.modalText")}
          </RetailText>
        </>
      }
      footer={[
        <RetailMainButton hasBackground className={cm.modalBtn} onClick={close}>
          {t("common.okay")}
        </RetailMainButton>,
      ]}
      {...modalProp}
    >
      <table className={cm.table}>
        <thead>
          <th>
            <RetailText weight="medium" family="poppins" className={cm.title}>
              {t("components.campaignForm.firstStep.recommended")}
            </RetailText>
          </th>
          <th>
            <RetailText weight="medium" family="poppins" className={cm.title}>
              {t("components.campaignForm.firstStep.accepted")}
            </RetailText>
          </th>
        </thead>

        <tbody className={cm.tableBody}>
          <td className={cm.recommendedSizes}>
            {sizeData?.data?.records?.map((x: any, index: number) => (
              <tr key={index}>
                <RetailText className={cm.data} size="xs" family="poppins">
                  {x.recommended_size}
                </RetailText>
              </tr>
            ))}
          </td>
          <td className={cm.sizes}>
            {sizeData?.data?.records?.map((x: any, index: number) => (
              <tr key={index} className={cm.sizes}>
                <RetailText className={cm.data} size="xs" family="poppins">
                  {x?.sizes?.map((size: string, index: number) => (
                    <span key={index}>{size}</span>
                  ))}
                </RetailText>
              </tr>
            ))}
          </td>
        </tbody>
      </table>
    </Modal>
  );
};

export default CreativeDimensionsModal;
