import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import useConfigSchema from "hooks/useConfigSchema";

interface CurrencyContextType {
  currency: string;
  currencySymbol: string;
}

const CurrencyContext = createContext<CurrencyContextType | null>(null);

interface CurrencyProviderProps {
  children: ReactNode;
}

export const CurrencyProvider = ({ children }: CurrencyProviderProps) => {
  const [currency, setCurrency] = useState<string>("USD");

  const [currencySymbol, setCurrencySymbol] = useState<string>("$");

  const { data: configData, error: configError } = useConfigSchema(
    true,
    null,
    "user_settings"
  );

  useEffect(() => {
    // Handle API error
    if (configError) {
      console.error("Failed to fetch config data:", configError);
      return;
    }

    // Update currency and symbol when configData is available
    if (configData) {
      const configs = configData?.data?.configs || [];
      // Find currency from the fetched configs
      const currencyConfig = configs.find(
        (config: any) => config.config_schema_name === "marketplace_currency"
      );

      const fetchedCurrency = currencyConfig?.value || "USD";

      // Update state with fetched data
      setCurrency(fetchedCurrency);
      setCurrencySymbol(getCurrencySymbol(fetchedCurrency));

      // Store the fetched data in localStorage for fallback purposes
      try {
        localStorage.setItem("user_settings", JSON.stringify(configs));
      } catch (e) {
        console.error("Failed to save user settings to localStorage:", e);
      }
    } else {
      // Fallback to localStorage if no configData is available
      const storedSettings = JSON.parse(
        localStorage.getItem("user_settings") || "[]"
      );
      const currencyConfig = storedSettings.find(
        (config: any) => config.config_schema_name === "marketplace_currency"
      );

      const storedCurrency = currencyConfig?.value || "USD";

      setCurrency(storedCurrency);
      setCurrencySymbol(getCurrencySymbol(storedCurrency));
    }
  }, [configData, configError]);

  return (
    <CurrencyContext.Provider value={{ currency, currencySymbol }}>
      {children}
    </CurrencyContext.Provider>
  );
};

const getCurrencySymbol = (currency: string): string => {
  switch (currency) {
    case "TRY":
      return "₺";
    case "IQD":
      return "د. ع";
    case "TWD":
      return "NT$";
    case "HKD":
      return "HK$";
    case "KRW":
      return "₩";
    case "JPY":
      return "¥";
    case "EUR":
      return "€";
    case "USD":
    default:
      return "$";
  }
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  return context;
};
