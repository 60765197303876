const ar = {
  marketplaceDependent: {
    campaign_form_creative_text:
      "يمكنك تحميل الصور التي ترغب في استخدامها في حملتك.",
    invoice_modal_help_text:
      "إذا تابعت بالنقر فوق الزر ' اختيار طريقة الدف' ، فسيتم اعتبارك قد أكدت أن المبلغ الذي سيتم تحصيله لن يتم استرداده.",
    invoice_general_error_modal_title: "فشل في تحميل الرصيد!",
    invoice_general_error_modal_text: "حدث خطأ غير متوقع.",
    invoice_unknown_error_modal_text: "حدث خطأ غير متوقع",
    invoice_product_id_error_modal_text: "ID المنتج مطلوب",
    invoice_signature_error_modal_text:
      "هنالك مشكلة في عملية التحقق من صحة التوقيع",
    invoice_transaction_error_modal_text:
      "ان ID المعاملة هذا تم استخدامه من قبل",
    invoice_store_error_modal_text: "لا ينتمي المتجر إلى الشركة المحددة",
    invoice_balance_error_modal_text: "لا يوجد بالمتجر رصيد كافي",
    invoice_error_modal_text: "حدث خطأ غير متوقع",
  },
  marketplaceDependentTable: {
    CPC: "تكلفة النقرة الواحدة (CPC)",
    CPM: "الحد الأدنى للتكلفة لكل ألف انطباع (CPM)",
    RoAS: "عائد الإعلان على الإنفاق (ROAS)",
  },
  sider: {
    user: {
      campaign: "الحملة",
      report: "التقرير",
      campaigns: "الحملات",
      reports: "التقارير",
      invoices: "الفواتير",
      academy: "الأكاديمية",
      chat: "الدعم عبر البريد الإلكتروني",
      offsiteCampaigns: "الحملات الخارجية",
    },
    admin: {
      campaign: "الحملة",
      advertisers: "المعلنون",
      agencies: "الوكالات",
      invoices: "الفواتير",
      finance: "المالية",
      transactions: "المعاملات",
      coupons: "الكوبونات",
      access: "الحقوق",
      users: "المستخدمون",
      invited: "تمت الدعوة",
      synchronization: "سجل المزامنة",
      activity: "سجل النشاط",
      report: "التقرير",
      settings: "الإعدادات",
      general: "المعلومات العامة",
      integration: "عمليةالدمج",
      advertiser: "إعدادات المعلن",
      auction: "إعدادات المزاد",
      single: "إعدادات الحملة",
      campaigns: "الحملات",
      reports: "التقارير",
      admin_report: "التقرير",
      ad: "الإعلان",
      placement: "مكان الإعلان",
      creatives: "الصور",
      review: "مراجعة الإعلان",
      restrictions: "القيود",
      console: "مصادر البيانات",
      dev: "Dev. Tools",
      auctions: "مزادات",
      offsite: "إعدادات خارج الموقع",
      offsiteCampaigns: "الحملات الخارجية",
      insights: "رؤى",
    },
  },
  components: {
    adminBar: {
      rn: "أنت تنظر حاليًاالى",
      visible: "شاشة المعلن.",
      back: "العودة إلى اعدادات الادمن",
    },
    advertiserSelect: {
      placeholder: "اختار المعلن",
    },
    articles: {
      date: "متى تبدأ وتنتهي حملتك؟",
      budget: "ما هي الميزانية؟ ما الذي يجب مراعاته عند اختيار الميزانية؟",
      bid: "ما هي المزايدة وما هي أنواع المزايدة؟",
      targeting: "أنواع الاستهداف وكيفية استهداف الجمهور الصحيح؟",
      frequency: "ما هو 'نوع التسليم' في التقرير؟",
      type: "كيفية إنشاء تقرير مخصص؟",
      product: "ما الذي يجب مراعاته عند اختيار المنتج؟",
    },
    breadcrumb: {
      finance: "التحويلات المالية",
      settings: "معلومات عامة",
      access: "المستخدمون",
    },
    bulkUpdate: {
      list: "القائمة المختارة",
      archive: "الارشيف المختار",
      pause: "إيقاف المختار",
      activate: "تنشيط المختار",
      selected: "{{value}} وحدة محددة",
    },
    datasource: {
      entity: "ما هو الكيان الخاص بك؟",
      method: "ما هي طريقة الاستيراد الخاصة بك؟",
      configurations: "اضبط التكوينات الخاصة بك",
      mapping: "تعيين الحقول",
      emptyEntity: "ما هو <strong>الكيان الخاص بك؟</strong>",
      emptyEntityText:
        "حدد نوع الكيان الذي تضيف البيانات له. سيحدد هذا الاختيار هيكل بياناتك ويحدد الحقول المطلوبة للاستيراد. يمكن أن تشمل الكيانات المنتجات أو المعلنين. بمجرد الاختيار، ستتمكن من تكوين حقول البيانات الخاصة بهذا الكيان.",
      productEntityText:
        "يمثل كيان المنتج البيانات المتعلقة بالمنتجات الفردية. يشمل الحقول مثل معرف المنتج، الاسم، الوصف، السعر، الفلاتر، ومعلومات المخزون. تم تصميم هذا الكيان لالتقاط وإدارة المعلومات التفصيلية لكل منتج.",
      advertiserEntityText:
        "مصدر بيانات المعلن يمثل البيانات المتعلقة بالمعلنين. يتضمن حقولًا مثل معرف المعلن، الاسم، والحالة. تم تصميم هذا النوع من البيانات لالتقاط وإدارة معلومات مفصلة لكل معلن.",
      selectEntity: "اختر الكيان",
      selectEntityDescription:
        "اختر نوع الكيان الذي تضيف البيانات له. سيحدد هذا الاختيار بنية بياناتك ويحدد الحقول المطلوبة للاستيراد. الكيانات يمكن أن تشمل المنتجات أو المعلنين. بعد الاختيار، ستتمكن من تكوين الحقول الخاصة بهذا الكيان.",
      product: "المنتج",
      productText:
        "يمثل كيان المنتج البيانات المتعلقة بالمنتجات الفردية. يتضمن الحقول مثل معرف المنتج، الاسم، الوصف، السعر، الفلاتر، ومعلومات المخزون. هذا الكيان مصمم لجمع وإدارة المعلومات التفصيلية لكل منتج.",
      seeFields: "عرض مخطط بيانات {{value}}",
      how: "عرض كيفية الاستخدام",
      advertiser: "المعلن",
      advertiserText:
        "يمثل كيان المعلن البيانات المتعلقة بالمعلنين. يتضمن الحقول مثل معرف المعلن، الاسم، والتفاصيل الأخرى ذات الصلة. الحقول المطلوبة تشمل 'المعرف' و 'الاسم' لضمان التكامل الصحيح. يساعد هذا الكيان في تنظيم وإدارة المعلومات الأساسية عن المعلنين.",
      soon: "قريباً",
      schema: "مخطط بيانات {{value}}",
      required: "إلزامي",
      showFields: "عرض الحقول الإضافية",
      emptyMethod: "ما هي <strong>طريقة الاستيراد</strong> الخاصة بك؟",
      emptyMethodText:
        "اختر الطريقة التي تريد استيراد بياناتك من المصدر المحدد بها. بناءً على الطريقة التي تختارها، سيقوم النظام بتكييف عملية الاستيراد تلقائيًا للتعامل مع البيانات بشكل صحيح. لكل طريقة مزاياها، لذا اختر الأنسب لاحتياجاتك.",
      filledMethod: "طريقة الاستيراد: <strong>{{value}}</strong>",
      googleFeedUrl: "رابط Google Feed",
      googleFeedFile: "تحميل ملف Google Feed (XML)",
      googleFeedDirectXml: "تحميل XML مباشرة (جسم الطلب)",
      filledMethodText:
        "استخدم هذا الخيار لاستيراد البيانات من {{value}} الخاص بك عن طريق تقديم URL للخلاصة أو تحميل الملفات بتنسيقات مدعومة.",
      selectMethod: "اختر طريقة الاستيراد",
      googleFeedText:
        "يُستخدم Google Feed لاستيراد البيانات مباشرة من Google Feed الخاص بك عن طريق تقديم URL للخلاصة أو تحميل الملفات بتنسيقات مدعومة. تعمل هذه الميزة على تبسيط العملية من خلال إدارة إنشاء المعلنين تلقائيًا أثناء تكامل تغذية المنتج.",
      feedUrl: "Feed URL",
      feedUrlText:
        "عنوان URL المحدد حيث يتم استرداد بيانات تغذية Google مباشرة من المصدر",
      fileUpload: "تحميل الملفات (XML)",
      fileUploadText:
        "يمكن استيراد ملفات Google Feed يدويًا باستخدام تنسيق XML.",
      requestBody: "تحميل XML المباشر (نص الطلب)",
      requestBodyText:
        "طريقة تكامل مباشرة تتيح استيراد بيانات تغذية Google عبر XML داخل جسم الطلب، متجاوزة تحميل الملفات التقليدي. (الميزة قادمة قريبًا)",
      configTitle: "قم بتعيين <strong>التكوينات</strong> الخاصة بك",
      configText:
        "حدد الإعدادات الأساسية لمصدر البيانات الخاص بك. يضمن التكوين الصحيح لهذه الإعدادات أن عملية استيراد البيانات تعمل بسلاسة وأن بياناتك تظل محدثة في النظام. لا يمكن تغيير هذه الإعدادات بعد حفظ مصدر البيانات.",
      name: "اسم مصدر البيانات",
      nameHelp:
        "أدخل اسمًا فريدًا لتكوين مصدر البيانات هذا. سيساعدك هذا في تحديد المصدر لاحقًا.",
      url: "Feed URL",
      urlHelp:
        "قم بتوفير URL الكامل حيث يمكن جلب الخلاصة. سيقوم النظام بجلب البيانات بانتظام من هذا الرابط.",
      cycle: "اختر الدورة",
      cycleHelp: "حدد عدد مرات جلب النظام للتحديثات من URL الخلاصة المقدمة.",
      fetch: "جلب وتعيين",
      mappingTitle: "تعيين <strong>الحقول</strong>",
      mappingText:
        "في هذا القسم، ستقوم بتعيين الحقول من مصدر البيانات الخاص بك إلى الحقول المقابلة في نظامنا. تحتوي الحقول النظامية على متطلبات محددة، ويجب تعيين أي حقول إلزامية بشكل صحيح لضمان استيراد ناجح. بالنسبة لبعض الحقول الإلزامية التي لا يوجد لها بيانات مطابقة في المصدر الخاص بك، يمكنك اختيار تعيين قيمة افتراضية. يساعد هذا في ضمان عدم فقدان المعلومات الهامة أثناء عملية الاستيراد. بعد إتمام تعيين الحقول، يجب عليك التحقق من صحة التعيين قبل المتابعة للتأكد من أن جميع الحقول الإلزامية تم تعيينها بشكل صحيح.",
      validate: "التحقق من صحة التعيين",
      system: "حقول النظام",
      imported: "الحقول المستوردة",
      preview: "معاينة",
      hideFields: "إخفاء الحقول الإضافية",
      feedUrlLink: "مواصفات بيانات منتج Google",
      upload: "تحميل ملف .XML",
      uploaded: "تم تحميل الملف!",
      uploadText: "أو اسحب وأفلت هنا",
      metaFeedText:
        "يدعم Meta Feed استيراد البيانات باستخدام تنسيقات متنوعة، بما في ذلك عناوين URL للتغذية وتحميل الملفات. سيتم تقديم تنسيقات إضافية وتكوينات الحقول قريبًا.",
      metaFeedUrlText:
        "مصدر URL مخصص يُستخدم لاسترداد البيانات مباشرة لتكامل Meta Feed.",
      metaFeedUrl: "رابط Meta Feed",
      xml: "كود XML المباشر",
      xmlHelp: "أدخل كود XML مباشرة لاستيراد البيانات من المصدر الخاص بك.",
      api: "تكامل API",
      apiText:
        "يوفر تكامل API اتصالًا بنقاط النهاية المحددة لـ API، مما يتيح مزامنة البيانات لتدفق المعلومات المستمر إلى نظامك.",
      apiButtonText:
        "يوفر إعدادًا لاسترداد البيانات من خلال الاتصال بنقاط النهاية المحددة لـ API. يتيح هذا التكامل مزامنة البيانات من خلال الطلبات المرسلة إلى واجهة برمجة التطبيقات الخاصة بنا، مما يضمن تدفق المعلومات المحدثة مباشرة إلى نظامك عند حدوث تغييرات.",
      impression: "الانطباع",
      impressionText:
        "تمثل كيان الانطباع البيانات المتعلقة بمشاهدات الإعلانات. يتضمن ذلك الحقول مثل معرف الإعلان، معرف الجلسة، والتفاصيل ذات الصلة الأخرى. يساعد هذا الكيان في قياس رؤية إعلاناتك عن طريق تسجيل كل مرة يظهر فيها إعلان على الشاشة.",
      click: "النقرات",
      clickText:
        "يلتقط كيان النقرة البيانات المرتبطة بتفاعلات المستخدم عندما ينقر على إعلان. تتضمن الحقول معرف الإعلان، معرف الجلسة، والتفاصيل ذات الصلة الأخرى لتوفير رؤى حول تفاعل المستخدم. يتيح هذا الكيان تحليلًا مفصلًا لإجراءات المستخدم عن طريق تسجيل كل نقرة على الإعلان.",
      sale: "المبيعات",
      saleText:
        "يتضمن كيان البيع البيانات لجميع التحويلات أو المشتريات الناتجة عن تفاعلات الإعلانات. من الضروري إرسال جميع أحداث البيع إلينا لضمان التتبع والإبلاغ بدقة. تتيح هذه البيانات لنا تقديم رؤى تفصيلية حول أنواع العملاء، مثل المشترين حسب الفئات، العملاء الجدد، والعملاء الحاليين، بالإضافة إلى التحويلات المباشرة وغير المباشرة.",
      productSchema: {
        id: "ID المنتج",
        name: "اسم المنتج",
        advertiser_id: "ID المعلن",
        image_url: "رابط صورة المنتج",
        description: "وصف المنتج",
        price: "سعر المنتج (يعيد -1 للمواقع المتعددة)",
        filters: "الفلاتر المطبقة على المنتج",
        buybox: "يشير إلى ما إذا كان المنتج يحتوي على buybox",
        sub_class_names: "أسماء الفئات الفرعية للمنتج",
        brand: "علامة المنتج التجارية",
        retailer_taxonomy: "التصنيف الخاص بتاجر التجزئة للمنتج",
        google_taxonomy: "تصنيف Google للمنتج",
        product_type: "نوع المنتج",
        global_identifier: "المعرّف العالمي للمنتج",
        global_identifier_type: "نوع المعرّف العالمي (GTIN أو ASIN)",
        rating: "تقييم المنتج",
        stock: "مخزون المنتج (يعيد -1 للمواقع المتعددة)",
        sku: "SKU للمنتج",
        barcode: "الباركود الخاص بالمنتج",
        price_range: "نطاق سعر المنتج",
        status: "حالة المنتج",
        retailer_taxonomy_id_structure: "هيكل معرف تصنيف بائع التجزئة",
        process_id: "معرف العملية",
      },
      advertiserSchema: {
        id: "ID المعلن",
        name: "اسم المعلن",
        time_zone: "المنطقة الزمنية للمعلن",
        email: "البريد الإلكتروني للمعلن",
        language: "لغة المعلن",
        status: "حالة المعلن",
        process_id: "معرف عملية المعلن",
      },
    },
    download: {
      campaigns: "الحملات",
      advertisers: "المعلنين",
      agencies: "الوكالات",
      transactions: "المعاملات",
      coupons: "الكوبونات",
      users: "المستخدمين",
      sync_logs: "سجلات المزامنة",
      reports: "التقارير",
      query: "تقرير الأداء",
      PRODUCTS: "المنتجات",
      KEYWORDS: "الكلمات",
      NEGATIVE_KEYWORDS: "الكلمات السلبية",
      AGE_RANGES: "العمر",
      CITIES: "المدن",
      PLATFORMS: "المنصات",
      GENDERS: "الجنس",
      PLACEMENTS: "مواقع الإعلان",
      CUSTOMERS: "الزبائن",
      CATEGORIES: "الفئات",
      NEGATIVE_CATEGORIES: "الفئات السلبية",
      CREATIVES: "الصور",
    },
    inbox: {
      title: "البريد الوارد",
      empty: "ليس لديك أي إشعارات.",
      unread: "غير المقروء فقط",
      default: "الكل",
      archived: "المؤرشف",
      readAll: "وضع علامة للكل كمقروء",
      archiveAll: "أرشفة الكل",
      archiveRead: "أرشفة المقروء",
      preferences: "تفضيلات الإشعارات",
      global: "التفضيلات العامة",
      balance: "رصيد منخفض",
      categories: "عرض منخفض (الفئات)",
      keywords: "عرض منخفض (الكلمات المفتاحية)",
      products: "تم إيقاف المنتجات",
      creative: "كرييتيف مرفوض",
      review: "تم إنشاء الإعلان الإبداعي",
    },
    info: {
      firstTipCreate:
        "يمكنك بسهولة إنشاء حملات ناجحة عن طريق اختيار الإعدادات الموصى بها. الإعدادات الموصى بها تحسن أداء إعلانك تلقائيًا.",
      secondTipCreate:
        "لزيادة أداء حملاتك، يمكنك اختيار منتجات ذات مخزون كافٍ وتقييم عالي وفائزين بصندوق الشراء لحملاتك.",
      thirdTipCreate:
        "زيادة ميزانية الإعلانات تساعدك على زيادة عدد النقرات والمبيعات من خلال جعل منتجاتك أكثر وضوحًا لعملائك.",
      forthTipCreate:
        "من خلال تقديم عروض عالية في حملاتك، يمكنك التأكد من عرض منتجاتك في اماكن أفضل.",
      survey: "ساعدنا على التحسين من خلال إجراء استطلاع سريع.",
    },
    invite: {
      selected: "{{value}} وحدة محددة",
      invite: "دعوة المحددين",
      reinvite: "إعادة دعوة المحددين",
    },
    modal: {
      campaign: {
        create: "إنشاء حملة جديدة",
        success: "تم إنشاء الحملة بنجاح",
        successText:
          "يمكنك الاستمرار إلى صفحة تفاصيل الحملة بالنقر على زر 'OK'.",
      },
      campaignDetails: {
        products: "اختيار المنتج",
        keywords: "إضافة كلمة مفتاحية",
        update: "تحديث الحملة",
        negative_keywords: "إضافة كلمة مفتاحية سلبية",
        products_btn: "إضافة منتجات",
        keywords_btn: "إضافة كلمات مفتاحية",
        negative_keywords_btn: "إضافة كلمات مفتاحية سلبية",
        update_btn: "تحديث",
        create_agency: "إضافة وكالة جديدة",
        update_agency: "تحديث الوكالة",
        create_agency_btn: "إضافة",
        update_agency_btn: "تحديث",
        create_user: "إضافة مستخدم جديد",
        create_user_marketplace: "إضافة مستخدم مسؤول",
        create_user_marketplace_btn: "إضافة",
        create_user_advertiser: "إضافة مستخدم معلن",
        create_user_advertiser_btn: "إضافة",
        select_advertiser: "اختيار معلن",
        select_advertiser_btn: "متابعة",
        update_advertiser: "تحديث المعلن",
        update_advertiser_btn: "تحديث",
        invoice: "إضافة رصيد",
        invoice_btn: "اختيار طريقة الدفع",
        coupons: "تحديد الكوبون",
        coupons_btn: "تحديد الكوبون",
        transactions: "إضافة رصيد",
        transactions_btn: "إضافة رصيد",
        categories: "إضافة فئة جديدة",
        categories_btn: "إضافة فئات",
        negative_categories: "إضافة فئة سلبية",
        negative_categories_btn: "إضافة فئات",
        transaction_details: "تفاصيل المعاملة",
        settings: "حفظ التغييرات",
        settings_btn: "حفظ",
        creatives: "إضافة صورة",
        creatives_btn: "إضافة صور",
        approve_balance: "إضافة رصيد",
        approve_balance_btn: "تأكيد المعاملة",
        payment_options: "إضافة رصيد",
        payment_options_btn: "تحميل",
        add_user: "دعوة مستخدم جديد",
        add_user_btn: "إرسال الدعوة",
        targeting_settings: "اختيار المعلن",
        targeting_settings_btn: "تحديد",
        update_transaction: "تحرير رقم الفاتورة",
        update_transaction_btn: "حفظ",
        select_user: "اختيار نوع المستخدم",
        select_user_btn: "تابع",
        select_language: "تغيير اللغة",
        select_language_btn: "موافق",
        update_dayparting: "تحديد الأيام",
        update_dayparting_btn: "تحديث",
        reject_creative: "رفض الإبداع",
        reject_creative_btn: "تأكيد",
        refund: "استرداد",
        refund_btn: "طلب استرداد",
        confirm_refund: "تأكيد الاسترداد",
        confirm_refund_btn: "تأكيد الاسترداد",
        archive_confirmation: "تأكيد الأرشيف",
        archive_confirmation_btn: "تأكيد وأرشفة",
        ad_summary: "إجمالي الإعلان",
        ad_summary_btn: "إنشاء إعلان",
        confirm_keyword_tab: "تأكيد العملية",
        confirm_keyword_tab_btn: "المتابعة وحذف الكلمات المفتاحية",
      },
      error: {
        invoices_error_title: "فشل في تحميل الرصيد!",
        invoices_error_text: "حدث خطأ.",
        beymen_invoices_error_title: "فشل في تحميل الرصيد!",
        beymen_invoices_error_text:
          "بالنسبة للخطأ الذي تلقيته أثناء تحميل الرصيد، يمكنك فتح طلب تحت عنوان 'إدارة الحملات والإعلانات' من خلال دعم البائع.",
        file_title: "خطأ في تحميل ملف XML",
        file_text:
          "حدثت مشكلة في معالجة ملف XML الخاص بك. يرجى التأكد من أن تنسيق الملف صحيح ويلبي البنية المطلوبة. إذا استمرت المشكلة، يرجى الاتصال بالدعم للحصول على مساعدة إضافية.",
        xml_title: "خطأ في معالجة XML",
        xml_text:
          "حدثت مشكلة أثناء معالجة بيانات XML. يرجى التأكد من صحة هيكل ومحتوى XML. إذا استمرت المشكلة، يرجى الاتصال بالدعم للحصول على مزيد من المساعدة.",
      },
      selectCampaign: {
        title: "اختر نوع حملتك",
        text: "يرجى اختيار نوع حملتك.",
        soon: "قريباً",
        sponsored_product_availability_title: "منتج ممول",
        sponsored_product_availability_text:
          "قدم منتجاتك للعملاء الذين يبحثون بكلمات رئيسية ذات الصلة أو يعرضون منتجات مماثلة.",
        sponsored_brand_availability_title: "علامة تجارية ممولة",
        sponsored_brand_availability_text:
          "زيِّن علامتك التجارية في البحوث ذات الصلة وعرض المنتجات المماثلة لتعزيز المعرفة بالعلامة التجارية.",
        sponsored_display_availability_title: "إعلان صوري",
        sponsored_display_availability_text:
          "زيادة مبيعاتك من خلال الوصول إلى جماهير مستهدفة بإعلانات بصرية داخل الموقع.",
        sponsored_store_availability_title: "متجر ممول",
        sponsored_store_availability_text:
          "اللشاركين المستمتعين بالتسوق من خلال مساعدتهم في استكشاف المتاجر.قم بتوسيع وصول متجرك وساعد العملاء على استكشاف المتاجر.",
        sponsored_video_availability_title: "إعلانات الفيديو",
        sponsored_video_availability_text:
          "يمكنك الوصول إلى جمهورك المستهدف وزيادة المبيعات باستخدام عناصر جذابة مع إعلانات الفيديو.",
        sponsored_display_with_product_availability_title:
          "إعلان صوري مع المنتج",
        sponsored_display_with_product_availability_text:
          "قم بالإعلان عن منتجاتك بفعالية من خلال استهداف المتسوقين ذوي الاهتمامات ذات الصلة داخل الموقع وخارجه.",
        sponsored_video_with_product_availability_title:
          "إعلان فيديو مع المنتج",
        sponsored_video_with_product_availability_text:
          "عزز ظهور منتجاتك وجذب الجماهير باستخدام محتوى فيديو ديناميكي وجذاب.",
        in_store_availability_title: "في المتجر",
        in_store_availability_text:
          "تفاعل مع المتسوقين الذين يزورون مواقع متاجرك فعليًا",
      },
      success: {
        advertisers_success_title: "تم إرسال الدعوة",
        advertisers_success_text:
          "تم إرسال دعوة إلى عنوان البريد الإلكتروني الذي أدخلته. يجب على المستخدمين إنشاء حساب عن طريق النقر على رابط الدعوة للوصول إلى حساباتهم.",
        reports_success_title: "تم إنشاء التقرير بنجاح",
        reports_success_text:
          "يمكنك متابعة صفحة تفاصيل التقرير بالنقر على زر 'OK'.",
        invited_success_title: "تم دعوة المستخدم",
        invited_success_text:
          "لكي يظهر المستخدم في القائمة، يجب عليهم النقر على رسالة الدعوة وإنشاء كلمة مرور.",
        agencies_success_title: "تم إنشاء الوكالة بنجاح",
        agencies_success_text: "",
        multiple_advertisers_success_title: "تم إرسال الدعوة",
        multiple_advertisers_success_text: "تمت دعوة المعلنين المحددين.",
        invoice_success_title: "تم تحميل الرصيد بنجاح!",
        invoice_success_text: "تم تحميل رصيدك. يمكنك إنشاء حملات جديدة.",
        file_success_title: "تم تحميل الملف بنجاح!",
        file_success_text:
          "تم تحميل ملفك ومعالجته بنجاح. يمكنك الآن المتابعة إلى الخطوات التالية.",
        xml_success_title: "تمت معالجة XML بنجاح!",
        xml_success_text:
          "تمت معالجة بيانات XML بنجاح. يمكنك الآن المتابعة إلى الخطوات التالية.",
      },
      warning: {
        campaign_warning_title: "هل أنت متأكد أنك تريد مغادرة هذه الصفحة؟",
        campaign_warning_text: "لن يتم حفظ التغييرات التي قمت بها!",
      },
    },
    navbar: {
      search: "اكتب للبحث...",
      help: "المساعدة والدعم",
      site: "العودة إلى لوحة البائع",
    },
    navbarPopover: {
      account: "تغيير الحساب",
      language: "تغيير اللغة",
      logout: "اخرج",
    },
    notification: {
      formTitle: "الاستمارة غير مكتملة!",
      formText: "يرجى ملء الحقول الفارغة والحقول المطلوبة!",
      paused: "تم إيقاف {{value}} بنجاح.",
      active: "تم تنشيط {{value}} بنجاح.",
      campaigns: "الحملة",
      reports: "التقرير",
      products: "المنتج",
      keywords: "الكلمة المفتاحية",
      negative_keywords: "الكلمة المفتاحية السلبية",
      categories: "الفئة",
      negative_categories: "الفئة السلبية",
      placements: "موقع الإعلان",
      users: "المستخدم",
      advertisers: "المعلن",
      agencies: "الوكالة",
      ad_placements: "مكان الإعلان",
      creatives: "الصورة",
      account_users: "مستخدم",
      statusError: "حدث خطأ.",
      activeReport: "تم أرشفة التقرير بنجاح.",
      archivedReport: "تم إلغاء أرشفة التقرير بنجاح.",
      activeCampaign: "تم أرشفة الحملة بنجاح.",
      pausedCampaign: "تم إيقاف الحملة بنجاح.",
      archivedCampaign: "تم إلغاء أرشفة الحملة بنجاح.",
      activeAgency: "تم أرشفة الوكالة بنجاح.",
      archivedAgency: "تم إلغاء أرشفة الوكالة بنجاح.",
      invitedAdvertiser: "تم أرشفة المعلن بنجاح.",
      activeAdvertiser: "تم إلغاء أرشفة المعلن بنجاح.",
      archivedAdvertiser: "تم أرشفة المعلن بنجاح.",
      archived_ad_placement: "تمت عملية أرشفة مكان الإعلان بنجاح.",
      archived_reports_bulk: "تم أرشفة التقرير بنجاح.",
      archived_campaigns_bulk: "تم أرشفة الحملة بنجاح.",
      archived_users_bulk: "تم أرشفة المستخدم بنجاح.",
      archived_advertisers_bulk: "تم أرشفة المعلن بنجاح.",
      archived_agencies_bulk: "تم أرشفة الوكالة بنجاح.",
      archived_account_users_bulk: "تم أرشفة المستخدم بنجاح.",
      keyword: "يرجى إضافة كلمة مفتاحية واحدة على الأقل.",
      category: "يرجى إضافة فئة واحدة على الأقل.",
      invite: "تم إرسال الدعوة بنجاح.",
      rejected: "تم رفض الإبداع بنجاح.",
      accepted: "تم قبول الإبداع بنجاح.",
      uninvitedAdvertiser: "تم تنشيط المعلن بنجاح.",
      active_account_user: "تم أرشفة المستخدم بنجاح.",
      archived_account_user: "تم إلغاء أرشفة المستخدم بنجاح.",
      advertiserBalanceLowSubject: "رصيدك ينفد!",
      advertiserBalanceLowBody:
        "لقد انخفض رصيدك كمعلن إلى مستوى حرج. اشحن الآن لضمان استمرار عرض إعلاناتك دون انقطاع.",
      campaignKeywordsBelowMinBidSubject:
        "عروض الكلمات المفتاحية منخفضة جدًا في الحملة رقم #{{value}}",
      campaignKeywordsBelowMinBidBody:
        "جميع الكلمات المفتاحية في حملتك (المعرف: {{value}}) تحتوي على عروض أقل من الحد الأدنى المطلوب في السوق. قم بتحديث عروضك لتحسين ظهور حملتك.",
      campaignCategoriesBelowMinBidSubject:
        "عروض الفئات منخفضة جدًا في الحملة رقم #{{value}}",
      campaignCategoriesBelowMinBidBody:
        "جميع الفئات في حملتك (المعرف: {{value}}) تحتوي على عروض أقل من الحد الأدنى المطلوب. قم بتحديث عروضك لتحسين ظهور حملتك.",
      campaignProductsStoppedSubject:
        "المنتجات في الحملة رقم #{{value}} غير نشطة",
      campaignProductsStoppedBody:
        "المنتجات في حملتك (المعرف: {{value}}) متوقفة. فكر في إضافة منتجات جديدة للحفاظ على فعالية حملتك.",
      creativeRejectedSubject: "تم رفض الكرييتيف في الحملة رقم #{{value}}",
      creativeRejectedBody:
        "تم رفض كرييتيف في حملتك (معرّف: {{value}}). يرجى مراجعة الملاحظات وتحديث الكرييتيف ليتوافق مع إرشادات المنصة.",
      marketplaceAdvertiserBalanceLowSubject: "{{value}} لديه ميزانية منخفضة",
      marketplaceAdvertiserBalanceLowBody:
        "لقد انخفض رصيد {{value}} إلى مستوى حرج. اشحن الآن لضمان استمرار عرض إعلاناته دون انقطاع.",
      creativeCreatedSubject: "`قام {{value}} بإضافة إعلان جديد.",
      creativeCreatedBody:
        "`قام {{value}} بإنشاء إعلان جديد. يمكنك مراجعة هذا الإعلان والموافقة عليه في لوحة السوق.",
      balanceAction: "اشحن الرصيد",
      productAction: "أضف منتجات جديدة",
      bidAction: "تحديث العروض",
      creativeAction: "تحديث الكرييتيف",
      reviewAction: "مراجعة",
      segments: "أجزاء",
    },
    suggestedTag: {
      text: "المُقترَح",
    },
    campaignCard: {
      graph: "عرض في الرسم البياني",
      hide: "إخفاء",
      message: "{{value}} يومًا",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "معلومات الكلمة المفتاحية",
        categoryStepTitle: "معلومات الفئة",
        targetingStepTitle: "معلومات الجمهور",
        campaignInfoTitle: "معلومات الحملة",
        campaignInfoText:
          "أدخل اسمًا يظهر في جدول الحملات. لا يتم عرض هذا الاسم للعملاء. نوصي بتحديد اسم مرتبط بالمنتجات التي ترغب في الإعلان عنها.",
        campaignLabel: "اسم الحملة",
        campaignSub: "أدخل اسم الحملة الخاص بك.",
        dateInfoTitle: "تاريخ الحملة",
        dateInfoText: "قم بتحديد الوقت المحدد بحملتك.",
        startDateLabel: "تاريخ البدء",
        startDateSub: "حدد تاريخ بدء الحملة.",
        stopped: "دائم",
        stoppedText: "ستبدأ حملتك اليوم وستستمر حتى تقوم انت بإيقافها.",
        stoppedSub: "تاريخ البدء: {{value}}",
        defined: "تاريخ البدء - تاريخ الانتهاء",
        definedText: "يمكنك تحديد مدة التواريخ الذي ستعمل فيه حملتك.",
        budget: "ميزانية",
        totalBudgetTitle: "حد الميزانية الإجمالية",
        totalBudget: "الميزانية الإجمالية",
        budgetText:
          "حدد نوع الميزانية للحملة وقم بتحديد الميزانية. عندما يصل إنفاقك إلى الميزانية، ستتوقف الحملة تلقائيًا.",
        dailyBudgetLabel: "الميزانية اليومية",
        dailyBudgetExtra:
          "سيكون الإنفاق اليومي لحملتك هو الحد الأقصى للمبلغ الذي تحدده.",
        dailyBudgetFilter: "الميزانية اليومية",
        dailyBudgetText: "يمكنك تحديد الميزانية اليومية الخاصة بك.",
        totalBudgetExtra: "ستنفق حملتك الحد الأقصى للمبلغ الذي تحدده كل يوم.",
        totalBudgetFilter: "الميزانية الإجمالية",
        totalBudgetText: "يمكنك تحديد الميزانية الإجمالية الخاصة بك.",
        flexibleBudgetLabel: "الميزانية اليومية",
        bid: "المزاد",
        bidText:
          "قم بتحديد نوع المزاد الخاص بحملتك. إنفاق الإعلانات الخاص بك هو لكل نقرة بناءً على نوع المزاد الذي تختاره.",
        dynamicBid: "المزاد التلقائي",
        dynamicBidText: "سيتم تحديد إنفاقك لكل نقرة تلقائيًا.",
        targetAcosTitle: "هدف ACoS",
        targetAcosText:
          "اختر كم من مبيعاتك الناتجة عن الإعلانات تريد أن تنفقها على الإعلان.",
        default: "Default",
        advanced: "Advanced",
        fixedBid: "المزاد الثابت",
        fixedBidText:
          "حدد تكلفة ثابتة لكل نقرة للكلمات الرئيسية والفئات. تظهر حملتك للعملاء استنادًا إلى تفضيل الاستهداف الخاص بك.",
        productTitle: "اختيار المنتج",
        productText: "حدد المنتجات التي ترغب في الإعلان عنها.",
        acosTooltip:
          "تعني ACoS تكلفة الإعلان للمبيعة. إنها نسبة تبين كم من أرباحك من المبيعات ترغب في أن تنفقها على الإعلان. على سبيل المثال، في حملة بقيمة ACoS تبلغ 10٪، ستنفق 1 ليرة على الإعلان مقابل كل 10 ليرات من المبيعات.",
        autoTargeting: "الاستهداف التلقائي",
        autoTargetingText:
          "سيتم استهداف الكلمات المفتاحية أو الفئات الأكثر ملاءمة لحملتك تلقائيًا.",
        manualTargeting: "الاستهداف اليدوي",
        manualTargetingText:
          "يمكنك استهداف الكلمات المفتاحية أو الفئات التي ترغب في عرض حملتك.",
        suggestedBidText: "المزاد المقترح: ",
        acosError: "يجب أن يكون ACoS بين 0.0001-100 .",
        creativeTitle: "معلومات الصورة",
        videoText: "يمكنك تحميل الصور التي ترغب في استخدامها في حملتك.",
        urlTitle: "عنوان URL لإعادة التوجيه",
        urlText:
          "هذه هي الصفحة التي سيتم توجيه المستخدمين إليها عند النقر على إعلانك. يجب عليك توجيه المستخدمين إلى صفحة داخل السوق.",
        displayDraggerTitle:
          "<strong>قم بسحب وإفلات صورة، أو </strong> <0>تصفح</0>",
        creativeLabel: "اسم الصورة",
        size: "الحجم:",
        listWarningSize: "5120KB(5MB)",
        listWarningType: "JPG, PNG, GIF",
        recommendedSizes: "الأحجام الموصى بها",
        allSizeText: "إذا كنت تريد رؤية جميع الأحجام المتوفرة على المنصة",
        visit: "زيارة",
        allowedFileTypes: "أنواع الملفات المسموح بها:",
        maximumFileSize: "الحد الأقصى لحجم الملف:",
        modalTitle: "أبعاد الصورة",
        modalText:
          "يمكنك تحميل الصورة بجميع الأحجام المذكورة. تحميل الصورة بالأحجام الموصى بها يحسن أداء إعلانك.",
        recommended: "الحجم الموصى به",
        accepted: "الأحجام المقبولة",
        creativeError: "يرجى إدخال اسم الصورة.",
        urlError: "يرجى إدخال عنوان URL.",
        budgetError: "يجب أن تكون ميزانيتك أكبر من {{value}}.",
        budgetErrorMessage: "الرجاء إدخال ميزانيتك.",
        invalid: "حجم غير صالح",
        advancedOptions: "خيارات متقدمة",
        placements: "مكان الإعلان",
        placementsText: "قرر في أي اماكن ترغب في عرض إعلانك.",
        in_search: "نتائج البحث",
        in_search_text: "تُعرض إعلاناتك في نتائج البحث ذات الصلة.",
        in_product_detail_page: "صفحات تفاصيل المنتج",
        in_product_detail_page_text:
          "تُعرض إعلاناتك على صفحات تفاصيل المنتج ذات الصلة.",
        in_category: "الفئات",
        in_category_text: "تُعرض إعلاناتك على صفحات الفئات ذات الصلة.",
        in_home_page: "الصفحة الرئيسية",
        in_home_page_text: "تُعرض إعلاناتك على الصفحة الرئيسية.",
        in_collection: "مجموعة",
        in_collection_text: "يتم عرض إعلاناتك على صفحات المجموعة ذات الصلة.",
        durationText:
          "إذا وصلت حملتك إلى هدف الإنفاق اليومي كل يوم، فستظل نشطة لمدة <strong>{{value}} يومًا</strong>",
        dayParting: "تحديد الأيام",
        dayPartingText: "حدد الأيام والأوقات التي ستظل فيها حملتك نشطة.",
        allTimes: "جميع الأوقات",
        allTimesText: "سيتم عرض حملتك في جميع الأيام وجميع الأوقات.",
        scheduled: "اختر نطاق التاريخ",
        scheduledText: "سيتم عرض حملتك في الأيام والأوقات المحددة.",
        dayPartingTableText:
          "يرجى تحديد الأيام والفترات الزمنية التي تريدها في المربعات أدناه. إذا كنت ترغب في اختيار جميع الفترات الزمنية خلال يوم واحد، يمكنك اختيار 'طوال اليوم'.",
        allDay: "طوال اليوم",
        clear: "مسح التحديدات",
        videoSideTitle: "إضافة إبداع",
        videoSideText: "حدد أنواع الإبداعات وعيّن تنسيقاتها.",
        firstDrawerTitle: "الخطوة 1: اختر نوع الإبداع",
        creativeTypeTitle: "نوع الإبداع",
        creativeTypeText: "اختر نوع الإبداع المناسب لإعلانك.",
        mp4Title: "إنشاء عبر ملف الفيديو",
        mp4Text: "يمكنك إنشاء إبداعك باستخدام ملف فيديو بامتداد .MP4.",
        vast: "وسم VAST",
        vast_drawer: "أضف وسم VAST للفيديو الذي سيظهر في إعلاناتك.",
        vastTitle: "إنشاء باستخدام وسم VAST",
        vastText: "يمكنك إنشاء إبداعك باستخدام وسم VAST المدعوم بالبيانات.",
        creativeLanguageTitle: "لغة الإعلان",
        creativeLanguageText: "حدد اللغات لإعلانك الإبداعي.",
        creativeLanguageSearch: "ابحث عن لغة",
        autoTargetingProductWarning:
          "لتمكين الاستهداف التلقائي، يجب تحديد <strong>“1”</strong> منتج على الأقل من خطوة <strong>المنتجات المرتبطة</strong>.",
        connectedProductsTitle: "المنتجات المرتبطة",
        connectedProductsText:
          "المنتجات المرتبطة بالإعلانات الإبداعية أعلاه، التي تم اختيارها خصيصًا لك.",
        showProducts: "عرض المنتجات المرتبطة",
        showProductsText:
          "قم بتفعيل هذا الخيار لإضافة المنتجات المرتبطة بإعلاناتك الإبداعية. ربط المنتجات ذات الصلة بإعلاناتك يساعد على تحسين ملاءمة الإعلانات.",
        promoteProduct: "الترويج للمنتجات",
        promoteProductText:
          "تضمن هذه الميزة عرض المنتجات المضافة إلى حملتك في موقع بارز.",
        promoteProductWarning:
          "يرجى ملاحظة أنه بمجرد اختيار هذا الخيار، لا يمكن تغييره.",
        closeProducts: "إغلاق المنتجات المرتبطة",
        closeProductsText:
          "لا يُنصح بإيقاف استخدام المنتجات المرتبطة بإعلاناتك الإبداعية، حيث قد يؤدي ذلك إلى تقليل ملاءمة إعلاناتك.",
        spendingTitle: "توزيع الإنفاق",
        spendingText:
          "حدد توزيع الوقت وشدة كيفية إنفاق ميزانية الإعلانات الخاصة بك.",
        spendingSub: "حدد توزيع الإنفاق الخاص بك.",
        asap: "بأسرع ما يمكن",
        front_loaded: "محمل أمامياً",
        evenly: "بالتساوي",
        frontPercentage: "النسبة المئوية",
        multiRegion: "اختيار المنطقة",
        multiRegionText: "يمكنك تشغيل حملتك في مناطق متعددة أو محددة.",
        allRegions: "جميع المناطق",
        allRegionsText: "ستُنشر حملتك في جميع المناطق.",
        selectedRegions: "المناطق المحددة",
        selectedRegionsText: "ستُنشر حملتك فقط في المناطق المحددة.",
        regionPlaceholder: "اختر المناطق",
        evenlyText:
          "تتقدم الحملة بثبات مع التركيز بشكل متساوٍ على جميع الفترات الزمنية.",
        asapText:
          "يتم إنفاق الميزانية بسرعة للحصول على نتائج سريعة في وقت مبكر من الحملة.",
        front_loadedText:
          "يتم تركيز جزء كبير من الجهود في البداية، ثم يتم تخفيفها تدريجيًا للحفاظ على الوجود مع مرور الوقت.",
        displayUploadTitle: "إبداع عبر ملف صورة",
        displayUploadText:
          "يمكنك إنشاء الإبداع الخاص بك باستخدام ملف .JPEG أو .PNG أو .GIF.",
        display_upload_second_drawer_title: "الخطوة 2: الإضافة عبر ملف صورة",
        display_upload: "رفع صورة",
        display_upload_drawer: "قم برفع ملف الصورة لعرضه في إعلاناتك.",
        changeDisplay: "تغيير الصورة",
        displayErrorTitle: "هناك خطأ في الصورة!",
        displayErrorText: "الصورة التي تحاول تحميلها ذات أبعاد غير صحيحة.",
        newDisplay: "قم بتحميل صورة جديدة",
        bulk: "تحميل بالجملة",
        scriptUploadTitle: "إنشاء إعلان باستخدام كود سكربت",
        scriptUploadText: "يمكنك إنشاء إعلانك باستخدام كود السكربت.",
        script: "كود السكربت",
        script_drawer: "أدخل الكود الذي تريد عرضه في إعلاناتك.",
        creativeSizeTitle: "حجم الإعلان",
        creativeSizeText: "اختر حجم الإعلان لإبداعك.",
        creativeSizeSearch: "اختر الحجم",
        video_upload_second_drawer_title: "الخطوة 2: أضف باستخدام ملف فيديو",
        creativeNameTitle: "اسم الإبداع",
        creativeNameText: "أدخل اسمًا لإبداعك.",
        creativeVideoLabel: "أدخل اسم الإبداع.",
        video_upload: "تحميل فيديو",
        video_upload_drawer: "قم بتحميل ملف الفيديو لعرضه في إعلاناتك.",
        videoDraggerTitle:
          "<strong>قم بسحب وإفلات فيديو، أو </strong> <0>تصفح</0>",
        maxVideoSize: "10240KB(10MB)",
        videoFormat: "MP4",
        vast_second_drawer_title: "الخطوة 2: أضف باستخدام علامة VAST",
        script_second_drawer_title: "الخطوة 2: أضف باستخدام كود النص البرمجي",
        code: "تحرير الكود",
        preview: "معاينة",
        errorStates: {
          name: "يرجى إدخال اسم الحملة.",
          keywordErr: "يجب أن تكون الكلمة المفتاحية فريدة.",
          advertiserErr: "المعلن غير نشط.",
          placementsError: "يرجى تحديد مكان إعلان واحد على الأقل.",
          productLimitErr: "يمكنك إضافة ما يصل إلى 250 منتجًا.",
          sameKeyword:
            "لا يمكن استخدام الكلمة الرئيسية نفسها في الكلمات الرئيسية المستهدفة والكلمات الرئيسية السلبية في نفس الوقت",
          duplicateKeyword: "تمت إضافة الكلمة الرئيسية بالفعل إلى الحملة",
          duplicateNegativeKeyword:
            "تمت إضافة الكلمة الرئيسية السلبية بالفعل إلى الحملة",
          generalErr: "حدث خطأ.",
          keywordLimitErr: "يمكن إضافة 100 كلمة مفتاحية كحد أقصى إلى الحملة.",
          categoryLimitErr: "يمكن إضافة 100 فئة كحد أقصى إلى الحملة.",
          frontPercentage: "يجب أن تكون النسبة المحملة أمامياً بين 0 و 100.",
          creativeDimensionErr: "يرجى تحميل إعلان بحجم الأبعاد الصحيح.",
          languageErr: "الرجاء إضافة لغة.",
          sizeErr: "الرجاء اختيار حجم.",
          script:
            "كرييتيف HTML غير صالح. تأكد من أنه يحتوي على وسم <meta> يتضمن خاصية content بالتنسيق width={value},height={value}، على سبيل المثال: <meta content='width=300,height=250'>.",
        },
      },
      secondStep: {
        wordTargeting: "استهداف الكلمات المفتاحية",
        wordTargetingText: "حدد في أي كلمات مفتاحية ستظهر إعلاناتك.",
        categorySub: "قرر في أي فئات ترغب في عرض إعلانك.",
        autoTargeting: "استهداف تلقائي",
        autoTargetingText:
          "سيتم عرض إعلانك في الكلمات المفتاحية والفئات الأكثر صلة. يمكنك إيقاف الكلمات المفتاحية من صفحة تفاصيل الحملة.",
        manualTargeting: "استهداف يدوي",
        manualTargetingText: "يمكنك تعيين كلمات مفتاحية لإعلانك.",
        defaultBid: "مزايدة افتراضية",
        keywordTitle: "استهداف الكلمات المفتاحية",
        keywordText: "أضف الكلمات المفتاحية التي سيتم عرض إعلانك فيها.",
        negativeTitle: "كلمات مفتاحية سلبية",
        negativeText: "أضف الكلمات المفتاحية التي لا ترغب في عرض إعلانك فيها.",
        placementTitle: "نوع الاستهداف",
        placementText:
          "يمكنك استهداف الكلمات المفتاحية أو الفئات المعينة التي ستظهر فيها حملتك.",
        search: "استهداف الكلمات المفتاحية",
        searchText: "استهدف الكلمات المفتاحية التي ترغب في أن تظهر حملتك فيها.",
        categories: "استهداف الفئات",
        categoriesText: "استهدف الفئات التي ترغب في أن تظهر حملتك عليها.",
        categoryTargeting: "استهداف",
        categoryTargetingTitle: "حدد في أي فئات ستظهر إعلاناتك.",
        categoryTargetingText: "أضف الفئات التي ستظهر فيها إعلاناتك.",
        category: "استهداف الفئات",
        negativeCategoryTitle: "الفئات السلبية",
        negativeCategoryText: "أضف الفئات التي لا ترغب في عرض إعلانك فيها.",
        autoCategoryText: "سيتم عرض إعلانك في الفئات الأكثر ملاءمة.",
        manualCategoryText: "يمكنك تعيين الفئات التي تستهدفها إعلانك.",
        placement: "استهداف",
        targetingText: "يمكنك تعيين استراتيجية الاستهداف الخاصة بحملتك.",
        bulkUploadCardTitle: "إضافة الكلمات المفتاحية بالجملة",
        bulkUploadCardSubtitle:
          "حدد ملفك وأضف جميع الكلمات المفتاحية بنقرة واحدة.",
        bulkUploadTabText: "تحميل بالجملة",
        addManualTabText: "إضافة يدوية",
        bulkUpload: "تحميل الكلمات المفتاحية بالجملة",
        reUpload: "إعادة التحميل",
        uploadInfoText: "يوجد إجمالي <strong>{{count}} كلمة مفتاحية</strong>.",
        generalInformations: "معلومات عامة",
        csvTemplateInstruction:
          "الرجاء استخدام <strong>قالب CSV</strong> لضمان تحميل معلوماتك بشكل دقيق.",
        downloadCsvButton: "تحميل قالب CSV",
        formattingRules: "قواعد التنسيق:",
        ruleAlphanumeric:
          "استخدم فقط الأحرف <strong>الأبجدية الرقمية</strong> (بدون رموز خاصة).",
        ruleMatchType:
          "نوع التطابق يجب أن يكون <strong> EXACT</strong>، <strong> PHRASE</strong>، أو <strong> BROAD</strong>.",
        ruleBidValue:
          "يجب أن تكون قيمة العرض <strong>أعلى</strong> من الحد الأدنى للعرض.",
        minimumBidValue: "قيمة العرض الأدنى:",
        uploadFileTitle: "تحميل الملف",
        uploadFileSubtitle:
          "قم بتحميل الملف لإضافة الكلمات المفتاحية الخاصة بك.",
        uploadDraggerText: "قم بسحب وإفلات ملفك، أو <strong>تصفح</strong>",
        uploadDraggerFileType: "أنواع الملفات المسموح بها: CSV",
        switchTabModal: {
          title: {
            manual: "التبديل إلى الإدخال اليدوي؟",
            bulk: "التبديل إلى التحميل بالجملة؟",
          },
          warning: {
            manual:
              "أنت على وشك التبديل من التحميل بالجملة إلى إدخال الكلمات المفتاحية يدوياً. إذا تابعت، سيتم حذف جميع الكلمات المفتاحية المحملة بالجملة ولا يمكن استعادتها.",
            bulk: "أنت على وشك التبديل من الإدخال اليدوي إلى التحميل بالجملة. إذا تابعت، سيتم حذف جميع الكلمات المفتاحية المُضافة يدوياً ولا يمكن استعادتها.",
          },
          proceedQuestion: "هل أنت متأكد من رغبتك في المتابعة؟",
        },
        switchTabConfirmation: {
          deletion: {
            manual: "أدرك أنه سيتم حذف جميع الكلمات المفتاحية المحملة بالجملة.",
            bulk: "أدرك أنه سيتم حذف جميع الكلمات المفتاحية المُضافة يدوياً.",
          },
        },
        errorStates: {
          csvFileNotRead: "خطأ في قراءة ملف CSV.",
          invalidCSVHeader: "رأس ملف CSV غير صالح.",
          invalidCSVFile: "ملف CSV غير صالح.",
          invalidCSVBidValue: "قيمة عرض CSV غير صالحة.",
          bidNotGreaterThanMin: "قيمة العرض يجب أن تكون أعلى من الحد الأدنى.",
          invalidBidType: "نوع العرض غير صالح.",
          generalErr: "حدث خطأ.",
        },
      },
      thirdStep: {
        targeting: "اختيار الجمهور",
        targetingText: "حدد أي جمهور ترغب في أن يظهر إعلانك له.",
        autoTargeting: "استهداف تلقائي",
        autoTargetingText:
          "سيتم عرض إعلانك في الكلمات المفتاحية والفئات الأكثر صلة.",
        manualTargeting: "استهداف يدوي",
        manualTargetingText: "يمكنك تحديد الجمهور الذي ترغب في عرض منتجاتك له.",
        platform: "استهداف النظام",
        platformText: "حدد أي مستخدمي النظام ترغب في أن يظهر إعلانك لهم.",
        tablet: "التابلت",
        mobile: "الجوال",
        app: "تطبيق الجوال",
        desktop: "الكمبيوتر",
        age: "استهداف نطاق العمر",
        ageText: "حدد في أي مجموعة عمرية ترغب في أن يظهر إعلانك.",
        city: "استهداف المدينة",
        cityText: "حدد في أي مدن ترغب في أن يظهر إعلانك.",
        allCities: "كل المدن",
        selectedCities: "المدن المختارة",
        cityPlaceholder: "حدد المدينة",
        customer: "استهداف العملاء",
        customerText:
          "حدد العملاء الذين ترغب في أن يظهروا إعلانك بناءً على تاريخ تسوقهم.",
        presentCustomers: "العملاء الحاليين",
        presentCustomersText:
          "استهدف العملاء الذين قاموا بالفعل بطلب المنتجات التي تعلن عنها.",
        newCustomers: "العملاء الجدد",
        newCustomersText: "استهدف العملاء الذين لم يطلبوا منتجاتك من قبل.",
        gender: "استهداف الجنس",
        genderText: "حدد أي جنس ترغب في أن يظهر إعلانك له.",
        female: "إناث",
        male: "ذكور",
        audience: "اختيار الجمهور",
        audienceText: "حدد الجمهور الذي تريد عرض إعلانك له.",
        segment: "استهداف القطاعات",
        segmentText: "حدد القطاعات التي تريد عرض إعلانك لها.",
        allSegments: "جميع القطاعات",
        selectedSegments: "القطاعات المحددة",
        segmentPlaceholder: "اختر القطاع",
      },
    },
    columnPopover: {
      title: "تعديل ال",
    },
    empty: {
      product_title: "الرجاء قم بتحديد المنتج.",
      dimensions_title: "لم تقم بإضافة أبعاد بعد.",
      product_text:
        "يمكنك إضافة المنتجات التي ترغب في الإعلان عنها بالنقر فوق زر الإضافة في قائمة المنتجات على اليمين.",
      dimensions_text: "يمكنك إضافة أبعاد لتخصيص مستوى تفصيل نتائج حملتك.",
      null_title: "لا توجد بيانات",
      null_text: "لا توجد بيانات في الجدول.",
      categories_title: "الرجاء إضافة الفئات التي ستستهدفها.",
      categories_text:
        "حدد الفئات التي ترغب في ظهور إعلانك فيها عن طريق إدخال فئة أو النقر فوق زر الإضافة في جدول الفئات المقترحة.",
      keywords_title: "الرجاء إضافة الكلمات الرئيسية التي ستستهدفها.",
      keywords_text:
        "حدد الكلمات الرئيسية التي ترغب في ظهور إعلانك من خلال إدخال كلمة أو النقر فوق زر الإضافة في جدول الكلمات الرئيسية المقترحة.",
      negative_keywords_title: "لم تقم بإضافة أي كلمات رئيسية سلبية بعد.",
      negative_keywords_text:
        "يمكنك تحديد الكلمات الرئيسية السلبية التي لا ترغب في ظهور إعلانك عنها من خلال إدخال كلمة.",
      negative_categories_title: "لم تقم بإضافة أي فئات سلبية بعد.",
      negative_categories_text:
        "يمكنك تحديد الفئات السلبية التي لا ترغب في ظهور إعلانك فيها.",
      campaigns_table_title: "لم تقم بإنشاء أي حملة بعد",
      campaigns_table_text:
        "يمكنك إنشاء أول حملة لك بالنقر على زر إنشاء حملة جديدة.",
      reports_table_title: "لم تقم بإنشاء أي تقرير بعد",
      reports_table_text:
        "يمكنك إنشاء أول تقرير لك بالنقر على زر إنشاء تقرير جديد.",
      invoices_table_title: "لم تقم بتحميل أي رصيد حتى الآن",
      invoices_table_text:
        "يمكنك إضافة رصيد إعلاني عن طريق الاتصال بمدير حسابك.",
      advertisers_table_title: "لا يوجد معلن مرتبط بحسابك",
      advertisers_table_text: "الرجاء التواصل مع مدير حسابك.",
      agencies_table_title: "لم تقم بإنشاء وكالة بعد",
      agencies_table_text:
        "يمكنك إنشاء وكالة جديدة بالنقر على زر إنشاء وكالة جديدة.",
      transactions_table_title: "لم تقم بتحميل أي رصيد حتى الآن",
      transactions_table_text:
        "يمكنك إضافة رصيد إعلاني إلى قائمة معلنيك بالنقر على زر تحميل الرصيد.",
      coupons_table_title: "لم تقم بإنشاء أي قسائم كوبون بعد",
      coupons_table_text:
        "يمكنك تعريف قسائم كوبون لمعلنيك بالنقر على زر تعريف قسيمة جديدة.",
      account_users_table_title: "لم تقم بإنشاء مستخدم بعد",
      account_users_table_text:
        "يمكنك إنشاء مستخدم جديد بالنقر على زر إنشاء مستخدم جديد.",
      archived_table_reports_title: "لا توجد تقارير مؤرشفة",
      archived_table_campaigns_title: "لا توجد حملات مؤرشفة",
      archived_table_agencies_title: "لا توجد وكالات مؤرشفة",
      archived_table_account_users_title: "لا توجد مستخدمين مؤرشفين",
      archived_table_advertisers_title: "لا توجد معلنين مؤرشفين",
      archived_table_ad_placements_title: "لا توجد مواقع إعلانية مؤرشفة",
      invited_table_users_title: "لا توجد مستخدمين مدعوين",
      searched_table_title: "لم يتم العثور على سجلات تطابق بحثك.",
      searched_table_text: "",
      searched_title: "لم يتم العثور على سجلات تطابق بحثك.",
      searched_text: "",
      products_table_title: "لم تقم بإضافة أي منتجات بعد.",
      products_table_text:
        "يمكنك إضافة المنتجات التي ترغب في الإعلان عنها بالنقر على زر إضافة منتجات.",
      keywords_table_title: "لم تقم بإضافة أي كلمات رئيسية بعد.",
      keywords_table_text:
        "يمكنك تحديد الكلمات الرئيسية التي ترغب في ظهور إعلانك عنها من خلال إدخال كلمة.",
      categories_table_title: "لم تقم بإضافة أي فئات بعد.",
      categories_table_text:
        "يمكنك تحديد الفئات التي ترغب في ظهور إعلانك عنها من خلال إدخال كلمة.",
      negative_keywords_table_title: "لم تقم بإضافة أي كلمات رئيسية سلبية بعد.",
      negative_keywords_table_text:
        "يمكنك تحديد الكلمات الرئيسية السلبية التي لا ترغب في ظهور إعلانك عنها من خلال إدخال كلمة.",
      negative_categories_table_title: "لم تقم بإضافة أي فئات سلبية بعد.",
      negative_categories_table_text:
        "يمكنك تحديد الفئات السلبية التي لا ترغب في ظهور إعلانك عنها.",
      sync_logs_table_title: "لا توجد بيانات تاريخ المزامنة",
      sync_logs_table_text: "لم يتم العثور على بيانات تاريخ المزامنة.",
      ad_placements_table_title: "لم تقم بإضافة أي مواقع إعلانية بعد.",
      ad_placements_table_text:
        "يمكنك إضافة مواقع إعلانية بالنقر فوق زر إضافة موقع إعلاني.",
      creatives_table_title: "لم يتم إضافة أي إبداعات بعد!",
      creatives_table_text:
        "يمكنك عرض الإبداعات المضافة في وقت لاحق في هذا المجال.",
      creatives_relations_table_title: "لم تقم بإنشاء إبداع بعد.",
      creatives_relations_table_text: "يمكنك إنشاء إبداع جديد من نموذج الحملة.",
      creativeForm_title: "لم تقم بإضافة أي إبداعات بعد.",
      creativeForm_text: "أحجام الإبداع المقترحة:",
      invites_table_title: "لم تقم بدعوة مستخدمين بعد.",
      invites_table_text:
        "يمكنك دعوة مستخدمين بالنقر على زر إضافة مستخدم جديد.",
      review_title: "لا توجد إبداعات حاليًا للمراجعة.",
      review_text:
        "تمت الموافقة على جميع الإبداعات أو لا يوجد إبداع جديد بعد. ستظهر الإبداعات التي تنتظر الموافقة على هذه الشاشة.",
      accepted_table_creatives_title: "لا توجد إبداعات معتمدة بعد!",
      accepted_table_creatives_text:
        "تم رفض جميع الإبداعات أو لا توجد إبداعات جديدة بعد.",
      rejected_table_creatives_title: "لا توجد إبداعات مرفوضة بعد!",
      rejected_table_creatives_text:
        "تمت الموافقة على جميع الإبداعات أو لا توجد إبداعات جديدة بعد.",
      pending_table_creatives_title: "لم يتم إضافة أي إبداع بعد!",
      pending_table_creatives_text:
        "تمت مراجعة جميع الإبداعات أو لا توجد إبداعات جديدة بعد.",
      blacklist_table_title: "لا يوجد قيود على الكلمات الرئيسية.",
      blacklist_table_text:
        "يمكنك إضافة قيد عن طريق النقر على زر إضافة كلمة مفتاحية جديدة.",
      cities_table_title: "لم تقم بإضافة أي مدن بعد.",
      cities_table_text: "",
      age_ranges_table_title: "لم تقم بإضافة أي نطاقات عمرية بعد.",
      age_ranges_table_text: "",
      genders_table_title: "لم تقم بإضافة أي أنواع جنسية بعد.",
      genders_table_text: "",
      platforms_table_title: "لم تقم بإضافة أي منصات بعد.",
      platforms_table_text: "",
      customers_table_title: "لم تقم بإضافة أي عملاء بعد.",
      customers_table_text: "",
      report_loading_title: "جاري إنشاء التقرير",
      report_loading_text:
        "يتم حاليًا إنشاء تقريرك. قد يستغرق هذا الأمر بعض الوقت اعتمادًا على تعقيد وحجم التقرير.",
      data_sources_table_title: "لم يتم العثور على مصادر بيانات",
      data_sources_table_text:
        "لا توجد أي مصادر بيانات في الوقت الحالي. أضف مصدر بيانات لبدء رؤية تفاصيل الاستيراد والتاريخ.",
      logs_title: "لم يتم العثور على سجلات",
      logs_text:
        "لا توجد حاليًا سجلات مسجلة تحت هذه الفئة. بمجرد حدوث الأحداث ذات الصلة، ستظهر السجلات هنا.",
      segments_table_title: "لم تقم بإضافة أي قطاعات بعد.",
      segments_table_text: "",
      xmeta_campaigns_table_title: "لم تقم بإنشاء أي حملات بعد.",
      meta_campaigns_table_text:
        "يمكنك إنشاء حملتك الأولى بالنقر فوق زر إضافة حملة جديدة.",
    },
    endDatePicker: {
      label: "تاريخ الانتهاء",
      campaigns: "حدد تاريخ انتهاء الحملة.",
      reports: "حدد تاريخ انتهاء التقرير.",
      button: "بلا تاريخ انتهاء",
    },
    reportForm: {
      title: "معلومات التقرير",
      nameTitle: "اسم التقرير",
      nameText: "أدخل اسمًا يظهر في جدول التقارير.",
      nameSub: "أدخل اسم تقريرك.",
      reportTypeTitle: "التسليم",
      reportTypeText:
        "حدد نوع التسليم الخاص بتقريرك. سيتم تسليم التقارير التي قمت بإنشائها وفقًا لتفضيلاتك.",
      static: "ثابت",
      staticText: "يتم تشغيل التقرير مرة واحدة ويتم عرضه في التقارير.",
      scheduled: "مجدول",
      scheduledText: "حدد التكرار ومستلمي تقريرك.",
      frequency: "اختر تكرار التقرير.",
      daily: "يوميًا",
      weekly: "أسبوعيًا",
      monthly: "شهريًا",
      sendDate: "اختر التاريخ الذي ترغب في استلام أول تقرير لك.",
      firstDay: "اليوم الأول من الشهر",
      lastDay: "اليوم الأخير من الشهر",
      exportType: "اختر الصيغة التي ترغب في استلام التقرير بها.",
      mail: "أدخل عناوين البريد الإلكتروني التي سيتم إرسال التقرير إليها.",
      dateTitle: "تاريخ التقرير",
      dateText: "حدد تاريخ بداية ونهاية التقرير.",
      startDateSub: "حدد تاريخ بدء التقرير.",
      reportKindTitle: "نوع التقرير",
      reportKindText:
        "يمكنك قياس الأداء بسهولة مع التقارير المحددة مسبقًا. بالتقرير المخصص، يمكنك إنشاء تقارير مخصصة عن طريق إضافة أبعاد ومقاييس ومرشحات.",
      predefined: "تقرير محدد مسبقًا",
      predefinedPlaceholder: "اختر نوع التقرير المحدد مسبقًا",
      special: "تقرير مخصص",
      breaks: "الأبعاد",
      breaksText: "حدد الأبعاد التي ترغب في تضمينها في تقريرك.",
      breaksPlaceholder: "ابحث عن الأبعاد",
      maxWarning: "*يمكنك إضافة ما يصل إلى 4 أبعاد إلى تقريرك.",
      clear: "مسح",
      metrics: "المقاييس",
      metricsText: "حدد المقاييس التي ترغب في تضمينها في تقريرك.",
      filters: "الفيلترز:",
      filtersText: "حدد الفيلترز التي ترغب في تطبيقها على الأبعاد والمقاييس.",
      product: "أداء المنتج",
      product_category: "أداء الفئة",
      product_brand: "أداء العلامة التجارية",
      keyword: "أداء الكلمة المفتاحية",
      campaign: "أداء الحملة",
      advertiser: "أداء المعلن",
      agency: "أداء الوكالة",
      errorStates: {
        date: "يرجى تحديد تاريخ.",
        mail: "الرجاء إدخال بريد إلكتروني.",
        type: "الرجاء تحديد نوع التقرير.",
        metric: "يرجى تحديد مقياس واحد على الأقل.",
      },
      dimensions: {
        campaignId: "معرّف الحملة",
        campaignName: "اسم الحملة",
        campaignBidType: "نوع المزايدة",
        campaignBudgetType: "نوع الميزانية",
        product: "المنتج",
        categoryName: "صفحات الفئة",
        keywordText: "الكلمة المفتاحية",
        productBrand: "علامة المنتج",
        productName: "المنتج",
        day: "يوم",
        week: "أسبوع",
        month: "شهر",
        year: "سنة",
        hour: "ساعة",
        advertiser: "المعلن ID",
        advertiserName: "المعلن",
        agency: "الوكالة ID",
        agencyName: "الوكالة",
        customerDeviceType: "نوع جهاز العميل",
        saleProductName: "منتج البيع غير المباشر",
        placementId: "معرّف مكان الإعلان",
        customerType: "نوع العميل",
        productRetailerTaxonomy: "فئة المنتج",
        adPlacementCustomKey: "معرّف مكان الإعلان",
        adPlacementName: "اسم مكان الإعلان",
        placementText: "نص مكان الإعلان",
        in_search: "نتائج البحث",
        in_category: "صفحات الفئة",
        in_product_detail_page: "صفحات تفاصيل المنتج",
        in_home_page: "الصفحة الرئيسية",
        campaignAdType: "نوع الحملة",
        campaignBudget: "ميزانية الحملة",
        campaignFixedBid: "مزاد السعر الثابت للحملة",
        keywordBid: "مزاد الكلمة المفتاحية",
        categoryBid: "مزاد الفئة",
        advertiserResourceID: "البائع",
        productSKU: "المنتج",
        customerDeviceEnvironmentType: "نوع جهاز المشتري",
        campaignDailyBudget: "الميزانية اليومية",
        campaignTotalBudget: "الميزانية الإجمالية",
      },
      metric: {
        sumImpression: "الانطباعات",
        sumViewableImpression: "انطباع قابل للعرض",
        sumClick: "النقرات",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "المبيعات",
        ACoS: "ACoS",
        sumDirectSale: "المبيعات المباشرة",
        sumIndirectSale: "المبيعات غير المباشرة",
        sumDirectSaleAmount: "مبلغ المبيعات المباشرة",
        sumIndirectSaleAmount: "مبلغ المبيعات غير المباشرة",
        sumSaleAmount: "مبلغ المبيعات",
        sumSpend: "الإنفاق",
        sale: "المبيعات",
        sumVideoStart: "بداية الفيديو",
        sumVideoComplete: "اكتمال الفيديو",
        VCR: "VCR",
        viewabilityRate: "معدل الظهور",
        deliveryRate: "معدل عرض الإعلانات",
        adResponseYieldRate: "معدل عائد استجابة الإعلانات",
        fillRate: "معدل الإشغال",
        sumPostClickSale: "المبيعات بعد النقر",
        sumPostViewSale: "المبيعات بعد العرض",
        sumPostClickSaleAmount: "مبلغ المبيعات بعد النقر",
        sumPostViewSaleAmount: "مبلغ المبيعات بعد العرض",
        sumPostClickDirectSale: "المبيعات المباشرة بعد النقر",
        sumPostClickIndirectSale: "المبيعات غير المباشرة بعد النقر",
        sumPostViewDirectSale: "المبيعات المباشرة بعد العرض",
        sumPostViewIndirectSale: "المبيعات غير المباشرة بعد العرض",
        sumPostClickDirectSaleAmount: "مبلغ المبيعات المباشرة بعد النقر",
        sumPostClickIndirectSaleAmount: "مبلغ المبيعات غير المباشرة بعد النقر",
        sumPostViewDirectSaleAmount: "مبلغ المبيعات المباشرة بعد العرض",
        sumPostViewIndirectSaleAmount: "مبلغ المبيعات غير المباشرة بعد العرض",
        ACoSByClick: "ACoS بعد النقر",
        ACoSByView: "ACoS بعد العرض",
        RoASByClick: "RoAS بعد النقر",
        RoASByView: "RoAS بعد العرض",
        CVRByClick: "معدل التحويل بعد النقر",
        CVRByView: "معدل التحويل بعد العرض",
      },
    },
    selectFilter: {
      title: "خيارات الفلترة",
      placeholder: "اختر الفلتر",
      budgetOpt: "الميزانية المتبقية",
      budgetAdvertiser: "اسم المعلن",
      placeholderCondition: "اختر الشرط",
      bigger: "أكبر",
      smaller: "أصغر",
      equal: "مساوٍ",
      notEqual: "غير مساوٍ",
      any: "يحتوي على",
      add: "إضافة فلتر",
      placeholderValue: "اختر القيمة",
      enter: "أدخل القيمة",
      date: "اختر التاريخ",
      reportsText: "حدد الفلتر الذي تريد تطبيقه على تقريرك.",
    },
    searchBar: {
      keywords: "إضافة كلمات مفتاحية...",
      negative_keywords: "إضافة كلمات مفتاحية سلبية...",
      categories: "إضافة فئات...",
      negative_categories: "إضافة فئات سلبية...",
    },
    settingsHeader: {
      general: "معلومات عامة",
      generalText: "معلومات عامة حول السوق",
      integration: "معلومات التكامل",
      integrationText: "معلومات التكامل اللازمة لأداء عمليات نقل البيانات",
      advertiser: "إعدادات المعلن",
      advertiserText: "إعدادات متعلقة بالمعلنين.",
      auction: "إعدادات المزاد",
      auctionText: "إعدادات متعلقة بعمليات المزاد",
      campaign: "إعدادات الحملة",
      campaignText: "إعدادات متعلقة بإعداد الحملة",
      bid: "قيود المزايدة",
      bidText: "إعدادات لقواعد المزايدة على مستوى المنصة",
      conversion: "التحويل",
      conversionText: "إعدادات متعلقة بأهداف التحويل على المنصة",
      category: "قيود الفئة",
      categoryText: "قيود على الفئات المعروضة على منصتك",
      keyword: "قيود الكلمات الرئيسية",
      keywordText: "قيود على الكلمات الرئيسية التي يتم البحث عنها على منصتك",
      offsite: "إعدادات خارج الموقع",
      offsiteText: "",
    },
    stepsNavbar: {
      report_create: "إنشاء تقرير",
      campaign_create: "إنشاء حملة",
      report_edit: "تحديث التقرير",
      step: "الخطوة التالية هي {{value}}",
      advertiser: "المعلن:",
    },
    suggestionCard: {
      addAll: "إضافة كل شيء",
    },
    table: {
      keywords: {
        keyword: "كلمة مفتاحية",
        matchType: "نوع التطابق",
        suggested: "المزايدة المقترحة",
        dynamic: "تلقائي",
        volume: "حجم البحث",
        competition: "المنافسة",
        search: "إضافة الكلمات المفتاحية...",
        suggestTitle: "الكلمات المفتاحية المقترحة",
        suggestText: "الكلمات المفتاحية الموصى بها لمنتجاتك.",
        negativePlaceholder: "إضافة الكلمات السلبية...",
        exact: "مصطلحات بحث تطابق كلمتك المفتاحية بالضبط.",
        phrase: "مصطلحات بحث تحتوي على مكونات من كلمتك المفتاحية بنفس الترتيب.",
        broad: "مصطلحات بحث تحتوي على مكونات من كلمتك المفتاحية بأي ترتيب.",
        warning: "يجب أن يكون القيمة الدنيا {{value}} على الأقل.",
      },
      products: {
        search: "البحث عن SKU أو منتج",
        loading: "جار التحميل",
        title: "يرجى تحديد منتج",
        text: "يمكنك إضافة المنتجات التي ترغب في الإعلان عنها بالنقر على زر الإضافة في قائمة المنتجات على اليمين.",
        selected: "المنتجات المحددة",
        remove: "إزالة الكل",
        price: "السعر",
        stock: "Stock",
        rating: "التقييم",
        drawerTitle: "اختيار السلع بالجملة",
        sku: "قائمة SKU أو الباركود",
        skuText:
          "أضف SKU أو الباركود للمنتج الذي ترغب في إضافته، واحدًا في كل سطر، في الحقل أدناه.",
      },
      categories: {
        suggestTitle: "الفئات الموصى بها",
        suggestText: "الفئات الموصى بها لمنتجاتك",
      },
      result: {
        campaignId: "معرّف الحملة",
        campaignName: "اسم الحملة",
        campaign: "ID الحملة",
        campaignBidType: "نوع العرض",
        campaignBudgetType: "نوع الميزانية",
        advertiserName: "اسم المعلن",
        agencyName: "اسم الوكالة",
        product: "المنتج",
        productName: "المنتج",
        categoryText: "الفئة",
        keyword: "ID الكلمة الرئيسية",
        keywordText: "نص الكلمة الرئيسية",
        keywordMatchType: "نوع التطابق",
        productBrand: "علامة المنتج",
        day: "يوم",
        week: "أسبوع",
        month: "شهر",
        year: "سنة",
        hour: "ساعة",
        sumImpression: "الانطباعات",
        sumViewableImpression: "انطباع قابل للعرض",
        sumClick: "النقرات",
        CTR: "نسبة النقرة إلى الانطباعة",
        CVR: "معدل التحويل",
        sumSale: "المبيعات",
        ACoS: "تكلفة الإعلان إلى إيرادات المبيعات",
        sumDirectSale: "المبيعات المباشرة",
        sumIndirectSale: "المبيعات غير المباشرة",
        sumDirectSaleAmount: "مبلغ المبيعات المباشرة",
        sumIndirectSaleAmount: "مبلغ المبيعات غير المباشرة",
        sumSaleAmount: "مبلغ المبيعات",
        sumSpend: "الإنفاق",
        campaignStartDate: "تاريخ البدء للحملة",
        campaignEndDate: "تاريخ الانتهاء للحملة",
        campaignStatus: "حالة الحملة",
        campaignBudget: "الميزانية للحملة",
        advertiser: "المعلن",
        agency: "الوكالة",
        weekOf: "أسبوع {{value}}",
        customerDeviceType: "نوع جهاز العميل",
        saleProductName: "اسم المنتج للمبيعات غير المباشرة",
        placementId: "ID المكان",
        customerType: "نوع العميل",
        categoryName: "صفحات الفئة",
        productRetailerTaxonomy: "فئة المنتج",
        adPlacementCustomKey: "ID مكان الإعلان المخصص",
        adPlacementName: "اسم مكان الإعلان",
        placementText: "مكان النص",
        in_search: "نتائج البحث",
        in_category: "صفحات الفئة",
        in_product_detail_page: "صفحات تفاصيل المنتج",
        in_home_page: "الصفحة الرئيسية",
        in_collection: "مجموعة",
        campaignAdType: "نوع الحملة",
        campaignFixedBid: "مزاد السعر الثابت للحملة",
        keywordBid: "مزاد الكلمة المفتاحية",
        categoryBid: "مزاد الفئة",
        advertiserResourceID: "البائع",
        productSKU: "المنتج",
        customerDeviceEnvironmentType: "نوع جهاز المشتري",
        campaignDailyBudget: "الميزانية اليومية",
        campaignTotalBudget: "الميزانية الإجمالية",
        sumVideoStart: "بداية الفيديو",
        sumVideoComplete: "اكتمال الفيديو",
        VCR: "VCR",
        viewabilityRate: "معدل الظهور",
        deliveryRate: "معدل عرض الإعلانات",
        adResponseYieldRate: "معدل عائد استجابة الإعلانات",
        fillRate: "معدل الإشغال",
        sumPostClickSale: "المبيعات بعد النقر",
        sumPostViewSale: "المبيعات بعد العرض",
        sumPostClickSaleAmount: "مبلغ المبيعات بعد النقر",
        sumPostViewSaleAmount: "مبلغ المبيعات بعد العرض",
        sumPostClickDirectSale: "المبيعات المباشرة بعد النقر",
        sumPostClickIndirectSale: "المبيعات غير المباشرة بعد النقر",
        sumPostViewDirectSale: "المبيعات المباشرة بعد العرض",
        sumPostViewIndirectSale: "المبيعات غير المباشرة بعد العرض",
        sumPostClickDirectSaleAmount: "مبلغ المبيعات المباشرة بعد النقر",
        sumPostClickIndirectSaleAmount: "مبلغ المبيعات غير المباشرة بعد النقر",
        sumPostViewDirectSaleAmount: "مبلغ المبيعات المباشرة بعد العرض",
        sumPostViewIndirectSaleAmount: "مبلغ المبيعات غير المباشرة بعد العرض",
        ACoSByClick: "ACoS بعد النقر",
        ACoSByView: "ACoS بعد العرض",
        RoASByClick: "RoAS بعد النقر",
        RoASByView: "RoAS بعد العرض",
        CVRByClick: "معدل التحويل بعد النقر",
        CVRByView: "معدل التحويل بعد العرض",
      },
    },
    states: {
      campaigns_not_started: "الحملة لم تبدأ بعد.",
      campaigns_date_passed: "انتهت الحملة عن موعدها المحدد.",
      campaigns_status_paused: "لقد قمت بإيقاف الحملة مؤقتًا.",
      campaigns_out_of_daily_budget: "تم استنفاذ ميزانية حملتك اليومية",
      campaigns_out_of_total_budget:
        "لقد وصلت حملتك إلى الحد الاجمالي للميزانية.",
      campaigns_products_stopped: "المنتجات غير نشطة.",
      campaigns_keywords_stopped: "الكلمات الرئيسية غير نشطة.",
      campaigns_categories_stopped: "الفئات غير نشطة.",
      campaigns_insufficient_balance: "رصيدك غير كافٍ.",
      campaigns_low_bid: "العرض أقل من الحد الأدنى للعطاء.",
      campaigns_status_archived: "لقد قمت بأرشفة الحملة.",
      campaigns_creatives_stopped: "لا توجد إعلانات نشطة.",
      campaigns_advertiser_paused: "المعلن غير نشط.",
      campaigns_regions_stopped: "المناطق غير نشطة.",
      products_status_paused: "قمت بإيقاف المنتج مؤقتًا.",
      products_low_stock: "تم إيقاف الحالة بسبب قيود المخزون.",
      products_low_rating: "تم إيقاف الحالة بسبب قيود التقييم.",
      products_buybox_loser: "تم إيقاف الحالة بسبب قيود Buybox.",
      products_product_paused: "المنتج غير نشط.",
      products_product_deleted: "المنتج غير نشط.",
      keywords_status_paused: "قمت بإيقاف حالة الكلمة الرئيسية مؤقتًا.",
      keywords_low_bid: "العرض أقل من الحد الأدنى.",
      categories_status_paused: "تم إيقاف استهداف الفئات.",
      categories_low_bid: "العرض أقل من الحد الأدنى.",
      creatives_paused: "قمت بإيقاف حالة الإعلان مؤقتًا.",
      creatives_pending: "الصورة في انتظار المراجعة.",
      creatives_rejected: "الصورة تم رفضه.",
      creatives_note: "سبب الرفض:",
    },
    payment: {
      amount: "المبلغ المطلوب:",
      title: "طريقة الدفع",
      subtitle:
        "سيتم إصدار فاتورة لرصيد الإعلان لديك بسعر يشمل ضريبة القيمة المضافة.",
      wire_transfer: "تحويل بنكي / EFT",
      milestone_payment: "دفعة ميزانية",
      bank: "معلومات البنك",
      helpUpper:
        "بعد موافقة مدفوعاتك من خلال التحويل البنكي أو EFT، ستتم العملية. قد تستغرق عملية الموافقة",
      helpSecondary: "2-3 أيام عمل.",
      ref: "Reference No:",
      info: "إدخال رقم المرجع الخاص بك بشكل صحيح في وصف العملية مهم جداً لتتبع العملية.",
      successTitle: "تم استلام طلب تحميل رصيدك!",
      successText:
        "بعد موافقة مدفوعاتك من خلال التحويل البنكي أو EFT، سيتم العملية. قد تستغرق عملية الموافقة",
      tax: "ضريبة القيمة المضافة (VAT):",
      verifyAmount: "المبلغ الذي سيتم سحبه من حسابك:",
    },
    tour: {
      prev: "السابق",
      next: "التالي",
      done: "انهاء الجولة",
      title: "مرحبًا بك في منصة الإعلان!",
      intro:
        "لتعلم كيفية استخدام المنصة، انقر فوق 'بدء' واستمر في جولة المنتج. يمكنك تخطي الجولة بالنقر فوق زر الخروج",
      menu: "شريط القائمة",
      menuText: "يمكنك استخدام شريط القائمة على اليسار للتبديل بين الصفحات.",
      campaigns: "الحملات",
      campaignsText:
        "يمكنك إدارة حملات الإعلانات الخاصة بك من صفحة 'الحملات'. هنا، يمكنك عرض عدد النقرات والمبيعات التي تحصل عليها من الإعلانات.",
      reports: "التقارير",
      reportsText:
        "يمكنك إنشاء تقارير تختار فيها أبعادًا مختلفة حول أداء إعلاناتك. باستخدام هذه التقارير، يمكنك عرض مرات ظهور إعلاناتك، وفي أي نتائج بحث أو فئات تم عرضها، أو كم عدد المبيعات التي تمت.",
      reportsTextSub:
        "عند إنشاء التقارير، يمكنك اختيار تقارير محددة مسبقًا أو اختيار تقارير مخصصة ستقوم بإنشائها بنفسك.",
      invoices: "الفواتير",
      invoicesText:
        "في هذا القسم، يمكنك عرض الأرصدة التي قمت بتحميلها لتنفيذ حملاتك الإعلانية، وقسائم الهدايا التي قد حصلت عليها، وإجمالي مصاريف الإعلانات.",
      academy: "الأكاديمية",
      academyText: "هنا يمكنك العثور على المعلومات اللازمة حول استخدام المنصة.",
      widgets: "إحصائيات",
      widgetsText:
        "يمكنك عرض أداء الحملات التي تقوم بإنشائها تدريجياً. يمكنك أيضًا فحص أداء إعلانك بالتفصيل على أساس يومي من خلال النقر فوق زر 'عرض في الرسم البياني'.",
      table: "جدول الحملات",
      tableText:
        "في هذا القسم، يمكنك عرض الحملات التي قمت بإنشائها وأدائها. يمكنك إيقاف الحملات الخاصة بك حسب رغبتك. لعرض تفاصيل الحملة، ما عليك سوى النقر فوق اسم الحملة.",
      button: "إضافة حملة جديدة",
      buttonText:
        "انقر على زر 'إضافة حملة جديدة' لبدء إنشاء أول حملة إعلانية لك.",
      modal: "نوع الحملة",
      modalText: "هنا يمكنك رؤية أنواع الحملات الإعلانية التي يمكنك اختيارها.",
      type: "منتج ممول",
      typeText:
        "يمكنك إنشاء حملة منتج ممول حيث يمكنك تسليط الضوء على منتجاتك في المراكز الأولى لنتائج البحث أو صفحات الفئات.",
    },
    widget: {
      title: "نطاق تاريخ المقاييس",
      description:
        "اختر تاريخ بدء وتاريخ انتهاء لعرض المقاييس الخاصة بك. النطاق الافتراضي هو آخر 30 يومًا ولا يمكن اختيار التواريخ المستقبلية.",
    },
    offsiteCampaignForm: {
      campaignInfoTitle: "معلومات الإعلان",
      adSummary: "راجع التحديدات في إعلانك.",
      info: "معلومات عامة",
      productName: "اسم المنتج والغلاف:",
      adName: "اسم الإعلان:",
      budget: "الميزانية:",
      noAdAccountErr: "لم يتم العثور على حساب إعلان ميتا.",
      generalErr: "حدث خطأ أثناء إنشاء الحملة.",
      firstStep: {
        budget: "الميزانية",
        budgetText:
          "حدد نوع ميزانية الحملة وقم بتعيين الميزانية. بمجرد أن يصل إنفاقك إلى الميزانية، ستتوقف الحملة تلقائيًا.",
        budgetLabel: "الميزانية اليومية",
        budgetError: "يرجى إدخال ميزانية صالحة.",
        urlTitle: "عنوان URL لإعادة التوجيه",
        urlText:
          "هذه هي الصفحة التي سيتم توجيه المستخدمين إليها عند النقر على إعلانك. تحتاج إلى توجيه المستخدمين إلى صفحة داخل السوق.",
        urlLabel: "عنوان URL لإعادة التوجيه",
        urlError: "يرجى إدخال عنوان URL صالح.",
        adPreview: "معاينة الإعلان",
        adPreviewText: "ستظهر تفاصيل إعلانك هنا.",
        pleaseSelectProduct: "يرجى اختيار منتج لمعاينة الإعلان.",
        productName: "اسم المنتج والغلاف",
        destination: "الوجهة",
        displayLink: "رابط العرض",
        primaryText: "النص الأساسي",
        primaryTextText: "أدخل النص الأساسي لإعلانك.",
        headline: "العنوان",
        headlineText: "أدخل عنوان إعلانك.",
        description: "الوصف",
        descriptionText: "أدخل وصف إعلانك.",
        cta: "دعوة لاتخاذ إجراء:",
        fixedCta: "تسوق الآن",
        editAd: "تحرير الإعلان",
        adName: "اسم الإعلان",
        adNameText: "أدخل اسم إعلانك.",
      },
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "إضافة حملة جديدة",
        placeholder: "البحث في الحملات",
        errorTitle: "لم يتم اختيار معلن",
        errorText: "الرجاء تحديد معلن.",
        table: {
          standart: "تلقائي",
          max_acos: "تلقائي",
          fixed: "ثابت",
          daily: "يوميًا",
          flexible: "يوميًا",
          total: "إجمالي",
          budgetLimit: "يقتصر على {{value}}",
          stopped: "لقد قمت بإيقاف الحملة.",
          product: "إعلان المنتج المموَّل",
          display: "عرض مموَّل",
          default: "العرض المفترض",
          checkUp: "عرض التدقيق",
          display_with_product: "إعلان صوري مع المنتج",
          video_with_product: "إعلان فيديو مع المنتج",
        },
      },
      campaignDetails: {
        subtext: "يمكنك تحرير معلومات الحملة.",
        products: "المنتجات",
        on: "نشط",
        keywords: "الكلمات الرئيسية",
        negativeKeywords: "الكلمات السلبية",
        targeting: "الجمهور",
        city: "المدينة",
        age: "العمر",
        gender: "الجنس",
        platform: "المنصة",
        customers: "العملاء",
        placements: "أماكن الإعلان",
        in_search: "نتائج البحث",
        in_category: "الفئات",
        placementPlaceholder: "البحث في أماكن الإعلان",
        date: "تاريخ البداية والنهاية:",
        bid: "العرض:",
        maxAcos: "أقصى ACoS %",
        fixed: "ثابت",
        standart: "تلقائي",
        daily: "يومي",
        total: "إجمالي",
        flexible: "يومي",
        none: "بدون",
        keywordsBtn: "إضافة كلمة مفتاحية",
        keywordsPlaceholder: "البحث عن كلمات مفتاحية",
        keywordsSub: "أضف الكلمات الرئيسية التي ترغب في عرض إعلانك بها.",
        negative_keywords_btn: "إضافة كلمة مفتاحية سلبية",
        negative_keywords_placeholder: "البحث في الكلمات الرئيسية السلبية",
        negative_keywords_modal_subtitle:
          "أضف الكلمات الرئيسية التي لا ترغب في عرض إعلانك بها.",
        negative_categories_btn: "إضافة فئة سلبية",
        negative_categories_placeholder: "البحث في الفئات السلبية",
        negative_categories_modal_subtitle:
          "أضف الفئات التي لا ترغب في عرض إعلانك بها.",
        productsBtn: "إضافة منتجات",
        productsPlaceholder: "البحث عن منتجات",
        productsSub: "حدد المنتجات التي ترغب في الإعلان عنها.",
        cities_placeholder: "البحث عن مدن",
        age_ranges_placeholder: "البحث عن نطاقات العمر",
        genders_placeholder: "البحث عن الأجناس",
        platforms_placeholder: "البحث عن المنصات",
        customers_placeholder: "البحث عن أنواع العملاء",
        new: "عملاء جدد",
        present: "عملاء حاليين",
        keywordTitle: "استهداف الكلمات الرئيسية",
        keywordText:
          "يتم استهداف الكلمات الرئيسية تلقائيًا لإعلانك. يمكنك إيقاف الكلمات الرئيسية التي لا ترغب في استهدافها.",
        maxAcosLabel: "الحد الأقصى لنسبة التكلفة للمبيعات (ACoS) %",
        fixedBidLabel: "العرض الثابت",
        productsError: "يجب أن يكون هناك ما لا يقل عن منتج واحد نشط.",
        keywordsError: "يجب أن يكون هناك ما لا يقل عن كلمة مفتاحية واحدة نشطة.",
        categoriesError: "يجب أن يكون هناك ما لا يقل عن فئة واحدة نشطة.",
        categories: "الفئات",
        categoriesBtn: "إضافة فئة",
        categoriesPlaceholder: "البحث في الفئات",
        categoriesSub: "يمكنك إضافة الفئات لاستهدافها.",
        negativeCategories: "الفئات السلبية",
        buyboxError: "المنتج ليس منتج الفوز بالBuyBox.",
        ratingError: "تقييم المنتج أقل من التقييم الأدنى.",
        stockError: "المنتج غير متوفر بالكمية الدنيا.",
        notActiveError: "المنتج غير نشط.",
        creatives: "الإعلانات المصورة",
        creativesBtn: "إضافة إعلان مصور",
        creativesPlaceholder: "البحث في الإعلانات المصورة",
        creativesSub: "أضف الإعلانات المصورة التي ترغب في عرضها في إعلانك.",
        creativesLinkTitle: "عنوان URL للتوجيه",
        creativesLinkText:
          "المستخدمون الذين ينقرون على إعلانك يتم توجيههم إلى {{value}}.",
        winner: "الفائز",
        loser: "الخاسر",
        creativesError: "يجب أن يكون هناك ما لا يقل عن إعلان مصور واحد نشط.",
        pendingError: "لا يمكن أن تكون الإعلانات المصورة قيد الانتظار نشطة.",
        rejectedError: "لا يمكن أن تكون الإعلانات المصورة مرفوضة نشطة.",
        in_product_detail_page: "صفحات تفاصيل المنتج",
        in_home_page: "الصفحة الرئيسية",
        in_collection: "مجموعة",
        allTimes: "جميع الأوقات",
        scheduled: "مجدولة",
        dayParting: "يمكنك اختيار الأيام والساعات التي ستظل فيها حملتك نشطة.",
        region: "المنطقة:",
        regionLength: "{{value}} مناطق",
        regionsDrawerTitle: "تحديث المناطق",
        languages: "{{value}} لغات",
        segments: "القطاعات",
        segments_placeholder: "ابحث عن القطاعات",
        categoryLimitError:
          "تم الوصول إلى الحد الأقصى. يمكنك إضافة ما يصل إلى 100 فئة. قم بإيقاف واحدة موجودة لإضافة فئة جديدة.",
        keywordLimitError:
          "تم الوصول إلى الحد الأقصى. يمكنك إضافة ما يصل إلى 100 كلمة مفتاحية. قم بإيقاف واحدة موجودة لإضافة كلمة مفتاحية جديدة.",
      },
      noMatch: {
        title: "عذرًا، لا يمكننا العثور على تلك الصفحة.",
        btn: "العودة إلى الصفحة الرئيسية",
      },
      reports: {
        button: "إضافة تقرير جديد",
        placeholder: "البحث في التقارير",
        name: "اسم التقرير",
        table: {
          type: "نوع التقرير",
        },
        form: {
          title: "تقرير 1",
          goBack: "العودة",
          create: "إنشاء التقرير",
        },
      },
      reportDetails: {
        result: "نتيجة التقرير",
        static: "ثابت",
        scheduled: "مجدول",
        ready: "نوع التقرير المحدد مسبقًا",
        startDate: "تاريخ البدء",
        endDate: "تاريخ الانتهاء",
        frequency: "التكرار",
        firstDate: "تاريخ البدء الأول",
        month: "اليوم الأول من الشهر",
        emails: "البريد الإلكتروني",
        export: "نوع الملف",
        predefined: "نوع التقرير المحدد مسبقًا",
        total: "احصل على الإحصائيات الإجمالية",
      },
      invoice: {
        balance: "الرصيد",
        coupon: "كوبون",
        lastPayment: "آخر رصيد محمل",
        searchPlaceholder: "البحث في الفواتير",
        duplicateCouponsError: "تم اكتشاف تعريف كوبون متعدد. انتظر من فضلك.",
        multipleTopUpsError: "تم اكتشاف تحميل رصيد متعدد. انتظر من فضلك.",
        limitError: "أنت تحاول إضافة رصيد بمبلغ كبير. يرجى التحقق من المبلغ.",
        data: {
          typeBudget: "دفع الرصيد",
          typeAd: "تكلفة الإعلان",
          typeBudgetExplanation: "تم تحميل رصيد الإعلان.",
          typeAdExplanation: "تم صرف تكلفة الإعلان.",
        },
        table: {
          type: "نوع العملية",
        },
        modal: {
          button: "إضافة رصيد",
          title: "إضافة رصيد",
          subtitle: "أدخل المبلغ الذي ترغب في تحويله إلى رصيد إعلانك.",
          loadingAmount: "المبلغ",
          differentAmount: "يرجى إدخال مبلغ.",
          label: "المبلغ",
          help: "إذا قمت بالمتابعة بالنقر فوق زر اختيار وسيلة الدفع، فإنك تعتبر أنك قد أكدت أن المبلغ الذي سيتم جمعه لن يتم استرداده.",
        },
      },
      academy: {
        more: "معرفة المزيد",
        copy: "نسخ الرابط",
        helpTitle: "لم تجد اجابات لأسئلتك؟",
        helpText:
          "بالنسبة لأسئلتك، يمكنك الوصول إلى فريق الدعم عن طريق إرسال بريد إلكتروني.",
        contact: "اتصل بنا",
        highlights: "عناصر مميزة",
        read: "اقرأ بقية المقالة",
        description:
          "مع مقالات مفيدة من {{value}} دليل الإعلان، يمكنك تعلم كل ما تحتاج إلى معرفته حول المنصة وبدء نشر إعلاناتك بسهولة.",
      },
      offsiteCampaigns: {
        button: "إضافة حملة جديدة",
        placeholder: "البحث في الحملات",
        errorTitle: "لم يتم اختيار معلن",
        errorText: "الرجاء تحديد معلن.",
        OUTCOME_TRAFFIC: "حركة المرور الناتجة",
        OUTCOME_ENGAGEMENT: "التفاعل الناتج",
        OUTCOME_SALES: "المبيعات الناتجة",
        OUTCOME_LEADS: "العملاء المتوقعين الناتجين",
        OUTCOME_AWARENESS: "الوعي الناتج",
        failedText:
          "فشلت الحملة. يرجى التحقق من تفاصيل الحملة وإنشاء حملة جديدة.",
      },
      offsiteDetails: {
        updateError: "حدث خطأ أثناء تحديث الحملة.",
        updateSuccess: "تم تحديث الحملة بنجاح.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext: "يمكنك تجميع الإعلانات عن طريق تعيينها للوكالات.",
        modalPlaceholder: "ابحث عن وكالة...",
        search: "ابحث في الإعلانات",
        select_advertiser_subtitle:
          "الرجاء اختيار الإعلان الذي تريد إنشاء حملة إعلانية له.",
        userSubtext: "يمكنك دعوة المستخدمين الذين سيستخدمون حساب الإعلان.",
        help: "أضف عنوان البريد الإلكتروني للمستخدم.",
        deleteSuccess: "تم إزالة الوكالة بنجاح.",
        drawerFilterWarning: "لا يمكن حذف الفلترات المضافة",
        create: "اضافة معلن جديد",
        update: "تحديث المعلن",
        type: "نوع المعلن",
        custom: "مخصص",
        customText:
          "إنه نوع المعلن الذي تم إنشاؤه باستخدام منتجات البائعين التي تم تعيينها لتكون قابلة للمشاركة داخل النظام الأساسي",
        name: "اسم المعلن",
        nameHelp: "اسم المعلن الخاص بك الذي سيتم عرضه",
        agency: "الوكالة",
        agencyHelp: "هي الوكالة التي يتبعها المعلن",
        email: "البريد الإلكتروني",
        emailHelp:
          "أدخل عنوان البريد الإلكتروني الذي سيتم إرسال الدعوة إليه. يستخدم البائع عنوان البريد الإلكتروني الذي أدخلته عند تسجيل الدخول إلى المنصة.",
        product: "اسم المنتج",
        brand: "ماركة",
        retailer_taxonomy: "فئة المنتج",
        advertiser_id: "المعلن ID",
        filters: "المرشحات",
        filterWarning: "الرجاء إضافة مرشح واحد على الأقل.",
        filterTitle: "فلتر",
        filterText: "حدد الفلتر الذي تريد تطبيقه على المعلنين.",
        keyLabel: "اختار الفلتر",
        opLabel: "اختر الحالة",
        valueLabel: "أدخل القيمة",
        categorySelectLabel: "اختار الفئة",
        filterKeyError: "الرجاء تحديد عامل التصفية.",
        filterOpError: "الرجاء اختيار الشرط.",
        filterValueError: "ادخل قيمه من فضلك.",
        filterCategoryError: "الرجاء اختيار الفئة",
      },
      advertiserSettings: {
        title: "إعدادات الإعلان",
        subtext: "الإعدادات المتعلقة بالمعلنين.",
        status: "حالة المعلن الافتراضية",
        statusText:
          "إذا كانت حالة المعلنين المضافين إلى المنصة هي مغلقة بشكل افتراضي، يجب عليك تنشيط الحالة على صفحة المعلنين حتى يتمكنوا من تسجيل الدخول إلى النظام.",
        welcome: "كوبون الترحيب",
        welcomeText:
          "يمكنك تحديد كوبون ترحيب يمكن للمعلن استخدامه عندما يأتون إلى المنصة لأول مرة.",
        warning: "إذا قمت بإدخال “0”، فلن يتم تحديد كوبون الترحيب للمعلنين.",
        label: "رصيد الكوبون",
        language: "اللغة",
        languageText:
          "هي اللغة الافتراضية لواجهة المستخدم للمعلنين. يمكن للمعلنين تغيير اختيار اللغة من خلال واجهتهم الخاصة.",
        balance: "قدرة تحميل الرصيد",
        balanceText:
          "هي قدرة المعلنين لنقل مدفوعاتهم التقدمية إلى أرصدهم الإعلانية عبر الواجهة.",
        open: "مفتوح",
        closed: "مغلق",
        error_title: "يجب عليك إدخال قيمة.",
        error_text:
          "إذا كنت لا تريد تحديد كوبون، يمكنك إدخال “0” والنقر على زر الحفظ.",
        error_text_input: "يجب عليك إدخال قيمة.",
      },
      agencies: {
        label: "اسم الوكالة",
        subtext: "أدخل اسم الوكالة",
        subtitle: "يمكنك إضافة وكالة لتجميع المعلنين",
        search: "البحث في الوكالات",
        edit: "تحديث اسم الوكالة",
      },
      auctionSettings: {
        title: "إعدادات المزاد",
        subtext: "إعدادات متعلقة بالمزاد",
        type: "نوع المزاد",
        typeText:
          "اختر نوع المزاد الذي سيتم تقييم مزايدات المعلنين من أجله. يمكنك العثور على معلومات مفصلة حول أنواع المزادات هنا.",
        warning: "ستؤثر التغييرات على إنفاق الإعلانات على المنصة.",
        firstOpt: "السعر الأول",
        firstOptText: "يدفع المعلنون الفائزون المزايدة المقدمة.",
        secondOpt: "السعر الثاني",
        secondOptText:
          "يدفع المعلنون الفائزون 1 سنتًا إضافيًا على أعلى مزايدة ثانية.",
        link: "أنواع المزادات",
      },
      campaignSettings: {
        title: "إعدادات الحملة",
        subtext: "إعدادات متعلقة بإعداد الحملة",
        conversionRange: "نطاق التحويل",
        conversionRangeText:
          "يمكنك تحديد الفترة الزمنية التي سيتم فيها نسبة نشاط الشراء للمستخدم إلى النقرة على الإعلان. عند تعيين نطاق التحويل إلى 7 أيام، إذا نقر المستخدم على الإعلان وقام بشراء المنتج خلال 7 أيام، سيتم صرف رصيد الحملة.",
        product: "تقييم المنتج",
        productText:
          "يمكنك إضافة متطلب تقييم الحد الأدنى للمنتجات التي سيقوم المعلنون بإضافتها إلى حملاتهم، وتخصيص السلوك للمنتجات التي لا تفي بهذا الشرط.",
        minimum: "الحد الأدنى لتقييم المنتج",
        minimumText:
          "أدخل الحد الأدنى لتقييم المنتجات التي سيتم إضافتها إلى الحملات.",
        behaviour: "تعيين السلوك",
        behaviourText:
          "أدخل الحد الأدنى لتقييم المنتجات التي سيتم إضافتها إلى الحملات.",
        placeholder: "اختر السلوك",
        stock: "مخزون المنتج",
        stockText:
          "يمكنك إضافة متطلب الحد الأدنى لمخزون المنتجات التي سيقوم المعلنون بإضافتها إلى حملاتهم، وتخصيص السلوك للمنتجات التي لا تفي بهذا الشرط.",
        minimumStock: "الحد الأدنى لمخزون المنتج",
        minimumStockText:
          "أدخل الحد الأدنى لمخزون المنتجات التي سيتم إضافتها إلى الحملات.",
        stockBehaviourText:
          "حدد السلوك الذي سيحدث عندما ينخفض عدد المنتجات دون الحد الأدنى من المخزون الذي حددته.",
        alert: "تحذير فقط",
        block: "تحذير ومنع عرض الإعلانات",
        targeting: "استهداف جمهوري",
        targetingText:
          "يمكنك إضافة متطلب الحد الأدنى لمخزون المنتجات التي سيقوم المعلنون بإضافتها إلى حملاتهم، وتخصيص السلوك للمنتجات التي لا تفي بهذا الشرط.",
        all: "جميع المعلنين",
        none: "لا معلن",
        selected: "المعلنون المحددين",
        format: "صيغ الإعلان",
        sponsored: "المنتج الممول",
        brand: "العلامة التجارية الممولة",
        ad: "الإعلان المرئي",
        rating_warn:
          "تقييم منتجك منخفض جدًا! إضافة هذا المنتج إلى حملتك سيقلل من الأداء.",
        rating_block:
          "تقييم المنتج منخفض جدًا! لا يمكنك إضافة هذا المنتج إلى حملتك!",
        stock_warn:
          "مخزونك منخفض جدًا! إضافة هذا المنتج إلى حملتك سيقلل من الأداء.",
        stock_block: "مخزونك منخفض جدًا! لا يمكنك إضافة هذا المنتج إلى حملتك!",
        buybox_warn:
          "المنتج ليس فائزًا بصندوق الشراء. إضافة هذا المنتج إلى حملتك سيقلل من الأداء.",
        buybox_block:
          "المنتج ليس فائزًا بصندوق الشراء. لا يمكنك إضافة هذا المنتج إلى حملتك!",
        buyBoxTitle: "شرط فوز صندوق الشراء",
        buyBoxText:
          "يمكنك إضافة شرط فوز صندوق الشراء للمنتجات التي سيقوم المعلنون بإضافتها إلى حملاتهم.",
        buyBoxSubtext:
          "حدد السلوك الذي سيحدث عندما لا تكون المنتجات المراد إضافتها إلى الحملات فائزة بصندوق الشراء.",
        noWorning: "بدون",
        warn: "ستؤثر التغييرات على جميع الحملات النشطة.",
        rangeError: "يجب أن تدخل قيمة. القيمة الموصى بها هي 7 أيام.",
        rangeErrorInput: "يمكن إدخال قيمة فقط بين 1 و 14 يومًا.",
        chooseBehaviour: "يجب عليك اختيار سلوك.",
        stockError: "يجب أن تدخل مخزونًا أدنى.",
        allStockError: "يجب أن تدخل مخزونًا أدنى وتختار سلوكًا.",
        ratingError: "يجب أن تدخل تقييمًا أدنى.",
        allRatingError: "يجب أن تدخل تقييمًا أدنى وتختار سلوكًا.",
        serverBlock: "تحذير ومنع عرض الإعلانات",
        advertisers: "المعلنين المحددين",
        advertisersText:
          "سيتم توفير استهداف جمهوري فقط للمعلنين الذين تختارهم.",
        advertisersBtn: "إضافة معلن",
        advertisersSub: "اختر المعلنين الذين يمكنهم استخدام استهداف جمهوري.",
        advertisersPlaceholder: "اختر معلن",
        advertisersLength: "تم تحديد {{value}} معلنًا.",
        advertisersTitle: "المعلنين",
        general: "عام",
        bid: "قيود العرض",
        conversion: "التحويل",
        conversionSubtext: "إعدادات تتعلق بأهداف التحويل على المنصة",
        biddingTypes: "أنواع المزايدة المتاحة للمنتج الممول",
        biddingTypesText:
          "في خطوة إنشاء الحملة، يمكنك تحديد أنواع المزايدات التي يمكن للمعلنين استخدامها.",
        biddingTypesWarning: "يجب أن يبقى على الأقل نوع واحد متاحًا.",
        auto: "مزايدة تلقائية",
        autoText:
          "نوع مزايدة يتم فيه تحديد المزايدة تلقائيًا. يتم حساب الإنفاق باستخدام سعر المنتج المباع والنسبة المستهدفة للتكلفة (ACoS) التي قمت بتعيينها.",
        acos: "نسبة الهدف ACoS",
        acosText:
          "يتم حساب المزايدة تلقائيًا بناءً على نسبة ACoS التي يدخلها المعلنون وسعر المنتجات المباعة.",
        fixed: "مزايدة ثابتة",
        fixedText:
          "تحديد المزايدة من قبل المعلن. يمكن للمعلنين تعيين مزايدة ثابتة للحملة بشكل عام لكل كلمة مفتاحية وفئة.",
        bidWarning: "يجب اختيار نوع واحد على الأقل من المزايدة.",
        bidText: "إعدادات لقواعد المزايدة عبر المنصة بشكل عام",
        bidLabelTitle: "الحد الأدنى الشامل للتكلفة للنقرة CPC",
        bidLabelText:
          "الحد الأدنى للمبلغ المدفوع للنقرة الواحدة يجب أن يتم إدخاله لجميع الفئات والكلمات الرئيسية.",
        bidLabel: "الحد الأدنى للتكلفة للنقرة CPC",
        cpmTitle: "الحد الأدنى الشامل للتكلفة لكل ألف مشاهدة CPM",
        cpmText:
          "الحد الأدنى للمبلغ المدفوع لكل ألف مشاهدة يجب أن يتم إدخاله لجميع الفئات والكلمات الرئيسية.",
        cpmLabel: "الحد الأدنى للتكلفة لكل ألف مشاهدة CPM",
        targetAcosTitle: "النسبة المستهدفة لـ ACoS العامة",
        targetAcosText:
          "يمكنك تعيين النسبة القصوى لـ ACoS المستهدفة افتراضيًا في المزاادات.",
        targetAcosLabel: "الحد الأقصى لـ ACoS",
        categoryRelevanceTitle: " العام للفئةالrelevance",
        categoryRelevanceText:
          "يحدد مستوى الصلة الذي يجب أن يكون بين المنتجات الممولة والمنتجات المدرجة عضويًا في انطباعات الإعلانات.",
        categoryRelevanceLabel: " للفئةالrelevance",
        bidTabWarning:
          "ستؤثر التغييرات التي تم إجراؤها على الإنفاق الإعلاني والانطباعات على المنصة.",
        inputErrorZero: "يجب عليك إدخال قيمة أكبر من الصفر.",
        inputErrorAcos: "يجب عليك إدخال عدد صحيح بين 0 و 100.",
        inputErrorRelevance: "يجب عليك إدخال عدد صحيح أكبر من الصفر.",
        inputError: "يجب عليك إدخال قيمة.",
        productLink: "قيود تقييم المنتج",
        stockLink: "قيود مخزون المنتج",
        targetingLink: "ميزة استهداف جمهوري",
        bidLink: "أنواع المزايدة",
        minThreshold: "الحد الأدنى للسعر",
        minThresholdText:
          "هذا هو الحد الأدنى للسعر المطلوب لإدراج المنتجات في المزاد.",
        minThresholdLabel: "الحد الأدنى للسعر",
        minThresholdError: "يرجى إدخال عدد صحيح.",
        formatTitle: "إعدادات التتبع حسب التنسيق",
        formatText:
          "يتم قياس نقاط الاتصال لتحديد فعاليتها عبر تنسيقات الحملات المختلفة. بناءً على التنسيق، يتتبع تحديد مرات الظهور الرؤية، بينما تركز النقرات على قياس التحويلات من خلال التفاعل المباشر.",
        sponsoredProductTitle: "منتج برعاية",
        sponsoredProductText:
          "بالنسبة لإعلانات المنتجات، يتم تقييم التحويلات بناءً على مدى تفاعل المستخدمين مع الإعلان، مع التركيز على ما إذا كان التفاعل أو التعرض يحقق الهدف المطلوب.",
        sponsoredDisplayTitle: "عرض برعاية",
        sponsoredDisplayText:
          "تقيس إعلانات العرض التحويلات من خلال تحليل ما إذا كانت الرؤية وحدها تؤثر على تصرفات المستخدمين أو إذا كانت هناك حاجة إلى تفاعل أعمق لتحقيق النجاح.",
        sponsoredVideoTitle: "فيديو برعاية",
        sponsoredVideoText:
          "بالنسبة لإعلانات الفيديو، يتمحور قياس التحويلات حول تحديد ما إذا كان مجرد مشاهدة المحتوى أو التفاعل النشط يسهم بشكل أكبر في تحقيق الهدف.",
        postView: "بعد العرض",
        postClick: "بعد النقر",
        reportTitle: "إعدادات التحويل حسب التقارير",
        reportText:
          "يحدد هذا الخيار كيفية تطبيق التحويل لأغراض إعداد التقارير، مما يشكل طريقة تنظيم البيانات وعرضها بناءً على النهج المحدد.",
        marketplaceReport: "فصل المقاييس في تقارير السوق",
        marketplaceReportText:
          "تمكين هذا الخيار يقسم المقاييس في تقارير السوق بناءً على التحويل، مما يوفر رؤى أكثر وضوحًا حول أداء كل نقطة اتصال.",
        advertiserReport: "فصل المقاييس في تقارير المعلنين",
        advertiserReportText:
          "تشغيل هذا الخيار يفصل المقاييس في تقارير المعلنين بناءً على التحويل، مما يساعدك على فهم تأثير تفاعلات الحملات المختلفة بشكل أفضل.",
        widgetTitle: "إعدادات التحويل للأداة",
        widgetText:
          "تحليل التحويل للأداة يقيم تفاعلات المستخدمين طوال رحلة التحويل. اختيار تتبع مرات الظهور يلتقط وضوح الإعلان، بينما تتبع النقرات يقيس التفاعل كمؤشر على التحويل.",
      },
      console: {
        add: "إضافة مصدر بيانات جديد",
        advertiser: "المعلن",
        product: "المنتج",
        google_feed_url_scheduler: "Google Feed URL",
        google_feed_file: "تحميل ملف Google Feed (XML)",
        google_feed_body: "Google Feed XML المباشر",
        meta_feed_url_scheduler: "رابط Meta Feed",
        minutes_15: "15 دقيقة",
        minutes_30: "30 دقيقة",
        hour_1: "1 ساعة",
        hours_2: "2 ساعة",
        hours_3: "3 ساعات",
        hours_4: "4 ساعات",
        hours_6: "6 ساعات",
        hours_12: "12 ساعة",
        hours_24: "24 ساعة",
        history: "عرض السجل",
        logs: "عرض الـ Logs",
        drawer: "عرض الـ Log",
        error: "خطأ",
        warning: "تحذير",
        success: "نجاح",
        info: "معلومة",
        prev: "الـ 50 Log السابقة",
        next: "الـ 50 Log التالية",
        api: "API",
        impression: "الانطباع",
        click: "النقرات",
        sale: "المبيعات",
        test: "اختبار",
        curlTest: "عرض الاختبار",
        curlWarning: "هام: تجنب تنفيذ هذه العملية في بيئة الإنتاج!",
        request: "طلب",
        run: "تشغيل",
        response: "استجابة",
        hide: "إخفاء الخام",
        show: "عرض الخام",
        auctionLogs: "سجلات المزاد",
      },
      coupons: {
        add: "تعريف الكوبون",
        title: "معلومات المعلن",
        subtext:
          "يمكنك تحديد الكوبونات التي يمكن للمعلنين استخدامها لإنفاق الحملات.",
        label: "تاريخ الانتهاء",
        warning:
          "يجب أن يقوم المعلنون باستبدال الكوبون بحلول التاريخ المدخل. إذا لم تقم بإدخال تاريخ انتهاء، سيتم إضافة الكوبون إلى رصيد المعلنين بشكل دائم، ويمكن للمعلنين استخدام الكوبون في أي وقت يرغبون.",
        gift_coupon: "كوبون هدية",
        welcome_coupon: "كوبون ترحيب",
        gift_coupon_desc: "تم تعريف كوبون الهدية.",
        welcome_coupon_desc: "تم تعريف كوبون الترحيب.",
        approve: "هل ترغب في متابعة تعريف الكوبون؟",
        note: "وصف الكوبون",
        noteHelp: "اشمل سبب تعريف الكوبون. مثال: كوبون الجمعة السوداء",
      },
      createDatasource: {
        title: "إضافة مصدر بيانات",
        save: "حفظ وتشغيل",
        existsErr: "مصدر بيانات بهذا URL موجود بالفعل. يرجى استخدام URL مختلف.",
        feedErr:
          "URL الخلاصة لا يمكن أن يكون فارغًا لهذا النوع من التكامل. يرجى تقديم URL صالح.",
        cycleErr:
          "فاصل الدورة لا يمكن أن يكون فارغًا لهذا النوع من التكامل. يرجى تقديم فاصل زمني صالح.",
        permissionErr:
          "غير قادر على الوصول إلى URL المقدم. يرجى التحقق من URL أو الأذونات.",
        generalErr: "حدث خطأ.",
        validateSuccess: "التحقق ناجح",
        validateSuccessText:
          "تم تعيين جميع الحقول المطلوبة بشكل صحيح. جاهز للمتابعة.",
      },
      creatives: {
        all: "كل شيء",
        accepteds: "تم قبولها",
        rejecteds: "تم رفضها",
        pendings: "قيد الانتظار",
        pending: "قيد الانتظار",
        accepted: "تم قبوله",
        rejected: "تم رفضه",
        adModalTitle: "معاينة الإعلان",
        creativeModalTitle: "معاينة الصورة",
        creative: "صورة",
        name: "اسم الصورة",
        search: "البحث في الصور",
        rejectSubtitle: "يمكنك إدخال سبب رفض الإبداع أو تركه فارغًا والمتابعة.",
        note: "أدخل سبب رفض الإبداع.",
      },
      datasourceDetails: {
        entity: "الكيان:",
        import: "نوع الاستيراد:",
        cycle: "دورة الفاصل الزمني:",
        url: "Feed URL:",
        success: "نجاح",
        failure: "فشل",
        in_progress: "قيد التنفيذ",
        partial_success: "نجاح جزئي",
      },
      generalInfo: {
        title: "معلومات عامة",
        subtext: "معلومات عامة حول السوق",
        market: "اسم السوق",
        marketText:
          "اسم المتجر الذي يظهر على الواجهة. يجب عليك التواصل مع مدير الحساب الخاص بك لتغيير هذه المعلومات.",
        url: "الرابط",
        urlText:
          "إنها رابط المتجر الذي سيتم استخدامه في الواجهة وللتوجيه. يجب عليك التواصل مع مدير الحساب الخاص بك لتغيير هذه المعلومات.",
        country: "البلد",
        countryText:
          "البلد الذي سيتم استخدامه في استهداف المدينة. يجب عليك التواصل مع مدير الحساب الخاص بك لتغيير هذه المعلومات.",
        zone: "المنطقة الزمنية",
        zoneText:
          "إنها المنطقة الزمنية الافتراضية التي ستستخدم في الواجهات. يجب عليك التواصل مع مدير الحساب الخاص بك لتغيير هذه المعلومات.",
        currency: "العملة",
        currencyText:
          "العملة المستخدمة في الواجهات. لا يمكن تغيير هذه المعلومات.",
        zoneIstanbul: "أوروبا/اسطنبول",
        email: "عنوان البريد الإلكتروني للدعم",
        emailText: "إنه عنوان البريد الإلكتروني الذي سيتصل به بائعوك.",
        emailLabel: "البريد الإلكتروني",
        emailError: "يجب عليك إدخال عنوان بريد إلكتروني.",
        seller: "لوحة البائع",
        sellerText:
          "إنه العنوان الذي سيتيح لبائعيك التوجيه مباشرةً إلى لوحتك عبر الواجهة.",
        sellerLabel: "لوحة البائع",
        marketError: "يجب عليك إدخال اسم السوق.",
        urlError: "يجب عليك إدخال عنوان URL.",
      },
      inspect: {
        campaign: "الحملة:",
        advertiser: "المعلن:",
        url: "الرابط:",
        size: "الحجم:",
        status: "الحالة:",
        keywords: "الكلمات الرئيسية",
        categories: "الفئات",
        languages: "اللغة:",
        regions: "المناطق:",
      },
      integrationInfo: {
        title: "معلومات الاندماج",
        subtext: "المعلومات المطلوبة من اجل نقل البيانات",
        api: "رمز السري لواجهة برمجة التطبيقات",
        apiText:
          "هو الرمز السري المطلوب للوصول إلى المنصة عبر واجهة برمجة التطبيقات.",
        ftp: "شهادة أمان FTP",
        ftpText: "هو الشهادة المطلوبة للوصول إلى خادم FTP.",
        download: "تحميل",
        copy: "انقر للنسخ.",
        copied: "تم النسخ",
        auto: "الانطباع التلقائي",
        autoText:
          "إعداد يسمح بتسجيل جميع الانطباعات تلقائيًا دون أي اندماج حدثي. عند تشغيل الإعداد، يتم تسجيل مقياس العرض لكل منتج تم إرجاعه في استجابة الإعلان بافتراض أنه تم عرض جميع المنتجات التي تم إرجاعها على موقعك. عند إيقاف الإعداد، يجب إرسال طلب حدث لكل عرض وفقًا للتنسيق المرغوب.",
        link: "حدث الانطباع",
        apiLink: "API Endpoints",
      },
      transactions: {
        subtext:
          "يمكنك تحديد الأرصدة للمعلنين لاستخدامها في إنفاق حملاتهم الإعلانية.",
        warning: "يمكنك إضافة رقم الفاتورة.",
        balance_updated: "دفع الرصيد",
        gift_coupon: "كوبون هدية",
        welcome_coupon: "كوبون ترحيب",
        refund: "إعادة",
        spend: "تكلفة الإعلان",
        ad_balance_loaded_desc: "تم تحميل رصيد الإعلان.",
        spend_desc: "تكلفة الإعلان مصروفة.",
        gift_coupon_desc: "تم كسب كوبون هدية.",
        welcome_coupon_desc: "تم كسب كوبون ترحيب.",
        ad_balance_refunded_desc: "تم إعادة رصيد الإعلان.",
        credit_card_desc: "تم تحميل الرصيد من خلال بطاقة الائتمان.",
        detailsSubtext: "يمكنك عرض تفاصيل حول إنفاقك الإعلاني.",
        spendTab: "تكاليف الإعلان",
        budgetTab: "موارد الميزانية",
        advertiser_budget: "الرصيد",
        approve: "هل ترغب في الاستمرار في إضافة رصيد؟",
        advertiser: "المعلن:",
        balance: "الرصيد المراد تحميله:",
        update: "يمكنك إضافة أو تحرير رقم الفاتورة.",
        title: "معلومات الفاتورة",
        payment: "الدفع",
        success: "نجاح",
        waiting: "انتظار",
        failure: "فشل",
        invoiceWarning: "رقم الفاتورة يجب ان يكون 30 حرف كحد اقصى",
        note: "ملاحظة تحميل الرصيد",
        noteHelp: "اشمل سبب تحميل هذا الرصيد. مثال: رصيد شهر أغسطس",
        coupon_expired: "انتهت صلاحية القسيمة",
        coupon_expired_desc: "انتهت صلاحية القسيمة.",
        risk: "هذا الإجراء عالي المخاطر!",
        riskDesc:
          "قد يؤثر استرداد الرصيد على الإنفاق اليومي للمعلن وقد يؤدي إلى رصيد سلبي. قبل المتابعة، تأكد من إيقاف جميع حملات المعلن.",
        refundTitle: "هذا الإجراء لا رجعة فيه!",
        refundDescription:
          "سيتم خصم المبلغ المحدد فورًا من رصيد المعلن. قد يؤدي استرداد الرصيد دون التحقق من إنفاق المعلن اليومي إلى رصيد سلبي، مما قد يسبب مشاكل محتملة.",
        selectAdvertiserHelp: "اختر المعلن الذي سيستلم الاسترداد.",
        refundAmount: "المبلغ",
        verifyRefundAmount: "تحقق من المبلغ",
        refundAmountHelp:
          "حدد المبلغ الدقيق للاسترداد. توخَ الحذر، لأن هذا الإجراء لا يمكن التراجع عنه.",
        verifyRefundAmountHelp: "تحقق من مبلغ الاسترداد",
        refundNote: "ملاحظات",
        refundNoteHelp:
          "يتيح لك هذا الحقل إضافة ملاحظة حول سبب إصدار الاسترداد. قد يكون ذلك مفيدًا لأغراض التتبع وتوضيح سبب الاسترداد في سجلاتك.",
        amountToRefund: "المبلغ المراد استرداده:",
        check1: "أؤكد أن المعلن لم ينفق أي رصيد خلال الـ 24 ساعة الماضية.",
        check2:
          "لقد تحققت من أن جميع حملات المعلن قد تم إيقافها لمدة لا تقل عن 24 ساعة.",
        check3: "أفهم أن هذا الاسترداد سيتم تنفيذه فورًا ولا يمكن التراجع عنه.",
        check4:
          "أقر بأنه إذا أدى هذا الاسترداد إلى رصيد سلبي، فأنا أتحمل المسؤولية الكاملة عن العواقب.",
        check5:
          "أؤكد أن لدي التفويض اللازم لمعالجة هذا الاسترداد نيابة عن المعلن.",
        refundSuccessTitle: "تمت معالجة الاسترداد بنجاح!",
        refundSuccessText:
          "تم خصم الرصيد بنجاح من حساب المعلن. إذا لاحظت أي مشكلات، يرجى التحقق من نشاط حساب المعلن أو الاتصال بالدعم.",
        refundErrorTitle: "فشل الاسترداد",
        refundErrorText:
          "تعذر معالجة الاسترداد في هذا الوقت. قد يكون ذلك بسبب مشكلة غير متوقعة.",
        negativeRefundErrorTitle: "فشل الاسترداد",
        negativeRefundErrorText:
          "مبلغ الاسترداد يتجاوز رصيدك المتاح. يرجى إدخال مبلغ يساوي أو يقل عن رصيدك الحالي.",
      },
      users: {
        label: "البريد الإلكتروني",
        subtext: "سيتم إرسال رسالة تأكيد عبر البريد الإلكتروني للدعوة.",
        invite: "أدخل عنوان البريد الإلكتروني الذي سيتم إرسال الدعوة إليه.",
        warn: "لا يمكنك إيقاف حسابك الخاص.",
        error: "لقد قمت بإضافة عنوان بريد إلكتروني موجود بالفعل.",
        search: "البحث في المستخدمين",
        select: "حدد نوع المستخدم الذي ترغب في إضافته.",
        advertiser: "معلن",
        advertiserText:
          "يمكن للمعلن الوحيد الذي تختاره الوصول، وهو الشخص الذي سيقوم بتنفيذ أنشطة الإعلان.",
        marketplace: "مدير",
        marketplaceText:
          "لديه جميع الحقوق في منصة الإعلانات، يمكنه منح الحقوق أو إدارة الحقوق للمستخدمين.",
        limited: "وصول مقيد",
        full: "الوصول الكامل",
      },
      invited: {
        error:
          "يمكن إرسال دعوة إلى نفس البريد الإلكتروني للحساب نفسه كحد أقصى كل 10 دقائق.",
        success: "تم إعادة دعوة المستخدم بنجاح.",
        search: "البحث في المستخدمين المدعوين",
        title: "تفاصيل الدعوة",
        info: "معلومات عامة",
        mail: "البريد الإلكتروني:",
        advertiser: "معلن:",
        urlTitle: "رابط الدعوة",
        csvInfo:
          "يمكنك دعوة المستخدمين بشكل جماعي إلى حسابات معلنين مختلفة باستخدام ملف CSV. يمكنك الوصول إلى معلومات حول تنسيق ملف CSV باستخدام الرابط أدناه.",
        archived: "لا يمكن إرسال الدعوات إلى المعلنين المؤرشفين",
      },
      sync: {
        file: "ملف",
        api: "API",
        feed: "Feed",
        products_created: "تم إنشاء المنتجات.",
        products_updated: "تم تحديث المنتجات.",
        products_deactivated: "تم تعطيل المنتجات.",
        products_activated: "تم تنشيط المنتجات.",
        missing_advertisers: "المعلنون مفقودون.",
        advertisers_created: "تم إنشاء المعلنين.",
        advertisers_updated: "تم تحديث المعلنين.",
        advertisers_deactivated: "تم تعطيل المعلنين.",
        advertisers_activated: "تم تنشيط المعلنين.",
        categories_created: "تم إنشاء الفئات.",
      },
      placement: {
        add: "إضافة مكان إعلان جديد",
        display: "عرض",
        product: "منتج مُموَّل",
        update: "تحديث مكان الإعلان",
        settings: "الإعدادات العامة",
        settingsText: "الإعدادات العامة لموقع الإعلان الخاص بك",
        name: "اسم مكان الإعلان",
        nameText: "هذا الاسم مرئي فقط لك.",
        format: "شكل الإعلان",
        formatText: "الشكل الذي سيتم عرض مكان الإعلان الخاص بك فيه",
        sponsoredText: "عرض المنتج في الكلمات الرئيسية والفئات ذات الصلة",
        displayText: "عرض إعلان بصري في الكلمات الرئيسية والفئات ذات الصلة",
        suggestedTitle: "الأحجام الموصى بها",
        suggestedText:
          "الحجم الفعلي لموقع الإعلان الخاص بك. سيتم عرض هذا الحجم من قبل المعلنين.",
        suggestedError: "يمكنك إضافة حتى حجم واحد فقط.",
        acceptedTitle: "الأحجام المقبولة",
        acceptedText:
          "أحجام الإعلانات الأخرى التي يمكن أن تظهر داخل موقع الإعلان الخاص بك. ستتم مشاركة الأبعاد من قبل المعلنين.",
        acceptedError: "يرجى اختيار حجم إعلان.",
        formatError: "الرجاء اختيار تنسيق.",
        cross_sell: "Cross-Sell",
        crossText:
          "عرض الإعلانات على صفحات تفاصيل المنتج ذات الصلة وعرض المنتجات المدرجة كإعلانات يتم منعها.",
        maxAds: "العدد الأقصى للإعلانات",
        maxAdsText: "العدد الأقصى للإعلانات التي يمكن أن يحتويها الموقع.",
        crossTitle: "سلوك حظر الإعلان",
        crossSub:
          "الحجم الفعلي لموقع الإعلان الخاص بك. سيتم عرض هذا الحجم من قبل المعلنين.",
        crossPlaceholder: "اختر نوعًا.",
        crossProduct: "منع المنتج المدرج",
        crossBlock: "حظر الإعلان",
        crossError: "اختر سلوكًا.",
        adPlacementType: "نوع موضع الإعلان",
        adPlacementTypeText:
          "حدد نوع الصفحة أو الموضع حيث سيتم عرض إعلانك. سيتم استخدام هذا الاختيار أيضًا في مرحلة استهداف موضع الإعلان للحملات التي يتم إنشاؤها.",
        typeError: "الرجاء تحديد نوع الصفحة",
        adPlacementTypePlaceholder: "اختر نوع الصفحة",
        search: "نتائج البحث",
        category: "صفحات الفئة",
        homepage: "الصفحة الرئيسية",
        detail: "صفحات تفاصيل المنتج",
        collection: "مجموعة",
        minAds: "الحد الأدنى لعدد الإعلانات",
        minAdsText:
          "الحد الأدنى لعدد الإعلانات التي يمكن أن يحتوي عليها الموقع.",
        minBid: "الحد الأدنى للعطاء",
        minBidText: "هذا هو الحد الأدنى لقيمة العطاء لهذا الموقع.",
        videoWarning:
          "الحد الأقصى لعدد الإعلانات للإعلانات الفيديو هو <strong>“1”</strong>.",
        promoteText: "عند تفعيل ترويج المنتج، يمكنك إضافة إعلان واحد فقط.",
        displayWithProduct: "إعلان صوري مع المنتج",
        videoWithProduct: "إعلان فيديو مع المنتج",
        display_with_product: "إعلان صوري مع المنتج",
        video_with_product: "إعلان فيديو مع المنتج",
        archive_confirmation_text:
          "أرشفة {{name}} ستزيله من القائمة النشطة. سيتم استبعاده من عرض الإعلانات.",
        archive_checkbox_text: "أنا أفهم وأرغب في المتابعة.",
      },
      restrictions: {
        category: "الفئة",
        keyword: "الكلمة الرئيسية",
        categoryTitle: "المزايدة وACoS بناءً على الفئة",
        categoryText:
          "يمكنك إدخال مزايدة دنيا ومعدل عائد الإعلان (ACoS) المستهدف والتي ستكون سارية المفعول فقط في الفئات التي تحددها، وتخصيص الفئة ذات الصلة. تأخذ الإعدادات التي تقوم بها في هذا المجال الأولوية على الإعدادات العامة.",
        keywordTitle: "قائمة الكلمات الرئيسية المحظورة",
        keywordText:
          "يمكنك تقييد عرض الإعلانات على الكلمات الرئيسية التي تحددها. في النتائج المحدودة بنوع الاستثناء، يتم الإعلان فقط عن المنتجات التي تحتوي على هذه الكلمة. لا تُعرض الإعلانات في النتائج المحدودة بنوع الحظر.",
        placeholder: "البحث عن الكلمات الرئيسية",
        add: "إضافة كلمة رئيسية جديدة",
        info: "معلومات عامة",
        label: "الكلمة الرئيسية",
        help: "الكلمة الرئيسية التي ترغب في تقييد نتائج البحث لها",
        type: "نوع التقييد",
        typeText:
          "نوع التقييد الذي سيتم تطبيقه على نتائج البحث باستخدام كلمتك الرئيسية",
        exclusive: "حصري",
        exclusiveText:
          "في نتائج البحث، يتم الإعلان عن المنتجات فقط التي تحتوي على كلمتك الرئيسية.",
        block: "حظر",
        blockText: "يتم حظر جميع الإعلانات في نتائج البحث.",
        all: "عرض الكل",
        edited: "عرض الفئات المعدلة",
        expand: "توسيع الكل",
        collapse: "طي الكل",
      },
      offsite: {
        connect: "ربط حساب ميتا",
        connectText:
          "قم بربط حساب ميتا الخاص بك لبدء إنشاء وإدارة إعلاناتك الخارجية بكل سهولة.",
        loginWithFacebook: "تسجيل الدخول باستخدام فيسبوك",
        success: "تم الاتصال بنجاح بـ Meta",
        reauthenticate: "إعادة المصادقة",
        revoke: "إلغاء الوصول",
        adAccount: "حساب الإعلانات",
        adAccountText:
          "تتطلب حملات فيسبوك حساب إعلانات. الكائنات مثل وحدات تتبع البيكسل والجماهير المخصصة مرتبطة بهذا الحساب. هذا أحد أصول الحساب، لذا سيكون جزءًا من إعدادات الحساب.",
        adAccountPlaceholder: "اختر حساب الإعلانات",
        identity: "الهوية",
        identityText:
          "يجب على المستخدمين تحديد حساب فيسبوك أو إنستاجرام لتمثيل أعمالهم في الإعلانات. يمنع ذلك تعارض الهوية ويُطبق على مستوى الحساب.",
        facebookPlaceholder: "اختر صفحة فيسبوك",
        instagramPlaceholder: "اختر حساب إنستاجرام",
        pixel: "بيكسل Meta",
        pixelText:
          "قبل إطلاق حملة مبيعات، من الضروري دمج بيكسل Meta أو SDK. تأكد من تتبع الأحداث الصحيحة وأنه تم إعداده بحدث التحويل الصحيح. تحقق دائمًا من إعداد بيكسل العميل، حتى عند استخدام أحداث Meta القياسية.",
        pixelPlaceholder: "اختر بيكسل",
        pendingTitle: "جاري ربط حساب Meta",
        pendingText:
          "يتم حاليًا ربط حساب Meta الخاص بك. قد تستغرق هذه العملية بعض الوقت اعتمادًا على إعدادات الحساب وخطوات الاتصال.",
        attribution: "إعدادات الإسناد",
        attributionText:
          "نافذة الإسناد هي عدد الأيام بين مشاهدة المستخدم للإعلان أو النقر عليه واتخاذ إجراء. نظرًا لأن إعدادًا واحدًا يُستخدم عادةً عبر الحملات، فمن المنطقي أن يكون هذا على مستوى الحساب.",
        none: "لا شيء",
        oneDay: "يوم واحد",
        sevenDays: "7 أيام",
        clickThrough: "النقر-عبر",
        viewThrough: "المشاهدة-عبر",
        engagedView: "المشاهدة المتفاعلة",
        clickThroughPlaceholder: "اختر النقر-عبر",
        viewThroughPlaceholder: "اختر المشاهدة-عبر",
        engagedViewPlaceholder: "اختر المشاهدة المتفاعلة",
        revokeSuccess: "تم إلغاء الوصول إلى Meta بنجاح",
        location: "موقع السوق",
        locationText: "حدد موقع العمل",
        locationPlaceholder: "اختر الموقع",
        multipleAccounts:
          "تم العثور على حسابات أعمال متعددة. يُرجى تحديد حساب أعمال واحد فقط.",
      },
    },
    auth: {
      title: "منصة الإعلانات {{value}}",
      accounts: {
        title: "حدد حساب",
        text: "حدد الحساب الذي تريد تسجيل الدخول إليه.",
        logout: "اخرج",
        noAccount: "هذا الحساب غير مفعّل!",
        noAccountText:
          "يبدو أنك غير متصل بأي حسابات مفعّلة. للحصول على المساعدة، يرجى الضغط على زر المساعدة أدناه.",
        noAccountTextNoEmail:
          "يبدو أنك غير متصل بأي حسابات نشطة. للحصول على المساعدة، يرجى الاتصال بمدير حسابك.",
        contact: "تواصل معنا",
        login: "العودة الى الصفحة الرئيسية",
      },
      login: {
        title: "تسجيل الدخول",
        text: "مرحبًا مرة أخرى! يرجى إدخال معلوماتك.",
        remember: "تذكرني",
        forget: "نسيت كلمة المرور؟",
        button: "تسجيل الدخول",
        mail: "البريد الإلكتروني",
        password: "كلمة المرور",
        mailErr: "يرجى إدخال بريدك الإلكتروني.",
        passwordErr: "يرجى إدخال كلمة المرور الخاصة بك.",
        incorrectPassword: "كلمة المرور غير صحيحة!",
        incorrectPasswordText: "يرجى المحاولة مرة أخرى.",
        recaptcha: "خطأ في التحقق من reCAPTCHA.",
        recaptchaText: "يرجى المحاولة مرة أخرى.",
        notActive: "لقد تم تعليق حسابك",
        notActiveText: "الرجاء التواصل مع فريق الدعم",
        errTitle: "كلمة المرور غير صحيحة!",
        errText: "يرجى المحاولة مرة أخرى.",
        notHaveAnAccount: "ليس لديك حساب موجود؟",
        signup: "اشترك الان",
        linkButton: "إرسال رابط تسجيل الدخول",
        success: "تم إرسال رابط تسجيل الدخول إلى بريدك الإلكتروني.",
        successText:
          "انقر على الرابط في البريد الإلكتروني الذي استلمته لتسجيل الدخول إلى حسابك.",
        error: "حدث خطأ أثناء تسجيل الدخول.",
        errorText: "يرجى المحاولة مرة أخرى.",
        invalidEmail: "عنوان البريد الإلكتروني غير صالح.",
        invalidEmailText: "يرجى إدخال عنوان بريد إلكتروني صالح.",
        userNotFound: "لم يتم العثور على مستخدم بهذا البريد الإلكتروني.",
        userNotFoundText:
          "يرجى التحقق من عنوان بريدك الإلكتروني والمحاولة مرة أخرى.",
        verificationError: "لم نستطع التحقق من طلبك. يرجى المحاولة مرة أخرى.",
      },
      signUp: {
        title: "التسجيل",
        text: "مرحبًا، {{value}}",
        password: "كلمة المرور",
        passwordErr: "الرجاء إدخال كلمة المرور.",
        warning:
          "أدخل كلمة مرور تتألف من 8 أحرف على الأقل، تتضمن أرقامًا وعلامات ترقيم وأحرف كبيرة وأحرف صغيرة.",
        already: "هل لديك حساب موجود؟",
        successBtn: "تسجيل الدخول",
        success: "تم التسجيل بنجاح!",
        successText:
          "تم التسجيل بنجاح باستخدام البريد الإلكتروني {{value}}. انقر فوق زر تسجيل الدخول لمتابعة الوصول إلى لوحة التحكم.",
        help: "كيف يمكننا مساعدتك؟",
        err: "الرجاء إدخال كلمة مرور صالحة.",
        privacyErr:
          "لإكمال عملية الاشتراك، يرجى قبول جميع الاتفاقيات المطلوبة.",
        error: "لم يتم العثور على الدعوة.",
        errorText:
          "تحتاج إلى دعوة للاشتراك. يرجى الاتصال بمدير حسابك عبر {{value}}.",
        errorTextNoEmail: "تحتاج إلى دعوة للاشتراك. يرجى الاتصال بمدير حسابك",
        errorBtn: "العودة الى الصفحة الرئيسية",
      },
      password: {
        title: "هل نسيت كلمة المرور؟",
        text: "سنرسل لك رابطًا لإعادة تعيين كلمة المرور. الرجاء إدخال عنوان البريد الإلكتروني الذي تستخدمه على المنصة.",
        mail: "البريد الإلكتروني",
        mailInputErr: "الرجاء إدخال عنوان بريد إلكتروني.",
        reset: "إعادة تعيين كلمة المرور",
        return: "الانتقال إلى صفحة تسجيل الدخول",
        error:
          "الرجاء إدخال عنوان البريد الإلكتروني الذي ترغب في إعادة تعيين كلمة مروره.",
        recaptchaError:
          "فشل محاولة استعادة كلمة المرور لأسباب أمنية، يرجى تحديث الصفحة والمحاولة مرة أخرى.",
        check: "تحقق من بريدك الإلكتروني",
        message: "لقد قمنا بإرسال رابط إعادة تعيين كلمة المرور إلى {{value}}",
      },
      newPassword: {
        title: "تعيين كلمة مرور جديدة",
        text: "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور القديمة التي استخدمتها.",
        help: "الرجاء إدخال كلمة مرور تحتوي على ما لا يقل عن 8 أحرف بما في ذلك الأرقام والعلامات الترقيمية والأحرف الكبيرة والصغيرة.",
        password: "كلمة المرور",
        passwordInputErr: "الرجاء إدخال كلمة المرور.",
        again: "تأكيد كلمة المرور",
        check: "تمت إعادة تعيين كلمة المرور بنجاح",
        message:
          "يمكنك تسجيل الدخول باستخدام كلمة المرور الجديدة التي قمت بتعيينها.",
        errTitle: "الرجاء إدخال كلمة مرور صالحة.",
      },
      welcome: {
        title: "أهلا بك!",
        loginText:
          "إذا كان لديك حساب، يمكنك إدخال المعلومات الخاصة بك عن طريق النقر على زر تسجيل الدخول.",
        loginBtn: "تسجيل الدخول",
        signupText:
          "إذا لم يكن لديك حساب بعد، يمكنك النقر فوق الزر أدناه للاشتراك وتعيين كلمة المرور الخاصة بك ثم تسجيل الدخول إلى حسابك.",
        signupBtn: "اشترك الان",
      },
    },
  },
  common: {
    save: "حفظ",
    selectAll: "اختيار كل شيء",
    search: "بحث",
    edit: "تحرير",
    export: "تصدير",
    remove: "إزالة",
    add: "إضافة",
    column: "عمود",
    archive: "أرشفة",
    removeArchive: "إلغاء الأرشفة",
    delete: "حذف",
    filter: "تصفية",
    continue: "متابعة",
    cancel: "إلغاء",
    cancellation: "إلغاء",
    okay: "موافق",
    create: "إنشاء",
    goBack: "العودة",
    listed: "مُدرجة",
    archived: "مُؤرشفة",
    invited: "المدعوين",
    exact: "تطابق دقيق",
    phrase: "تطابق العبارة",
    broad: "تطابق عام",
    turkey: "تركيا",
    abd: "الولايات المتحدة الأمريكية",
    try: "الليرة التركية",
    usd: "الدولار الأمريكي",
    iqd: "دينار العراق",
    zone: "أوروبا/إسطنبول",
    clear: "مسح",
    clearAll: "مسح الكل",
    completed: "تم",
    notCompleted: "لم يتم",
    now: "الآن",
    checkAll: "تحديد الكل",
    keyword: "الكلمة الرئيسية",
    negative: "الكلمة السلبية",
    tr: "اللغة التركية",
    en: "اللغة الإنجليزية",
    day: "يوم",
    invite: "دعوة",
    accept: "قبول",
    reject: "رفض",
    pageTotal: "عدد الصفوف المعروضة:",
    addUser: "إضافة مستخدم",
    reinvite: "إعادة الدعوة",
    advertiser: "معلن",
    admin: "مدير",
    new_customer: "زبائن جدد",
    existing_customer: "زبائن حاليين",
    category_buyer: "مشترون في الفئة",
    invite_url: "عرض رابط الدعوة",
    or: "أو",
    exit: "خروج",
    docs: "تحقق من توثيق واجهة برمجة التطبيقات (API) للحصول على معلومات حول التكامل وتقديم الإعلانات!",
    apply: "تطبيق",
    reset: "إعادة ضبط",
    read: "وضع علامة كمقروء",
    unread: "وضع علامة كغير مقروء",
    view: "عرض",
    onAndOff: "تشغيل/إيقاف",
    days: {
      monday: "الاثنين",
      tuesday: "الثلاثاء",
      wednesday: "الأربعاء",
      thursday: "الخميس",
      friday: "الجمعة",
      saturday: "السبت",
      sunday: "الأحد",
    },
    languages: {
      arabic: "العربية",
      english: "الإنجليزية",
    },
    table: {
      name: "الاسم",
      status: "نشط",
      state: "الحالة",
      agency_name: "وكالة",
      type: "نوع",
      resource_id: "معرف البائع",
      last_login: "تاريخ آخر تسجيل دخول",
      balance: "الرصيد",
      sum_coupons_amount: "الكوبون",
      total_balance: "الرصيد الإجمالي",
      impressions: "الانطباع",
      viewable_impressions: "انطباع قابل للعرض",
      clicks: "النقر",
      CTR: "CTR",
      spend: "المصروف",
      sale_amount: "مبلغ المبيعات",
      direct_sale_amount: "مبلغ المبيعات المباشرة",
      indirect_sale_amount: "مبلغ المبيعات غير المباشرة",
      sale: "المبيعات",
      direct_sale: "المبيعات المباشرة",
      indirect_sale: "المبيعات غير المباشرة",
      ACoS: "ACoS",
      CVR: "CVR",
      campaign_name: "الاسم",
      advertiser_name: "المعلن",
      ad_type: "نوع الإعلان",
      start_date: "تاريخ البدء",
      end_date: "تاريخ الانتهاء",
      budget_type: "الميزانية",
      bid_type: "نوع المزاد",
      fixed_bid: "المزاد",
      custom_key: "ID",
      ad_format: "شكل الإعلان",
      max_ads: "الحد الأقصى لعدد الإعلانات",
      recommended_size: "الحجم الموصى به",
      sizes: "الأحجام",
      image_url: "صورة",
      policy_status: "الحالة",
      transaction_date: "التاريخ",
      invoice_number: "رقم الفاتورة",
      transaction_type: "نوع العملية",
      description: "الوصف",
      created_by: "المنشئ",
      amount: "المبلغ",
      spend_details: "التفاصيل",
      note: "ملحوظة",
      expire_date: "تاريخ الانتهاء",
      account_user_status: "نشط",
      user_email: "البريد الإلكتروني",
      account_user_role: "الدور",
      user_last_login: "تاريخ آخر تسجيل دخول",
      account_user_created_at: "تاريخ الإنشاء",
      email: "البريد الإلكتروني",
      account_role: "الدور",
      created_at: "تاريخ الدعوة",
      report_name: "الاسم",
      report_start_date: "تاريخ البدء",
      report_end_date: "تاريخ الانتهاء",
      report_type: "نوع التقرير",
      report_delivery_frequency: "التكرار",
      report_created_at: "تاريخ الإنشاء",
      text: "الاسم",
      minimum_bid: "أدنى سعر للنقرة (CPC)",
      minimum_cpm: "Min. CPM",
      relevance: "الصلة",
      restriction_type: "نوع القيد",
      row_count: "عدد الصفوف",
      message: "رسالة العملية",
      sync_type: "نوع المزامنة",
      file_name: "اسم الملف",
      product: "المنتج",
      sku: "SKU",
      buybox: "Buybox",
      city: "المدن",
      age_range: "العمر",
      gender: "الجنس",
      platform: "المنصات",
      customer: "الزبائن",
      category: "الفئات",
      keyword: "الكلمات",
      match_type: "نوع التطابق",
      bid: "المزاد",
      category_volume: "حجم الانطباع",
      active: "نشط",
      paused: "متوقف مؤقتًا",
      invited: "تمت الدعوة",
      uninvited: "لم يتمت الدعوة",
      accepted: "تمت الموافقة",
      archived: "مؤرشف",
      on: "نشط",
      stopped: "متوقف",
      open: "مفتوح",
      closed: "مغلق",
      coupon: "الكوبون",
      sync_log_created_at: "التاريخ",
      category_text: "الفئات",
      keyword_text: "الكلمات",
      creative_name: "الاسم",
      invoice: "رقم الفاتورة",
      acos: "ACoS",
      sum_click: "النقرات",
      sum_spend: "الإنفاق",
      sum_impression: "عدد المشاهدات",
      sum_viewable_impression: "انطباع قابل للعرض",
      sum_sale: "المبيعات",
      sum_direct_sale: "المبيعات المباشرة",
      sum_indirect_sale: "المبيعات غير المباشرة",
      sum_direct_sale_amount: "مبلغ المبيعات المباشرة",
      sum_indirect_sale_amount: "مبلغ المبيعات غير المباشرة",
      sum_sale_amount: "مبلغ المبيعات",
      invite_status: "حالة الدعوة",
      spending_type: "توزيع الإنفاق",
      min_ads: "الحد الأدنى لعدد الإعلانات",
      min_bid: "الحد الأدنى للعطاء",
      languages: "اللغة",
      video_start: "بداية الفيديو",
      video_complete: "اكتمال الفيديو",
      VCR: "VCR",
      entity: "الكيان",
      import_method: "طريقة الاستيراد",
      schedule_interval: "الدورة",
      finish_date: "تاريخ الانتهاء",
      id: "ID",
      uuid: "UUID",
      updated_by: "تم التحديث بواسطة",
      objective: "الهدف",
      daily_budget: "الميزانية اليومية",
      reach: "الوصول",
      frequency: "التردد",
      link_click: "نقرة الرابط",
      meta_created_at: "تاريخ الإنشاء",
      ACTIVE: "نشط",
      PAUSED: "متوقف مؤقتًا",
      DELETED: "محذوف",
      ARCHIVED: "مؤرشف",
      FAILED: "فشل",
      PENDING: "قيد الانتظار",
    },
    equality: {
      contains: "يحتوي على",
      not_contains: "لا يحتوي على",
      equal: "مساوي",
      not_equal: "غير مساوي",
      less_than: "أقل من",
      greater_than: "أكبر من",
      greater_or: "أكبر أو مساوي لـ",
      less_or: "أقل أو مساوي لـ",
      any_of: "أي منها",
      none_of: "ولا واحدة منها",
    },
    filters: {
      campaignName: "اسم الحملة",
      campaignStartDate: "تاريخ البدء للحملة",
      campaignEndDate: "تاريخ الانتهاء للحملة",
      campaignStatus: "نشط",
      campaignState: "الحالة",
      campaignBudget: "الميزانية للحملة",
      campaignBudgetType: "نوع الميزانية للحملة",
      campaignBidType: "نوع المزاد للحملة",
      campaignMaxAcos: "أقصى ACoS",
      campaignFixedBid: "عرض ثابت للحملة",
      campaignAdType: "نوع الإعلان",
      sumSale: "المبيعات الإجمالية",
      sumSpend: "الإنفاق",
      ACoS: "ACoS",
      productName: "المنتج",
      productCampaignStatus: "حالة الحملة للمنتج",
      sumClick: "النقرات الإجمالية",
      CVR: "نسبة التحويل (CVR)",
      keywordCampaignStatus: "حالة الكلمة الرئيسية",
      keywordText: "كلمة رئيسية",
      keywordMatchType: "نوع التطابق للكلمة الرئيسية",
      negativeKeywordCampaignStatus: "حالة الكلمة السلبية",
      negativeKeywordText: "كلمة سلبية",
      negativeKeywordMatchType: "نوع التطابق للكلمة السلبية",
      cityName: "المدينة",
      cityCampaignStatus: "حالة المدينة",
      ageRangeText: "العمر",
      ageRangeCampaignStatus: "حالة العمر",
      genderText: "الجنس",
      genderCampaignStatus: "حالة الجنس",
      platformText: "المنصة",
      platformCampaignStatus: "حالة المنصة",
      customerTargetingText: "العميل",
      customerTargetingCampaignStatus: "حالة العميل",
      placementText: "مكان الإعلان",
      placementCampaignStatus: "حالة مكان الإعلان",
      reportName: "اسم التقرير",
      reportStartDate: "تاريخ البدء للتقرير",
      reportEndDate: "تاريخ الانتهاء للتقرير",
      reportType: "نوع التقرير",
      reportDeliveryFrequency: "تكرار التقرير",
      reportCreatedAt: "تاريخ الإنشاء للتقرير",
      agencyName: "اسم الوكالة",
      advertiserName: "المعلن",
      advertiserStatus: "حالة المعلن",
      sumSaleAmount: "مبلغ المبيعات",
      sumDirectSale: "المبيعات المباشرة",
      sumIndirectSale: "المبيعات غير المباشرة",
      sumDirectSaleAmount: "مبلغ المبيعات المباشرة",
      sumIndirectSaleAmount: "مبلغ المبيعات غير المباشرة",
      sumImpression: "عدد المشاهدات",
      productBrand: "علامة المنتج",
      CTR: "نسبة النقر (CTR)",
      categoryName: "الفئة",
      advertiserId: "ID المعلن",
      agencyId: "ID الوكالة",
      transaction_type: "نوع العملية",
      advertiser_name: "المعلن",
      created_by: "المنشئ",
      campaignDailyBudget: "الميزانية اليومية",
      campaignTotalBudget: "الميزانية الإجمالية",
      campaignId: "ID الحملة",
    },
    equalityValues: {
      ct: "يحتوي",
      nc: "لا يحتوي",
      eq: "مساوي",
      ne: "غير مساوي",
      in: "أي منها",
      ni: "ولا واحدة منها",
      notin: "ولا واحدة منها",
      lt: "أقل من",
      gt: "أكبر من",
      ge: "أكبر من أو مساوي لـ",
      le: "أقل من أو مساوي لـ",
    },
    values: {
      ACTIVE: "فعال",
      PAUSED: "متوقف",
      DAILY: "يومي",
      TOTAL: "إجمالي",
      STANDART: "تلقائي",
      FIXED: "ثابت",
      MAX_ACOS: "أقصى ACoS",
      WEEKLY: "أسبوعي",
      MONTHLY: "شهري",
      EXACT: "مطابق",
      PHRASE: "عبارة",
      BROAD: "شامل",
      BALANCE_UPDATED: "دفع الرصيد",
      GIFT_COUPON: "كوبون هدية",
      WELCOME_COUPON: "كوبون ترحيب",
      SPEND: "تكلفة الإعلان",
      NOT_STARTED: "الحملة لم تبدأ بعد.",
      DATE_PASSED: "انتهت الحملة عن موعدها المحدد.",
      STATUS_PAUSED: "لقد قمت بإيقاف الحملة مؤقتًا.",
      OUT_OF_DAILY_BUDGET: "تم استنفاذ ميزانية حملتك اليومية",
      OUT_OF_TOTAL_BUDGET: "لقد وصلت حملتك إلى الحد الاجمالي للميزانية.",
      PRODUCTS_STOPPED: "المنتجات غير نشطة.",
      KEYWORDS_STOPPED: "الكلمات الرئيسية غير نشطة.",
      CATEGORIES_STOPPED: "الفئات غير نشطة.",
      INSUFFICIENT_BALANCE: "رصيدك غير كافٍ.",
      LOW_BID: "العرض أقل من الحد الأدنى للعطاء.",
      STATUS_ARCHIVED: "لقد قمت بأرشفة الحملة.",
      CREATIVES_STOPPED: "لا توجد إعلانات نشطة.",
      ADVERTISER_PAUSED: "المعلن غير نشط.",
      REGIONS_STOPPED: "المناطق غير نشطة.",
      PRODUCT: "منتج ممول",
      BRAND: "علامة تجارية ممولة",
      DISPLAY: "إعلان صوري",
      STORE: "متجر ممول",
      VIDEO: "إعلانات الفيديو",
      DISPLAY_WITH_PRODUCT: "إعلان صوري مع المنتج",
      VIDEO_WITH_PRODUCT: "إعلان فيديو مع المنتج",
    },
    month: {
      may: "مايو",
      june: "يونيو",
      september: "سبتمبر",
      october: "أكتوبر",
      december: "ديسمبر",
    },
  },
};

export default ar;
