import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SMOContext, SMOContextType } from "context/SMOProvider";
import { retailAdminRouteObject } from "./RetailAdminRouteObject";
import Menu from "antd/lib/menu";
import SubMenu from "antd/lib/menu/SubMenu";
import RetailText from "components/Typography/RetailText";

import cc from "classcat";
import cm from "../style.module.scss";
import useConfigSchema from "hooks/useConfigSchema";

const RetailAdminSider = ({
  isOffsiteAdsEnabled,
}: {
  isOffsiteAdsEnabled: boolean;
}) => {
  const { t } = useTranslation();

  const { showDocs } = useContext(SMOContext) as SMOContextType;

  const [selectedKey, setSelectedKey] = useState<string>("/admin/campaigns");

  const [openKeys, setOpenKeys] = useState<string>("");

  const location = useLocation();

  const navigate = useNavigate();

  const submenuItemsCheck = (route: any) =>
    route.children
      ?.map((child: any) => child.link)
      .toString()!
      .includes(selectedKey);

  useEffect(() => {
    let newKey = location.pathname;

    if (location.pathname.includes("/report/")) newKey = "/admin/reports";
    else if (
      location.pathname.includes("campaign") &&
      !location.pathname.includes("offsite")
    )
      newKey = "/admin/campaigns";
    else if (location.pathname.includes("console")) newKey = "/admin/console";
    else if (location.pathname.includes("offsite-campaign"))
      newKey = "/admin/offsite-campaigns";

    if (newKey !== selectedKey) setSelectedKey(newKey);
  }, [location, selectedKey]);

  const regex = /\d/.test(decodeURI(location.pathname));

  const handleOpenKeys = (item: any) => {
    if (openKeys === item.key) setOpenKeys("");
    else setOpenKeys(item.key);
  };

  const clearOpenKeys = () => setOpenKeys("");

  const handleDetailsPage = () => {
    navigate(selectedKey);
    clearOpenKeys();
  };

  const { data: configDatas } = useConfigSchema(true);

  const metabaseDashboardLink = configDatas?.data?.configs?.find(
    (c: any) => c.config_schema_name === "metabase_dashboard_link"
  )?.value;

  const filteredAdminRoutes = retailAdminRouteObject(
    isOffsiteAdsEnabled
  ).filter((route) => {
    if (route.key === "insights") {
      return metabaseDashboardLink && metabaseDashboardLink !== "";
    }
    return true;
  });

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[selectedKey]}
      selectedKeys={[selectedKey]}
      openKeys={[openKeys]}
      theme="light"
      className={cc([cm.menu, showDocs ? cm.justDocs : ""])}
    >
      {filteredAdminRoutes.map(
        (route: {
          link?: string;
          key: string;
          iconFilled: JSX.Element;
          iconOutlined: JSX.Element;
          sub?: boolean;
          breadcrumb?: boolean;
          children?: { key: string; link: string }[];
        }) =>
          route.sub ? (
            <SubMenu
              title={
                <>
                  {t(`sider.admin.${route.key}`)}
                  {route.key === "dev" && (
                    <RetailText size="xxxs" weight="bold" className={cm.beta}>
                      BETA
                    </RetailText>
                  )}
                </>
              }
              key={route.key}
              className={cc([
                cm.subMenu,
                cm.admin,
                route.key === "dev" ? cm.dev : "",
              ])}
              icon={
                submenuItemsCheck(route) ? route.iconFilled : route.iconOutlined
              }
              onTitleClick={handleOpenKeys}
            >
              {route.children?.map((child: any) => (
                <Menu.Item
                  key={child.link}
                  className={cc([
                    cm.listItem,
                    selectedKey === child.link ? cm.selected : "",
                  ])}
                >
                  <Link
                    to={child.link}
                    state={{
                      breadcrumb: route.breadcrumb,
                      breadcrumbType: route.key,
                      value: child.key,
                    }}
                  >
                    {t(`sider.admin.${child.key}`)}
                  </Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={route.link}
              className={cc([
                cm.listItem,
                cm.admin,
                selectedKey === route.link ? cm.selected : "",
              ])}
              onClick={
                regex && route.link === selectedKey
                  ? handleDetailsPage
                  : clearOpenKeys
              }
            >
              <Link to={route.link!}>
                {selectedKey === route.link
                  ? route.iconFilled
                  : route.iconOutlined}
                <span>{t(`sider.admin.${route.key}`)}</span>
              </Link>
            </Menu.Item>
          )
      )}
    </Menu>
  );
};

export default RetailAdminSider;
