import Sider from "antd/lib/layout/Sider";
import RetailAdminSider from "./RetailAdminSider";
import RetailUserSider from "./RetailUserSider";

import cc from "classcat";
import cm from "./style.module.scss";

export interface RetailSiderProps {
  adminSider: boolean;
  isOffsiteAdsEnabled: boolean;
}

const RetailSider = ({ adminSider, isOffsiteAdsEnabled }: RetailSiderProps) => (
  <Sider
    className={cc([cm.sider, adminSider ? cm.adminSider : cm.userSider])}
    width={adminSider ? 230 : 80}
  >
    {adminSider ? (
      <RetailAdminSider isOffsiteAdsEnabled={isOffsiteAdsEnabled} />
    ) : (
      <RetailUserSider isOffsiteAdsEnabled={isOffsiteAdsEnabled} />
    )}
  </Sider>
);

export default RetailSider;
